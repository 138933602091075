import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {
  /* This pipe transform a date to a format Number day and Month in Spanish
  * @param value:Date - the date to transform
  */
  transform(value: Date): string {
    let month = '';
    switch (value.getMonth()) {
      case 0:
        month = 'ENERO';
      break;
      case 1:
        month = 'FEBRERO';
      break;
      case 2:
        month = 'MARZO';
      break;
      case 3:
        month = 'ABRIL';
      break;
      case 4:
        month = 'MAYO';
      break;
      case 5:
        month = 'JUNIO';
      break;
      case 6:
        month = 'JULIO';
      break;
      case 7:
        month = 'AGOSTO';
      break;
      case 8:
        month = 'SEPTIEMBRE';
      break;
      case 9:
        month = 'OCTUBRE';
      break;
      case 10:
        month = 'NOVIEMBRE';
      break;
      case 11:
        month = 'DICIEMBRE';
      break;
      default:
        break;
    }
    return value.getDate() + " DE " + month;
  }

}
