// Class to implement attributes of a publication type
export class PublicationType {
    guid: string;
    description: string;

    constructor(data: any) {
        this.guid = data.type_publication_guid;
        this.description = data.description;
    }

}
