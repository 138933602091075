<div class="main-container-card page-style">
    <div class="pt-3 container animated fadeIn px-4">
    <header class="pt-2 text-left">
      <span class="new-post-title">
          Nueva publicación
      </span>
    </header>
  <section>
  <div class="pt-3">
      <form autocomplete="off" [formGroup]="formb"  (ngSubmit)="send()">
        <div class="form-group">
            <label for="post" class="text-label text-left"> Comienza una publicación:</label>
            <small *ngIf="noValid" class="text-danger animated fadeIn float-right"><i class="fa fa-exclamation-triangle fa-lg"></i></small>
            <textarea   class="form-control input-rounded"
                        id="post"
                        rows="6"
                        aria-label="Large"
                        formControlName="post"
                        [class.is-invalid]="noValid"
                        placeholder="¿Sobre que quieres hablar?"></textarea>
                        <div class="text-label text-right">
                          <p  class="mt-2" *ngIf="countChars(formFields.post.value) == 0"> Máx 1500 caracteres</p>
                          <p class="mt-2" *ngIf="countChars(formFields.post.value) >= 1 && countChars(formFields.post.value) < 1500"> {{1500 - countChars(formFields.post.value) }} caracteres faltantes.</p>
                          <p class="mt-2" *ngIf="countChars(formFields.post.value) == 1500"> Máximo de caracteres alcanzado.</p>
                      </div>
            <div class="mb-5 pb-5 mx-1">
              <input  type="file"
                      multiple
                      (change)="detectFiles($event.target.files)"
                      #fileInput
                      name="filesUpload"
                      formControlName="filesUpload"
                      style="display: none;"
                      maxlength="1500"
                      >
              <button type="button" class="mt-2 btn-lg btn btn-block btn_style btn-style-add-files" (click)="fileInput.click()">
                  <span class="add-pic-videos-txt text-center"><i class="fa fa-file-image-o fa-lg text-warning" aria-hidden="true"></i> &nbsp;  Agregar fotos o video</span>
              </button>
              <ul>
                  <li *ngFor="let file of this.fileList.uploadingFiles" class="mt-2 inlin-block">
                    <i (click)="deleteFileItem(file)" class="fa fa-times-circle delete fa-xl" aria-hidden="true"></i>
                    <img *ngIf="file.isImage()" [src]="file.getResult()" [height]="height" [width]="width" [class]="styles">
                    <video *ngIf="file.isVideo()" [src]="file.getResult()" [height]="height" [width]="width" [class]="styles"></video>
                </li>
              </ul>
            </div>
          </div><!--form-group-->
        <button *ngIf="show" type="submit"
                [disabled]="isLoading ? true : null"
                class="mt-4 btn-lg btn btn-block btn-warning btn_style">
            <span class="btn_style_text text-center"> Publicar </span>
        </button>
        <br>
  </form>
</div>
  <a class="mt-3 text-center nav-link backText" [routerLink]="['/app/inicio']">
    <svg *ngIf="show" width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 6V8L4 8L8 12L7 14L0 7L7 0L8 2L4 6L16 6Z" fill="#7C7C7C"/>
    </svg>
    &nbsp;
    <span *ngIf="show" style="text-decoration:underline;"> Regresar</span></a>
  </section>
  </div>
  </div>

