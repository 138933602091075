
export enum Education {
    "Primaria" = 0,
    "Secundaria" = 1,
    "Bachillerato" = 2,
    "Licenciatura" = 3,
    "Posgrado" = 4,
    "Tecnológico Nacional de México" = 5
}

export const SchoolType = [
    { id: true, description: 'Privada'},
    { id: false, description: 'Pública'}
];

export const SpecifyPublic = [
    { id: true, description: 'Tecnológico Nacional de México'},
    { id: false, description: 'Otra'}
];



export namespace EducationType {

    export function values() {
        return Object.keys(Education).filter(k => isNaN(Number(k)) );
    }

}
