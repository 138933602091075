import { Component, OnInit } from '@angular/core';
import { Notification } from 'src/app/models/notification/notification';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { SnackbarService } from 'src/app/services/notifications/snackbar.service';
import { AuthService } from 'src/app/services/auth.service';
import { ChatService } from 'src/app/services/chat/chat.service';
import { Subscription } from 'rxjs';
import { FirebaseService } from 'src/app/services/firebase-service/firebase.service';

//import { Notifications } from 'src/app/models/notification/notifications';


@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  // property to identify if there is a petition in progress
  isLoading = false;

  subscriptions: Subscription[]= [];

  // list of notifications
  //notifications: Notifications = new Notifications();
  notificationsSeen: Notification[] = [];
  notificationsUnseen: Notification[] = [];

  constructor(
    private notificationsService: NotificationService,
    private snackbarService: SnackbarService,
    private authenticationService: AuthService,
    private chatService : ChatService,
    private firebase: FirebaseService
  ) { }

  ngOnInit(): void {

    this.getNotifications();
    this.firebase.logEvent('pageNotifications');

    let newNotificationSubscription = new Subscription();
    newNotificationSubscription.add(
        this.chatService.onEvent('actionpublication')
        .subscribe(notificationResponse => {

          this.chatService.raisefriendRequestChanged();

           let notification = new Notification();

            notification.id = notificationResponse.publication_guid;//'4',
            notification.contactId = notificationResponse.from_user;//'1234',
            notification.contactName = notificationResponse.from_user_name;//'Cynthia Romero',
            notification.contactPicture = notificationResponse.from_user_image;
            notification.publicationId = notificationResponse.publication_guid;//'8635d783-f616-4439-864b-f291c918a609',
            notification.date = notificationResponse.created_date;//'2020-07-28T01:30:51.685761Z',
            notification.seen = notificationResponse.viewed;
            notification.type = notificationResponse.publication_action;//'Comentar'
            notification.description = notification.generateDescription();
            notification.daysPassed = notification.diffDays(notification.date);


          this.notificationsUnseen.push(notification);

        }));

    this.subscriptions.push(newNotificationSubscription);

    let deleteNotificationSubscription = new Subscription();
    newNotificationSubscription.add(
      this.chatService.onEvent('deleteactionpublication')
      .subscribe(msg => {
          this.removeItem(this.notificationsSeen, msg);
          this.removeItem(this.notificationsUnseen, msg);
      })
    );

    this.subscriptions.push(deleteNotificationSubscription);
  }
  //

  /**
   *  This method search for a publication in the list and the delete from the list if was found
   * @param notificationList :Notification[] - array of notifications  Notification[]
   * @param notification instance of notification
   */
  removeItem(notificationList, notification) : void{
        let removeFile = notificationList.findIndex( item =>
              item.contactId == notification.from_user&&
              item.type == notification.publication_action &&
              item.publicationId == notification.publication_guid );

            if(removeFile >= 0){
              notificationList.splice(removeFile, 1);
            }

  }

  // Get the list of notifications
  getNotifications() {
    this.isLoading = true;
    this.chatService.raisefriendRequestChanged();

    this.notificationsSeen = [];
    this.notificationsUnseen = [];
    let user = this.authenticationService.getDecodeTokenInfo();
    this.notificationsService
      .getAll(user.user_id,'Publication')
      .then((response) => {

        if (response && response.length>0)
        {

          const notifications = response.map(notification => new Notification(notification));

          this.notificationsSeen = notifications.filter(item=> item.seen);
          this.notificationsUnseen = notifications.filter(item=> !item.seen);

          this.authenticationService.contacts()
            .subscribe((resp:any) => {
               if (resp && resp.length>0){
                 resp.forEach(contact => {
                   this.setUserStatus(this.notificationsSeen, contact);
                   this.setUserStatus(this.notificationsUnseen, contact);
                 });
               }
              });
        }
        }).catch(error => this.snackbarService.showSnackBar('cerrar', error))
          .finally(() => this.isLoading = false);
  }

  /**
   * This method allow to assing the value of contact status in a list
   * @param notifications : NOtification[], list of notifications of the user
   * @param contact, data of the contact and status of the user
   */
  setUserStatus(notifications:Notification[], contact:any):void{
    if (notifications && notifications.length >0){
      let filterNOtifications =notifications.filter( n=> n.contactId === contact.contact);
      if (filterNOtifications && filterNOtifications.length >0){
        filterNOtifications.forEach(n => {
          n.contactStatus = contact.status || 'Offline';
        });
      }
    }
  }

  /**
   *  Group the notifications in two groups, seen or unseen
   * @param array - array to implement the group by functionality
   */
  groupByType(array): Notification[] {
    return array.reduce((accumulator, actualObject) => {
      accumulator[actualObject.seen] = accumulator[actualObject.seen] || [];
      accumulator[actualObject.seen].push(actualObject);
      return accumulator;
    }, Object.create(null));
  }

  ngOnDestroy(){
    this.subscriptions.forEach(
      subscription => subscription.unsubscribe() );
  }

}

