import { Injectable } from '@angular/core'
import { Subject } from 'rxjs';

@Injectable()
export class ModalGenericService {

  // Observable string sources
  private emitChangeSource = new Subject<Object>();
  // Observable string streams
  changeEmitted$ = this.emitChangeSource.asObservable();
  // Service message commands
  emitChange(change: Object) {
    this.emitChangeSource.next(change);
  }

}


