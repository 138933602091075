<div class="row box cursor__pointer" [routerLink]="['app','publicacion', publication.guid]">
  <div class="col-12">
    <p class="username">
      {{publication.company}}
    </p>
    <p class="createdAt">
      {{publication.date}}
    </p>
    <div appSearchHighlight [text]="publication.description" [textSearched]="publication.hashtag"></div>
  </div>
</div>
