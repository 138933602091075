import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProjectService } from './../../../services/project.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BusinessModel } from '../../../models/businessModel/businessModel';
import { SnackbarService } from '../../../services/notifications/snackbar.service';
import { NavigationSteps } from 'src/app/classes/navigationSteps';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ProjectCompletedDialogComponent } from '../../shared/dialogs/project-completed-dialog/project-completed-dialog/project-completed-dialog.component';
import { ModelProgress } from 'src/app/models/modelProgress/modelProgress';
@Component({
  selector: 'app-value-proposal',
  templateUrl: './value-proposal.component.html',
  styleUrls: ['./value-proposal.component.css']
})
export class ValueProposalComponent implements OnInit {
  // current step number of this page
  stepNumber = 4;
  // total number of steps
  totalNumSteps = 7;
  // Property to identify the form
  forma: FormGroup;
  // Property to identify the current project
  currentProject: BusinessModel;
  // Property to identify the project id based on a url param
  projectId: string;
  // Property to manage the project select disabled for las option
  visibleAllyDescr: boolean = false;
  // Indicates with colors the steps that are already done
  modelProgress: ModelProgress[];
  // Load steps of diagnostic in order to display navbar
  projectSteps = NavigationSteps;
  submitted = false;

  constructor(
    private snackbarService: SnackbarService,
    private fb: FormBuilder,
    private router: Router,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {
    // set values of the project via localstorage
    this.forma = this.fb.group({
      productos: ['', [Validators.maxLength(600)]],
      client_solution: ['', [Validators.maxLength(600)]],
      distingue: ['', [Validators.maxLength(600)]],
      alianzas: false,
      alianzasDescr: ['', [Validators.maxLength(600)]]
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      if (params.id) {
        this.projectId = params.id;
        this.setProjectValues();
      }
    });
  }

  // Method to add/remove value of a checkbox into the checkCategories formcontrol value
  onAllianceChange(e): void {
    this.formFields.alianzasDescr.setValue(this.currentProject.allianceDescription);
    if (this.formFields.alianzas.value === false) {
      this.visibleAllyDescr = false;
      this.formFields.alianzasDescr.setValidators([Validators.maxLength(600)]);
    } else {
      this.visibleAllyDescr = true;
      this.formFields.alianzasDescr.setValue(null);
      this.formFields.alianzasDescr.setValidators([Validators.required, Validators.maxLength(600)]);
    }
    this.forma.get('alianzasDescr').setErrors(null);
    this.forma.updateValueAndValidity();
  }

  // Method to set the current form fields data from  api's database according to the current model_guid
  setProjectValues(): void {
    this.projectService.getProjectStageData(this.projectId)
      .then(data => {
        this.currentProject = new BusinessModel(data.data);
        this.modelProgress = this.currentProject.modelProgress;
        this.formFields.productos.setValue(data.data.similarSolutions);
        this.formFields.client_solution.setValue(data.data.client_solution);
        this.formFields.distingue.setValue(data.data.solutionAdditions);
        this.formFields.alianzas.setValue(data.data.haveAlliance);
        this.formFields.alianzasDescr.setValue(data.data.allianceDescription);
        this.visibleAllyDescr = data.data.haveAlliance;
        if(this.visibleAllyDescr == true){
          this.formFields.alianzasDescr.setValidators([Validators.required, Validators.maxLength(600)]);
        }
        this.forma.updateValueAndValidity();
      })
      .catch(error => {
        this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error. ⚠️');
      });
  }

  /**
   * Method to manage the valid inputs with a css color
   */
  get alianzasNoValido() {
    return this.forma.get('alianzas').invalid && this.forma.get('alianzas').touched;
  }

  // Convenience getter for easy access to form fields
  get formFields() {
    return this.forma.controls;
  }

  // Method to send data to store new project info
  generateProject(): void {
    this.currentProject.similarSolutions = (this.formFields.productos.value !== null ? this.formFields.productos.value.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '') : null) || null;
    this.currentProject.client_solution = (this.formFields.client_solution.value !== null ? this.formFields.client_solution.value.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '') : null) || null;
    this.currentProject.solutionAdditions = (this.formFields.distingue.value !== null ? this.formFields.distingue.value.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '') : null ) || null;
    this.currentProject.haveAlliance = this.formFields.alianzas.value;
    this.currentProject.allianceDescription = (this.formFields.alianzasDescr.value !== null ? this.formFields.alianzasDescr.value.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '') : null) || null;
  }
  /**
   * Method to return the lenght of a textfield
   * @param str - string to look for the chars
   * Return - number of chars
   */
  countChars(str): number {
    if (str) {
      const chars = str.length;
      return chars;
    } else {
      return 0;
    }
  }

  // Method to send data to store account info
  update(): void {
    this.submitted = true;
    if (this.forma.invalid) {
      this.snackbarService.showSnackBar('Cerrar', 'Revise los campos requeridos ⚠️');
      return;
    }
    this.generateProject();
    if (this.projectId) {
      this.projectService.updateProject(this.currentProject, this.projectId).subscribe(data => {
          this.snackbarService.showSnackBar('Cerrar', 'Datos guardados! 🎉');
          this.router.navigate(['app','publicidad-distribucion', this.projectId]);
      }, (err) => { this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error. ⚠️'); },
      () => { this.submitted = false;});
    }

  }


}
