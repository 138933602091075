import { HttpEventType } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SnackbarService } from 'src/app/services/notifications/snackbar.service';
import { PublicationService } from '../../../services/publication/publication.service';
import { FileList } from 'src/app/classes/file-list';
import { uploadingFileType, UploadingFile } from 'src/app/classes/fileList/uploading-file';
import { Observable, from } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { PostUploadModalComponent } from '../../components/post-upload/post-upload-modal.component';
import { ModalPostUploadService } from '../../components/post-upload/post-upload-modal.service';
import { ModalLimitFilesComponent } from '../../components/modal-limit-files/modal-limit-files.component';

@Component({
  selector: 'app-new-post',
  templateUrl: './new-post.component.html',
  styleUrls: ['./new-post.component.scss']
})
export class NewPostComponent implements OnInit, OnDestroy {
  // var to manage data for web form
  formb: FormGroup;
  // var to store the data from textarea
  post: string;
  // var to store the class styles for uploaded files in webapp
  height: number = 65;
    // var to store the class styles for uploaded files in webapp
  width: number = 98;
  // var to store the class styles for uploaded files in webapp
  styles: string = 'float-left rounded mx-1 my-1';
  // var to manage the loading files status at the webapp
  isLoading = false;
  // var to display the upload percent at the webapp
  uploadPercent: any = null;
// var to manage the display elements on the webapp
  show:boolean = true;
  // var to manage the subscription of the http method
  subscription: any = null;
  // Control the list of files to upload
  fileList: FileList;
  publication_guid = '';
  // Initial value, when media files are uploading
  filesUploading = 1;
  // Reference to modal instance
  modalRef: NgbModalRef;
  // Indicates the initial configuration of modals
  configModal: NgbModalOptions = {windowClass: 'modal-holder', centered: true, backdrop : 'static'};


  constructor(  private fb: FormBuilder,
                private snackbarService: SnackbarService,
                private publicationService: PublicationService,
                private modalPostUpdate: ModalPostUploadService,
                private modalService: NgbModal) {
    this.formb = this.fb.group({
      post        : ['', [Validators.required]],
      filesUpload : ['']
    });
    this.fileList = new FileList();

   }
  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if ( this.subscription != null){
      this.subscription.unsubscribe();
    }
  }

  /**
   * Method to delete uploaded image files to be displayed at the page and store in file var
   * @param index - send from web app from the ul-list of files (img-video)
   */
  deleteFileItem(file: UploadingFile){
    this.fileList.remove(file);
  }

/**
 * Method to return the length of a textfield
 * @param str - string to look for the chars
 * Return - number of chars
 */
  countChars(str){
    if (str) {
      const chars = str.length;
      return chars;
    }else{
      return 0;
    }
  }
  /**
   * Method to manage uploaded files to be displayed at the page and store in file var
   * @param event - event triggered from web app when a file is uploaded
   */
  detectFiles(files: File[]): void {
    if (files) {
      for (let file of files){
        let reader = new FileReader();
        reader.onload = (e: any) => {
           if ((this.fileList.uploadingFiles.length) < 5) {
            if (file.type === 'image/jpg' || file.type === 'image/jpeg' ||  file.type === 'image/png'){
              let newFile = this.fileList.addNewFile(uploadingFileType.IMAGE);
              newFile.setFile(file);
              newFile.setResult(e.target.result);
            }else if ( file.type === 'video/mp4') {
              let newFile = this.fileList.addNewFile(uploadingFileType.VIDEO);
              newFile.setFile(file);
              newFile.setResult(e.target.result);
            }else{
              this.snackbarService.showSnackBar('Cerrar', `Formato de archivo no permitido`);
            }
          }else{
            this.snackbarService.showSnackBar('Cerrar', `Máximo 5 archivos`);
            this.modalService.open(ModalLimitFilesComponent, this.configModal);
          }
        };
        reader.readAsDataURL(file);
      }
    }
  }

  /**
   * Method to upload files to the server
   */
  progressMedia(): void{
    if(this.fileList.uploadingFiles.length == 0){      
      this.isLoading = false;
      return;
    }
    this.uploadsFiles().subscribe(event => {
      if( event.type === HttpEventType.UploadProgress ){
        let percentage = Math.round((100 * event.loaded) / event.total); 
        this.uploadPercent = `${percentage}%`;
      }
      if( event.type === HttpEventType.Response ){
        if(this.filesUploading < this.fileList.uploadingFiles.length){            
          this.filesUploading++;
        }
        if(this.filesUploading == this.fileList.uploadingFiles.length){      
          this.isLoading = false;
          this.snackbarService.showSnackBar('Cerrar', `Archivo ${this.filesUploading} subido exitosamente`);  
        }
      }
      this.passDataModal();
    }, (err) => {
      this.snackbarService.showSnackBar('Cerrar', err);
    });
  }

  /**
   * Method to manage the valid inputs with a css color
   */
  get noValid(): boolean {
    return this.formb.get('post').invalid && this.formb.get('post').touched;
  }

  // Convenience getter for easy access to form fields
  get formFields() {
    return this.formb.controls;
  }

  /**
   * Method to manage the submit of th form
   */
  send(): void {
    this.isLoading = true;
    if ( this.formb.invalid ) {      
      this.isLoading = false;
     return Object.values(this.formb.controls).forEach( control => {
          control.markAllAsTouched();
      });
    }

    this.publicationService.newPost(this.formFields.post.value)
    .then(data => {
      this.publication_guid = data['data'].publication_guid;
      this.snackbarService.showSnackBar('Cerrar', `Publicación creada`);
      this.openModal();
    }).catch(err => {
       this.snackbarService.showSnackBar('Cerrar', `${err} ⚠️`);
    }).finally( () => {      
      this.progressMedia();
    });
  }
  
  // Method that creates an observer for each file
  uploadsFiles(): Observable<any>{
    return from(this.fileList.uploadingFiles).pipe(
      concatMap( (file) => {     
        let form = new FormData;
        form.append('file',file.getFile());
        return <Observable<any>>this.publicationService.uploadMedia(this.publication_guid,form);
      })
    )
  }

  /**
   * Method that send the guideline id if this need to be modified or create a new one
   * @param guidelineId Identifier of guideline
   */
  openModal(): void{
    this.modalRef = this.modalService.open(PostUploadModalComponent, this.configModal);
   }

  passDataModal(){
    let data ={
      isLoading: this.isLoading,
      filesUploading: this.filesUploading,
      uploadPercent: this.uploadPercent,
      totalFiles: this.fileList.uploadingFiles.length

    };
    this.modalPostUpdate.emitChange(data);
  }
}