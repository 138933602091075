import { PublicationDetail } from 'src/app/models/publication-detail/publication-detail';
import { UserStatus } from './../../../models/user-status/user-status.enum';
import { Component, OnInit, Input } from '@angular/core';
import { PublicationService } from 'src/app/services/publication/publication.service';
import { SnackbarService } from 'src/app/services/notifications/snackbar.service';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ChatService } from 'src/app/services/chat/chat.service';
import { NotificationTypes } from 'src/app/models/notification-types/notification-types';
@Component({
  selector: 'app-publication',
  templateUrl: './publication.component.html',
  styleUrls: ['./publication.component.scss']
})
export class PublicationComponent implements OnInit {
  @Input() publication;
  @Input() isDetail: boolean;
  @Input() isShareable = false;
  @Input() isShared = false;
  @Input() sharedPublication: PublicationDetail;
  // Receives through a binding variable or by reading from get params, it indicates from which list
  // of publications come from, an then register it on firebase
  @Input() parentPage: string;
  // Indicates if publications are from a user or and admin of posible when publications are in FeedPosible
  @Input() posibleUser = false;
  // property to identify if there is a petiton currently in progress
  isLoading = false;
  // property to identify if this publication is already liked by the current user
  likedByMe = false;
  // get the username of the current user
  username: string;
  // property to display the whole text
  showMore = false;
  // Property to identify when the whole comments section should be displayed
  showComments = true;
  // Property to identify when to show/hide the comments only
  commentsOpen = true;
  // Property to identify the whole list of user status
  userStatus = UserStatus;

  constructor(
    private publicationService: PublicationService,
    private authenticationService: AuthService,
    private snackBarService: SnackbarService,
    private chatService: ChatService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.username = this.authenticationService.currentUserValue.username;
    this.parentPage = this.parentPage!='' ? this.parentPage : this.route.snapshot.queryParamMap.get('feed');
  }

  /**
   * Method to like/dislike a publication
   * @param guid - guid of the publication to like/dislike
   */
  like(guid: string): void {
    let user = this.authenticationService.getDecodeTokenInfo();
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.publicationService.like(guid).then(response => {
      this.publication.liked = !this.publication.liked;
      this.publication.liked ? this.publication.likes++ : this.publication.likes--;
      if(user.user_id != this.publication.userGuid)
      {
        this.chatService.SendActionPublicationRequest(
          this.publication.guid,
          user.user_id,
          currentUser.firstName + " " +currentUser.lastName, //"username",
          currentUser.image,//"from userimage",
          this.publication.userGuid , //"toUserGuid",
          this.publication.firstName + " " +this.publication.lastName, //"username","toUSerName",
          NotificationTypes.Like, //"publication type type"
          !this.publication.liked
        );
      }
    }).catch(error => this.snackBarService.showSnackBar('Cerrar', `${error} ⚠️`));

  }

  // Method to toggle between showing only 300 characteres or the whole text
  toggleShowMore = (): boolean => this.showMore = !this.showMore;

  // Method to toggle between showing the comments or not
  toggleShowComments = (): boolean => this.showComments = !this.showComments;

  // Method to toggle between showing the comments or not
  toggleCloseComments = (): boolean => this.commentsOpen = !this.commentsOpen;

  /**
   * Method to received the comment recently created and insert it at the beginning of in our comments array
   * @param comment - comment to publish
   */
  insertComment(comment: Comment): void {
    if (this.publication as PublicationDetail) {
      this.publication.commentsList.unshift(comment);
    }
  }

  /**
   * Redirects to share page with given publication info
   * @param guid Identifier of publication
   */
  goToShare(guid: string){
    this.router.navigate(['/app/compartir-publicacion',guid]);
  }

  /**
  * Redirects to publication page with given publication info
  * @param guid Identifier of publication
  */
  goToPublicationDetail(publication_guid: string): void{
    this.router.navigate(['/app/publicacion', publication_guid],{queryParams: {feed: this.parentPage}});
  }

}
