<app-pending-businessmodels-modal></app-pending-businessmodels-modal>
<div class="container animated fadeIn fast publications__container">
    <section class="pt-3">
        <button class="btn-lg btn btn-block btn_backProjects" [routerLink]="['/app/perfil']">Ir a mis proyectos</button>
        <form autocomplete="off">
            <div class="pt-3 pb-3 input-group input-group-lg text-center">
                <input [routerLink]="['/app/nueva-publicacion']" type="text" readonly required style="border-radius: 4px; font-family:Arial, FontAwesome;" class="form-control input_rounded cursor__pointer" aria-label="Large" placeholder="&#xf044;   Publica en el muro">
            </div>
        </form>

        <ng-container *ngIf="allPosts?.length else noResults">
            <div class="search-results" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50" (scrolled)="onScroll()">
                <div *ngFor="let publication of allPosts">
                    <app-publication-detail [publicationGiven]="publication" [isDetail]="false" [parentPage]="parentPage"></app-publication-detail>
                </div>
            </div>
        </ng-container>

        <ng-template #noResults>
            <div class="row" *ngIf="!isLoading && !allPosts?.length">
                <div class="col">
                    <span class="pl-2">No se encontraron publicaciones...</span>
                </div>
            </div>
        </ng-template>

        <div *ngIf="isLoading" class="d-flex justify-content-center mt-5">
            <mat-spinner diameter="60"></mat-spinner>
        </div>

    </section>
</div>