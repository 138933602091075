<div *ngIf="isLoading; else elseLoading" class="d-flex justify-content-center mt-5">
    <mat-spinner diameter="60"></mat-spinner>
</div>

<ng-template #elseLoading>
    <div class="container header_feed animated fadeIn fas">
        <div class="row p-2">
            <div class="col-3 d-flex align-items-center" *ngIf="user">
                <img *ngIf="!user.image_path" src="../../../../assets/img/home/profile_img.png" height="56px" width="56px">
                <img *ngIf="user.image_path" clang-template class="img-circle" height="56px" width="56px" [src]="user.image_path">
                <span class="Online online"></span>
            </div>
            <div class="col-9">
                <div class="d-flex flex-row align-items-center pt-2 pb-2 justify-content-between">
                    <div>
                        <span class="txt_title_profile">{{ user?.first_name }} {{ user?.last_name }}</span>
                        <p class="job_title mb-0">Emprendedor</p>
                    </div>
                    <div>
                        <i class="fa fa-list pl-2 header__icons--size header__icons--yellow" aria-hidden="true" (click)="openProfileDetails()"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container animated fadeIn fast" *ngIf="!isMaxProjects">

        <div class="row align-items-center add_project p-2" *ngIf="!announcementClosed">
            <div class="col-12 d-flex flex-column px-0">
                <span class="title">Todas las fases de nuestra convocatoria han concluido, si te interesa volver aplicar, espera nuestra nueva convocatoria POSiBLE 2023</span>
            </div>
        </div>
        <div class="row align-items-center add_project" [routerLink]="['/app/nuevo-proyecto']" *ngIf="announcementClosed">
            <div class="col-2 d-flex justify-content-center">
                <i class="fa fa-plus-circle header__icons--size header__icons" aria-hidden="true"></i>
            </div>
            <div class="col-8 d-flex flex-column px-0">
                <span (click)="newBusinessModel()" class="title">Crear nuevo proyecto</span>
            </div>
            <div class="col-2 d-flex align-items-end chevron_right_end">
                <i class="fa fa-chevron-right pr-3 header__icons--size header__icons" aria-hidden="true"></i>
            </div>
        </div>
    </div>

    <div class="container project-container animated fadeIn">
        <div *ngFor="let project of projects" class="row align-items-center project" (click)="openProjectDetails(project.businessModel_guid, project.status, $event)">
            <div class="col-8 d-flex flex-column overflow">
                <h5 class="name mb-1">
                    {{ project.name }}
                </h5>
                <div class="edit_text" *ngIf="project.status == projectStatus.Nuevo">
                    <div class="badge new">&nbsp;</div> Editar modelo de negocio</div>
                <p class="edit_text" *ngIf="project.status == projectStatus.Rechazado"><span class="badge rejected">&nbsp;</span>Tu modelo de negocio no avanzo a la siguiente fase y agregamos retroalimentación a tu modelo</p>
                <p class="edit_text" *ngIf="project.status == projectStatus.Aprobado"><span class="badge approved">&nbsp;</span>
                    <!-- ¡Tu proyecto avanzó a los Paneles de POSiBLE 2022! ¡Da click aquí para agendar! -->Terminó tu plazo para agendar</p>
                <p class="edit_text" *ngIf="project.status == projectStatus.Revision"><span class="badge onreview">&nbsp;</span>Tu modelo de negocio fue enviado a revisión</p>
                <p class="edit_text" *ngIf="project.status == projectStatus.Completado"><span class="badge completed">&nbsp;</span>Tu modelo de negocio fue completado</p>
                <p class="edit_text" *ngIf="project.status == projectStatus.Asignado"><span class="badge completed">&nbsp;</span>Tu modelo de negocio ha sido asignado a un evaluador</p>
                <p class="edit_text" *ngIf="project.status == projectStatus.Evaluado"><span class="badge completed">&nbsp;</span>Tu modelo de negocio ha sido evaluado</p>
                <p class="edit_text" *ngIf="project.status == projectStatus.Agendado"><span class="badge completed">&nbsp;</span>Lamentamos que no hayas asistido a tu panel, más adelante estaremos compartiéndote cursos y talleres para seguir con tu formación
                    <!-- Proyecto agendado para paneles 2022 ¡Da click aquí para agregar material para tu pitch! -->
                </p>
                <p class="edit_text" *ngIf="project.status == projectStatus.EvaluadoP"><span class="badge completed">&nbsp;</span>Lamentamos informarte que tu proyecto no ha avanzado a la fase final. Recuerda que no es una competencia, revisa tu correo o descarga tu modelo de negocio para mas detalles.</p>
                <p class="edit_text" *ngIf="project.status == projectStatus.RechazadoP"><span class="badge rejected">&nbsp;</span>Lamentamos informarte que tu proyecto no ha avanzado a la fase final. Recuerda que no es una competencia, revisa tu correo o descarga tu modelo de negocio para mas detalles</p>
                <p class="edit_text" *ngIf="project.status == projectStatus.CampamentoE"><span class="badge completed">&nbsp;</span>Lamentamos informarte que tu proyecto no ha avanzado a la fase final. Recuerda que no es una competencia, revisa tu correo o descarga tu modelo de negocio para mas detalles.</p>
                <p class="edit_text" *ngIf="project.status == projectStatus.CampamentoN"><span class="badge completed">&nbsp;</span>Has llegado a la última etapa de POSiBLE, es decir, el Campamento Nacional ¡Felicidades! Revisa tu correo para más detalles.</p>
                <p class="edit_text" *ngIf="project.status == projectStatus.NoAsistioEstatal"><span class="badge rejected">&nbsp;</span>Lamentamos no haber contado con tu participación en el campamento. Te invitamos a estar pendiente de nuestras redes sociales</p>
                <p class="edit_text" *ngIf="project.status == projectStatus.AsistioEstatal"><span class="badge approved">&nbsp;</span>Lamentamos informarte que tu proyecto no ha avanzado a la fase final. Recuerda que no es una competencia, revisa tu correo o descarga tu modelo de negocio para mas detalles.</p>
                <p class="edit_text" *ngIf="project.status == projectStatus.NoAsistioNacional"><span class="badge rejected">&nbsp;</span>Lamentamos informarte que tu proyecto no ha avanzado a la fase final. Recuerda que no es una competencia, revisa tu correo o descarga tu modelo de negocio para mas detalles</p>
                <p class="edit_text" *ngIf="project.status == projectStatus.AsistioNacional"><span class="badge approved">&nbsp;</span>Has llegado a la última etapa de POSiBLE, es decir, el Campamento Nacional ¡Felicidades! Revisa tu correo para más detalles.</p>


            </div>
            <div class="col-4 d-flex chevron_right_end align-items-start justify-content-end">
                <div class="d-flex align-items-center">
                    <button type="button" *ngIf="project.percentage == 100 && project.status == projectStatus.Nuevo" class="btn btn-block btn_continuar mr-3">
                      <span class="btn_continuar_text">Enviar</span>
                    </button>
                    <!-- <button type="button" class="btn btn-block btn_continuar mr-3" *ngIf="project.status == projectStatus.Aprobado">
                        <span class="btn_continuar_text">Agendar</span>
                    </button> -->
                    <h5 class="mr-2 mb-0">{{project.percentage}}%</h5>
                    <i class="fa fa-chevron-right header__icons align-items-center justify-content-end" aria-hidden="true"></i>
                </div>
            </div>
        </div>
    </div>

</ng-template>
<p>
    &nbsp;
</p>