<div class="main-container-card page-style">
    <div class="pt-5 container animated fadeIn px-4">
        <header class="pt-5 text-center">
            <span class="titulo_pass_recover">
        Confirmar contraseña
      </span>
        </header>
        <section>
            <div class="animated fadeIn fast px-4">
                <p class="common_txt">
                    <br> Ingresa la nueva contraseña y confirmala para así para restablecerla.
                </p>
            </div>
            <form autocomplete="off" [formGroup]="forma" (ngSubmit)="submit()">
                <div class="mt-5  input-group input-group-lg">
                    <input class="form-control input_posible_pass" placeholder="Contraseña" formControlName="pass1" [class.is-invalid]="submitted && formFields.pass2.errors" [type]="showPassword ? 'text' : 'password'">
                    <div class="input-group-append">
                        <span (click)="togglePassword()" class="input-group-text" id="basic-addon2">
              <i class='fa fa-lg' [ngClass]="{'fa-eye': showPassword, 'fa-eye-slash': !showPassword}"
                aria-hidden="true"></i>
            </span>
                    </div>
                </div>

                <div *ngIf="submitted && formFields.pass1.errors" class="errors pt-1">
                    <small *ngIf="formFields.pass1.errors.required" class="text-danger animated fadeIn">
            La contraseña es obligatoria.
          </small>
                    <small *ngIf="formFields.pass1.errors.pattern" class="text-danger animated fadeIn">
            Debe contener solo letras mayúsculas, minúsculas y números.
          </small>
                    <small *ngIf="formFields.pass1.errors.minlength" class="text-danger animated fadeIn">
            Debe de contener mínimo 4 caracteres.
          </small>
                </div>

                <div class="input-group input-group-lg mt-3">
                    <input type="password" class="form-control inpts_posible" aria-label="Large" placeholder="Repetir contraseña" formControlName="pass2" [class.is-invalid]="submitted && formFields.pass2.errors">
                </div>
                <div *ngIf="submitted && formFields.pass2.errors" class="errors pt-1">
                    <small *ngIf="formFields.pass2.errors.required" class="text-danger animated fadeIn">
            La confirmacion de contraseña es obligatoria.
          </small>
                </div>

                <div *ngIf="submitted && passwordsAreEqual()" class="errors pt-1">
                    <small class="text-danger animated fadeIn">
            Las contraseñas deben ser iguales.
          </small>
                </div>

                <button [disabled]="isLoading" type="submit" class="mt-5 btn-lg btn btn-block btn-warning btn_style">
          <span class="btn_style_text">Continuar</span>
        </button>
                <br>
            </form>
            <a class="text-center nav-link backText" [routerLink]="['/app/login']"><i class="fa fa-arrow-left"
          aria-hidden="true"></i> Regresar</a>
        </section>
    </div>
</div>
<!--main-container-->