import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-project7',
  templateUrl: './create-project7.component.html',
  styleUrls: ['./create-project7.component.scss']
})
export class CreateProject7Component implements OnInit {

  forma: FormGroup;
  constructor( private fb: FormBuilder, private router: Router ) {
    this.forma = this.fb.group({          //'[0-9]+'     /^[a-zA-Z0-9]+$/
      ventas  : ['', [Validators.required,  Validators.pattern('^\\d*$') ] ]
    });
  }

  ngOnInit(): void {
  }

  get ventasNoValido(){
    return this.forma.get('ventas').invalid && this.forma.get('ventas').touched;
  }

  guardar() {
    if ( this.forma.invalid )
    {
      return Object.values(this.forma.controls).forEach( control => {
            control.markAllAsTouched();
        });
    }else{

     

      this.router.navigate(['crpy8']);
    }
  }

}
