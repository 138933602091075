import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UsuarioModel } from '../../../models/usuario.model';
import { AuthService } from '../../../services/auth.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { flatMap } from 'rxjs/operators';
import { SnackbarService } from 'src/app/services/notifications/snackbar.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { LoginModalComponent } from '../../components/login-modal/login-modal.component';
import { FirebaseService } from 'src/app/services/firebase-service/firebase.service';
import { adsParams } from 'src/app/classes/ads-params';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  pass: boolean = false;
  inputType = 'password';
  usuario: UsuarioModel = new UsuarioModel();
  // Property to identify the register form
  loginForm: FormGroup;
  // Property to identify if the form has been already submitted
  submitted = false;
  // Property to change the password inputs to text inputs and reversely
  showPassword = false;
  // Property to identify the url to return
  returnUrl: string;
  // Property to identify if a petition is in progress
  isLoading = false;
  // Indicates the initial configuration of modals
  configModal: NgbModalOptions = {windowClass: 'modal-holder', centered: true, backdrop : 'static'};
  // Params ads campaings
  adsParams = adsParams;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthService,
    private snackbarService: SnackbarService,
    private modalService: NgbModal,
    private firebase: FirebaseService,
    
  ) {
    this.loginForm = this.fb.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
    this.firebase.logEvent('loginUser');
    const modalShown = localStorage.getItem("modal");
    if(!modalShown){
      localStorage.setItem("modal", "1");
      this.openModal();
    }
  }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || 'app/perfil';
  }

  // Convenience getter for easy access to form fields
  get formFields() {
    return this.loginForm.controls;
  }

  // Method to change the input type password to type text and the other way around
  togglePassword = () => this.showPassword = !this.showPassword;

  // method to force the email input to lowercase and trim
  mailHelperInput = (evt) => {
    this.loginForm.patchValue({ username: evt.target.value.toLowerCase() });
    this.loginForm.patchValue({ username: evt.target.value.trim() });
  }

  // Method to handle de submit event and login
  onSubmit() {
    this.submitted = true;
    this.isLoading = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.isLoading = false;
      return;
    }

    this.authenticationService.login((this.formFields.username.value).toLowerCase(), this.formFields.password.value)
      .pipe( flatMap( () => this.authenticationService.loadDiagnosticData() ))
      .subscribe( data => {
          const diagnostic = this.authenticationService.diagnosticValue;
          if(diagnostic.diagnosticFinished) {
            this.router.navigateByUrl(this.returnUrl);
          } else {
            this.router.navigateByUrl(this.returnUrl);
            //this.router.navigate(['app/dejanos-conocerte']);
          }
          this.isLoading = false;
        },
        (error) => {
          this.snackbarService.showSnackBar('Cerrar', `${error} ⚠️`);
          this.isLoading = false;
        });
  }

  openModal():void{
    this.firebase.logEvent('lookBenefits');
    this.modalService.open(LoginModalComponent, this.configModal);
  }
}
