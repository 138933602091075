import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA, throwMatDialogContentAlreadyAttachedError } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SnackbarService } from 'src/app/services/notifications/snackbar.service';
import { ProjectService } from 'src/app/services/project.service';
import { CongratulationsDialogComponent } from '../../congratulations-dialog/congratulations-dialog/congratulations-dialog.component';
import { SurveyComponent } from '../../survey/survey.component';
import { FOCUS_MONITOR_DEFAULT_OPTIONS } from '@angular/cdk/a11y';

@Component({
  selector: 'app-project-completed-dialog',
  templateUrl: './project-completed-dialog.component.html',
  styleUrls: ['./project-completed-dialog.component.scss']
})
export class ProjectCompletedDialogComponent implements OnInit {

  // Property to identify the form
  form: FormGroup;
  // Property to identify the announcement selected (select control)
  announcementGuid: string = '';
  // Property to store the current project announcements
  Announcements: [];
  // Property to control if the announcements select is to be shown
  announcementsVisible: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<ProjectCompletedDialogComponent>,
    private route: Router,
    private fb: FormBuilder,
    private projectService: ProjectService,
    private snackbarService: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
  ) {
    this.form = this.fb.group({
      announcementGuid: ['']
    });
  }

  ngOnInit(): void {
    window['dataLayer'] = window['dataLayer'] || {};
    this.projectService.getCurrentAnnouncements().then(data => {
      this.Announcements = data.data;
      this.announcementsVisible = this.Announcements?.length > 0;
    }).catch(error => {
      this.route.navigate(['app/inicio']);
      this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error. ⚠️');
    }
    );
    this.announcementGuid=localStorage.getItem("currentAnnouncementGuidSelected");
    this.formFields.announcementGuid.setValue(this.announcementGuid==null?'':this.announcementGuid);
  }

  // Convenience getter for easy access to form fields
  get formFields() {
    return this.form.controls;
  }

  // close the dialog
  close() {
    localStorage.setItem('currentAnnouncementGuidSelected', this.announcementGuid);
    this.dialogRef.close({ data: this.announcementGuid });
  }

  // Method to navigate to the profile page
  navigateToProfile(): void {
    this.close();
    this.route.navigate(['app','perfil']);
  }

  // Method to handle the continue working in the project button
  continueWorking(): void {
    this.announcementGuid = this.formFields.announcementGuid.value;
    if(this.data.editCompleted == true){
      this.route.navigate(['app','nuevo-proyecto', this.data.id]);
      this.close();
    }else{
      if (!this.data.nextPage) {
        this.close();
        return;
      }
      this.route.navigate(['app',this.data.nextPage, this.data.id]);
      this.close();
    }
  }

  // Send to review the current project
  sendReview(): void {
    this.announcementGuid = this.formFields.announcementGuid.value===null?'':this.formFields.announcementGuid.value;
    let surveyOpen = false;
    if (this.announcementGuid!=''){
      surveyOpen = true;
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.width = '80%';
      dialogConfig.height = '80%';
      dialogConfig.data = { };
      dialogConfig.panelClass = ['no-padding'];

      let dialogRef = this.dialog.open(SurveyComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(data => {
        this.projectService.sendModelBusinessReview(
          this.data.id,
          {
            status: 2,
            announcement : this.announcementGuid
          }
        ).then((data:any) => {
          if(data.success) {
            window['dataLayer'] = window['dataLayer'] || [];
            window['dataLayer'].push({
            'event': 'SentModel',
            'projectFolio': data.folio, 
            });
          }
          if(!data.success) {
            this.snackbarService.showSnackBar('Cerrar', data.message);
            return;
          }
          this.openCongratulations();
        })
          .catch(error => {
            this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error. ⚠️');
            this.navigateToProfile();
        });
        return;
      });
    }

    if(!surveyOpen) {
      if (this.announcementsVisible) {
        this.snackbarService.showSnackBar("Error", 'Seleccione la convocatoria a la que irá asociado este proyecto. ⚠️');
      }
      else{
        this.snackbarService.showSnackBar("Error", 'No es posible mandar a revision el proyecto, no hay convocatorias abiertas. ⚠️');
      }
    }

  }

  // open congratulations dialog
  openCongratulations(): void {
    this.close();

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '450px';
    dialogConfig.data = { fromProfile: this.data.editCompleted };
    this.dialog.open(CongratulationsDialogComponent, dialogConfig);
  }


}
