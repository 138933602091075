export const adsParams = {
  // Page View
  pageView: {
    utm_source: "Facebook",
    utm_medium: "cpc",
    utm_campaign: "Prueba_2021",
    utm_content: "Imagen_01_amarillo_trafico"
  },
  // Start project
  startProject: {
    utm_source: "Facebook",
    utm_medium: "cpl",
    utm_campaign: "Prueba_2021",
    utm_content: "Imagen_01_gris_mdnstart"
  }
}
