import { Component, OnInit } from '@angular/core';
import { FriendRequest } from 'src/app/models/friend-request/friend-request';
import { Friend } from 'src/app/models/friend/friend';
import { ContactService } from 'src/app/services/contact/contact.service';
import { SnackbarService } from 'src/app/services/notifications/snackbar.service';
import { UserService } from 'src/app/services/user/user.service';
import { ChatService } from 'src/app/services/chat/chat.service';
import { Subscription } from 'rxjs';
import { FirebaseService } from 'src/app/services/firebase-service/firebase.service';

@Component({
  selector: 'app-red',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.scss']
})
export class NetworkComponent implements OnInit {
  // property to identify the section to show, the optiones are "personas" or "solicitudes"
  section = 'personas';

  // list of friends
  friends: Friend[];

  // list of friend requests
  requests: FriendRequest[];

  requestSubscription :  Subscription[] = [];

  // verify if there is a petition in progress
  isLoading = false;

  constructor(
    private userService: UserService,
    private snackBarService: SnackbarService,
    private contactService: ContactService,
    private chatService: ChatService,
    private firebase: FirebaseService
  ) { }

  ngOnInit(): void {
    this.getPersons();
    this.getFriendRequests();
    this.firebase.logEvent('pageFriends');

    const requestSubscription = new Subscription();
    requestSubscription.add(
      this.chatService.onEvent('addcontact')
        .subscribe(msg => {

            if (this.requests === undefined)
              this.requests=[];
            msg["activityStatus"] = "Online";
            this.requests.push(new FriendRequest(msg.data));

        })
    );
    this.requestSubscription.push(requestSubscription);

    const cancelSubscription = new Subscription();
    cancelSubscription.add(
      this.chatService.onCancel('cancelFriendRequest')
        .subscribe(msg => {

            if (this.requests )
            {
              let removeFile = this.requests.findIndex( value =>
                value.fromUserid == msg );

                if(removeFile >= 0){
                  this.requests.splice(removeFile, 1);
                }

                this.contactService.raisefriendRequestChanged(this.requests);
            }
        })
    );
    this.requestSubscription.push(cancelSubscription);
  }

  /**
   * Method to change the section
   * @param section - section selected
   */
  changeSection(section: string): string {
    return this.section = section;
  }

  // Method to get the list of friends
  getPersons(): void {
    this.isLoading = true;
    this.userService.getFriends().then(response => {
      this.friends = response.map(request => new Friend(request));
    })
      .catch(error => {
        this.snackBarService.showSnackBar('Cerrar', `${error} ⚠️`);
      })
      .finally(() => this.isLoading = false);
  }

  // Method to get the list of friend requests
  getFriendRequests(): void {
    this.isLoading = true;
    this.contactService.friendRequests().then(response => {
      this.requests = response['data'].map(request => new FriendRequest(request));
    })
      .catch(error => {
        this.snackBarService.showSnackBar('Cerrar', `${error} ⚠️`);
      })
      .finally(() => this.isLoading = false);
  }

  /**
   * Remove a specific value of the request array based on the index number of the element
   * @param requestData - object that contains the index of the element and a flag to identify if we need to refresh the friends list
   */
  removeRequest(requestData: object): void {
    if (!this.requests.length) {
      return;
    }

    this.requests.splice(requestData['index'], 1);
    if (requestData['updateList']) {
      this.getPersons();
    }
    this.contactService.raisefriendRequestChanged([]);
  }

  ngOnDestroy(){
    this.requestSubscription.forEach(
      subscription => subscription.unsubscribe() );
  }

}
