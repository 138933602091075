<div class="mt-3 mb-3 ml-3">
    <h3><i class="fa fa-angle-left" aria-hidden="true" style="margin-right: 10px;"></i> Nuevo proyecto</h3>
</div>
 <div class="main-container animated fadeIn fast">
    <div class="card text-center border-0 animated fadeIn fast" style="background-color: #F7F7F7;">
        <div class="card-body animated fadeIn" style="text-align: left;">
 
            <div class="mt-2 pb-5">
                <form autocomplete="off" [formGroup]="forma" (ngSubmit)="guardar()">
                    <div class="form-group">
                        <label for="exampleFormControlTextarea2" style="text-align: left;"> ¿Cuáles son tus ventas mensuales promedio? </label>
                        <br>
                        <br>
                        
                        <div class="input-group input-group-lg">
                            <input type="number" 
                                    style="border-radius: 7px;" 
                                    class="form-control inpts_posible" 
                                    aria-label="Large" 
                                    placeholder="Coloca la cantidad..."
                                    formControlName="ventas"
                                    [class.is-invalid]="ventasNoValido"
                                    >
                                    
                        </div>

                      </div><!-- form-group-->

                    <div style="text-align: center;" *ngIf="ventasNoValido">
                    <small class="text-danger animated fadeIn">
                        ¡¡ Este campo es requerido !!
                    </small> 
                    </div>
                      <br>

                        <div class="form-group row">
                            <label class="col-form-label">&nbsp;</label>

                            <div class="input-group col">
                            <button type="submit" class="btn-lg btn btn-block btn-warning popup-button" style="color:#252525;">Siguiente</button>
                        <!--    <button type="button" class="btn-lg btn-outline-info" (click)="continuar()">reedirigir</button>-->
                            </div>
                        </div> 
                </form>
    </div>
     
        </div><!--card body-->
        
      </div> <!-- card main-->
      
    </div>
    