<div class="container p-0">
  <div class="header">
    <a class="linkColor" [routerLink]="['/app/dejanos-conocerte']">
      <i class="fa fa-angle-left mr-2" aria-hidden="true"></i>
    </a>
    <span class="txt_title">Déjanos conocerte</span>
  </div>
  <div class="main-container animated fadeIn fast marginTop">
    <div class="card text-center border-0 animated fadeIn fast">
      <div class="card-body animated fadeIn text-left">
        <div class="mt-2 pb-5">
          <form autocomplete="off" [formGroup]="formb" (ngSubmit)="save()">
            <div class="form-group">
              <label for="exampleFormControlTextarea2" class="text-left txt_common">
                ¿Qué buscas en Posible? </label>
            </div>
            <div class="form-check my-4 " *ngFor="let option of options; let i=index">
              <input class="form-check-input" type="checkbox" name="statusp" [value]="option.statusPosible_guid" (change)="addItem($event)"
                [id]="option.statusPosible_guid" formArrayName="statusp" [checked]="checkedGenerator(option.statusPosible_guid)" [disabled]="diagnosticFinished">
              <label class="form-check-label" [for]="option.statusPosible_guid">
                <span class="txt_common_bold"> {{ option.description }}</span>
              </label>
            </div>
            <div class="text-left" *ngIf="submitted && statusPosible.length == 0">
              <small class="text-danger animated fadeIn" *ngIf="statusPosible.length == 0">Elige al menos una opción</small>
            </div>
            <br>
            <button type="submit" class="my-5 btn-lg btn btn-block btn-warning btn_style ">
              <span class="btn_style_text">Siguiente</span>
            </button>
          </form>
        </div>
      </div>
      <!--card body-->

    </div> <!-- card main-->

  </div>
</div>
