import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'src/app/classes/global';
import { Subject, BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SupportMaterialService {
  // variable that store the import of Globals Class that will help us to retrieve the urls for every service
  globals = Globals;

  constructor() { }

  /**
  * Method to get the support material of module
  * @param moduleId - Id of module to get the material
  */
  async getSupportMaterial(moduleId: any) {
    // Return a mock data while the API is ready, used only to example and display values on the view
    switch(moduleId) {

      case '1':

        return await {
          results:
              {module: {id: 1, description: 'Modulo 1'},
              videos: [
                {id: '122', title: 'Describe el problema',  video: 'https://player.vimeo.com/video/152123376', typeSrc: 1},
                {id: '123', title: 'Mini clase: Define el Problema', video: 'https://player.vimeo.com/video/151136234', typeSrc: 1}
              ],
              posts: [
                {id: '118', title: '¿Qué es una idea de negocio?', url: 'https://www.youtube.com/embed/FRTjiy3tbgc', isVideo: true},
                {id: '119', title: '8 tips para encontrar ideas de negocio', url: 'https://www.entrepreneur.com/article/265694'},
                {id: '120', title: 'Ser un emprendedor', url: 'https://www.youtube.com/embed/7WOorza0NSw', isVideo: true},
                {id: '121', title: 'Razón para Emprender ', url: 'https://www.youtube.com/embed/XZv1KNde0_U', isVideo: true}
              ],
              glossary: [
                {id: '49', title: 'Desarrollo de comunidades', description: `El desarrollo comunitario debe ser entendido como un proceso educacional, donde el agente no solamente se dedica a identificar, diagnosticar y dar recomendaciones para la solución de problemas, sino que conduce a la comunidad a:
                                                                              <ul>
                                                                                <li>Organizarse</li>
                                                                                <li>Que definan las necesidades</li>
                                                                                <li>Formulen planes</li>
                                                                                <li>Ejecute actividades que la conduzcan a elevar su nivel de vida en forma continuada</li>
                                                                              </ul>`},
                {id: '50', title: 'Idea de negocio', description: 'Son los productos o servicios que serán ofertados y que tienen como objetivo generar una venta y ganancia. Se considera, una idea de negocio como un medio para atraer clientes y poder tener beneficios como emprendedores.'},
                {id: '51', title: 'Problema', description: 'Surge a partir de una necesidad del cliente. Es aquí cuando surgen oportunidades de negocio, productos o servicios que van a cubrir las necesidades del consumidor. Al conocer el problema que quieres resolver en los consumidores, podrás descubrir qué productos o servicios son los más adecuados para satisfacer al cliente.'}
              ]  }
          }
      case '2':
        return await {
          results:
              {module: {id: 2, description: 'Modulo 2'},
            videos: [
              {id: '124', title: 'Explica tu idea de negocio',  video: 'https://player.vimeo.com/video/152123461', typeSrc: 1},
              {id: '125', title: 'Mini clase: Idea de negocio', video: 'https://player.vimeo.com/video/151136575', typeSrc: 1}
            ],
            posts: [
              {id: '97', title: 'Identifica tu idea emprendedora', url: 'https://www.youtube.com/embed/hI6LM0xokkA', isVideo: true},
              {id: '98', title: 'Define tu idea de negocio en 5 pasos', url: 'https://www.nextu.com/blog/definir-idea-de-negocio/'},
              {id: '200', title: 'Tipos de innovación', url: 'https://www.youtube.com/embed?v=tAw046C2fus&list=PLUO2cKLu8xMA9p8VyIPNQIkLRN6fz9IkR&index=20', isVideo: true},
              {id: '99', title: 'Cinco pasos para estructurar tu idea de negocio', url: 'https://www.pqs.pe/tu-negocio/cinco-pasos-para-estructurar-tu-idea-de-negocio'}
            ],
            glossary: [
              {id: '52', title: 'Desarrollo científico', description: `Al conjunto de actividades creativas emprendidas de forma sistemática, a fin de aumentar el caudal de conocimientos científicos y técnicos, así como la utilización de los resultados de estos trabajos para conseguir nuevos dispositivos, productos, materiales o procesos. Comprende esta actividad la investigación fundamental, la investigación aplicada y el desarrollo tecnológico`},
              {id: '53', title: 'Desarrollo tecnológico', description: `Consiste en la utilización de los conocimientos científicos existentes para la producción de nuevos materiales, dispositivos, productos, procedimientos, sistemas o servicios o para su mejora sustancial, incluyendo la realización de prototipos y de instalaciones piloto.`},
              {id: '54', title: 'Innovación', description: `La innovación representa todas aquellas transformaciones que introducen originalidad y novedad, suele desarrollarse con mayor frecuencia en el contexto económico, sobre todo cuando las empresas implementan nuevos productos o servicios que llegan a ser exitosos dentro del mercado`},
              {id: '55', title: 'Modelo de negocio', description: `El modelo de negocio tiene el objetivo de describir las bases sobre las que una empresa crea, proporciona y capta valor. En otras palabras, cómo la empresa es capaz de generar dinero y beneficio económico en función de su propuesta de valor`}
            ]  }
        }

      case '3':
        return await {
          results:
             {module: {id:32, description: 'Modulo 3'},
              videos: [
                {id: '17', title: 'Herramientas para emprendedores de alto impacto',  video: 'https://www.youtube.com/embed/FoYBiR4xWb8', typeSrc: 0},
                {id: '126', title: 'Describe a tus clientes', video: 'https://player.vimeo.com/video/152123581', typeSrc: 1},
                {id: '127', title: 'Mini clase: Describe a tus clientes', video: 'https://player.vimeo.com/video/151137059', typeSrc: 1}
              ],
              posts: [
                { id: '104', title: 'Cómo definir a tu cliente ideal y dónde encontrarlo', url: 'https://www.youtube.com/embed/nS6RGdZtIPg', isVideo: true},
                { id: '201', title: 'Segmento de clientes', url: 'https://www.youtube.com/embed?v=hNROkySrxXc', isVideo: true},
                { id: '105', title: '¿Cómo crear un perfil del consumidor?', url: 'https://www.nextu.com/blog/como-crear-un-perfil-del-consumidor/'},
                { id: '106', title: '¿Por qué es importante conocer a nuestros clientes?', url:	'https://www.marketeroslatam.com/importante-conocer-clientes/'}
              ],
              glossary: [

                {id: '59', title: 'Cliente', description: `Es aquella persona que a cambio de un pago recibe serviciosde alguien que se los presta por ese concepto.`},
                {id: '60', title: 'Empresa como cliente', description: `Se entiende como la acción de comercio entre empresas y no entre un negocio y una persona. Se caracteriza, principalmente, por:
                                                                      <ol>
                                                                        <li>La venta de un producto o servicio en volumen o en grandes cantidades. </li>
                                                                        <li>Se ofrecen precios competitivos o más bajo que los que se encuentran en tiendas. </li>
                                                                        <li>Los tiempos de entrega son menores. </li>
                                                                        <li>Incluyen diferentes formas de pago, ya sea a crédito o en varios pagos, como puede ser en el caso de venta de servicios: un anticipo o adelanto de pago y un el pago restante a la entrega del producto o servicio.</li>
                                                                      </ol>`},
                {id: '61', title: 'Perfil del Cliente', description: `Un perfil del consumidor es el conjunto de características que, con base en el análisis de las variables de un mercado, describe al cliente meta. Por ejemplo:Características demográficas. La gente puede ser descrita en función de sus características físicas (edad, sexo, etc), de sus características sociales (estado civil, clase social, etc), o de sus características económicas (ingresos, educación, empleo, etc). <br> Estilo de vida, motivaciones, personalidad, valores, Creencias y actitudes.`},
                {id: '62', title: 'Segmento de clientes', description: `La segmentación de clientes es el proceso que permite a las empresas y emprendedores dividir a sus consumidores en categorías específicas, basadas en características que se extraen de su comportamiento como clientes y la información que pueden obtener de sus interacciones con la empresa.`}
              ]  }
        }
      case '4':
        return await {
          results:
            {module: {id:32, description: 'Modulo 3'},
            videos: [
              {id: '128', title: 'Explica tu propuesta de valor',  video: 'https://player.vimeo.com/video/152123691', typeSrc: 1},
              {id: '129', title: 'Mini clase: Explica tu propuesta de valor', video: 'https://player.vimeo.com/video/151138586', typeSrc: 1}
            ],
            posts: [
              {id: '106', title: 'Qué es la Propuesta de Valor', url: 'https://aprendamosmarketing.com/la-propuesta-de-valor/'},
              {id: '107', title: 'Qué es la Propuesta de Valor y cómo crearla', url: 'https://www.youtube.com/embed/X4af5ncLeJ0', isVideo: true}
            ],
            glossary: [
              {id: '64', title: 'Alianzas estratégicas', description: 'Una alianza puede definirse como la unión de personas, empresas o países, mediante la cual se da un compromiso de ayuda o apoyo mutuo para lograr un fin determinado, con beneficios similares para las partes que la conforman. Dichos aportes son clave para el desarrollo de nuestro modelo de negocios actual, y/o que pueden ser base de construcción de nuevos negocios.'},
              {id: '65', title: 'Competencia', description: 'Son aquellos conjuntos de empresas que ofrecen productos iguales y/o similares  que comercializan los mismos que una determinada empresa'},
              {id: '66', title: 'Diferenciador', description: 'Diferenciación de producto es una estrategia competitiva que tiene como objetivo que el consumidor perciba de forma diferente el producto o servicio ofrecido por una empresa, con respecto a los de la competencia.'},
              {id: '67', title: 'Propuesta de valor', description: 'Es la expresión concreta del conjunto de beneficios que recibirán los clientes a los que se dirige como consecuencia de hacer negocio con quien la propone. A cambio de esos productos y servicios ofrecidos, la empresa obtiene de sus clientes, en la mayoría de los casos, una remuneración.'},
              {id: '68', title: 'Productos similares o sustitutos', description: 'Son aquellos bienes que pueden ser consumidos en el lugar de otros. Su característica principal es que tienen demandas relacionadas entre sí, es decir, que el consumidor sabe que puede sustituir uno por otro cuando lo crea oportuno.'}

            ]  }
      }
      case '5':
        return await {
          results:
            {module: {id:32, description: 'Modulo 3'},
            videos: [
              {id: '130', title: 'Define tu estrategia de publicidad y promoción',  video: 'https://player.vimeo.com/video/152123832', typeSrc: 1},
              {id: '131', title: 'Mini clase: Publicidad y distribución', video: 'https://player.vimeo.com/video/151137475', typeSrc: 1}
            ],
            posts: [
              {id: '109', title: 'Los 10 mandamientos del marketing para emprendedores', ulr: 'https://www.emprenderalia.com/los-10-mandamientos-del-marketing-para-emprendedores/'},
              {id: '110', title: '¿Qué son los canales de distribución?', url: 'https://www.youtube.com/embed/qjTVRRMw7bw', isVideo: true},
              {id: '202', title: 'Redes sociales', url: 'https://www.youtube.com/embed?v=OQ8Dd720Tak&list=PLUO2cKLu8xMA9p8VyIPNQIkLRN6fz9IkR&index=12', isVideo: true},
              {id: '203', title: 'Canales de venta', url: 'https://www.youtube.com/embed?v=9IblkRk0RYM', isVideo: true},
              {id: '111', title: 'Consejos sobre márketing, publicidad y ventas', url: 'https://www.emprendedores.es/crear-una-empresa/a5376/consejos-para-emprendedores-marketing-ventas-publicidad-comunicacion/'},
            ],
            glossary: [
              {id: '69', title: 'Difusión', description: 'La difusión es un proceso de comunicación y aceptación del producto por el mercado a lo largo del tiempo. Supone dar a conocer la existencia, características y ventajas del productos. Puede llevarse a cabo de forma verbal y personal, por medio de los vendedores de la empresa o de los propios usuarios del producto.'},
              {id: '70', title: 'Distribución', description: 'La distribución es una herramienta de la mercadotecnia que incluye un conjunto de estrategias, procesos y actividades necesarios para llevar los productos desde el punto de fabricación hasta el lugar en el que esté disponible para el cliente final (consumidor o usuario industrial) en las cantidades precisas, en condiciones óptimas de consumo o uso y en el momento y lugar en el que los clientes lo necesitan y/o desean.'},
              {id: '71', title: 'Medios digitales', description: 'Los medios digitales son espacios en los que se genera la comunicación y el intercambio de información entre usuarios y productores de contenidos digitales, ya sean empresas, bloggers o sitios de noticias.'},
              {id: '72', title: 'Publicidad', description: 'Forma de comunicación que intenta incrementar el consumo de un producto o servicio, insertar una nueva marca o producto dentro del mercado de consumo, mejorar la imagen de una marca o reposicionar un producto o marca en la mente de un consumidor.'},
            ]  }
      }
      case '6':
        return await {
          results:
            {module: {id:32, description: 'Modulo 3'},
            videos: [
              {id: '132', title: 'Explica cómo generas ingresos',  video: 'https://player.vimeo.com/video/152123914', typeSrc: 1},
              {id: '133', title: 'Mini clase: Generación de ingresos', video: 'https://player.vimeo.com/video/151137982', typeSrc: 1}
            ],
            posts: [
              {id: '112', title: 'Cómo monetizar',	url: 'https://www.youtube.com/embed/91EHg-FBbGU', isVideo: true},
              {id: '113', title: 'Cómo generar ingresos en nuestra empresa', url: 'https://www.youtube.com/embed/gg0nnr0vc4g', isVideo: true},
              {id: '114', title: 'Escalabilidad: ¿qué es una empresa escalable?', url: 'https://rockcontent.com/es/blog/escalabilidad/'}
            ],
            glossary: [
              {id: '73', title: 'Capital semilla', description: 'Financiamiento inicial para la creación o consolidación de un emprendimiento. Este tipo de fondo no debe ser '},
              {id: '74', title: 'Crédito Prendario', description: 'Es una forma de financiamienton, similar a la del préstamo, donde una persona está dispuesta a ofrecerle a su cliente o usuario una cantidad económica determinada, pero esta debe dejarle a cambio un inmueble o una prenda que tenga como garantía, a un precio similar a esa cantidad'},
              {id: '75', title: 'Cuota por Uso', description: 'Es un pago que se hace cada vez que usas el servicio o producto, puede ser una sola vez o periódicamente. Por ejemplo: el pago de energía eléctrica o servicio de internet.'},
              {id: '76', title: 'Financiamiento', description: 'Conjunto de recursos monetarios y de crédito que se destinarán a una empresa, actividad, organización o individuo para que los mismos lleven a cabo una determinada actividad o concreten algún proyecto, siendo uno de los más habituales la apertura de un nuevo negocio.'},
              {id: '77', title: 'Licencia', description: 'Es un pago que un cliente debe hacer para tener el permiso de utilizar un producto o servicio. Por ejemplo: el pago de un permiso de uso de software que instalas en tu computadora o el pago de un permiso para usar las figuras de Disney en la elaboración de muebles.'},
              {id: '78', title: 'Membresía o Suscripción', description: 'Ser miembro de alguna institución o asociación. Hay membresías que requieren un pago recurrente como gimnasios, seguros, publicaciones, medicina, servicios como los de electricidad, gas, teléfono, Internet, televisión, etc.'},
              {id: '79', title: 'Venta de Espacios Publicitarios', description: 'Es una forma de rentabilizar un espacio, así como una posibilidad para aquellos que buscan un lugar o medio en el que mostrar y promocionar un producto o marca para provocar cierta actitud de un potencial cliente hacia ese producto o servicio'},
            ]  }
      }
      case '7':
        return await {
          results:
            {module: {id:32, description: 'Modulo 3'},
            videos: [
              {id: '134', title: 'Describe tu plan de crecimiento',  video: 'https://player.vimeo.com/video/152123914', typeSrc: 1},
              {id: '135', title: 'Mini clase: Plan de crecimiento', video: 'https://player.vimeo.com/video/151138527', typeSrc: 1}
            ],
            posts: [

              {id: '115', title: 'Estrategias de crecimiento empresarial', url:	'https://www.youtube.com/embed/EIyVkOmuOaQ', isVideo: true},
              {id: '204', title: 'Validación ', url:	'https://www.youtube.com/embed?v=rM1omFcI3Cw&list=PLUO2cKLu8xMA9p8VyIPNQIkLRN6fz9IkR&index=9', isVideo: true},
              {id: '205', title: 'Estructura de costos', url:	'https://www.youtube.com/embed?v=8Sdy-hg0SS0&list=PLUO2cKLu8xMA9p8VyIPNQIkLRN6fz9IkR&index=10', isVideo: true},
              {id: '116', title: 'Las técnicas para acelerar el crecimiento de un negocio', url: 'https://konfio.mx/tips/negocios/las-tecnicas-para-acelerar-el-crecimiento-de-un-negocio/'},
              {id: '117', title: 'Importancia de la tracción', url:	'https://www.customia.com/blogs/post/que-es-la-traccion-y-porque-es-tan-importante-para-una-startup'}
            ],
            glossary: [
              {id: '81', title: 'Crecimiento Empresarial', description: 'Es un proceso por medio del cual esta llegará a aumentar su dimensión, como relación directa del resultado del aumento de las ventas.'},
              {id: '82', title: 'Escalabilidad', description: 'La capacidad de crecimiento de un modelo basada en generar más ingresos con los recursos disponibles'},
              {id: '83', title: 'Tracción', description: 'Es la muestra de que hay una demanda real de tu producto en el mercado… es decir, que está razonablemente validado, algo clave antes de tomar nuevas decisiones sobre el futuro de la compañía. Engloba variables relacionadas con la rentabilidad, las ganancias y los nuevos usuarios y clientes.'},
            ]  }
      }
      default: {
         //statements;
         break;
      }
   }

  }

}
