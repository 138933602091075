import { PublicationService } from 'src/app/services/publication/publication.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Comment } from 'src/app/models/comment/comment';
import { SnackbarService } from 'src/app/services/notifications/snackbar.service';
import { ChatService } from 'src/app/services/chat/chat.service';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationTypes } from 'src/app/models/notification-types/notification-types';
import { FirebaseService } from 'src/app/services/firebase-service/firebase.service';
@Component({
  selector: 'app-create-comment',
  templateUrl: './create-comment.component.html',
  styleUrls: ['./create-comment.component.scss']
})
export class CreateCommentComponent implements OnInit {
  @Input() publicationGuid: string;
  // Property to identify the comment form
  commentForm: FormGroup;

  // Property to identify if the form has been already submitted
  submitted = false;

  // Property to identify if a petition is in progress
  isLoading = false;

  @Output() commentCreated = new EventEmitter();
  @Input() parentPage = '';

  constructor(private fb: FormBuilder,
    private publicationService: PublicationService,
    private snackBarService: SnackbarService,
    private chatService: ChatService,
    private authenticationService: AuthService,
    private firebase: FirebaseService
    ) { }

  ngOnInit(): void {
    this.commentForm = this.fb.group({
      description: ['', [Validators.required, Validators.maxLength(1500)]],
    });
  }

/**
 * Method to return the lenght of a textfield
 * @param str - string to look for the chars
 * Return - number of chars
 */
countChars(str): number{
  if (str) {
    const chars = str.length;
    return chars;
  }else{
    return 0;
  }
}
  // Convenience getter for easy access to form fields
  get formFields() {
    return this.commentForm.controls;
  }

  // Method to create a comment
  createComment():void {
    this.publicationService.createComment(this.formFields.description.value, this.publicationGuid)
      .then( (response:any) => {

        let user = this.authenticationService.getDecodeTokenInfo();
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        response.data.user.image_path =  currentUser.image;
          this.commentEmmiter(response['data']);
          this.resetComponent();
        if(this.parentPage == "feedPosible"){
          this.firebase.logEvent('newComment',{ commentIn: 'feedPosible'});
        }else if(this.parentPage == "homePosible"){
          this.firebase.logEvent('newComment',{ commentIn: 'homePosible'});
        }
        if(user.user_id != response.data.publication_user_guid){
          this.chatService.SendActionPublicationRequest(
            this.publicationGuid,
            user.user_id,
            currentUser.firstName + " " +currentUser.lastName,
            currentUser.image,
            response.data.publication_user_guid ,
            response.data.publication_user_name,
            NotificationTypes.Comment
          );
        }
      })
      .catch(error => this.snackBarService.showSnackBar('Cerrar', `${error} ⚠️`))
      .finally(() => this.isLoading = false);
  }

  // Method to handle de submit event and login
  onSubmit() :void{
    this.submitted = true;
    this.isLoading = true;

    // stop here if form is invalid
    if (this.commentForm.invalid) {
      this.isLoading = false;
      return;
    }

    this.createComment();
  }

  // reset form and submitted property to their initial values
  resetComponent() {
    this.commentForm.reset();
    this.submitted = false;
  }

  // Method to send the created comment to the parent component
  commentEmmiter(comment: Comment) {
    this.commentCreated.emit(new Comment(comment));
  }

}
