import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homeapp',
  templateUrl: './homeapp.component.html',
  styleUrls: ['./homeapp.component.css']
})
export class HomeappComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
