<div class="main-container row">
  <div class="container-header px-4 col-12" style="background-image: url({{currentMicroSiteStateInfo['bg']}})">
    <div class="content-header">
      <header>
        <div class="txt_title pt-5" style="color: white;">CONVOCATORIA HACKATON DE INTELIGENCIA ARTIFICIAL</div>
      </header>
      <section class="mt-3">
        <p class="txt_common" [innerHTML]="currentMicroSiteStateInfo['text']" style="color: white; text-align: justify;"></p>
        <div class="mt-5 animated fadeIn fast">
          <a class="button-register" [routerLink]="['/crear-cuenta']">¡Regístrate ahora!</a>
        </div>
        <div class="mt-5 animated fadeIn fast">
          <a class="button-login" [routerLink]="['/login']" style="color: white;">Inicia sesión aquí</a>
        </div>
      </section>
    </div>
    <h3 class="name-project">{{currentMicroSiteStateInfo['name']}}</h3>
  </div>
  <section id="logos">
    <div class="row">
      <div class="col">
        <img src="assets/img/landing/logo-monte-red.png" class="logo-img-monte">
      </div>
      <div class="col" *ngIf="currentMicroSiteStateInfo['logo']">
        <img [src]="currentMicroSiteStateInfo['logo']" class="logo-img-edo" style="width: 35vw;">
      </div>
      <div class="col">
        <img src="assets/img/landing/logo-televisa.png" class="logo-img-televisa" style="width: 60%;">
      </div>
    </div>
  </section>

  <section id="campaings" class="mt-4">
    <h2>Conoce nuestros diferentes programas</h2>
    <div class="row">
      <div class="col-6 mt-3" *ngFor="let data of benefitsCampaings">
        <img [src]="data['backgroundImg']" class="container-campaing" [ngClass]="{'opacity': selectedCampaing != data['title'], 'selected-campaing-shadow': selectedCampaing == data['title']}">
      </div>
    </div>
    <div class="row campaing-detail" *ngFor="let data of benefitsCampaings" [hidden]="(selectedCampaing != data['title']) || (selectedCampaing == 'Reto POSiBLE' )">
      <div class="border"></div>
      <div class="col mx-2">
        <h2 class="title">{{data['title']}}</h2>
        <h3 class="subtitle">{{data['subtitle']}}</h3>
        <p class="description">{{data['description']}}</p>
        <h3 class="benefits-title">Beneficios del programa</h3>
        <div class="benefits-container">
          <div class="row mt-3" *ngFor="let benefit of data['benefits']">
            <div class="col-md-4 text-center">
              <img [src]="benefit['img']" class="benefits-img">
            </div>
            <div class="col-md-8">
              <div class="benefits-text">
                <h2>{{benefit['title']}}</h2>
                {{benefit['text']}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- reto oaxaca -->
    <div class="row campaing-detail" [hidden]="selectedCampaing != 'Reto POSiBLE'">
      <div class="border"></div>
      <div class="col mx-2">
        <h2 class="title">Hackatón IA Conalep 2021</h2>
        <h3 class="subtitle"></h3>
        <p class="description">La inteligencia artificial (IA) es un concepto que ha tomado relevancia recientemente debido a los grandes avances en robótica e informática; esta tecnología está provocando una evolución en las herramientas de aprendizaje.</p>
        <p class="description">Ante el creciente avance tecnológico, es fundamental que los estudiantes tengan las herramientas de trabajo para enfrentarse al mercado laboral, por este motivo se ha creado esta iniciativa, para que estudiantes de preparatoria con interés en programación e inteligencia artificial, a buscar soluciones orientadas a la innovación tecnológica.</p>
        <h2 class="title" style="text-align: center !important;">¿Cómo postularse al Hackatón IA Conalep 2021?</h2>
        <div class="col-md-12 col-xs-12 mt-5" >
          <div class="process-register" >
            <p>Todos los estudiantes mayores de edad que estén cursando una carrera en el Colegio Nacional de Educación Profesional Técnica.</p>
          </div>
        </div>
        <div class="col-md-12 col-xs-12 mt-5" >
          <div class="process-register" >
            <p>Que tengan posibilidad de dedicar al menos 3 horas diarias durante 4 días en el marco de la jornada de Expotalento.</p>
          </div>
        </div>
        <div class="col-md-12 col-xs-12 mt-5" >
          <div class="process-register" >
            <p>Podrán participar de manera individual o con hasta equipos de 3 personas.</p>
          </div>
        </div>
        <div class="col-md-12 col-xs-12 mt-5" >
          <div class="process-register" >
            <p>Que cuenten con una idea de negocio innovadora la cual pueda ser desarrollada o reforzada con alguna aplicación de inteligencia artificial: chats bots, voice bots, visión computacional y/o análisis de datos.</p>
          </div>
        </div>
        <div class="col-md-12 col-xs-12 mt-5" >
          <div class="process-register" >
            <p>Todos los postulantes se someterán a los lineamientos de evaluación y selección contenidos en esta Convocatoria.</p>
          </div>
        </div>
        <h2 class="title" style="text-align: center !important;">ETAPAS</h2>
        <h3 class="subtitle"></h3>
        <p class="description" style="text-align: center !important;">Pasarás por distintas etapas de selección para formar parte este hackatón:</p>
        <section id="reto">
          <ngb-carousel #retoCarousel>
            <ng-template ngbSlide>

              <div class="first_slide">
                <div class="row">
                  <h2 class="title"> ETAPA 1: Registro y selección</h2>
                  <h3 class="subtitle"></h3>
                  <ol style="font-size: 24px;">
                    <li style="margin-top: 2.5rem;">Vigencia: 23 de septiembre al 19 de octubre de 2021.</li>
                    <li style="margin-top: 2.5rem;">Ingresa en https://posible.org.mx/hackatonIA  y regístrate con tu nombre completo, correo electrónico y contraseña, deberá registrarse el líder del equipo.</li>
                    <li style="margin-top: 2.5rem;">Al completar tu perfil, te pediremos nos describas de manera general tu idea de negocio y por qué crees que puede tener mayor valor con inteligencia artificial.</li>
                    <li style="margin-top: 2.5rem;">Una vez completado tu registro, un grupo de expertos evaluará y seleccionará los proyectos participantes.</li>
                    <li style="margin-top: 2.5rem; margin-bottom: 2.5rem;">Durante la semana del 18 al 22 de octubre serás notificado por correo electrónico si tu proyecto fue seleccionado para que te prepares.</li>
                  </ol>
                  <!-- aqui -->
                  <!-- <div class="col-md-6 col-xs-12 mt-5"  *ngFor="let processItem of process['process']">
                    <div class="" [ngClass]="{'process-item': i == 0, 'process-item-2': i == 1}">
                      <p [innerHTML]="processItem['text']"></p>
                    </div>
                  </div> -->
                </div>
                <!-- <h2 class="text-center" *ngIf="process['slide']==2">{{process['subslide']}}</h2>
                <div class="row mt-5" *ngIf="process['slide']==2">
                  <h2 class="text-center" >{{process['subslide1']}}</h2>
                  <div class="col-md-6 col-xs-6 mt-3"  *ngFor="let processItem of process['subprocess2']" style="display: inline-block; height: auto;">
                    <div class="" [ngClass]="{'process-item': i == 0, 'process-item-2': i == 1}" style="display: inline-block; height: auto; width: 100%;">
                      <p [innerHTML]="processItem['text']" style="padding-top: 20px; text-align: justify;"></p>
                    </div>
                  </div>
                </div> -->
              </div>
            </ng-template>
            <ng-template ngbSlide>

              <div class="first_slide">
                <div class="row">
                  <h2 class="title">ETAPA 2: Contenido técnico</h2>
                  <h3 class="subtitle"></h3>
                  <p class="description">Fecha: 25 y 26 de octubre de 2021.</p>
                  <p class="description">Los equipos seleccionados tomarán al menos 6 horas de talleres y 2 de mentoría, en formato online por la plataforma TEAMS, durante estos dos días donde podrán aprender los siguientes temas:</p>
                  <p class="description">¿Qué es inteligencia artificial?</p>
                  <p class="description">Ejemplos de aplicaciones basadas en inteligencia artificial como chat bots, voice bots, visión computacional y análisis de datos.</p>
                  <p class="description">Herramientas para el desarrollo de inteligencia artificial aplicada a soluciones reales.</p>
                  <br>
                  <br>
                  <p class="description">*Los temas son enunciativos, más no limitativos. <span style="color: transparent; user-select: none;">espacio para linea</span></p>
                  <p class="description">Aquellos equipos que no tomen la capacitación técnica no serán considerados para los incentivos.</p>
                </div>
              </div>
            </ng-template>
            <ng-template ngbSlide>

              <div class="first_slide">
                <div class="row">
                  <h2 class="title">ETAPA 3: Desarrollo del proyecto</h2>
                  <h3 class="subtitle"></h3>
                  <p class="description">Fecha: 27 y 28 de octubre.</p>
                  <p class="description">Con la capacitación técnica y los conocimientos adquiridos, cada equipo, acompañados de su mentor desarrollará su emprendimiento con 3 componentes:</p>
                  <p class="description">Prototipo o mockup de la solución. <span style="color: transparent; user-select: none;">"aqui va texto para espacio de linea"</span></p>
                  <p class="description">Modelo de negocio. <span style="color: transparent; user-select: none;">aqui va texto para espacio de linea aqui va texto para espacio de linea</span></p>
                  <p class="description">Ejercicio de validación. <span style="color: transparent; user-select: none;">aqui va texto para espacio de linea</span></p>
                </div>
              </div>
            </ng-template>
            <ng-template ngbSlide>

              <div class="first_slide">
                <div class="row">
                  <h2 class="title">ETAPA 4: Incentivo al talento</h2>
                  <h3 class="subtitle"></h3>
                  <p class="description">Fecha: 28 de octubre de 2021.</p>
                  <p class="description">Los 30 equipos presentarán ante un panel online el desarrollo de su emprendimiento, en un pitch de 3 minutos, tomando en cuenta los criterios siguientes:</p>
                  <p class="description">Originalidad <span style="color: transparent; user-select: none;">aqui va texto para espacio de linea aqui va texto para espacio de linea</span></p>
                  <p class="description">Avance durante la etapa del desarrollo del proyecto.<span style="color: transparent; user-select: none;">aqui va texto para espacio de linea </span></p>
                  <p class="description">Aplicación de inteligencia artificial<span style="color: transparent; user-select: none;">aqui va texto para espacio de linea aqui va </span></p>
                  <p class="description">Funcionalidad de la solución<span style="color: transparent; user-select: none;">aqui va texto para espacio de linea aqui va texto </span></p>
                  <p class="description">Potencial de negocio<span style="color: transparent; user-select: none;">aqui va texto para espacio de linea aqui va texto para espacio de linea</span></p>
                  <p class="description">Impacto<span style="color: transparent; user-select: none;">aqui va texto para espacio de linea aqui va texto para espacio de linea</span></p>
                  <p class="description">El panel seleccionará a 3 equipos acreedores al incentivo al talento, que consistirá en:</p>
                  <p class="description">1er. Lugar<br>Incentivo en especie con valor equivalente a $10,000 (MN)<span style="color: transparent; user-select: none;">aqui va texto para espacio de linea aqui va texto para espacio de linea</span></p>
                  <p class="description">2do. Lugar<br>Incentivo en especie con valor equivalente a $7,000 (MN)<span style="color: transparent; user-select: none;">aqui va texto para espacio de linea aqui va texto para espacio de linea</span></p>
                  <p class="description">3er. Lugar<br>Incentivo en especie con valor equivalente a $5,000 (MN)<span style="color: transparent; user-select: none;">aqui va texto para espacio de linea aqui va texto para espacio de linea</span></p>
                </div>
              </div>
            </ng-template>
          </ngb-carousel>  
          <p class="description">CONFIDENCIALIDAD<br></p>
          <p class="description">Toda la información presentada por los participantes y aquella que se genere durante el proceso de evaluación, tendrá el carácter confidencial y no será empleada para ningún fin distinto al de los procesos de evaluación y selección.</p>
          <p class="description">CONSIDERACIONES <br></p>
          <p class="description">Este programa es totalmente gratuito <br></p>
          <p class="description">Los casos no previstos en la convocatoria serán resueltos por el equipo de POSIBLE en conjunto con el Instituto Mexiquense del Emprendedor.</p>
          <p class="description">Las decisiones del panel serán inapelables. <br></p>
          <p class="description">Los participantes al registrarse en la plataforma, aceptan los Términos de Uso y las Políticas de Privacidad de la misma.</p>
        </section>
        <!-- <div class="benefits-container">
          <div class="row mt-3" *ngFor="let benefit of data['benefits']">
            <div class="col-md-4 text-center">
              <img [src]="benefit['img']" class="benefits-img">
            </div>
            <div class="col-md-8">
              <div class="benefits-text">
                <h2>{{benefit['title']}}</h2>
                {{benefit['text']}}
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </section>
  <footer>
    <div class="row">
      <a [routerLink]="['/terminos']">Términos y condiciones</a>
      <a [routerLink]="['/aviso-privacidad']">Aviso de privacidad</a>
      <a href="assets/convocatoria-hackaton.pdf" target="_blank">Convocatoria</a>
    </div>
    <div class="row mt-5">
      <div class="col-12">
        <img src="assets/img/landing/logo-monte.png">
        <img src="assets/img/landing/logo-televisa-blanco.png">
      </div>
    </div>
  </footer>
</div> <!-- main-container-->
