<div class="container project-completed-dialog">
  <div class="row">
    <div class="col-12 text-center">
      <img src="assets/img/board.png" class="img-fluid img-project mb-3">
      <h2 class="title">¡Felicidades!</h2>
      <div class="text-description mt-3">
        <p>
          Haz completado con éxito el registro de tu proyecto. <br>
          Ya puedes enviarlo a revisión o si lo deseas puedes hacerlo mas tarde.
        </p>
        <img src="assets/img/logo.png" class="img-fluid posible">
      </div>
    </div>
  </div>

  <div class="row mt-4">
      <div class="col-12">
        <form autocomplete="off" [formGroup]="form">
            <div class="form-group">
              <label *ngIf="announcementsVisible" for="announcementGuid" class="subtitle text-left">Convocatoria:</label>
                <div class="input-group input-group-lg">
                        <!--api/announcements/-->
                        <select *ngIf="announcementsVisible" class="form-control input-rounded" aria-placeholder="Convocatoria"
                          aria-label="Large" placeholder="Elegir" name="announcementGuid" formControlName="announcementGuid">
                          <option value="" disabled selected>Seleccione una convocatoria</option>
                          <option *ngFor="let Announcement of Announcements"
                              [value]="Announcement.announcement_guid"> {{ Announcement.description }} </option>
                        </select>

                        <button type="button" class="btn-lg btn btn-block btn-warning btn_send_review mt-2" (click)="sendReview()">
                          <span class="btn_continuar_text">Enviar a revisión</span>
                        </button>

                        <button type="button" class="icon btn-lg btn btn-block btn_edit" (click)="continueWorking()">
                          <span class="btn_continuar_text">Seguir editando proyecto</span>
                        </button>

                        <button type="button" class="icon btn-lg btn btn-block btn_send_later" (click)="navigateToProfile()">
                          <span class="btn_continuar_text">Salir y enviar después</span>
                        </button>
                </div>
            </div>
        </form>
    </div>
  </div>

  <div class="row mt-2 mb-3">
    <div class="col-12">


    </div>
  </div>
</div>
