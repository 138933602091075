import { Topic } from './../../../models/topic/topic';
import { UserProfile } from './../../../models/user-profile/user-profile';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Profile } from 'src/app/models/User/profile';
import { ContactService } from 'src/app/services/contact/contact.service';
import { SnackbarService } from 'src/app/services/notifications/snackbar.service';
import { UserService } from 'src/app/services/user/user.service';
import { UserStatus } from 'src/app/models/user-status/user-status.enum';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  // identify if there is a petition in progress
  isLoading = false;

  // user profile
  user: UserProfile;

  // Property to identify the whole list of user status
  userStatus = UserStatus;

  // identify if there is a petition in progress
  haveRequest = false;

  // identify if there is a petition in progress
  requestId: string;

  constructor(
    private contactService: ContactService,
    private snackBarService: SnackbarService,
    private userService: UserService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      if (params.id) {
        this.getUserTopics(params.id);
        this.checkFriendRequest(params.id);
      }
    });
  }

  /**
   * Get the details of a user
   * @param id - user id
   * @param topics - list of topics of interest of this user
   */
  getUser(id: string, topics: Topic[]): void {
    this.isLoading = true;
    this.userService.getUser(id).then(response => {
      this.user = new UserProfile(response, topics);
    })
      .catch(error => {
        this.snackBarService.showSnackBar('Cerrar', `${error} ⚠️`);
      })
      .finally(() => this.isLoading = false);
  }

  /**
   * Get the topics of interest of a user
   * @param id - user id
   */
  getUserTopics(id: string): void {
    this.isLoading = true;
    this.userService.getTopics(id)
      .then(response => {
        const topics = response['data'].map(topic => new Topic(topic));
        this.getUser(id, topics);
      })
      .catch(error => this.snackBarService.showSnackBar('Cerrar', error))
      .finally(() => this.isLoading = false);
  }

  /**
   * Check if there is a pending friend request of this user
   * @param id - user id
   */
  checkFriendRequest(id: string): void {
    this.isLoading = true;
    this.contactService.haveRequest(id)
      .then(response => {
        // verify if a request exists
        if (response['data'].request_guid) {
          this.requestId = response['data'].request_guid;

          // verify that the request is not confirmed
          if (!response['data'].confirmed) {
            this.haveRequest = true;
          }
        }
      })
      .catch(error => this.snackBarService.showSnackBar('Cerrar', error))
      .finally(() => this.isLoading = false);
  }

  // Method to accept a friend request
  acceptFriend(): void {
    this.isLoading = true;
    this.contactService.acceptRequest(this.requestId).then(response => {
      if (response['success']) {
        this.haveRequest = false;
        this.snackBarService.showSnackBar('Cerrar', `Solicitud Aceptada.`);
        this.contactService.raisefriendRequestChanged([]);
      }
    })
      .catch(error => {
        this.snackBarService.showSnackBar('Cerrar', `${error} ⚠️`);
      })
      .finally(() => this.isLoading = false);
  }

  // Method to decline a friend request
  declineFriend(): void {
    this.isLoading = true;
    this.contactService.cancelRequest(this.user.guid).then(response => {
      if (response['success']) {
        this.haveRequest = false;
        this.snackBarService.showSnackBar('Cerrar', `Solicitud Rechazada.`);
        this.contactService.raisefriendRequestChanged([]);
      }
    })
      .catch(error => {
        this.snackBarService.showSnackBar('Cerrar', `${error} ⚠️`);
      })
      .finally(() => this.isLoading = false);
  }

}
