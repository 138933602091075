import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, flatMap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { Globals } from '../classes/global';
import { Router } from '@angular/router';
import { User } from '../models/User/user';

import * as jwt_decode from "jwt-decode";
import { Diagnostic } from '../models/diagnostic/diagnostic';
import { ChatService } from './chat/chat.service';
import { environment } from 'src/environments/environment';
import * as firebase from 'firebase/app';
import { FirebaseService } from './firebase-service/firebase.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // variable to store the currentUsersSubject BehaviorSubject
  private currentUserSubject: BehaviorSubject<any>;
  // variable to store the currentUser observable
  public currentUser: Observable<User>;
  // variable that store the import of Globals Class that will help us to retrieve the urls for every service
  globals = Globals;
  // base url to use in this service
  baseUrl = `${this.globals.urlPosibleBase}${this.globals.users.pathUsers}`;
  // variable to store the currentUsersSubject BehaviorSubject
  private currentDiagnosticSubject: BehaviorSubject<any>;
  // variable to store the currentUser observable
  public currentDiagnostic: Observable<Diagnostic>;

  constructor(private http: HttpClient,
    private router: Router,
    private chatService: ChatService,
    private firebase: FirebaseService) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.currentDiagnosticSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('diagnosticUser')));
    this.currentDiagnostic = this.currentDiagnosticSubject.asObservable();
  }

  // Validate if the currentUserSubject exists, if exists get its value.
  public get currentUserValue() {
    if (this.currentUserSubject && this.isAuthenticated()) {
      return this.currentUserSubject.value;
    } else {
      return false;
    }
  }


  // Validate if the currentUserSubject exists, if exists get its value.
  public get diagnosticValue() {
    if (this.currentDiagnosticSubject && this.isAuthenticated()) {
      return this.currentDiagnosticSubject.value;
    } else {
      return false;
    }
  }

  // Verifies if user is logged in
  isAuthenticated(): boolean {
    const token = localStorage.getItem('currentUser');
    if (token) {
      return true;
    }
    else {
      return false;
    }
  }

  getDecodeTokenInfo() {
    try {
      return jwt_decode(JSON.parse(localStorage.getItem('currentUser')).token);
    }
    catch (Error) {
      return null;
    }
  }

  /**
  *  Method that updates the current user when profile data changes and image profile was updated or removed
  * @param data - user data
  */
  updateLocalStorageUser = (data) => {
    return new Promise((resolve, reject) => {
      try {
        const token = JSON.parse(localStorage.getItem('currentUser'))['token'];
        const profileAuth = JSON.parse(localStorage.getItem('currentUser'))['profileAuth'];
        let user = { 'user': data, 'token': token, 'profileAuth': profileAuth };
        const currentUser = new User(user);
        localStorage.setItem('currentUser', JSON.stringify(currentUser));
        this.currentUserSubject.next(currentUser);
        resolve(data);
      } catch {
        reject('No se pudo actualizar los datos de sesión del usuario')
      }
    });
  }

  /**
   * Method to get the current user data by id
   */
  async getCurrentUser(): Promise<any> {
    let id = this.getDecodeTokenInfo().user_id;
    const url = `${this.baseUrl}${id}/`;
    return await this.http.get(url).toPromise().then(data => {
      return this.updateLocalStorageUser(data)
    });
  }

  async recoverPassword(email): Promise<object> {
    return await this.http.post(`${this.baseUrl}forgotPassword/`, { email }).toPromise();
  }

  /**
   * Method to change the password
   * @param password new password
   * @param newPassword new password confirmed
   * @param userToken token request
   */
  async confirmPassword(password, newPassword, userToken) {

    const headers = {
      headers: new HttpHeaders({
        Authorization: 'token ' + userToken
      })
    };
    const url = `${this.baseUrl}${this.globals.users.password}`;
    return await this.http.put(url, { password, newPassword }, headers).toPromise();
  }


  /**
   * Posible login - store the token in the local storage.
   * @param username Username of the user
   * @param password Password of the user
   */
  login(username: string, password: string) {
    const loginUrl = `${this.baseUrl}${this.globals.users.login}`;

    return this.http.post<any>(loginUrl, {
      username,
      password,
    })
      .pipe(
        map(result => {

          // login successful if there's a jwt token in the response
          if (result && result.Data.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            
            const user = new User(result.Data);
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(user);
            const diagnosticUser = new Diagnostic({});
            localStorage.setItem('diagnosticUser', JSON.stringify(diagnosticUser));
            this.currentDiagnosticSubject.next(diagnosticUser);
          }
          return result;
        }));
  }

  // Posible logout - remove the token of the local storage.
  logout(): boolean {
    const currentUser = localStorage.getItem('currentUser');
    if (currentUser) {
      // remove user from local storage to log user out
      this.chatService.leaveRoom(currentUser['username']);
      localStorage.removeItem('currentUser');
      this.currentUserSubject.next(null);
      localStorage.removeItem('diagnosticUser');
      this.currentDiagnosticSubject.next(null);      
      this.firebase.logEvent('logout');
      this.router.navigate(['/login']);
      return true;
    }
  }

  getUserDetails(username, password): Observable<object> {
    return this.http.post(`${this.baseUrl}/login/`, {
      username,
      password
    });
  }

  async nuevoUsuario(account) {
    return await this.http.post(this.baseUrl, account).toPromise();
  }

  async nuevaMujerPosible(account) {
    const url = `${this.globals.urlPosibleBase}${this.globals.users.pathUsers}${this.globals.users.createMujer}`;
    console.log("url", url)
    console.log("account", account);
    
    return await this.http.post(url, account).toPromise();
  }
  async nuevaMujerEmprendedora(account) {
    const url = `${this.globals.urlPosibleBase}${this.globals.users.pathUsers}${this.globals.users.createMujerEmprendedora}`;
    console.log("url", url)
    console.log("account", account);
    
    return await this.http.post(url, account).toPromise();
  }
  /** Method to post the user data
  * @param profile - Profile object
  */
  saveProfile(profile: object): Observable<object> {
    return this.http.put(`${this.baseUrl}${profile['user_guid']}/`, profile);
  }

  /* Method to upload a profile image
  * @param image - FormData
  */
  uploadProfileImage(image): Observable<object> {
    const url = `${this.globals.urlPosibleBase}${this.globals.users.pathUsers}${this.globals.users.imageProfile}`;
    return this.http.put(url, image);
  }

  /* Method to delete the profile image
  *
  */
  deleteImage(): Observable<object> {
    const url = `${this.globals.urlPosibleBase}${this.globals.users.pathUsers}${this.globals.users.deleteImageProfile}`;
    return this.http.put(url, {});
  }

  /* Method to get all the current user contacts
  *
  */
  contacts(): Observable<object> {
    const url = `${this.globals.urlPosibleBase}${this.globals.users.pathUsers}${this.globals.users.contacts}`;
    return this.http.get(url);
  }

  /* Method to get all the current user contacts
  *
  */
  async heardFrom(): Promise<any> {
    const url = `${this.globals.urlPosibleBase}${this.globals.users.pathUsers}${this.globals.users.heardFrom}`;
    return await this.http.get(url).toPromise();
  }

  /* Method to get all the entrepreneurships
  *
  */
  async entrepreneurship(): Promise<any> {
    const url = `${this.globals.urlPosibleBase}${this.globals.users.pathUsers}${this.globals.users.entrepreneurships}`;
    return await this.http.get(url).toPromise();
  }
  /* Method to get all the nodes
  *
  */
  async assistanceNode(): Promise<any> {
    const url = `${this.globals.urlPosibleBase}${this.globals.users.pathUsers}${this.globals.users.nodes}`;
    return await this.http.get(url).toPromise();
  }

  /* Method to get all the components
  *
  */
  async componentsList(): Promise<any> {
    const url = `${this.globals.urlPosibleBase}${this.globals.users.pathUsers}${this.globals.users.components}`;
    return await this.http.get(url).toPromise();
  }
  async componentsListParticipated(): Promise<any> {
    const url = `${this.globals.urlPosibleBase}${this.globals.users.pathUsers}${this.globals.users.componentsParticipated}`;
    return await this.http.get(url).toPromise();
  }
  
  async SedesList(): Promise<any> {
    const url = `${this.globals.urlPosibleBase}${this.globals.users.pathUsers}${this.globals.users.sedesList}`;
    return await this.http.get(url).toPromise();
  }

  async componentsListZacatecas(): Promise<any> {
    const url = `${this.globals.urlPosibleBase}${this.globals.users.pathUsers}${this.globals.users.componentsListZacatecas}`;
    return await this.http.get(url).toPromise();
  }
  async componentsListEntrepreneur(): Promise<any> {
    const url = `${this.globals.urlPosibleBase}${this.globals.users.pathUsers}${this.globals.users.componentsListEntrepreneur}`;
    return await this.http.get(url).toPromise();
  }
  
  async municipalityAttend(): Promise<any> {
    const url = `${this.globals.urlPosibleBase}${this.globals.users.pathUsers}${this.globals.users.municipalityAttend}`;
    return await this.http.get(url).toPromise();
  }
  async municipalityAttendEntrepreneur(): Promise<any> {
    const url = `${this.globals.urlPosibleBase}${this.globals.users.pathUsers}${this.globals.users.municipalityAttendEntrepreneur}`;
    return await this.http.get(url).toPromise();
  }
  async listLocalAttend(): Promise<any> {
    const url = `${this.globals.urlPosibleBase}${this.globals.users.pathUsers}${this.globals.users.listLocalAttend}`;
    return await this.http.get(url).toPromise();
  }
  async listCampInvitation(): Promise<any> {
    const url = `${this.globals.urlPosibleBase}${this.globals.users.pathUsers}${this.globals.users.listCampInvitation}`;
    return await this.http.get(url).toPromise();
  }

  async listGiro(): Promise<any> {
    const url = `${this.globals.urlPosibleBase}${this.globals.users.pathUsers}${this.globals.users.listGiros}`;
    return await this.http.get(url).toPromise();
  }
  async listDate(): Promise<any> {
    const url = `${this.globals.urlPosibleBase}${this.globals.users.pathUsers}${this.globals.users.listDate}`;
    return await this.http.get(url).toPromise();
  }

  /* Method to get the chatroom by id contact
  * @param idContact : string - id of the contact that going to chat
  */
  async getChatroom(idContact: string): Promise<any> {
    const url = `${this.globals.urlPosibleBase}${this.globals.users.pathContacts}${idContact}/${this.globals.users.chatroom}`;
    return await this.http.get(url).toPromise();
  }

  /* Method to get the a specific contact by id
  * @param idContact : string - id of the contact that going to chat
  */
  getContact(idContact: string): Observable<object> {
    const url = `${this.globals.urlPosibleBase}${this.globals.users.pathContacts}${idContact}/`;
    return this.http.get(url);
  }

  /**
   * Updates variable diagnosticCaptured variable
   * @param value Indicates the boolean value to update
   */
  updateDiagnosticCaptured(value: boolean){
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    currentUser['diagnosticCaptured'] = value;
    localStorage.setItem('currentUser', JSON.stringify(currentUser));
  }

  // Method that loads diagnostic data and save it in local storage
  loadDiagnosticData(){
    const url = `${this.globals.urlPosibleBase}${this.globals.diagnostic.diagnosticDetail}`;
    return this.http.get(url).pipe(
      map(result => {
        if (result && result['data']['diagnostic_guid']) {
          const diagnostic = new Diagnostic(result['data']);
          localStorage.setItem('diagnosticUser', JSON.stringify(diagnostic));
          this.currentDiagnosticSubject.next(diagnostic);
        }
        return result;
      }));
  }

  // When a user answered a question in the diagnostic steps,
  // this will be saved in the server and updated the local storage
  updateDiagnosticData(param: {[key:string]: any}){
    let diagnosticToSave = this.diagnosticValue;
    const url = `${this.globals.urlPosibleBase}${this.globals.diagnostic.diagnosticUpdate}`;
     for(let value in param){
      diagnosticToSave[value] = param[value];
     }
      return this.http.post(url,diagnosticToSave)
        .pipe( flatMap( () => this.loadDiagnosticData() )).toPromise();

  }

  // Remove diagnostic data from local storage
  removeDiagnosticData():void{
    localStorage.removeItem('diagnosticUser');
    this.currentDiagnosticSubject.next(null);
  }

}
