import { Component, OnInit } from '@angular/core';
import { ProjectService } from './../../../services/project.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SnackbarService } from '../../../services/notifications/snackbar.service';
import { NavigationSteps } from 'src/app/classes/navigationSteps';
import { BusinessModel } from '../../../models/businessModel/businessModel';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ProjectCompletedDialogComponent } from '../../shared/dialogs/project-completed-dialog/project-completed-dialog/project-completed-dialog.component';
import { ModelProgress } from 'src/app/models/modelProgress/modelProgress';

@Component({
  selector: 'app-explain-idea',
  templateUrl: './explain-idea.component.html',
  styleUrls: ['./explain-idea.component.css']
})
export class ExplainIdeaComponent implements OnInit {
  // current step number of this page
  stepNumber = 2;
  // total number of steps
  totalNumSteps = 7;
  // Property to identify the current project
  currentProject: BusinessModel;
  // Property to identify the project id based on a url param
  projectId: string;
  // Categories list
  options: [];
  // Property to identify the form
  forma: FormGroup;
  // Indicates with colors the steps that are already done
  modelProgress: ModelProgress[];
  // Load steps of diagnostic in order to display navbar
  projectSteps = NavigationSteps;

  regExp = / *\([^)]*\) */g;

  constructor(private snackbarService: SnackbarService, private fb: FormBuilder, private router: Router, private projectService: ProjectService, private route: ActivatedRoute, private dialog: MatDialog) {
    // set up the form
    this.forma = this.fb.group({
      proceso: ['', [Validators.maxLength(600)]],
      opciones: ['']
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      if (params.id) {
        this.projectId = params.id;
        this.setProjectValues();
      } else {
        this.getOptionsSolutions();
      }
    });
  }
  // Method to set the current form fields data from  api's database according to the current model_guid
  setProjectValues(): void {
    this.projectService.getProjectStageData(this.projectId)
      .then(data => {
        this.currentProject = new BusinessModel(data.data);
        this.modelProgress = this.currentProject.modelProgress;
        this.formFields.proceso.setValue(data.data.descriptionProduct);
        this.formFields.opciones.setValue(data.data.optionSolution?.optionSolution_guid);
        this.getOptionsSolutions();
      })
      .catch(error => {
        this.router.navigate(['app/inicio']);
        this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error. ⚠️');
      }
      );
  }

  /**
   * Method to return the lenght of a textfield
   * @param str - string to look for the chars
   * Return - number of chars
   */
  countChars(str): number {
    if (str) {
      const chars = str.length;
      return chars;
    } else {
      return 0;
    }
  }

  // Convenience getter for easy access to form fields
  get formFields() {
    return this.forma.controls;
  }

  // Method to get the project categories list
  getOptionsSolutions(): void {
    this.projectService.getOptionsSolutions()
      .then(data => {
        this.options = data.data;
      })
      .catch(error => {
        this.router.navigate(['app/inicio']);
        this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error. ⚠️');
      }
      );
  }

  /**
   * Method to manage the valid inputs with a css color
   */
  get opcionesNoValido() {
    return this.forma.get('opciones').invalid && this.forma.get('opciones').touched;
  }

  // Method to send data to store new project info
  generateProject(): void {
    if (this.projectId) {
      this.currentProject.descriptionProduct = (this.formFields.proceso.value !== null ? this.formFields.proceso.value.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '') : null )   || null;
      this.currentProject.optionSolution = this.formFields.opciones.value;
    }
  }

  // Method to send data to store account info
  update(): void {
    if (this.forma.invalid) {
      this.snackbarService.showSnackBar('Cerrar', 'Revise los campos requeridos ⚠️');
      return;
    }
    this.generateProject();
    if (this.projectId) {
      this.projectService.updateProject(this.currentProject,this.projectId).subscribe(data => {
          this.snackbarService.showSnackBar('Cerrar', 'Datos guardados! 🎉');
          this.router.navigate(['app/describe-clientes', this.projectId]);
      }, (err) => { this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error. ⚠️'); });
    }
  }

}
