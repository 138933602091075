import { Component, OnInit } from '@angular/core';
import { ProjectService } from './../../../services/project.service';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BusinessModel } from '../../../models/businessModel/businessModel';
import { SnackbarService } from '../../../services/notifications/snackbar.service';
import { NavigationSteps } from 'src/app/classes/navigationSteps';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ProjectCompletedDialogComponent } from '../../shared/dialogs/project-completed-dialog/project-completed-dialog/project-completed-dialog.component';
import { ModelProgress } from '../../../models/modelProgress/modelProgress';

@Component({
  selector: 'app-describe-clients',
  templateUrl: './describe-clients.component.html',
  styleUrls: ['./describe-clients.component.css']
})
export class DescribeClientsComponent implements OnInit {

  // current step number of this page
  stepNumber = 3;
  // total number of steps
  totalNumSteps = 7;
  // Property to identify the current project
  currentProject: BusinessModel;
  // Property to identify the project id based on a url param
  projectId: string;
  // Property to identify the form
  forma: FormGroup;
  // Property to identify the acquisitionsLevel (select control)
  acquisitionsLevel: string = '';
  // Property to identify if the form has been already submitted
  submitted = false;
  // Property to store the age ranges of the service
  ageRanges: [];
  // Property to store the client types of the service
  ClientTypes: [];
  // Property to store the client types of the service
  genders: [];
  // Property to store the genders of the service
  AcquisitionLevels: [];
  // Property to manage the ange ranges of checkbox for the formbuilder
  ageRange: any;
  // Property to manage the project select disabled
  visibleClientOpt: boolean = false;
  // Property to manage the project select disabled
  visibleCompanyOpt: boolean = false;
   // Property to manage the project select disabled
  visibleAgeOpt: boolean = false;
  // property to store guid from api others support
  client_Persons: string = '';
  client_Companies: string = '';
  // Indicates with colors the steps that are already done
  modelProgress: ModelProgress[];
  // Load steps of diagnostic in order to display navbar
  projectSteps = NavigationSteps;

  constructor(private snackbarService: SnackbarService,
              private fb: FormBuilder,
              private router: Router,
              private projectService: ProjectService,
              private route: ActivatedRoute,
              private dialog: MatDialog) {
    this.forma = this.fb.group({
      cliente: [''],
      genero: [''],
      tipo: [''],
      acquisitionsLevel: [''],
      caracteristicas: ['', [Validators.maxLength(600)]],
      checkArray: this.fb.array([])
    });
  }

  /* Method to get the options to be displayed as checked
  * @param guid - generated guid from api
  */
  checkedGenerator(guid) {
    return this.formFields.checkArray.value.includes(guid);
  }

  /**
   * Validator method to disable all the other checkbox when there is 2 already checked.
   * @param agesGuid - category guid to verify if is inside the array of checked values
   */
  validatorAgesCheckboxes(agesGuid: string): boolean {
    const control = this.forma.get('checkArray').value;
    return control.length === 2 && !control.includes(agesGuid) ? true : false;
  }

  // Convenience getter for easy access to form fields
  get formFields() {
    return this.forma.controls;
  }

  // Method to add/remove value of a checkbox into the checkCategories formcontrol value
  onClientChange(): void {
    if (this.formFields.cliente.value === this.client_Persons) {
      this.visibleClientOpt = true;
      this.visibleCompanyOpt = false;
      this.visibleAgeOpt = true;
      this.formFields.acquisitionsLevel.setValue(null);
    } else {
      this.visibleClientOpt = false;
      this.visibleCompanyOpt = true;
      this.visibleAgeOpt = false;
      this.formFields.genero.setValue(null);
      this.formFields.checkArray = this.fb.array([]);
    }
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      if (params.id) {
        this.projectId = params.id;
        this.setProjectValues();
      }
    });
  }

  /**
   * Method to return the lenght of a textfield
   * @param str - string to look for the chars
   * Return - number of chars
   */
  countChars(str): number {
    if (str) {
      const chars = str.length;
      return chars;
    } else {
      return 0;
    }
  }

  // Method to set the current form fields data from  api's database according to the current model_guid
  setProjectValues(): void {
    this.projectService.getProjectStageData(this.projectId)
      .then(data => {
        this.currentProject = new BusinessModel(data.data);
        this.modelProgress = this.currentProject.modelProgress;
        (data.data.clientTypes.length) ? this.formFields.cliente.setValue(data.data.clientTypes[0].clientType_guid) : [];
        (data.data.genders.length) ? this.formFields.genero.setValue(data.data.genders[0].gender_guid) : [];
        this.formFields.acquisitionsLevel.setValue(data.data.acquisitionsLevel?.acquisitionsLevel_guid);
        this.formFields.caracteristicas.setValue(data.data.customerDescription);
        const checkArray: FormArray = this.forma.get('checkArray') as FormArray;
        data.data.ageRanges.forEach(element => {
          checkArray.push(new FormControl(element.ageRange_guid));
        });
        this.getAgesRanges();
        this.getClientTypes();
        this.getGenders();
        this.getAcquisitionLevels();

      })
      .catch(error => {
        this.router.navigate(['app/inicio']);
        this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error. ⚠️');
      }
      );
  }

  // Method to get the project AcquisitionLevels
  getAcquisitionLevels(): void {
    this.projectService.getAcquisitionLevels()
      .then(data => {
        this.AcquisitionLevels = data.data;
      })
      .catch(error => {
        this.router.navigate(['app/inicio']);
        this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error.⚠️');
      }
      );
  }

  // Method to get the project genders
  getGenders(): void {
    this.projectService.getGenders()
      .then(data => {
        this.genders = data.data;
      })
      .catch(error => {
        this.router.navigate(['app/inicio']);
        this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error.⚠️');
      }
      );
  }

  // Method to get the project ageRange-list
  getAgesRanges(): void {
    this.projectService.getAgesRanges()
      .then(data => {
        this.ageRanges = data.data;
      })
      .catch(error => {
        this.router.navigate(['app/inicio']);
        this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error.⚠️');
      }
      );
  }

  // Method to get the project ageRange-list
  getClientTypes(): void {
    this.projectService.getClientTypes()
      .then(data => {
        this.ClientTypes = data.data;
        data.data.forEach(item => {
          if (item.description === 'Personas') {
            this.client_Persons = item.clientType_guid;
          }

          if (item.description === 'Empresas e instituciones') {
            this.client_Companies = item.clientType_guid;
          }
        });
        this.visibleClientOpt = this.formFields.cliente.value == this.client_Persons ? true : false;
        this.visibleCompanyOpt = this.formFields.cliente.value == this.client_Companies ? true : false;
        this.visibleAgeOpt = this.visibleClientOpt;
      })
      .catch(error => {
        this.router.navigate(['app/inicio']);
        this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error. ⚠️');
      }
      );
  }
  /**
   * Method to manage the valid inputs with a css color
   */
  get clienteNoValido() {
    return this.forma.get('cliente').invalid && this.forma.get('cliente').touched;
  }
  /**
   * Method to manage the valid inputs with a css color
   */
  get GeneroNoValido() {
    return this.forma.get('genero').invalid && this.forma.get('genero').touched;
  }

  // Method to add/remove value of a checkbox into the checkArray formcontrol value
  onCheckboxChange(e): void {
    const checkArray: FormArray = this.forma.get('checkArray') as FormArray;
    if (e.target.checked) {
      checkArray.push(new FormControl(e.target.value));
    } else {
      let i = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  // Method to set the current project data from the current page's form
  generateProject(): void {
    if (!this.projectId) { return; }
    
    this.currentProject.clientTypes = (this.formFields.cliente.value) ? [this.formFields.cliente.value] : [];
    this.currentProject.customerDescription = (this.formFields.caracteristicas.value !== null ? this.formFields.caracteristicas.value.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '') : null )  || null;
    this.currentProject.acquisitionsLevel = this.formFields.acquisitionsLevel.value;
    this.currentProject.genders = (this.formFields.genero.value) ? [this.formFields.genero.value] : [];
    this.currentProject.ageRanges = (this.formFields.cliente.value) ? this.formFields.checkArray.value : null;
  }

  // Method to send data to store account info to be updated
  update(): void {
    this.submitted = true;
    if (this.forma.invalid) {
      this.snackbarService.showSnackBar('Cerrar', 'Revise los campos requeridos ⚠️');
      return;
    }
    this.generateProject();

    if (this.projectId) {
      this.projectService.updateProject(this.currentProject,this.projectId).subscribe(data => {
          this.snackbarService.showSnackBar('Cerrar', 'Datos guardados! 🎉');
          this.router.navigate(['app/propuesta-valor', this.projectId]);
      }, (err) => { this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error.⚠️'); });
    }
  }

}
