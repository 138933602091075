import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FirebaseService } from 'src/app/services/firebase-service/firebase.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  constructor(private location: Location,
    private firebase: FirebaseService) { }

  ngOnInit(): void {
    this.firebase.logEvent('termsConditions');
  }

  // Go to previous page
  goBack(): void {
    this.location.back();
  }

}
