<div class="main-container animated fadeIn fast ">
  <div class="card-body card text-center border-0 animated fadeIn fast pt-5">
    <div class="text-center w-100">
      <img src="assets/img/board.png" class="img-fluid posible-logo mb-3">
      <h2>¡¡Felicidades!!</h2>
      <div class="text-description mt-3">
        <p>Haz completado con éxito el registro de tu proyecto. Ya puedes enviarlo a revisión o si lo deseas puedes
          hacerlo mas tarde.</p>
        <img src="assets/img/logo.png" class="img-fluid tabla-img">
      </div>
    </div>
    <div class="mt-3 pb-5 px-5 text-center">
      <button type="button" class="icon btn-lg btn btn-block btn_send_later" (click)="navigateHome()">
        <span class="btn_continuar_text">Salir y enviar después</span>
      </button>
    </div>
    <div class="mt-5 px-4 text-center">
      <button type="button" class="btn-lg btn btn-block btn-warning btn_send_review" (click)="sendReview()">
        <span class="btn_continuar_text">Enviar a revisión</span>
      </button>
    </div>
  </div> <!-- card main-->
</div>