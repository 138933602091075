<app-pending-businessmodels-modal></app-pending-businessmodels-modal>
<div class="container animated fadeIn fast publications__container">
  <section>
    <div class="row">
      <div class="col" *ngFor="let type of types" (click)="selectCategory(type.guid)">
        <p class="section-title cursor__pointer" [ngClass]="{'title--active': currentType == type.guid}">
          {{ type.description }}
        </p>
        <hr class="hr-title" [ngClass]="{'hr--active': currentType == type.guid}">
      </div>
    </div>

    <ng-container *ngIf="allPosts?.length && !isLoading else noResults">
      <div class="search-results" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50"
        (scrolled)="onScroll()">
        <div *ngFor="let publication of allPosts">
          <app-publication [publication]="publication" [isDetail]="false" [posibleUser]="true" parentPage="feedPosible"></app-publication>
        </div>
      </div>
    </ng-container>



    <ng-template #noResults>
      <div class="row" *ngIf="!isLoading && allPosts?.length == 0">
        <div class="col">
          <span class="pl-2">No se encontraron publicaciones...</span>
        </div>
      </div>
    </ng-template>

    <div *ngIf="isLoading" class="d-flex justify-content-center mt-5">
      <mat-spinner diameter="60"></mat-spinner>
    </div>


  </section>
</div>


<!--  -->
