import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'src/app/classes/global';

@Injectable({
  providedIn: 'root'
})
export class PanelService {
  globals = Globals;
  baseUrl = `${this.globals.urlPosibleBase}${this.globals.panel.pathPanel}`;

  constructor(private http: HttpClient) { }

  // Method to get all the states
  async getAll(): Promise<any> {
    return await this.http.get(this.baseUrl).toPromise();
  }

  /**
   * Method to get the cities based on the state id received
   * @param id - state id
   */
  async getDays(): Promise<any> {
    const url = `${this.baseUrl}${this.globals.panel.days}`;
    return await this.http.get(url).toPromise();
  }

  async getTimes(id): Promise<any> {
    const url = `${this.baseUrl}${id}${this.globals.panel.times}`;
    return await this.http.get(url).toPromise();
  }

  async createAppointment(requirements): Promise<any> {
    const url = `${this.baseUrl}${this.globals.panel.agendar}`;
    return await this.http.post(url, requirements).toPromise();
  }

  async uploadPPT(file,id): Promise<any> {
    const url = `${this.baseUrl}${id}${this.globals.panel.ppt}`;
    const formData: FormData = new FormData();
    formData.append('ppt', file);
    return await this.http.post(url, formData).toPromise();
  }

  async uploadLink(file,id): Promise<any> {
    const url = `${this.baseUrl}${id}${this.globals.panel.enlace}`;
    const formData: FormData = new FormData();
    formData.append('enlace', file);
    return await this.http.post(url, formData).toPromise();
  }

  async getStatus(id): Promise<any> {
    const url = `${this.baseUrl}${id}${this.globals.panel.status}`;
    return await this.http.get(url).toPromise();
  }

  async getAppointment(id): Promise<any> {
    const url = `${this.baseUrl}${id}${this.globals.panel.detalles}`;
    return await this.http.get(url).toPromise();
  }
}
