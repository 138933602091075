import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase-service/firebase.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-privacy-advice-woman',
  templateUrl: './privacy-advice-woman.component.html',
  styleUrls: ['./privacy-advice-woman.component.scss']
})
export class PrivacyAdviceWomanComponent implements OnInit {

  constructor(private location: Location,
    private firebase: FirebaseService) { }

  ngOnInit(): void {
    this.firebase.logEvent('privacy-advice');
  }

  // Go to previous page
  goBack(): void {
    this.location.back();
  }

}
