<div class="container-fluid support_module pb-5">

        <div class="row section_header">
            <div class="col-12 d-flex align-items-center">
                <a (click)="returnToBack()" class="return_link mr-2"><i class="fa fa-chevron-left"></i></a>
                <span class="title_module">Material de apoyo</span>
            </div>
        </div>
        <!-- Recommended videos -->
        <div class="row section_content my-3">
            <div class="col-12">
                <span class="section_support_title">Videos recomendados: </span>
            </div>
            <div class="col-12">
                <hr class="orange_hr mb-3 mt-2" />
            </div>
            <div class="col-12">
              <div class="container_videos_support">
                    <div class="card card_video" *ngFor="let video of supportMaterialData?.videos;" >
                       <iframe *ngIf="video.typeSrc == 1" [src]="video.url | safe"  height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen ></iframe>

                       <iframe *ngIf="video.typeSrc == 0" src="https://www.youtube.com/embed/FoYBiR4xWb8"  height="300" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                       allowfullscreen ></iframe>
                        <div class="card-body">
                            <p class="card-text video_title">{{video.title}}.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Recommended videos -->
        <!-- Posts -->
        <div class="row section_content my-4">
            <div class="col-12">
                <span class="section_support_title">Artículos: </span>
            </div>
            <div class="col-12">
                <hr class="orange_hr mb-3 mt-2" />
            </div>
            <div class="col-12 pb-3">
                <span class="help_posts_title">Temas que podrían ayudarte a completar tu modelo de negocio: </span>
            </div>
            <div class="col-12 help_posts">
              <div *ngFor="let post of supportMaterialData?.posts;">
                <div *ngIf="post.isVideo">
                  <span class="item_title mr-auto">{{post.title}} </span>
                  <iframe width="100%" height="552" [src]="post.url | safe" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div *ngIf="!post.isVideo" class="item_help my-2" (click)="goToPost(post.url)">
                    <span class="item_title mr-auto">{{post.title}} </span>
                    <img src="assets/img/icons/link.png" class="item_help_link" width="19px" alt="">
                </div>
              </div>
            </div>
        </div>
        <!-- Posts -->
        <!-- Glossary -->
        <div class="row section_content my-4">
            <div class="col-12">
                <span class="section_support_title">Glosario: </span>
            </div>
            <div class="col-12">
                <hr class="orange_hr mb-3 mt-2" />
            </div>
            <div class="col-12 glossary my-1" *ngFor="let glossary_item of supportMaterialData?.glossary;">
                <mat-accordion>
                    <mat-expansion-panel (opened)="glossary_item.panelOpenState = true" (closed)="glossary_item.panelOpenState = false" [ngClass]="{'glossaryItemOpened' : glossary_item.panelOpenState}">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="glossary_title">{{glossary_item.title}}</mat-panel-title>
                        </mat-expansion-panel-header>
                        <p class="glossary_item" [innerHTML]="glossary_item.description"></p>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
        <!-- Glossary -->


</div>
