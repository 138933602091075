import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-congratulation-women-entrepreneur',
  templateUrl: './congratulation-women-entrepreneur.component.html',
  styleUrls: ['./congratulation-women-entrepreneur.component.scss']
})
export class CongratulationWomenEntrepreneurComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
