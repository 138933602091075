<footer [ngClass]="addStickyStyle()" class="fixed-bottom" *ngIf="isLoggedIn">
    <div class="container">
        <ul class="footer-menu">
            <li class="menu-item">
                <i [routerLink]="['/app/inicio']" routerLinkActive="i-active" class="menu-home"></i>
            </li>
            <li class="menu-item">
                <i (click)="sendWhatsapp()" routerLinkActive="i-active" class="menu-whatsapp"></i>
            </li>
            <li class="menu-item ">
                <i [routerLink]="[ '/app/contactos'] " routerLinkActive="i-active " class="menu-chat "></i>
                <span class="news " *ngIf="haveMessageNotifications "></span>
            </li>
            <li class="menu-item ">
                <i class="menu-feed " [routerLink]="[ '/app/feed'] " routerLinkActive="i-active "></i>
            </li>
            <li class="menu-item ">
                <i class="menu-notification " [routerLink]="[ '/app/notificaciones'] " routerLinkActive="i-active "></i>
                <span class="news " *ngIf="havePublicationNotifications "></span>

            </li>
            <li class="menu-item ">
                <i [routerLink]="[ '/app/red'] " routerLinkActive="i-active " class="menu-contacts "></i>
                <span class="news " *ngIf="haveFriendRequest "></span>
            </li>
        </ul>
    </div>
</footer>