import * as moment from 'moment';
import { Globals } from 'src/app/classes/global';
import { Comment } from '../comment/comment';
// Class to implement attributes of a publication with details
export class PublicationDetail {
    // variable that store the import of Globals Class that will help us to retrieve the urls for every service
    private globals = Globals;

    guid: string;
    userGuid: string;
    username: string;
    userPicture: string;
    userStatus: string;
    feedPosible: boolean;
    categories: [];
    description: string;
    likes: number;
    liked: boolean;
    comments: number;
    commentsList: Comment[];
    files: any;
    company: string;
    firstName: string;
    lastName: string;
    date: any;
    isAd: boolean = false;
    publicationParent: PublicationDetail;
    video: string;
    url: string;

    constructor(data: any) {
        // setup moment language and set the time passed of this publication
        moment.locale('es');
        this.guid = data.publication_guid;
        this.userGuid = data.user.user_guid;
        this.username = data.user.username;
        this.userPicture = data.user.image_path;
        this.userStatus = data.user.status;         
        this.company = data.user.company;
        this.firstName = data.user.first_name;
        this.lastName = data.user.last_name;
        this.feedPosible = data.feedPosible;
        this.categories = data.categories;
        this.description = data.description;
        this.likes = data.likes;
        this.liked = data.liked;
        this.comments = data.comments;
        this.commentsList = data.comments_list.map(comment => new Comment(comment));
        this.isAd = data.is_ad;
        this.video = data.video;
        this.url = data.videoUrl;
        this.files = this.manageFileStructure(data.file);
        this.date = moment(data.createDate).format('LL');
        this.publicationParent = data.publicationParent.publicationParent != null ? new PublicationDetail(data.publicationParent) : null;
    }

    /**
     * Method to manage the file structure based in if the publication is an ad or not.
     * @param filesReceived - file object received from an api
     */
    manageFileStructure(filesReceived: []): [] {
        let files;
        if (this.isAd) {
            files = this.generateAdsFiles(filesReceived);
        } else {
            files = filesReceived.map(file => this.generateFileObject(file));
        }
        return files;
    }

    /**
     * Method to generate the files array when a publication is an Ad
     * @param files - array of file objects received from the api
     */
    generateAdsFiles(files) {
        let newFiles = files
            .map(file => {
                return { image: file['image'], thumbImage: file['image'], type:'image' };
            });
        if (this.video) {
            newFiles = [...newFiles, { video: this.video }];
        }
        return newFiles;
    }
    /**
     * Method to generate an object with an specific format
     * @param file - file object received from an api
     */
    generateFileObject(file): object {
        let fileObject = {};
        if (file.type === 'image') {
            fileObject[file.type] = file.url;
            fileObject['thumbImage'] = file.url;
            fileObject['type'] = file.type;
        } else {
            fileObject[file.type] = file.url;
        }
        return fileObject;
    }
}
