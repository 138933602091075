import { Component, OnInit, Input } from '@angular/core';
import { UserStatus } from 'src/app/models/user-status/user-status.enum';

@Component({
  selector: 'app-shared-publication',
  templateUrl: './shared-publication.component.html',
  styleUrls: ['./shared-publication.component.scss']
})
export class SharedPublicationComponent implements OnInit {
  
  @Input() publication;  
  // Property to identify the whole list of user status
  userStatus = UserStatus;
  // property to display the whole text
  showMore = false;

  constructor() { }

  ngOnInit(): void {
  }
}
