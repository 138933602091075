<div class="container loading-files w-100 m-0" [ngClass]="{'show': fileList.uploadingFiles.length}">
    <div class="row">
      <div class="col">
        <span>Subiendo archivos</span>
      </div>
    </div>
    <div class="row">
      <div class="col file-list">
        <div class="file-item" *ngFor="let file of fileList.uploadingFiles">
          <i class="fa" [ngClass]="getElementIcon(file)"></i>
          <div class="progress">
            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" attr.aria-valuenow="{{file.porcentage}}" aria-valuemin="0" aria-valuemax="100" style="width: {{file.porcentage}}%">{{file.porcentage}}%</div>
          </div>
        </div>
      </div>
    </div>
</div>