<div *ngIf="isLoading; else elseLoading" class="d-flex justify-content-center mt-5">
  <mat-spinner diameter="60"></mat-spinner>
</div>
<ng-template #elseLoading>
  <div class="container header">
    <div class="row align-items-center">
      <div class="col-3">
        <div *ngIf="!user?.image" class="img-placeholder"></div>
        <img *ngIf="user?.image" clang-template class="img-circle" height="56px" width="56px" [src]="user?.image">
        <span class="{{user.status}}" ></span>
      </div>
      <div class="col-9 d-flex flex-column">
        <p class="name">{{ user?.name }}</p>
        <div class="btn-container d-flex" *ngIf="haveRequest">
          <button class="btn action-btn mr-2" (click)="declineFriend()" [disabled]="isLoading">
            <i class="fa fa-times small-icon reject"></i>
            <i class="fa fa-user big-icon reject pr-1"></i>
            Rechazar
          </button>
          <button class="btn action-btn" (click)="acceptFriend()" [disabled]="isLoading">
            <i class="fa fa-check small-icon approve"></i>
            <i class="fa fa-user big-icon approve pr-1"></i>
            Aceptar
          </button>
        </div>
      </div>
    </div>
  </div>
  <div id="no-editable">
    <div class="container about-user">
      <div class="row align-items-center">
        <div class="col-12 mt-2">
          <div class="field">
            <div class="title">
              <i class="icon icon-user"></i> <span class="label">Acerca de mí:</span>
            </div>
            <p class="value">
              {{user?.description}}
            </p>
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-12 mt-2">
          <div class="field">
            <div class="title">
              <i class="icon icon-study"></i><span class="label">Estudios:</span>
            </div>
            <p class="value">{{user?.education}}</p>
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-12 mt-2">
          <div class="field">
            <div class="title">
              <i class="icon icon-star"></i> <span class="label">Intereses:</span>
            </div>
            <p class="value">
              <span *ngFor="let topic of user?.topics; index as i">
                {{ topic.description }}{{ (i < (user.topics.length - 1)) ? ', ' : ''  }}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

