<div class="main-container-card cardColor">
  <div class="container animated fadeIn px-4">
  <header class="text-center" style="text-align: center;">
    <span class="titulo_pass_recover">
      <br><br><br> <br>
        Olvidé mi contraseña
    </span>
  </header>
  <section>
    <div class="animated fadeIn fast px-2">
      <p class="common_txt">
        <br>
        Ingresa tu correo electrónico registrado y te enviaremos las instrucciones para restablecerla.
    </p>
    </div>
      <form autocomplete="off" [formGroup]="forgetForm">
        <div class="mt-5 input-group input-group-lg text-center">
            <input  type="email"
                    style="border-radius: 7px;"
                    class="form-control input-rounded"
                    aria-label="Large"
                    placeholder="Ej Correo electrónico"
                    formControlName="correo"
                    [class.is-invalid]="formFields.correo.errors"
                    >
        </div>
        <div *ngIf="isSubmitted && formFields.correo.errors" class="text-danger animated fadeIn text-center">
          <span *ngIf="formFields.correo.errors.required">Este correo no es válido.</span>
        </div>
        <br>
        <button (click)="enviar()" class="btn-lg btn btn-block btn-warning btn_style" [disabled]="isLoading">
          <span class="btn_style_text">Continuar</span>
        </button>
    <br>
  </form>
  <a style="text-align: center;" class="nav-link backText" [routerLink]="['/login']"><i class="fa fa-arrow-left" aria-hidden="true"></i> Regresar</a>

  </section>
</div>





</div> <!--main-container-->



