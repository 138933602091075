import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DiagnosticService } from '../../../services/diagnostic/diagnostic.service';
import { SnackbarService } from 'src/app/services/notifications/snackbar.service';
import { AuthService } from '../../../services/auth.service';
import { DiagnosticSteps } from 'src/app/classes/diagnosticSteps';
@Component({
  selector: 'app-create-project4',
  templateUrl: './create-project4.component.html',
  styleUrls: ['./create-project4.component.scss']
})
export class CreateProject4Component implements OnInit {
  // Property to store the stage of the diagnostic project
  stage: string;
  // Property to identify the form
  formb: FormGroup;
  // var to store info from api-service list
  options: [];
  // current step number of this page
  stepNumber = 4;
  // total number of steps
  totalNumSteps = 6;
  // property to store guid value
  idea_value: string = '';
  // Load steps of diagnostic in order to display navbar
  diagnosticSteps = DiagnosticSteps;
  // Show the states of the steps
  modelProgress = [];

  constructor(private fb: FormBuilder,
              private router: Router,
              private dgService: DiagnosticService,
              private authService: AuthService,
              private snackbarService: SnackbarService) {
    this.stage = this.authService.diagnosticValue.stage || '';
    this.modelProgress = this.authService.diagnosticValue.modulesCompleted || [];
    this.formb = this.fb.group({
      stagefb: [this.stage, [Validators.required]]
    });
  }
  ngOnInit(): void {
    this.getStages();
  }

  // Method to get the project stages list
  getStages(): void {
    this.dgService.getStages().then(data => {
      this.options = data.data;
    }).catch( err =>  { this.snackbarService.showSnackBar('Cerrar', err )});
  }

  /**
   * Method to manage the valid inputs with a css color
   */
  get noValid(): boolean {
    return this.formb.get('stagefb').invalid && this.formb.get('stagefb').touched;
  }

  // Method to store the posible stage of the diagnostic info
  save(): void {
    if (this.formb.invalid) {
      return Object.values(this.formb.controls).forEach(control => {
        control.markAllAsTouched();
      });
    }
    const stage = this.formb.value.stagefb;
    this.authService.updateDiagnosticData({ stage }).then( result => {
      this.snackbarService.showSnackBar('Cerrar', "Se actualizó diagnóstico satisfactoriamente");
      if (stage === this.idea_value) {
        this.router.navigate(['app/crear-proyecto8']);
      } else {
        this.router.navigate(['app/crear-proyecto5']);
      }
    }).catch( err =>  { this.snackbarService.showSnackBar('Cerrar', err )});
  }

  /**
   * Receives the url to the empty question
   * @param $event - Url
   */
  redirectToEmptyQuestion($event): void{
    this.router.navigate([$event]);
  }

}
