import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DiagnosticService } from '../../../services/diagnostic/diagnostic.service';
import { SnackbarService } from 'src/app/services/notifications/snackbar.service';
import { AuthService } from '../../../services/auth.service';
@Component({
  selector: 'app-create-project8',
  templateUrl: './create-project8.component.html',
  styleUrls: ['./create-project8.component.scss']
})
export class CreateProject8Component implements OnInit {
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  // Redirects to topics page
  goToLastStep(): void {
    this.router.navigate(['app/feed']);
  }

}
