<div class="publication-detail pt-2" [ngClass]="{ 'share': isShareable, 'container mb-5': isDetail, 'shared': isShared }">  
  <div class="row">
    <div class="col-12">
      <div class="container-post">        
        <app-share-publication *ngIf="isShareable"></app-share-publication>
        <ng-container *ngIf="publication else noResults" class="w-100">
          <app-publication [publication]="publication" [isDetail]="isDetail" [isShareable]="isShareable"
            [isShared]="isShared" [sharedPublication]="publicationShared" [parentPage]="parentPage"></app-publication>
        </ng-container>

        <ng-template #noResults>
          <div class="row" *ngIf="!isLoading">
            <div class="col">
              <span class="pl-2">No se encontró la publicación...</span>
            </div>
          </div>
        </ng-template>

        <div *ngIf="isLoading" class="d-flex justify-content-center mt-5">
          <mat-spinner diameter="60"></mat-spinner>
        </div>
      </div>
    </div>
  </div>
</div>
