import { ValidatorFn, FormGroup, ValidationErrors, AbstractControl } from '@angular/forms';
import * as moment from 'moment';

export function minimumAge(age:number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
        let date = control.value.startDate;
        if(date){
          date = date.format('DD/MM/yyyy');
          let years = Math.floor(moment(new Date()).diff(date,'years',true));
          if (date && years < age ){
            return {
              'minimumAge': {
                'requiredAge': age,
                'actualAge': years,
              }
            };
          }
        }
        return null;
      };
  }
  