import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { microsites } from 'src/app/classes/microsites';

@Component({
  selector: 'app-reto-zacatecas',
  templateUrl: './reto-zacatecas.component.html',
  styleUrls: ['./reto-zacatecas.component.scss']
})
export class RetoZacatecasComponent implements OnInit {
  // Edo microsite info
  currentMicroSiteStateInfo = {}
  microsites = microsites;

  constructor(private _vps: ViewportScroller,  private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.currentMicroSiteStateInfo = this.microsites['no-edo']

    // Get microsite param
    this.route.params.subscribe((params: any) => {
      this.currentMicroSiteStateInfo = this.microsites['retoZacatecas']
    });
  }

  scrollFn(anchor: string): void{
  	this._vps.scrollToAnchor(anchor)
  }
}
