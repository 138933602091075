<div class="card-body create-comment pt-0">
  <div class="row">
    <div class="col">
      <form [formGroup]="commentForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label for="exampleInputEmail1" class="comments__label">Escribe tu comentario:</label>
          <span class="float-right txt_advice"  *ngIf="countChars(formFields.description.value) == 0" >Máx 1500 caracts.</span>
          <span class="float-right txt_advice"  *ngIf="countChars(formFields.description.value) >= 1 && countChars(formFields.description.value) < 1500"> {{1500 - countChars(formFields.description.value) }} restantes caracts.</span>
          <span class="float-right txt_advice"  *ngIf="countChars(formFields.description.value) == 1500"> Tope alcanzado.</span>
          <textarea class="form-control comments__textarea" 
                    id="exampleFormControlTextarea1" 
                    rows="3"
                    maxlength="1500"
                    formControlName="description"></textarea>            
          <div *ngIf="submitted && formFields.description.errors" class="errors pt-1">
            <small *ngIf="formFields.description.errors.required" class="text-danger animated fadeIn">
              El comentario no puede estar vacío.
            </small>
          </div>
        </div>
        <button type="submit" [disabled]="isLoading" class="btn comments__publish pull-right">{{ isLoading ? 'Publicando':'Publicar' }}</button>
      </form>
    </div>
  </div>
</div>
