<div *ngIf="isLoading; else elseLoading" class="d-flex justify-content-center mt-5">
    <mat-spinner diameter="60"></mat-spinner>
</div>
<ng-template #elseLoading>
    <div class="container">
        <div class="row header">
            <div class="col-12 d-flex flex-column align-items-center justify-content-center">
                <span class="title pt-3">{{ project.name }}</span>
                <p class="project-percentage mb-2">
                    Avance <b>{{ project.percentage }}%</b>
                </p>
                <span class="folio text-center" *ngIf="project.status != projectList.Nuevo">
          Folio: <strong>#{{ project.invoice }}</strong>  
        </span>
            </div>
            <div class="col-12 status-text">
                <!-- -->
                <button *ngIf="project.status == projectList.Nuevo" type="button" class="edit" style="height: 50px; border-radius: 15px;" [routerLink]="['/app','nuevo-proyecto', id]">
          Click aqui para editar modelo de negocios
        </button>
                <!-- -->
                <button *ngIf="project.status == projectList.Revision" type="button" class="revision">
          Tu modelo de negocios fue enviado a revisión
        </button>
                <!-- -->
                <button *ngIf="project.status == projectList.Aprobado" type="button" class="panels-termino">
        <!-- ¡Tu proyecto avanzó a los Paneles de POSiBLE 2022! --> Terminó tu plazo para agendar
        </button>
                <!-- -->
                <button *ngIf="project.status == projectList.Agendado" type="button" class="panels">
        <!-- Proyecto agendado para paneles 2022 --> Lamentamos que no hayas asistido a tu panel, más adelante estaremos compartiéndote cursos y talleres para seguir con tu formación
        </button>
                <!-- -->
                <button *ngIf="project.status == projectList.EvaluadoP" type="button" class="panels">
                    Lamentamos informarte que tu proyecto no ha avanzado a la fase final. Recuerda que no es una competencia, revisa tu correo o descarga tu modelo de negocio para mas detalles.
        </button>
                <!-- -->
                <button *ngIf="project.status == projectList.Rechazado" type="button" class="rejected">
          Tu modelo tiene retroalimentación
        </button>
                <!-- -->
                <button *ngIf="project.status == projectList.Completado" type="button" class="not-panels">
          tu modelo avanzó al campamento nacional
        </button>
                <p *ngIf="project.reasonRejection" class="description mb-0">
                    {{ project.reasonRejection }}
                </p>
                <button *ngIf="project.status == projectList.Asignado" type="button" class="not-panels">
          Tu modelo ha sido asignado a un evaluador
        </button>
                <button *ngIf="project.status == projectList.Evaluado" type="button" class="not-panels">
          Tu modelo ha sido evaluado
        </button>
                <button *ngIf="project.status == projectList.RechazadoP" type="button" class="rejected">
                    Lamentamos informarte que tu proyecto no ha avanzado a la fase final. Recuerda que no es una competencia, revisa tu correo o descarga tu modelo de negocio para mas detalles.
            </button>
                <button *ngIf="project.status == projectList.CampamentoE" type="button" class="not-panels">
                    Lamentamos informarte que tu proyecto no ha avanzado a la fase final. Recuerda que no es una competencia, revisa tu correo o descarga tu modelo de negocio para mas detalles.
            </button>
                <button *ngIf="project.status == projectList.CampamentoN" type="button" class="not-panels">
                    Has llegado a la última etapa de POSiBLE, es decir, el Campamento Nacional ¡Felicidades! Revisa tu correo para más detalles.
            </button>
                <button *ngIf="project.status == projectList.NoAsistioEstatal" type="button" class="rejected">
                Lamentamos no haber contado con tu participación en el campamento. Te invitamos a estar pendiente de nuestras redes sociales
            </button>
                <button *ngIf="project.status == projectList.AsistioEstatal" type="button" class="panels">
                    Lamentamos informarte que tu proyecto no ha avanzado a la fase final. Recuerda que no es una competencia, revisa tu correo o descarga tu modelo de negocio para mas detalles.
            </button>
                <button *ngIf="project.status == projectList.NoAsistioNacional" type="button" class="rejected">
                Lamentamos informarte que tu proyecto no ha avanzado a la fase final. Recuerda que no es una competencia, revisa tu correo o descarga tu modelo de negocio para mas detalles.
            </button>
                <button *ngIf="project.status == projectList.AsistioNacional" type="button" class="panels">
                Has llegado a la última etapa de POSiBLE, es decir, el Campamento Nacional ¡Felicidades! Revisa tu correo para más detalles.
            </button>

            </div>
        </div>

        <button *ngIf="project.status == projectList.Aprobado" disabled type="button" class="panels btn-lg btn btn-block btn-warning btn_continuar"><!-- (click)="openProjectSchedule(id)" -->
        Agenda tu cita para los Paneles 2022
    </button>
        <button *ngIf="project.status > 1" type="button" class="panels btn-lg btn btn-block btn-warning btn_continuar" (click)="downloadBusinessModel(id)">
        Descarga tu modelo de negocio 
    </button>
        <button *ngIf="project.status == projectList.EvaluadoP || project.status == projectList.RechazadoP || project.status == projectList.CampamentoE" type="button" class="panels btn-lg btn btn-block btn-warning btn_continuar" (click)="downloadCertificatePaneles(id)">
            Descarga tu constancia de Panel
    </button>
        <button *ngIf="(project.status == projectList.Agendado) && !expired" type="button" class="panels btn-lg btn btn-block btn-warning btn_continuar" (click)="addToSchedule(id)">
        Material de apoyo para tu pitch 
    </button>
        <div class="row button-place" *ngIf="project.status === projectList['Nuevo']">
            <button class="btn btn-block btn-default" #btnDeleteProject (click)="deleteProject()">
        <img src="../../../../assets/img/icons/eliminar.svg" class="mr-2"/>
        <div class="text">Eliminar proyecto</div>
      </button>
        </div>
    </div>
</ng-template>