<div class="container terms animated fadeIn fast">
  <div class="row">
    <div class="col-12 mt-4">
      <a class="text-left backText cursor__pointer" (click)="goBack()"><i class="fa fa-arrow-left"
          aria-hidden="true"></i> Regresar</a>
      <h2 class="txt_title text-center">PREGUNTAS FRECUENTES POSIBLE 2022</h2>
    </div>
  </div>

  <section class="pb-4 txt_common">
    <div class="animated fadeIn text-justify">
      <h3 class="mt-4"><b><u>¿Qué es POSiBLE?</u></b></h3>
      <p class="mt-4"><b>1. ¿Qué es POSiBLE?</b>
        <br>
        Es un programa que te ofrece formación, herramientas, conocimientos y contactos necesarios para que emprendas tu 
        idea de negocio de manera exitosa. Para participar, sólo debes regístrate en <a href="https://www.posible.org.mx">www.POSiBLE.org.mx</a> y es completamente gratuito.
      </p>

      <p><b>2. ¿POSiBLE tiene algún costo?</b>
        <br>
        No, participar en POSiBLE es gratis.
      </p>

      <p><b>3. ¿Mi idea de negocio está protegida?</b>
        <br>
        La idea de negocio plasmada te pertenece. Únicamente será utilizada para evaluar los proyectos o empresas. Los evaluadores que revisan las postulaciones firman 
        una carta compromiso, asegurando la protección de tu idea. La base de datos será administrada únicamente por el equipo POSiBLE, por lo que puedes confiar que tu 
        idea está protegida.
      </p>
      <p>
        En el siguiente link podrás encontrar los términos y condiciones donde viene el aviso de privacidad de datos: <a href="https://posible.org.mx/terminos/">https://posible.org.mx/terminos/</a>
      </p>

      <h3 class="mt-4"><b><u>¿Participar en POSiBLE?</u></b></h3>
      <p class="mt-4"><b>4. ¿Quién puede participar en POSiBLE?</b>
        <br>
        En POSiBLE cualquier persona mayor a 18 años que resida en México puede participar. Queremos ayudar a todos los emprendedores a desarrollar su idea de 
        negocio y/o mejorar sus habilidades emprendedoras.
      </p>

      <p><b>5. ¿Cómo puedo registrarme y participar en la convocatoria POSiBLE?</b>
        <br>
        <u>Paso 1:</u> Debes ingresar a la página <a href="https://www.posible.org.mx">www.posible.org.mx</a>, te registrarás con tu correo electrónico y generarás una contraseña para poder acceder a la plataforma. Aceptarás 
        nuestro aviso de privacidad, así como términos y condiciones. Una vez hecho esto, podrás iniciar sesión.
      </p>
      <p>
        <u>Paso 2:</u> Una vez que inicies sesión, comenzarás a llenar información básica de tu idea o proyecto de negocio; esto nos permitirá conocerte un poco mejor.
      </p>
      <p>
        <u>Paso 3:</u> Después de haber proporcionado esta información, comenzarás con el llenado de tu modelo de negocio, que consta de 7 módulos con sencillas preguntas que te permitirán desarrollar tus ideas.
      </p>
      <p>
        En cada uno de los 7 módulos, encontrarás herramientas de apoyo como artículos, glosario y videos que te ayudarán a entender lo que te preguntamos, y de esta manera logres explicar a la perfección tu idea de negocio.
      </p>

      <p><b>6. ¿Si soy extranjero puedo participar en la convocatoria?</b>
        <br>
        ¡Claro! Mientras residas en el país puedes participar ya que, si eres elegido para las siguientes etapas, puedas asistir sin ningún problema.
      </p>

      <p><b>7. ¿Qué tipo de ideas, proyectos o empresas pueden participar?</b>
        <br>
        En POSiBLE participan todas las personas que tienen una idea de negocio o que operan una empresa. Pueden ser de cualquiera de las siguientes categorías:
      </p>
      <ol>
        <li>Alimentos, bebidas y restaurantes</li>
        <li>Telecomunicaciones y telefonía móvil</li>
        <li>Ciencia, energía, tecnología e Internet de las cosas</li>
        <li>Comercio electrónico y marketplaces</li>
        <li>Software as a service</li>
        <li>Entretenimiento y medios</li>
        <li>Diseño, arte y moda</li>
        <li>Web y redes sociales</li>
        <li>Hardware y robótica</li>
        <li>Software y desarrollo de videojuegos</li>
        <li>Servicios y tecnología financieros</li>
        <li>Servicios agropecuarios, animales y mascotas</li>
        <li>Fabricación, materiales y objetos</li>
        <li>Viajes, turismo y hotelería</li>
        <li>Transporte y traslados</li>
        <li>Logística</li>
        <li>Salud, nutrición y deporte</li>
        <li>Educación, capacitación y educación tecnológica</li>
        <li>Construcción vivienda y servicios para el hogar</li>
        <li>Servicios profesionales, consultoría y legal</li>
        <li>Ciberseguridad</li>
        <li>Medio ambiente</li>
        <li>Artesanías</li>
      </ol>

      <h3 class="mt-4"><b><u>Etapas y proceso de selección POSiBLE</u></b></h3>
      <p class="mt-4"><b>8. ¿Cómo puedo llegar al Campamento POSiBLE?</b>
        <br>
        Regístrate en <a href="https://www.posible.org.mx">www.posible.org.mx</a> completa y envía a revisión el Modelo de Negocio. Si nuestro grupo de evaluadores te elige, podrás participar en los paneles.
        <br>
        En el panel, en una dinámica de pitch, expondrás tu proyecto frente a expertos que te calificarán y darán retroalimentación a tu proyecto. Si ellos califican 
        tu proyecto como innovador, de alto impacto, que tiene estructura, claridad y es viable, estarás invitado al Campamento Nacional POSiBLE y formarás parte de la comunidad POSiBLE
      </p>

      <p><b>9. ¿Cuáles son las etapas de POSiBLE?</b>
        <br>
        <b class="pl-2">1. Registra tu modelo de negocio:</b> Ingresa a <a href="https://www.posible.org.mx">www.posible.org.mx</a>, regístrate con tu correo y contraseña. Acepta el aviso de privacidad, así como los términos y condiciones de nuestra 
        convocatoria e inicia sesión. Desarrolla tu idea de negocio, a través del Modelo de Negocio POSiBLE que consta de 7 módulos, te ayudaremos a desarrollar tu idea de negocio, es muy 
        fácil. Para poder pasar cada etapa, es necesario completar el 100% de tu modelo de negocio y mandarlo a revisión.
      </p>
      <p>
        <b class="pl-2">2. Presenta tu proyecto en Paneles Estatales</b> Los modelos de negocio terminados son revisados por expertos para pasar a esta etapa, donde podrán presentar su proyecto, en una dinámica de pitch, ante un panel de expertos 
        especializados. Esto se realizará en distintos estados de la República de manera presencial o remota
      </p>
      <p>
        <b class="pl-2">3. Asiste al Campamento:</b> Hasta 100 proyectos podrán ser invitados a participar en un programa de cinco días, de manera presencial o remota, enfocado en validar su modelo de negocio, afinar sus estrategias de captación 
        de clientes, identificar fuentes de financiamiento y prepararse para hacer un pitch.
      </p>

      <p><b>10. ¿Cómo me entero de los resultados de cada una de las etapas?</b>
        <br>
        Recibirás la información por correo electrónico y a través de la plataforma de POSiBLE; te recomendamos revisar tu bandeja de “spam” o “no deseados”. De ser seleccionado o no, recibirás siempre una respuesta. Es importante 
        capturar de manera correcta tu correo electrónico.
      </p>

      <h3 class="mt-4"><b><u>¿Como participar en la convocatoria?</u></b></h3>
      <p class="mt-4"><b>11. ¿Qué es el Modelo de Negocio POSiBLE?</b>
        <br>
        El Modelo de Negocio POSiBLE es un formato, basado en preguntas específicas, que te ayudarán a aterrizar tu idea de negocio o empresa en un documento conciso. Cuenta con 7 módulos:
      </p>
      <ol>
        <li>Describe el Problema</li>
        <li>Explica tu idea de Negocio</li>
        <li>Describe a tus Clientes</li>
        <li>Explica tu Propuesta de Valor</li>
        <li>Define tu Publicidad y Distribución</li>
        <li>Explica cómo Generas Ingresos</li>
        <li>Describe tu Plan de Crecimiento</li>
      </ol>
      <p>
        En cada uno, te guiaremos paso a paso y podrás consultar las herramientas de apoyo, que tiene cada módulo, encontrarás también artículos que te servirán para un mejor llenado de tu 
        modelo de negocios, y lo harás de manera práctica.
      </p>

      <p><b>12. ¿Una vez que inicié mi modelo de negocio, puedo salir de la sesión y retomarlo en otro momento?</b>
        <br>
        Sí, una vez que comiences a llenar tu modelo de negocio podrás salir solo si das clic en Guardar, así que cuando quieras regresar a la información que escribiste estará disponible. 
        Tienes hasta el 16 de mayo de 2022 para hacer modificaciones y enviar tu modelo de negocios. En caso de haber cambios en fechas, te mantendremos informado
      </p>

      <p><b>13 . ¿Qué pasa si olvidé mi contraseña?</b>
        <br>
        No te preocupes, podrás recuperarla. En la sección para ingresar con usuario y contraseña encontrarás un botón con la pregunta “¿Olvidaste tu contraseña?” en el que le debes dar clic.
        <br>
        En tu correo electrónico recibirás un mensaje con una liga a la que podrás ingresar, para crear una contraseña nueva. Te recomendamos revisar tu bandeja de correos no deseados.
      </p>

      <p><b>14. ¿Puedo modificar mi información?</b>
        <br>
        Sí, puedes editar la información de los 7 módulos mientras no hayas enviado “a revisión” tu proyecto (esto es al final del último módulo), una vez que los evaluadores de 
        POSiBLE lo reciban, ya no tendrás oportunidad de editarlo, así que te recomendamos que conforme termines cada una de las etapas, lo revises bien.
      </p>

      <p><b>15. ¿Puedo adjuntar documentos a mi modelo de negocio?</b>
        <br>
        POSiBLE no necesita que adjuntes ningún documento durante el llenado de la aplicación. Si ya tienes documentos en los que has estado trabajando, utilízalos como apoyo 
        para completar tu Modelo de Negocio POSiBLE en la plataforma.
      </p>

      <p><b>16. Si tengo varias ideas. ¿Puedo inscribir todas?</b>
        <br>
        Tienes la oportunidad de registrar hasta 3 proyectos por participante.
      </p>

      <p><b>17. Si sólo tengo una idea pero no he comenzado a desarrollar mi negocio, ¿puedo aplicar?</b>
        <br>
        Sí, no importa que únicamente cuentes con una idea. En POSiBLE queremos apoyar a emprendedores para que conviertan esas ideas en una realidad. Una vez que empiezas a 
        llenar los módulos en la solicitud de POSiBLE podrás darle más estructura y claridad a tu idea de negocio.
      </p>

      <p><b>18. ¿Si llevo operando varios meses puedo aplicar a POSiBLE?</b>
        <br>
        Sí, al entrar a POSiBLE las herramientas/beneficios que te daremos te ayudarán a consolidar y hacer crecer tu negocio.
      </p>

      <p><b>19. Si apliqué en una convocatoria POSiBLE anterior y no fui seleccionado/a, ¿puedo volver a aplicar?</b>
        <br>
        Puedes aplicar las veces que quieras, lo puedes hacer con el mismo proyecto o uno nuevo, excepto cuando llegas a campamento nacional, sólo recuerda 
        que la convocatoria se realiza una vez al año.
      </p>

      <p><b>20. ¿Cuándo cierra la Convocatoria?</b>
        <br>
        La convocatoria tiene vigencia del 15 de febrero al 16 de mayo de 2022
      </p>

      <h3 class="mt-4"><b><u>Paneles de selección</u></b></h3>
      <p class="mt-4"><b>21. ¿Qué son los paneles?</b>
        <br>
        Una vez seleccionados los mejores proyectos de la primera etapa, los paneles se llevan a cabo en formato virtual. En una sesión de la plataforma zoom, 
        tendrás oportunidad de presentar un “pitch” o presentación con una duración máxima de 5 minutos ante los expertos, quienes después tendrán 10 minutos 
        para dar retroalimentación a tu proyecto. El panel de jueces está conformado por expertos con gran experiencia en el tema de emprendimiento, que te 
        calificarán para pasar a la siguiente ronda.
      </p>
      <p>
        Puede asistir todo el equipo, pero la recomendación es que sólo una o dos personas presenten el pitch. Estas deben ser las que tengan mayor habilidad 
        para comunicar su idea y el conocimiento total de ésta.
      </p>
      <p>
        A los emprendedores se les notificará vía correo electrónico, que fueron seleccionados para los paneles, con una serie de instrucciones para agendar 
        su fecha y subir su presentación a una plataforma.
      </p>

      <p><b>22. ¿Qué es un Pitch?</b>
        <br>
        Es una presentación clara, sencilla y precisa sobre el producto o servicio que ofreces, o de la idea de negocio que quieres llevar a cabo. Se llama 
        así por la frase en inglés “Elevator Pitch”, que se refiere a la posibilidad de encontrar un potencial inversionista en un elevador, sólo tendrías 
        un par de minutos (lo que dura el estar en un elevador) para convencerlo de aportar su dinero a tu Proyecto.
        <br>
        En este caso tu pitch nos ayudará a entender tu idea de negocio y evaluar si tiene el potencial para pasar a la siguiente etapa, que es el Campamento Nacional.
      </p>

      <p><b>23. ¿Quiénes son los expertos que evaluarán los proyectos?</b>
        <br>
        Son profesionistas que tienen experiencia en emprendimiento, y también manejan temas de diferentes industrias y sectores económicos. Algunos de ellos son también 
        profesores, empresarios, inversionistas o consultores en la materia.
      </p>

      <h3 class="mt-4"><b><u>Campamento Nacional POSiBLE</u></b></h3>
      <p class="mt-4"><b>24. ¿Qué es el Campamento Nacional POSiBLE?</b>
        <br>
        El campamento consiste en 5 días de talleres, capacitación, mentorías, herramientas y vinculación con aliados estratégicos. Durante el Campamento POSiBLE, los 
        participantes trabajarán de manera intensiva, acompañados por un mentor, en el desarrollo y mejora de sus proyectos.
      </p>

      <p><b>25. ¿Cómo me notificarán si fui seleccionado para asistir al Campamento?</b>
        <br>
        Los participantes seleccionados serán publicados en el sitio y notificados vía e-mail y a través de una llamada telefónica. Es muy importante que estés atento 
        a nuestros correos; te recomendamos revisar tu bandeja de “no deseados”
      </p>

      <p><b>26. ¿Cuándo y dónde se llevará a cabo el Campamento POSiBLE?</b>
        <br>
        El campamento está planeado para llevarse a cabo en Septiembre 2022 en una localidad en el centro del país. En caso de que hubiera cambios, te mantendremos 
        informado, ya sea del formato presencial o virtual.
      </p>

      <p><b>27. ¿Puede asistir todo mi equipo al Campamento?</b>
        <br>
        Si el equipo se conforma por varios emprendedores y el campamento es presencial, sólo asistirá una persona por equipo. POSiBLE podrá contribuir a cubrir 
        los viáticos (transporte terrestre o aéreo) de los emprendedores seleccionados que <b><i>no residan en la ciudad en donde se realizará el campamento, 
        con un tope económico de apoyo en función del Estado de procedencia,</i></b> sin cubrir la totalidad del costo. Se podrá cubrir hospedaje y ciertos alimentos. 
        Según POSiBLE determine y señale a los proyectos seleccionados, el apoyo que podrá proporcionar. En caso de que el campamento se moviera a un formato 
        virtual, si será posible que todo el equipo se conecte a las sesiones.
      </p>

      <p><b>28. ¿Se me pagarán viáticos para asistir al Campamento POSiBLE?</b>
        <br>
        A los emprendedores seleccionados para formar parte del Campamento se les cubrirán <b><i>sólo algunos gastos de noche de hotel, desayuno y comida, en función del 
        estado de procedencia</i></b> Se contará con transporte del hotel al lugar donde se realiza el campamento. Es importante, los traslados en taxi 
        al aeropuerto o cualquier origen y destino, no están cubiertos por POSiBLE, por lo que deberán ser pagados por los emprendedores.
      </p>

      <p><b>29. ¿Qué beneficios se otorgan a los emprendedores que forman parte de la comunidad POSiBLE?</b></p>
      <ul>
        <li>Participación en talleres o cursos organizados por aliados de POSiBLE.</li>
        <li>Visibilidad: para dar a conocer su idea de negocio en medios masivos y eventos especiales.</li>
        <li>Enriquecimiento profesional: acceso a viajes especializados y concursos internacionales.</li>
        <li>Mentores: acceso a mentores expertos presenciales y en línea.</li>
        <li>Contactos: vinculación con inversionistas y potenciales socios de negocios.</li>
        <li>Participación en programas de mentoría en línea.</li>
        <li>Invitación como panelistas en eventos de emprendimiento.</li>
        <li>Acceso gratuito a eventos de emprendimiento en distintas partes de la República y en otros países en los que participe POSiBLE.</li>
      </ul>
      <p>
        POSiBLE no entrega premios monetarios ni créditos, pero te ofrece todas las herramientas y conexiones para que logres fortalecer y crecer tu idea de negocio.
      </p>

      <p><b>30. ¿Qué tratamiento se le da a la información del proyecto en caso de no ser seleccionado?</b>
        <br>
        Toda la información recibida en relación con las ideas de negocio o empresas es confidencial. Dicha información será exclusiva para los efectos de la 
        convocatoria y proceso de selección y en ningún caso será divulgada.
        <br>
        Tu idea desde el inicio es privada y solo tiene acceso el equipo POSiBLE.
        <br>
        Te recomendamos que leas el apartado de Términos y Condiciones en la página <a href="https://posible.org.mx/terminos/">https://posible.org.mx/terminos/</a>
      </p>

      <h3 class="mt-4"><b><u>OPORTUNIDADES ESPECIALES</u></b></h3>
      <p class="mt-4"><b>31. ¿Qué son las oportunidades especiales de la Convocatoria 2022?</b>
        <br>
        Son aquellas que se especializan en un estado de la República Mexicana en particular, derivado de una colaboración conjunta con el Gobierno del Estado, o alguna 
        otra institución estatal, con el objetivo de lograr la identificación de emprendedores/proyectos de alto impacto.
      </p>

      <p><b>32. ¿Puedo participar en la Convocatoria normal y en las oportunidades especiales?</b>
        <br>
        Al momento de realizar tu registro en la Convocatoria y elegir algunos de los estados con oportunidad especial de participación, automáticamente formarás parte 
        de esta opción y tendrás acceso al resto de las actividades adicionales.
      </p>

      <p><b>33. ¿Quiénes son los beneficiarios por estas oportunidades especiales?</b>
        <br>
        Los beneficiarios son los residentes de los estados participantes, y se realizarán talleres especiales en su estado y un Campamento Estatal.
      </p>

      
    </div>
    <hr class="line_txt_style_msj">
    <div class="text-center">
      <small class="txt_style_msj">
        <p><b> © 2022 POSiBLE</b></p>
      </small>
    </div>

  </section>





</div>
