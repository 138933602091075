<div class="container project-completed-dialog">
  <div class="row">
    <div class="col-12 text-center pt-3">
      <span class="titulo_pass_recover">¡¡ Felicidades 🎉 !! </span>
      <div class="animated fadeIn fast px-4 mt-3">
        <span class="common_txt">
          Tu proyecto ha sido enviado a revisión, muy pronto obtendrás una retroalimentación de nuestra parte.
        </span>
        <br>
        <span class="link_txt" (click)="completeProfile()">
          <u>Haz click aqui para completar tu perfil.</u>
        </span>
      </div>
    </div>
  </div>
  <div class="row mt-4 mb-3">
    <div class="col-12">
      <button class="btn-lg btn btn-block btn-warning btn_continuar modal-button" (click)="navigateToProfile()"
        data-dismiss="modal"> Continuar </button>
    </div>
  </div>
</div>
