import { SupportMaterialBaseClass } from './SupportMaterialBaseClass';

// Class to implement all content of support material of glossary
export class SupportGlossary extends SupportMaterialBaseClass {
    description: string;
    panelOpenState: false;
    constructor(data: any) {
        super(data.id,data.title,data.url);
        this.description = data.description
    }
}