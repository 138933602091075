<div class="row notification align-items-center cursor__pointer" [ngClass]="{'unseen': !notification.seen}"
  (click)="openNotification()">
  <div class="col-auto pr-0">
    <img *ngIf="notification.contactPicture else noImg" class="picture" [src]="notification.contactPicture"
      alt="image">
    <ng-template #noImg>
      <img class="picture" src="assets/img/home/profile_img.png" [alt]="notification.contactName">
    </ng-template>
    <span class="{{notification.contactStatus}}"></span>
  </div>
  <div class="col-7 col-sm-8 col-md-8 col-lg-8 col-xl-8 info">
    <p class="description">
      {{notification.description}}
    </p>
  </div>
  <div class="col-auto text-right p-0">
    <span class="time-ago d-block">{{notification.daysPassed}}</span>
  </div>
</div>
