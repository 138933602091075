import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';
import { ProjectService } from '../../../../services/project.service';
import { Project } from '../../../../models/project/project';
import { Profile } from '../../../../models/User/profile';
import { SnackbarService } from 'src/app/services/notifications/snackbar.service';
import { ProjectStatus } from 'src/app/models/project-status/project-status';
import { PanelService} from '../../../../services/panel/panel.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProfileProjectComponent implements OnInit {

    // loading spinner
    isLoading = true;
    id: string;
    project: Project;
    user: Profile;

    // editable
    canEditAbout = false;
    canEditMission = false;
    canEditVision = false;

    submitted = false;

    // Project status list
    projectList = ProjectStatus;
    cita = false;
    expired = false;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private projectService: ProjectService,
                private authenticationService: AuthService,
                private snackbarService: SnackbarService,
                private panelService: PanelService,
              ) { }

    ngOnInit(): void {
      this.id = this.route.snapshot.paramMap.get('ID');
      this.getProject();
      this.getUser();
    }

    /* Method to get the current project by id
    *
    */
    getProject(){
      this.projectService.getProject(this.id)
        .then(data => {
          this.project = new Project(data.data);
          this.isLoading = false;
          if(this.project.status == this.projectList.Agendado) {
            this.panelService.getStatus(this.id).then(data => {
              if(data.success) {
                this.cita = data.success;
                this.expired = data.expired;
              }
            });
          }
        })
        .catch(error => this.snackbarService.showSnackBar('Cerrar', error));
    }

    /* Method to save the values of project
    *
    */
    save(){
      this.projectService.saveProfileProject(this.project.businessModel_guid, this.project.about, this.project.mission, this.project.vision)
        .subscribe(resp => {
          this.snackbarService.showSnackBar('Cerrar', resp['message']);
       }, (error) => this.snackbarService.showSnackBar('Cerrar', error));
    }

    /*Route to schedule
    *
    *
    * */
    openProjectSchedule(projectID: string): void {
      this.router.navigate(['app/perfil/agendar', projectID]);
    }

    addToSchedule(projectID: string): void {
      this.router.navigate(['app/perfil/cita', projectID]);
    }

    /* Method to get the current user
    *
    */
    getUser(): void {
      this.authenticationService.getCurrentUser()
        .then(data => {
          this.user = data;
        })
        .catch(error => this.snackbarService.showSnackBar('Cerrar', error));
    }

    /*Download BM
    *
    *
    * */
    downloadBusinessModel(projectID: string): void {
      this.projectService.download(projectID);
    }

    downloadCertificatePaneles(projectID: string): void {
      this.projectService.downloadCertificate(projectID);
    }


    deleteProject():void{
      Swal.fire({
        title: '¿Estas seguro?',
        text: "Se eliminará el proyecto",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.value) {
          this.projectService.delete(this.id).then( resp => {
            this.snackbarService.showSnackBar('Cerrar', resp['message']);
            this.router.navigateByUrl('/perfil');
          }).catch( error => this.snackbarService.showSnackBar('Cerrar', error))
        }
      })
    }
  }
