export const Ocupacion = [
    {id: 1, descripcion:'Docente universitario'},
    {id: 2, descripcion:'Administrativo escolar'},
    {id: 3, descripcion:'Encargado de una incubadora, aceleradora o programa de emprendimiento escolar'}
]
export const OcupacionEstudiante = [
    {id: 1, descripcion:'Estudiante'},
    {id: 2, descripcion:'Recién egresado'},
]
export const HrsGrupo = [
    {descripcion : "Entre 1 y 4 horas", id : 1},
    {descripcion : "Entre 5 y 9 horas", id : 2},
    {descripcion : "Mas de 10 horas", id : 3}
]

export const Antiguedad = [
    {descripcion : "Menos de 1 año", id : 1},
    {descripcion : "Entre 2 y 4 años", id : 2},
    {descripcion : "Entre 5 y 7 años", id : 3},
    {descripcion : "Mas de 8 años", id : 4}
]

export const Materia = [
    {descripcion : "Relacionada con negocios", id : 1},
    {descripcion : "Relacionada con emprendimiento", id : 2},
    {descripcion : "Relacionada con finanzas y contabilidad", id : 3},
    {descripcion : "Otra", id : 4}
]

export const Integrantes = [
    {descripcion : "1", id : 1},
    {descripcion : "2", id : 2},
    {descripcion : "3", id : 3}
]

export const Empleo = [
    {id: 1, descripcion:'Docente'},
    {id: 2, descripcion:'Funcionario en dependencia del Estado de México o sus municipios'},
    {id: 3, descripcion:'Líder de emprendimiento mexiquense'},
    {id: 4, descripcion:'Otro'}
]