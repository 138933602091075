import { SnackbarService } from './../../../services/notifications/snackbar.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidationErrors } from '@angular/forms';
import { Router,ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { UsuarioModel } from '../../../models/usuario.model';
import { StateService } from 'src/app/services/state/state.service';
import { ConfirmedValidator } from 'src/app/validators/equal-value.validator';
import { minimumAge } from '../../../validators/minimum-age.validator';
import { GendersType } from 'src/app/models/gender/gender';
import { EducationType, SchoolType, Education, SpecifyPublic } from 'src/app/models/education/education';
import { Ocupacion, HrsGrupo, Antiguedad, Materia, Integrantes, Empleo, OcupacionEstudiante } from 'src/app/models/retoOaxaca/reto';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { CampaingBulder } from 'src/app/models/campaingBuilder.model';
import { SchoolTechService } from 'src/app/services/school-tech.service';
import { TechSchool } from '../../../models/tech-school/tech-school';
import { Subject } from 'rxjs';
import { ItemsMultiselect } from 'src/app/classes/select/item-multiselect';
import { FirebaseService } from 'src/app/services/firebase-service/firebase.service';
import * as moment from 'moment';
import { flatMap } from 'rxjs/operators';

@Component({
  selector: 'app-create-acount',
  templateUrl: './create-acount.component.html',
  styleUrls: ['./create-acount.component.scss']
})
export class CreateAcountComponent implements OnInit {
  // Property to identify if the form has been already submitted
  submitted = false;
  // Property to manage the form and its attributes
  formAccount: FormGroup;
  // Property to manage the user instance
  usuario: UsuarioModel = new UsuarioModel();
  // Property to manage the states from current api in array
  states: [];
  // Property to manage the cities from current api in array
  cities: [];
  // Variable that show or hide password
  show_pass = false;
  // Variable that show or hide repeat password
  show_repeatpass = false;
  // Indicates when account is saving
  isLoading = false;
  // List of genders
  genders = GendersType.values();
  // List of educations level
  educationsType = EducationType.values();
  // List of school types
  schoolTypes = SchoolType;
  // Specify public school
  specifyPublic = SpecifyPublic;
  // List of educations level
  educations = Education;
  // Items already shown in the select, when Tecnologicos is shown
  tech_schools: ItemsMultiselect[] = [];
  // Show loading when is getting more items
  loading = false;
  // Actual page on paginate
  currentPage = 1;
  // Numebr of items to display per page
  itemsPerPage = 10;
  // Total items on API
  totalItems: number;
  // total of paginate pages
  totalPages: number;
  // Observer waiting for new
  select$ = new Subject<string>();
  // Use to search item
  searchText = '';
  //campain Builder parameter of the compain
  campainBuilder:CampaingBulder = new CampaingBulder();
  // Sets the birthdate that will be shown
  _birthDate;
  // Min birth date on datepicker
  minBirthDate = moment(new Date("1950-01-01T00:00"));
  // Is loading cities
  isLoadingCities = false;
  // Property to manage the heard sources from current api in array
  heardSource: [];
  entrepreneurshipsList: [];
  nodesList: [];
  messageDate:any = false;
  messagePass:any = false;
  showTextareaDetail:boolean = false;
  set birthDate(event){
    if(event['startDate']){
      this._birthDate = event['startDate'].format('YYYY-MM-DD');
      this.messageDate = false;
    }
  }
  get birthDate(){
    return this._birthDate;
  }

  //reto posible oaxaca
  /* oaxaca = false;
  oaxacaAccept = false; */
  ocupacionSelect = 0;
  materiaSelect = 0;
  ocupacion = Ocupacion;
  antiguo = Antiguedad;
  horas = HrsGrupo;
  tema = Materia;
  empleoSelect = 0;
  empleo = Empleo;

  //reto posible kids zacatecas
  kidsZacatecas = false;
  kidsZacatecasAccept = false;
  registerSon = false;
  registerTeam = false;
  

  //reto posible chiapas
  /* chiapas = false;
  chiapasAccept = false; */
  //reto posible zacatecas
  zacatecas = false;
  zacatecasAccept = false;
  retoAccept = false;
  profile = false;
  ocupacionEstudiante = OcupacionEstudiante;
  docente = false;
  estudiante = false;
  //reto posible edomex
  edoMex = false;
  edoMexAccept = false;
  //hackaton
  hackaton = false;
  hackatonAccept = false;
  participantes = Integrantes;
  nivelSeleccionado = 0;
  numberParticipants: any;
  participants:number= 0;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private auth: AuthService,
    private stateService: StateService,
    private snackbarService: SnackbarService,
    private activatedRoute: ActivatedRoute,
    private techSchoolService: SchoolTechService,
    private firebase: FirebaseService,
    
  ) {
    this.crearFormulario();
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params.utm_source)
      {
        this.campainBuilder.utm_source = params.utm_source;
        this.campainBuilder.utm_campaign = params.utm_campaign;
        this.campainBuilder.utm_medium = params.utm_medium;
        this.campainBuilder.created = new Date();
        localStorage.setItem("campaignUTM", JSON.stringify( this.campainBuilder));
      }
    });
  }

  ngOnInit(): void {
    window['dataLayer'] = window['dataLayer'] || {};
    this.numberParticipants = [
      {
        id: 2, name:'2'
      },
      {
        id: 3, name:'3'
      },
      {
        id: 4, name:'4'
      },
      {
        id: 5, name:'5'
      }
   ];
    //this.educationsType.splice(0,0, this.educationsType.pop());
    this.educationsType.pop();
    this.onSearch();
    this.getStates();
    this.getHeardSources();
    this.getEntrepreneurshipsList();
    this.getNodesList();
    this.firebase.logEvent('createAccount');
    this.formAccount.controls['educacionNivel'].valueChanges
      .pipe(distinctUntilChanged()).subscribe((value: number) => {
        this.nivelSeleccionado = value;
        if (value == this.educations['Licenciatura']) {
          this.formFields.tipoEscuela.setValidators([Validators.required]);
          this.formFields.tipoEscuela.updateValueAndValidity();
          
          this.formAccount.controls['tipoEscuela'].valueChanges
            .pipe(distinctUntilChanged()).subscribe((value: boolean) => {
              if(!value) {
                this.formFields.universitario.setValidators([Validators.required]);
                this.formFields.universitario.updateValueAndValidity();

                this.formFields.especificaEscuela.setValidators([Validators.required]);
                this.formFields.especificaEscuela.updateValueAndValidity();
                this.formAccount.controls['especificaEscuela'].valueChanges
                  .pipe(distinctUntilChanged()).subscribe((value: boolean) => {
                    if(value) {
                      this.formFields.tecnologicos.setValidators([Validators.required]);
                      this.formFields.tecnologicos.updateValueAndValidity();
                      this.formFields.institucion.clearValidators();
                      this.formFields.institucion.updateValueAndValidity();
                    }
                    else {
                      if(this.nivelSeleccionado == this.educations['Licenciatura']) {
                        this.formFields.institucion.setValidators([Validators.required]);
                        this.formFields.institucion.updateValueAndValidity();
                      }
                      this.formFields.tecnologicos.clearValidators();
                      this.formFields.tecnologicos.updateValueAndValidity();
                    }
                  });
              }
              else {
                this.formFields.institucion.setValidators([Validators.required]);
                this.formFields.institucion.updateValueAndValidity();
                this.formFields.tecnologicos.clearValidators();
                this.formFields.tecnologicos.updateValueAndValidity();
                this.formFields.universitario.clearValidators();
                this.formFields.universitario.updateValueAndValidity();
                this.formFields.especificaEscuela.clearValidators();
                this.formFields.especificaEscuela.updateValueAndValidity();
              }
            });

        }else if (value == this.educations['Posgrado']) {
          this.formFields.tipoEscuela.setValidators([Validators.required]);
          this.formFields.tipoEscuela.updateValueAndValidity();
          
          this.formAccount.controls['tipoEscuela'].valueChanges
            .pipe(distinctUntilChanged()).subscribe((value: boolean) => {
              if(value == false) {

                this.formFields.especificaEscuela.setValidators([Validators.required]);
                this.formFields.especificaEscuela.updateValueAndValidity();
                this.formAccount.controls['especificaEscuela'].valueChanges
                  .pipe(distinctUntilChanged()).subscribe((value: boolean) => {
                    if(value == true) {
                      this.formFields.tecnologicos.setValidators([Validators.required]);
                      this.formFields.tecnologicos.updateValueAndValidity();
                      this.formFields.institucion.clearValidators();
                      this.formFields.institucion.updateValueAndValidity();
                    }
                    else {
                      if(this.nivelSeleccionado == this.educations['Posgrado']) {
                        this.formFields.institucion.setValidators([Validators.required]);
                        this.formFields.institucion.updateValueAndValidity();
                      }
                      this.formFields.tecnologicos.clearValidators();
                      this.formFields.tecnologicos.updateValueAndValidity();
                    }
                  });
              }
              else {
                this.formFields.institucion.setValidators([Validators.required]);
                this.formFields.institucion.updateValueAndValidity();
                this.formFields.especificaEscuela.clearValidators();
                this.formFields.especificaEscuela.updateValueAndValidity();
                this.formFields.tecnologicos.clearValidators();
                this.formFields.tecnologicos.updateValueAndValidity();
              }
            });

        }else {
          this.formFields.institucion.clearValidators();
          this.formFields.institucion.updateValueAndValidity();
          this.formFields.tipoEscuela.clearValidators();
          this.formFields.tipoEscuela.updateValueAndValidity();
          this.formFields.especificaEscuela.clearValidators();
          this.formFields.especificaEscuela.updateValueAndValidity();
          this.formFields.tecnologicos.clearValidators();
          this.formFields.tecnologicos.updateValueAndValidity();
          this.formFields.universitario.clearValidators();
          this.formFields.tecnologicos.updateValueAndValidity();
        }

        if (value == this.educations['Tecnológico Nacional de México']) {
          this.formFields.universitario.setValidators([Validators.required]);
          this.formFields.universitario.updateValueAndValidity();
        }
        else {
          this.formFields.universitario.clearValidators();
          this.formFields.universitario.updateValueAndValidity();
        }
      });

      this.formAccount.controls['heardFrom'].valueChanges
      .pipe(distinctUntilChanged()).subscribe((value: number) => {
        if(value == 9) {
          this.formFields.otroHeard.setValidators([Validators.required]);
          this.formFields.otroHeard.updateValueAndValidity();
        }
        else {
          this.formFields.otroHeard.clearValidators();
          this.formFields.otroHeard.updateValueAndValidity();
        }
      });

  }
  showMessage(pass){
    if(pass == 1){
      this.messagePass = true;
    }else{
      this.messagePass = false;
    }
  }
  /**
   * Method to manage the valid inputs with a css color
   */
  get terminosNoValido(): boolean {
    return this.formAccount.get('terminos').invalid && this.formAccount.get('terminos').touched;
  }

  crearFormulario(): void {
    this.formAccount = this.fb.group({
      nombre: ['', [Validators.required, Validators.minLength(2)]],
      apellido: ['', [Validators.required, Validators.minLength(2)]],
      apellidoMat: [''],
      fechaNac: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), minimumAge(18)]],
      correo: ['', [Validators.required, Validators.pattern('[A-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      telefono: ['', [Validators.required, Validators.minLength(13), Validators.maxLength(13), Validators.pattern('^[(][0-9]{3}[)][0-9]{3}[-\.][0-9]{4,6}$')]],
      estado: [null, [Validators.required]],
      municipio: [null, [Validators.required]],
      genero: [null, [Validators.required]],
      educacionNivel: [null, [Validators.required]],
      tecnologicos: [null],
      tipoEscuela: [''],
      especificaEscuela: [''],
      universitario: [''],
      institucion: [''],
      retoOaxaca: [''],
      //reto kids zacatecas
      kidsZacatecas: [''],
      kidsregister: [''],
      kidAge: [''],
      levelStudy:[''],
      theme:[''],
      nameTeam:[''],
      numberParticipants:[''],
      interest: [''],
      entrepreneurship:[''],
      entrepreneurshipDetail:[''],
      assistanceNode:[''],
      ageParticipant1:[''],
      ageParticipant2:[''],
      ageParticipant3:[''],
      ageParticipant4:[''],
      ageParticipant5:[''],
      levelStudyTeam1:[''],
      levelStudyTeam2:[''],
      levelStudyTeam3:[''],
      levelStudyTeam4:[''],
      levelStudyTeam5:[''],
      school1:[''],
      school2:[''],
      school3:[''],
      school4:[''],
      school5:[''],
      //fin reto kids zacatecas
      //reto zacatecas
      selectedProfileZacatecas: [''],
      professionalPerformance:[null],
      replicateLearned:[null],
      ocupacionEstudiante:[null],
      ideaStudent:[null],
      //fin reto zacatecas
      /* retoChiapas: [''],
      retoChiapasEmprendedores:[''], */
      retoEdoMex: [''],
      institutoEducativo: [null],
      tellEntrepreneurship:[''],
      useScholarship:[''],
      ocupacionReto: [null],
      empleoReto: [null],
      horasReto: [null],
      antiguedadReto: [null],
      materiaReto: [null],
      puestoReto: [null],
      interesReto: [null],
      otraMateriaReto: [null],
      otroEmpleoReto: [null],
      institutoReto: [null],
       hackaton: [''],
       conalep: [null],
       integrantes: [null],
       proyecto: [null],
       descripcion: [null],
       implementacion: [null],
      terminos: ['', Validators.required],
      heardFrom: [null, Validators.required],
      otroHeard: [''],
      pass1: ['', [Validators.required, Validators.minLength(4), Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])[A-Za-z0-9]*$')]],
      pass2: ['', Validators.required]
    }, {
      validator: ConfirmedValidator('pass1', 'pass2')
    });
  }

  // method to force the email input to lowercase and trim
  mailHelperInput = (evt) => {
    this.formAccount.patchValue({ correo: evt.target.value.toLowerCase() });
    this.formAccount.patchValue({ correo: evt.target.value.trim() });
  }

  getAccount(): object {
    this.campainBuilder = JSON.parse( localStorage.getItem('campaignUTM'));
    if (this.campainBuilder === null) {
      this.campainBuilder = new CampaingBulder();
    }
    return {
      first_name: this.formFields.nombre.value,
      last_name: this.formFields.apellido.value,
      second_last_name: this.formFields.apellidoMat.value,
      birth_date: this.birthDate,
      username: this.formFields.correo.value,
      email: this.formFields.correo.value,
      phonenumber: this.formFields.telefono.value.replace(/([(]|[)]|-)/g , ''),
      password: this.formFields.pass1.value,
      state: this.formFields.estado.value,
      education: this.formFields.educacionNivel.value,
      institution_guid: this.formFields.tecnologicos.value,
      gender: this.formFields.genero.value,
      municipality: this.formFields.municipio.value,
      is_public: this.formFields.tipoEscuela.value,
      specify_public: this.formFields.especificaEscuela.value === "" ? false:this.formFields.especificaEscuela.value,
      institution_name: this.formFields.institucion.value === "" ? null:this.formFields.institucion.value,
      is_university: this.formFields.universitario.value,
      heard_from: this.formFields.heardFrom.value,
      other_heard: this.formFields.otroHeard.value === "" ? null:this.formFields.otroHeard.value,
      is_reto: this.formFields.retoOaxaca.value,
      /* is_reto_chiapas: this.formFields.retoChiapas.value,
      is_reto_chiapas_emprendedores: this.formFields.retoChiapasEmprendedores.value, */
      is_reto_edoMex: this.formFields.retoEdoMex.value,
      is_reto_zacatecas: this.retoAccept == true ? this.retoAccept : false,
      //reto kids zacatecas
      is_reto_kidsZacatecas: this.formFields.kidsZacatecas.value,
      kidsregister: this.formFields.kidsregister.value == true ? 1 : 0,
      kidAge: this.formFields.kidAge.value,
      levelStudy: this.formFields.levelStudy.value,
      theme: this.formFields.theme.value,
      interestProgram: this.formFields.interest.value,
      entrepreneurship: this.formFields.entrepreneurship.value,
      entrepreneurshipDetail: this.formFields.entrepreneurshipDetail.value,
      assistanceNode: this.formFields.assistanceNode.value,
      nameTeam: this.formFields.nameTeam.value,
      numberParticipants: this.formFields.numberParticipants.value,
      participantAge1:this.formFields.ageParticipant1.value,
      participantAge2:this.formFields.ageParticipant2.value,
      participantAge3:this.formFields.ageParticipant3.value,
      participantAge4:this.formFields.ageParticipant4.value,
      participantAge5:this.formFields.ageParticipant5.value,
      levelStudyTeam1:this.formFields.levelStudyTeam1.value,
      levelStudyTeam2:this.formFields.levelStudyTeam2.value,
      levelStudyTeam3:this.formFields.levelStudyTeam3.value,
      levelStudyTeam4:this.formFields.levelStudyTeam4.value,
      levelStudyTeam5:this.formFields.levelStudyTeam5.value,
      school1:this.formFields.school1.value,
      school2:this.formFields.school2.value,
      school3:this.formFields.school3.value,
      school4:this.formFields.school4.value,
      school5:this.formFields.school5.value,
      //fin reto kids zacatecas
      institutoEducativo_reto: this.formFields.institutoEducativo.value,
      tellEntrepreneurship: this.formFields.tellEntrepreneurship.value,
      useScholarship: this.formFields.useScholarship.value,
      ocupacion_reto: this.formFields.ocupacionReto.value,
      empleo_reto: this.formFields.empleoReto.value,
      instituto_reto: this.formFields.institutoReto.value,
      horas_reto: this.formFields.horasReto.value,
      antiguedad_reto: this.formFields.antiguedadReto.value,
      materia_reto: this.formFields.materiaReto.value,
      otra_materia_reto: this.formFields.otraMateriaReto.value,
      otro_empleo_reto: this.formFields.otroEmpleoReto.value,
      puesto_reto: this.formFields.puestoReto.value,
      interes_reto: this.formFields.interesReto.value,
      is_hackaton: this.formFields.hackaton.value,
      teammates: this.formFields.integrantes.value,
      conalep: this.formFields.conalep.value,
      project_name: this.formFields.proyecto.value,
      project_description: this.formFields.descripcion.value,
      implementation: this.formFields.implementacion.value,
      utm_source : this.campainBuilder.utm_source,
      utm_campaign : this.campainBuilder.utm_campaign,
      utm_medium : this.campainBuilder.utm_medium,
      docente: this.docente === true ? this.docente : false,
      impactoProfesional: this.formFields.professionalPerformance.value ? this.formFields.professionalPerformance.value : null,
      replicarAprendizaje: this.formFields.replicateLearned.value ? this.formFields.replicateLearned.value : null,
      estudiante: this.estudiante === true ? this.estudiante : false,
      ocupacionEstudiante: this.formFields.ocupacionEstudiante.value ? this.formFields.ocupacionEstudiante.value : null,
      ideaEstudiante: this.formFields.ideaStudent.value ? this.formFields.ideaStudent.value : null,

    };
  }

  getFormValidationErrors() {
    Object.keys(this.formFields).forEach(key => {
      const controlErrors: ValidationErrors = this.formFields[key].errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
         //console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }
  
  // Method to send data to store account info
  save(): void {
    
    this.submitted = true;
    this.isLoading = true;
    this.messagePass = false;

    if (this.formAccount.invalid) {
      this.isLoading = false;
      this.snackbarService.showSnackBar('Cerrar', 'Verifique que los datos estén completos. ⚠️');
      return;
    }
    let account = this.getAccount();
    this.campainBuilder = JSON.parse( localStorage.getItem('campaignUTM'));
    if (this.campainBuilder === null) {
      this.campainBuilder = new CampaingBulder();
    }
    account['utm_source'] = this.campainBuilder.utm_source;
    account['utm_campaign'] = this.campainBuilder.utm_campaign;
    account['utm_medium'] = this.campainBuilder.utm_medium;

    this.auth.nuevoUsuario(account).then((resp:any) => {
      window['dataLayer'] = window['dataLayer'] || [];
          window['dataLayer'].push({
          'event': 'UserRegistered',
          'userEmail': this.formFields.correo.value, 
          'userId': resp.user_guid
          });
      this.snackbarService.showSnackBar('Cerrar', '¡Registro exitoso! 🎉');
      localStorage.removeItem("campaignUTM");
      this.firebase.logEvent('accountCreated');
      // Login after account is created.
      this.autoLoginUser(account['username'], account['password']);
    }).catch(err => {
      if (Object.keys(err.error).length > 1) {
        this.snackbarService.showSnackBar('Cerrar', `${err.message}. ⚠️`);
      } else {
        let nameError = Object.keys(err.error)[0];
        this.snackbarService.showSnackBar('Cerrar', `${err.error[nameError]} ⚠️`);
      }
    }).finally(() => {
      this.isLoading = false;
      this.submitted = false;
    });
  }

  // Convenience getter for easy access to form fields
  get formFields() {
    return this.formAccount.controls;
  }

  // Method to get the states list
  getStates(): void {
    this.stateService.getAll()
      .then(data => this.states = data)
      .catch(error => {
        this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error al consultar los estados. ⚠️');
      }
      );
  }

  // Method to get the heard from list
  getHeardSources(): void {
    this.auth.heardFrom()
      .then(data => this.heardSource = data)
      .catch(error => {
        this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error al consultar los estados. ⚠️');
      }
      ).finally(()=> {
        console.log(this.heardSource);
      });
  }

  getEntrepreneurshipsList():void{
    this.auth.entrepreneurship()
      .then(data => this.entrepreneurshipsList = data)
      .catch(error => {
        this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error al consultar los estados. ⚠️');
      }
      ).finally(()=> {
        console.log(this.entrepreneurshipsList);
      });
  }

  getNodesList():void{
    this.auth.assistanceNode()
      .then(data => this.nodesList = data)
      .catch(error => {
        this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error al consultar los estados. ⚠️');
      }
      ).finally(()=> {
        console.log("nodesList",this.nodesList);
      });
  }
  
  /**
   * Method to get the cities based on the state id received
   * @param id - state id
   */
  getCities(): void {
    // Reset cities when state changes
    if(this.formFields.estado.value == '00000000-0000-0000-0000-00000000000f'){
      this.edoMex = true;
      this.formFields.retoEdoMex.setValidators([Validators.required]);
      this.formFields.retoEdoMex.updateValueAndValidity();
    }else{
      this.edoMex = false;
      this.edoMexAccept = false;
      this.removeValidatorsReto(8);
    }
    /* if(this.formFields.estado.value == '00000000-0000-0000-0000-000000000014') {
      this.oaxaca = true;
      this.chiapas = false;
      this.chiapasAccept = false;
      this.formFields.retoOaxaca.setValidators([Validators.required]);
      this.formFields.retoOaxaca.updateValueAndValidity();
      this.removeValidatorsReto(6);
    }else */ 
    /* if(this.formFields.estado.value == '00000000-0000-0000-0000-000000000007') { 
      this.chiapas = true;
      this.formFields.retoChiapasEmprendedores.setValidators([Validators.required]);
      this.formFields.retoChiapasEmprendedores.updateValueAndValidity();
      this.removeValidatorsReto(5);
    }
    else {
      this.chiapas = false;
      this.chiapasAccept = false;
      this.removeValidatorsReto(6);
      this.removeValidatorsReto(5);
      this.removeValidatorsReto(4);
      this.removeValidatorsReto(3);
      this.removeValidatorsReto(2);
      this.removeValidatorsReto(1);
      this.formFields.tellEntrepreneurship.clearValidators();
      this.formFields.tellEntrepreneurship.updateValueAndValidity();
      this.formFields.tellEntrepreneurship.reset();

      this.formFields.useScholarship.clearValidators();
      this.formFields.useScholarship.updateValueAndValidity();
      this.formFields.useScholarship.reset();
    } */
    
    /*if(this.formFields.estado.value == '00000000-0000-0000-0000-00000000000f') {
      this.hackaton = true;
      this.formFields.hackaton.setValidators([Validators.required]);
      this.formFields.hackaton.updateValueAndValidity();
    }
    else {
      this.hackaton = false;
      this.hackatonAccept = false;
    }*/

    /*reto posible kids zacatecas*/
   if(this.formFields.estado.value == '00000000-0000-0000-0000-000000000020') {
      this.zacatecas = true;
      /* this.formFields.selectedProfileZacatecas.setValidators([Validators.required]);
      this.formFields.selectedProfileZacatecas.updateValueAndValidity(); */
      this.kidsZacatecas = false;
      /* this.formFields.kidsZacatecas.setValidators([Validators.required]);
      this.formFields.kidsZacatecas.updateValueAndValidity(); */
    }
    else {
      this.zacatecas = false;
      this.zacatecasAccept = false;
      this.profile = false;
      this.docente = false;
      this.estudiante = false;
      this.removeValidatorsReto(9);
      this.removeValidatorsReto(4);
      this.removeValidatorsReto(3);
      this.removeValidatorsReto(2);
      this.removeValidatorsReto(1);
    }

    this.formAccount.patchValue({ municipio: null });
    this.isLoadingCities = true;
    this.stateService.getCities(this.formFields.estado.value)
      .then(data => {this.cities = data; this.isLoadingCities = false})
      .catch(error => {
        this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error al consultar las ciudades. ⚠️');
        this.isLoadingCities = false;
      }
      );
  }

  /* retoAccept(accept) {
    this.oaxacaAccept = accept;
    if(accept) {
      this.formFields.institutoEducativo.setValidators([Validators.required]);
      this.formFields.institutoEducativo.updateValueAndValidity();
      this.formFields.ocupacionReto.setValidators([Validators.required]);
      this.formFields.ocupacionReto.updateValueAndValidity();
    }
    else {
      this.removeValidatorsReto(1);
      this.removeValidatorsReto(2);
      this.removeValidatorsReto(3);
      this.removeValidatorsReto(4);
    }
  } */
  
  retoZacatecasAccept(accept) {
    if(accept) {
      this.retoAccept = accept;
      /* this.formFields.selectedProfileZacatecas.setValidators([Validators.required]);
      this.formFields.selectedProfileZacatecas.updateValueAndValidity(); */
      this.selectedProfileZacatecasAccept(false)
      this.profile = accept;
    }
    else {
      this.retoAccept = accept;
      this.selectedProfileZacatecasAccept(true)
      this.profile = accept;
      this.formFields.selectedProfileZacatecas.clearValidators();
      this.formFields.selectedProfileZacatecas.updateValueAndValidity();
      this.formFields.selectedProfileZacatecas.reset();
    }
  }

   retokidsZacatecasAccept(accept) {
    this.kidsZacatecasAccept = accept;
    if(accept) {
      this.formFields.kidsregister.setValidators([Validators.required]);
      this.formFields.kidsregister.updateValueAndValidity();
      this.participants = 0;
    }
    else {
      this.removeValidatorsKidsregister()
      this.registerSon = false;
      this.registerTeam = false;
    }
  }
  removeValidatorsKidsregister(){
    this.formFields.kidsregister.clearValidators();
    this.formFields.kidsregister.updateValueAndValidity();
    this.formFields.kidsregister.reset();

  }
  retokidsregister(register){
    this.validateRegister()
    if(register == 'son'){
      this.removeValidateRegisterKids("team");
      this.registerSon = true;
      this.registerTeam = false;
      this.participants = 0;
      if(this.registerSon){
        this.validateRegisterSon()
      }
    }else{
      this.removeValidateRegisterKids("son");
      this.registerSon = false;
      this.registerTeam = true;
      if(this.registerTeam){
        this.validateRegisterTeam();
      }
    }
    
  }
  validateRegister(){
    this.formFields.theme.setValidators([Validators.required]);
    this.formFields.theme.updateValueAndValidity();
    this.formFields.interest.setValidators([Validators.required]);
    this.formFields.interest.updateValueAndValidity();
    this.formFields.entrepreneurship.setValidators([Validators.required]);
    this.formFields.entrepreneurship.updateValueAndValidity();
    this.formFields.assistanceNode.setValidators([Validators.required]);
    this.formFields.assistanceNode.updateValueAndValidity();
  }
  validateRegisterSon(){
      this.formFields.kidAge.setValidators([Validators.required]);
      this.formFields.kidAge.updateValueAndValidity();
      this.formFields.levelStudy.setValidators([Validators.required]);
      this.formFields.levelStudy.updateValueAndValidity();

  }
  validateRegisterTeam(){
    this.formFields.nameTeam.setValidators([Validators.required]);
    this.formFields.nameTeam.updateValueAndValidity();
    this.formFields.numberParticipants.setValidators([Validators.required]);
    this.formFields.numberParticipants.updateValueAndValidity();
  }
  removeValidateRegisterKids(register){
    if(register == 'son'){
      this.formFields.kidAge.clearValidators();
      this.formFields.kidAge.updateValueAndValidity();
      this.formFields.kidAge.reset();
      this.formFields.levelStudy.clearValidators();
      this.formFields.levelStudy.updateValueAndValidity();
      this.formFields.levelStudy.reset();

    }
    else{
      this.formFields.nameTeam.clearValidators();
      this.formFields.nameTeam.updateValueAndValidity();
      this.formFields.nameTeam.reset();

      this.formFields.numberParticipants.clearValidators();
      this.formFields.numberParticipants.updateValueAndValidity();
      this.formFields.numberParticipants.reset();
      
      this.formFields.ageParticipant1.clearValidators();
      this.formFields.ageParticipant1.updateValueAndValidity();
      this.formFields.ageParticipant1.reset();

      this.formFields.levelStudyTeam1.clearValidators();
      this.formFields.levelStudyTeam1.updateValueAndValidity();
      this.formFields.levelStudyTeam1.reset();

      this.formFields.school1.clearValidators();
      this.formFields.school1.updateValueAndValidity();
      this.formFields.school1.reset();

      this.formFields.ageParticipant2.clearValidators();
      this.formFields.ageParticipant2.updateValueAndValidity();
      this.formFields.ageParticipant2.reset();

      this.formFields.levelStudyTeam2.clearValidators();
      this.formFields.levelStudyTeam2.updateValueAndValidity();
      this.formFields.levelStudyTeam2.reset();

      this.formFields.school2.clearValidators();
      this.formFields.school2.updateValueAndValidity();
      this.formFields.school2.reset();

      this.formFields.ageParticipant3.clearValidators();
      this.formFields.ageParticipant3.updateValueAndValidity();
      this.formFields.ageParticipant3.reset();

      this.formFields.levelStudyTeam3.clearValidators();
      this.formFields.levelStudyTeam3.updateValueAndValidity();
      this.formFields.levelStudyTeam3.reset();

      this.formFields.school3.clearValidators();
      this.formFields.school3.updateValueAndValidity();
      this.formFields.school3.reset();

      this.formFields.ageParticipant4.clearValidators();
      this.formFields.ageParticipant4.updateValueAndValidity();
      this.formFields.ageParticipant4.reset();

      this.formFields.levelStudyTeam4.clearValidators();
      this.formFields.levelStudyTeam4.updateValueAndValidity();
      this.formFields.levelStudyTeam4.reset();

      this.formFields.school4.clearValidators();
      this.formFields.school4.updateValueAndValidity();
      this.formFields.school4.reset();

      this.formFields.ageParticipant5.clearValidators();
      this.formFields.ageParticipant5.updateValueAndValidity();
      this.formFields.ageParticipant5.reset();

      this.formFields.levelStudyTeam5.clearValidators();
      this.formFields.levelStudyTeam5.updateValueAndValidity();
      this.formFields.levelStudyTeam5.reset();

      this.formFields.school5.clearValidators();
      this.formFields.school5.updateValueAndValidity();
      this.formFields.school5.reset();


      
    }
  }

  /* retoChiapasAccept(accept) {
    this.chiapasAccept = accept;
    if(accept) {
      this.formFields.tellEntrepreneurship.setValidators([Validators.required]);
      this.formFields.tellEntrepreneurship.updateValueAndValidity();
      this.formFields.useScholarship.setValidators([Validators.required]);
      this.formFields.useScholarship.updateValueAndValidity();
    }
    else {
      this.formFields.tellEntrepreneurship.clearValidators();
      this.formFields.tellEntrepreneurship.updateValueAndValidity();
      this.formFields.tellEntrepreneurship.reset();

      this.formFields.useScholarship.clearValidators();
      this.formFields.useScholarship.updateValueAndValidity();
      this.formFields.useScholarship.reset();
    }
  } */

  selectedProfileZacatecasAccept(accept) {
    this.zacatecasAccept = accept;
    /* this.formFields.institutoEducativo.setValidators([Validators.required]);
    this.formFields.institutoEducativo.updateValueAndValidity(); */
    
    
    if(this.zacatecas){

      if(accept) {
        /* this.formFields.ocupacionReto.setValidators([Validators.required]);
        this.formFields.ocupacionReto.updateValueAndValidity();

        this.formFields.professionalPerformance.setValidators([Validators.required]);
        this.formFields.professionalPerformance.updateValueAndValidity();

        this.formFields.replicateLearned.setValidators([Validators.required]);
        this.formFields.replicateLearned.updateValueAndValidity();

        this.docente = true; */
        this.estudiante = false;
        this.formFields.ocupacionEstudiante.clearValidators();
        this.formFields.ocupacionEstudiante.updateValueAndValidity();
        this.formFields.ocupacionEstudiante.reset();
        this.formFields.ideaStudent.clearValidators();
        this.formFields.ideaStudent.updateValueAndValidity();
        this.formFields.ideaStudent.reset();
        this.formFields.interesReto.clearValidators();
        this.formFields.interesReto.updateValueAndValidity();
        this.formFields.interesReto.reset();
        this.formFields.institutoEducativo.clearValidators();
        this.formFields.institutoEducativo.updateValueAndValidity();
        this.formFields.institutoEducativo.reset();
      }
      else {
        this.docente = false;
        this.estudiante = true;
        this.formFields.ocupacionEstudiante.setValidators([Validators.required]);
        this.formFields.ocupacionEstudiante.updateValueAndValidity();
        this.formFields.interesReto.setValidators([Validators.required]);
        this.formFields.interesReto.updateValueAndValidity();
        this.formFields.ideaStudent.setValidators([Validators.required]);
        this.formFields.ideaStudent.updateValueAndValidity();
        this.formFields.institutoEducativo.setValidators([Validators.required]);
        this.formFields.institutoEducativo.updateValueAndValidity();

        /* this.formFields.ocupacionReto.clearValidators();
        this.formFields.ocupacionReto.updateValueAndValidity();
        this.formFields.ocupacionReto.reset(); */

       /*  this.formFields.professionalPerformance.clearValidators();
        this.formFields.professionalPerformance.updateValueAndValidity();
        this.formFields.professionalPerformance.reset();
        this.formFields.replicateLearned.clearValidators();
        this.formFields.replicateLearned.updateValueAndValidity();
        this.formFields.replicateLearned.reset(); */
      }
    }else{
      this.removeValidatorsReto(1);
      this.removeValidatorsReto(2);
      this.removeValidatorsReto(3);
      this.removeValidatorsReto(4);
    }
  }

  retoEdoMexAccept(accept) {
    this.edoMexAccept = accept;
    if(accept){
      this.formFields.empleoReto.setValidators([Validators.required]);
      this.formFields.empleoReto.updateValueAndValidity();
      this.formFields.institutoReto.setValidators([Validators.required]);
      this.formFields.institutoReto.updateValueAndValidity();
    }else{
      this.formFields.empleoReto.clearValidators();
      this.formFields.empleoReto.updateValueAndValidity();
      this.formFields.empleoReto.reset();
      this.formFields.institutoReto.clearValidators();
      this.formFields.institutoReto.updateValueAndValidity();
      this.formFields.institutoReto.reset();
      this.removeValidatorsReto(7);
    }
  }

  hackAccept(accept) {
    this.hackatonAccept = accept;
    if(accept) {
      this.formFields.conalep.setValidators([Validators.required]);
      this.formFields.conalep.updateValueAndValidity();
      this.formFields.integrantes.setValidators([Validators.required]);
      this.formFields.integrantes.updateValueAndValidity();
      this.formFields.proyecto.setValidators([Validators.required]);
      this.formFields.proyecto.updateValueAndValidity();
      this.formFields.descripcion.setValidators([Validators.required]);
      this.formFields.descripcion.updateValueAndValidity();
      this.formFields.implementacion.setValidators([Validators.required]);
      this.formFields.implementacion.updateValueAndValidity();
    }
    else {
      this.formFields.conalep.clearValidators();
      this.formFields.conalep.updateValueAndValidity();
      this.formFields.conalep.reset();
      
      this.formFields.integrantes.clearValidators();
      this.formFields.integrantes.updateValueAndValidity();
      this.formFields.integrantes.reset();
      
      this.formFields.proyecto.clearValidators();
      this.formFields.proyecto.updateValueAndValidity();
      this.formFields.proyecto.reset();
      
      this.formFields.descripcion.clearValidators();
      this.formFields.descripcion.updateValueAndValidity();
      this.formFields.descripcion.reset();
      
      this.formFields.implementacion.clearValidators();
      this.formFields.implementacion.updateValueAndValidity();
      this.formFields.implementacion.reset();
      
    }
  }

  ocupacionReto() {
    this.ocupacionSelect = this.formFields.ocupacionReto.value
    if(this.ocupacionSelect == 1) {
      this.removeValidatorsReto(2);
      this.formFields.horasReto.setValidators([Validators.required]);
      this.formFields.horasReto.updateValueAndValidity();
      this.formFields.materiaReto.setValidators([Validators.required]);
      this.formFields.materiaReto.updateValueAndValidity();
    }
    else if(this.ocupacionSelect > 1) {
      this.removeValidatorsReto(3);
      this.removeValidatorsReto(4);
      this.formFields.puestoReto.setValidators([Validators.required]);
      this.formFields.puestoReto.updateValueAndValidity();
    }
    this.formFields.antiguedadReto.setValidators([Validators.required]);
    this.formFields.antiguedadReto.updateValueAndValidity();
    this.formFields.interesReto.setValidators([Validators.required]);
    this.formFields.interesReto.updateValueAndValidity();  
  }
  empleoReto(){
    this.empleoSelect = this.formFields.empleoReto.value
    if(this.empleoSelect == 4) {
      this.formFields.otroEmpleoReto.setValidators([Validators.required]);
      this.formFields.otroEmpleoReto.updateValueAndValidity();
    }
    else {
      this.removeValidatorsReto(7);
    }
  }
  materiaReto() {
    this.materiaSelect = this.formFields.materiaReto.value
    if(this.materiaSelect == 4) {
      this.formFields.otraMateriaReto.setValidators([Validators.required]);
      this.formFields.otraMateriaReto.updateValueAndValidity();
    }
    else {
      this.removeValidatorsReto(4);
    }
  }

  removeValidatorsReto(opcion) {
    if(opcion == 1) {
      this.formFields.antiguedadReto.clearValidators();
      this.formFields.antiguedadReto.updateValueAndValidity();
      this.formFields.antiguedadReto.reset();
      this.formFields.interesReto.clearValidators();
      this.formFields.interesReto.updateValueAndValidity();
      this.formFields.interesReto.reset();
      this.formFields.ocupacionReto.clearValidators();
      this.formFields.ocupacionReto.updateValueAndValidity();
      this.formFields.ocupacionReto.reset();
      this.ocupacionSelect = 0;
    }
    if(opcion == 2) {
      this.formFields.puestoReto.clearValidators();
      this.formFields.puestoReto.updateValueAndValidity();
      this.formFields.puestoReto.reset();
    }
    if(opcion == 3) {
      this.formFields.horasReto.clearValidators();
      this.formFields.horasReto.updateValueAndValidity();
      this.formFields.horasReto.reset();
      this.formFields.materiaReto.clearValidators();
      this.formFields.materiaReto.updateValueAndValidity();
      this.formFields.materiaReto.reset();
    }
    if(opcion == 4) {
      this.formFields.otraMateriaReto.clearValidators();
      this.formFields.otraMateriaReto.updateValueAndValidity();
      this.formFields.otraMateriaReto.reset()
      this.materiaSelect = 0;
    }
    if(opcion == 5) {
      this.formFields.retoOaxaca.clearValidators();
      this.formFields.retoOaxaca.updateValueAndValidity();
      this.formFields.retoOaxaca.reset();
    }
    /* if(opcion == 6) {
      this.formFields.retoChiapasEmprendedores.clearValidators();
      this.formFields.retoChiapasEmprendedores.updateValueAndValidity();
      this.formFields.retoChiapasEmprendedores.reset();
    } */
    if(opcion == 7) {
      this.formFields.otroEmpleoReto.clearValidators();
      this.formFields.otroEmpleoReto.updateValueAndValidity();
      this.formFields.otroEmpleoReto.reset()
      this.empleoSelect = 0;
    }
    if(opcion == 8) {
      this.formFields.retoEdoMex.clearValidators();
      this.formFields.retoEdoMex.updateValueAndValidity();
      this.formFields.retoEdoMex.reset();
    }
    if(opcion == 9) {
      this.formFields.selectedProfileZacatecas.clearValidators();
      this.formFields.selectedProfileZacatecas.updateValueAndValidity();
      this.formFields.selectedProfileZacatecas.reset();
    }
  }

  /**
   * Method that add format to phone number input
   * @param field Phone number input
   */
  validatePhoneNo(field: { value: string }): void {
    let phoneNumDigits = field.value.replace(/\D/g, '');
    let formattedNumber = phoneNumDigits;
    if (phoneNumDigits.length > 6)
      formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ')' + phoneNumDigits.substring(3, 6) + '-' + phoneNumDigits.substring(6);
    else if (phoneNumDigits.length > 3)
      formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ')' + phoneNumDigits.substring(3);

    field.value = formattedNumber;
    this.formAccount.patchValue({ telefono: formattedNumber });
  }



  // Retrieve the first page of results
  resetQuery(): void {
    this.tech_schools = [];
    this.currentPage = 1;
    this.searchText = '';
    this.fetchMore();
  }

  // Return all query parameters into json object
  getQueryParameters(): string {
    const searchParams = new URLSearchParams();
    const queryParams: object = {
      description: this.searchText || '',
      current_page: this.currentPage,
      per_page: this.itemsPerPage
    };
    Object.keys(queryParams).forEach(key => searchParams.append(key, queryParams[key]));
    return searchParams.toString();
  }

  // Get next data to display with infinity scroll
  fetchMore(): void {
    this.loading = true;
    let queryString = this.getQueryParameters();

    this.techSchoolService.getTechnologics(queryString).then( data => {
      this.loadData(data);
    }).catch(error => {
      this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error al consultar tecnológicos. ⚠️');
    }).finally(() => this.loading = false );
  }


  // Execute on typing to search
  onSearch(): void {
    this.select$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      tap(() => this.loading = true))
      .subscribe( data => {
        this.searchText = data;
        this.fetchMore();
      });
  }


  // Execute when scroll bar is on bottom
  onScrollToEnd(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.fetchMore();
    }
  }

  /**
   * Set retrieved data  to select and paginations options
   * @param data Info requested
   */
  loadData(data: object): void{
    this.currentPage = data['current_page'];
    this.totalPages = data['total_pages'];
    this.totalItems = data['total'];
    if (this.currentPage == 1) {
      this.tech_schools = [];
    }
    let newData = data['data'].map(tech => new TechSchool(tech));
    this.tech_schools = this.tech_schools.concat(newData);
  }

  /**
   * Login user after create an account
   * @param string username - Username from created account
   * @param string password - Password from created account
   */
  autoLoginUser(username: string, password: string){
    this.isLoading = true;
    this.auth.login(username, password)
      .pipe( flatMap( () => this.auth.loadDiagnosticData() ))
      .subscribe( data => {
        //this.router.navigate(['app/dejanos-conocerte']);
        this.router.navigate(['app/perfil']);
        this.isLoading = false;
      },
      (error) => {
        this.snackbarService.showSnackBar('Cerrar', `${error} ⚠️`);
        this.isLoading = false;
      });
  }
  onClickDate(){
    this.messageDate = true;
  }

  onEntrepreneurshipDetail(){
    if(this.formFields.entrepreneurship.value == 2){
      this.showTextareaDetail = false;
      this.formFields.entrepreneurshipDetail.clearValidators();
      this.formFields.entrepreneurshipDetail.updateValueAndValidity();
      this.formFields.entrepreneurshipDetail.reset();
    }else{
      this.showTextareaDetail = true;
      this.formFields.entrepreneurshipDetail.setValidators([Validators.required]);
      this.formFields.entrepreneurshipDetail.updateValueAndValidity();
    }
  }
  /**
   * Method to return the lenght of a textfield
   * @param str - string to look for the chars
   * Return - number of chars
   */
  countChars(str): number{
    if (str) {
      const chars = str.length;
      return chars;
    }else{
      return 0;
    }
  }
  onParticipants(){
    this.participants = this.formFields.numberParticipants.value
    if(this.participants == 2 ){
      this.formFields.ageParticipant1.setValidators([Validators.required]);
      this.formFields.ageParticipant1.updateValueAndValidity();
      this.formFields.levelStudyTeam1.setValidators([Validators.required]);
      this.formFields.levelStudyTeam1.updateValueAndValidity();
      this.formFields.school1.setValidators([Validators.required]);
      this.formFields.school1.updateValueAndValidity();

      this.formFields.ageParticipant2.setValidators([Validators.required]);
      this.formFields.ageParticipant2.updateValueAndValidity();
      this.formFields.levelStudyTeam2.setValidators([Validators.required]);
      this.formFields.levelStudyTeam2.updateValueAndValidity();
      this.formFields.school2.setValidators([Validators.required]);
      this.formFields.school2.updateValueAndValidity();

      this.formFields.ageParticipant3.clearValidators();
      this.formFields.ageParticipant3.updateValueAndValidity();
      this.formFields.ageParticipant3.reset();

      this.formFields.levelStudyTeam3.clearValidators();
      this.formFields.levelStudyTeam3.updateValueAndValidity();
      this.formFields.levelStudyTeam3.reset();

      this.formFields.school3.clearValidators();
      this.formFields.school3.updateValueAndValidity();
      this.formFields.school3.reset();

      this.formFields.ageParticipant4.clearValidators();
      this.formFields.ageParticipant4.updateValueAndValidity();
      this.formFields.ageParticipant4.reset();

      this.formFields.levelStudyTeam4.clearValidators();
      this.formFields.levelStudyTeam4.updateValueAndValidity();
      this.formFields.levelStudyTeam4.reset();

      this.formFields.school4.clearValidators();
      this.formFields.school4.updateValueAndValidity();
      this.formFields.school4.reset();

      this.formFields.ageParticipant5.clearValidators();
      this.formFields.ageParticipant5.updateValueAndValidity();
      this.formFields.ageParticipant5.reset();

      this.formFields.levelStudyTeam5.clearValidators();
      this.formFields.levelStudyTeam5.updateValueAndValidity();
      this.formFields.levelStudyTeam5.reset();

      this.formFields.school5.clearValidators();
      this.formFields.school5.updateValueAndValidity();
      this.formFields.school5.reset();

    }
    if(this.participants == 3 ){

      this.formFields.ageParticipant1.setValidators([Validators.required]);
      this.formFields.ageParticipant1.updateValueAndValidity();
      this.formFields.levelStudyTeam1.setValidators([Validators.required]);
      this.formFields.levelStudyTeam1.updateValueAndValidity();
      this.formFields.school1.setValidators([Validators.required]);
      this.formFields.school1.updateValueAndValidity();

      this.formFields.ageParticipant2.setValidators([Validators.required]);
      this.formFields.ageParticipant2.updateValueAndValidity();
      this.formFields.levelStudyTeam2.setValidators([Validators.required]);
      this.formFields.levelStudyTeam2.updateValueAndValidity();
      this.formFields.school2.setValidators([Validators.required]);
      this.formFields.school2.updateValueAndValidity();

      this.formFields.ageParticipant3.setValidators([Validators.required]);
      this.formFields.ageParticipant3.updateValueAndValidity();
      this.formFields.levelStudyTeam3.setValidators([Validators.required]);
      this.formFields.levelStudyTeam3.updateValueAndValidity();
      this.formFields.school3.setValidators([Validators.required]);
      this.formFields.school3.updateValueAndValidity();

      this.formFields.ageParticipant4.clearValidators();
      this.formFields.ageParticipant4.updateValueAndValidity();
      this.formFields.ageParticipant4.reset();

      this.formFields.levelStudyTeam4.clearValidators();
      this.formFields.levelStudyTeam4.updateValueAndValidity();
      this.formFields.levelStudyTeam4.reset();

      this.formFields.school4.clearValidators();
      this.formFields.school4.updateValueAndValidity();
      this.formFields.school4.reset();

      this.formFields.ageParticipant5.clearValidators();
      this.formFields.ageParticipant5.updateValueAndValidity();
      this.formFields.ageParticipant5.reset();

      this.formFields.levelStudyTeam5.clearValidators();
      this.formFields.levelStudyTeam5.updateValueAndValidity();
      this.formFields.levelStudyTeam5.reset();

      this.formFields.school5.clearValidators();
      this.formFields.school5.updateValueAndValidity();
      this.formFields.school5.reset();

    }
    if(this.participants == 4 ){
      this.formFields.ageParticipant1.setValidators([Validators.required]);
      this.formFields.ageParticipant1.updateValueAndValidity();
      this.formFields.levelStudyTeam1.setValidators([Validators.required]);
      this.formFields.levelStudyTeam1.updateValueAndValidity();
      this.formFields.school1.setValidators([Validators.required]);
      this.formFields.school1.updateValueAndValidity();

      this.formFields.ageParticipant2.setValidators([Validators.required]);
      this.formFields.ageParticipant2.updateValueAndValidity();
      this.formFields.levelStudyTeam2.setValidators([Validators.required]);
      this.formFields.levelStudyTeam2.updateValueAndValidity();
      this.formFields.school2.setValidators([Validators.required]);
      this.formFields.school2.updateValueAndValidity();

      this.formFields.ageParticipant3.setValidators([Validators.required]);
      this.formFields.ageParticipant3.updateValueAndValidity();
      this.formFields.levelStudyTeam3.setValidators([Validators.required]);
      this.formFields.levelStudyTeam3.updateValueAndValidity();
      this.formFields.school3.setValidators([Validators.required]);
      this.formFields.school3.updateValueAndValidity();

      this.formFields.ageParticipant4.setValidators([Validators.required]);
      this.formFields.ageParticipant4.updateValueAndValidity();
      this.formFields.levelStudyTeam4.setValidators([Validators.required]);
      this.formFields.levelStudyTeam4.updateValueAndValidity();
      this.formFields.school4.setValidators([Validators.required]);
      this.formFields.school4.updateValueAndValidity();
      
      this.formFields.ageParticipant5.clearValidators();
      this.formFields.ageParticipant5.updateValueAndValidity();
      this.formFields.ageParticipant5.reset();

      this.formFields.levelStudyTeam5.clearValidators();
      this.formFields.levelStudyTeam5.updateValueAndValidity();
      this.formFields.levelStudyTeam5.reset();

      this.formFields.school5.clearValidators();
      this.formFields.school5.updateValueAndValidity();
      this.formFields.school5.reset();

    }
    if(this.participants == 5 ){
      this.formFields.ageParticipant1.setValidators([Validators.required]);
      this.formFields.ageParticipant1.updateValueAndValidity();
      this.formFields.levelStudyTeam1.setValidators([Validators.required]);
      this.formFields.levelStudyTeam1.updateValueAndValidity();
      this.formFields.school1.setValidators([Validators.required]);
      this.formFields.school1.updateValueAndValidity();

      this.formFields.ageParticipant2.setValidators([Validators.required]);
      this.formFields.ageParticipant2.updateValueAndValidity();
      this.formFields.levelStudyTeam2.setValidators([Validators.required]);
      this.formFields.levelStudyTeam2.updateValueAndValidity();
      this.formFields.school2.setValidators([Validators.required]);
      this.formFields.school2.updateValueAndValidity();

      this.formFields.ageParticipant3.setValidators([Validators.required]);
      this.formFields.ageParticipant3.updateValueAndValidity();
      this.formFields.levelStudyTeam3.setValidators([Validators.required]);
      this.formFields.levelStudyTeam3.updateValueAndValidity();
      this.formFields.school3.setValidators([Validators.required]);
      this.formFields.school3.updateValueAndValidity();
      
      this.formFields.ageParticipant4.setValidators([Validators.required]);
      this.formFields.ageParticipant4.updateValueAndValidity();
      this.formFields.levelStudyTeam4.setValidators([Validators.required]);
      this.formFields.levelStudyTeam4.updateValueAndValidity();
      this.formFields.school4.setValidators([Validators.required]);
      this.formFields.school4.updateValueAndValidity();
      
      this.formFields.ageParticipant5.setValidators([Validators.required]);
      this.formFields.ageParticipant5.updateValueAndValidity();
      this.formFields.levelStudyTeam5.setValidators([Validators.required]);
      this.formFields.levelStudyTeam5.updateValueAndValidity();
      this.formFields.school5.setValidators([Validators.required]);
      this.formFields.school5.updateValueAndValidity();

    }
    
  }
}
