<div class="container p-0">
  <div class="header">
    <span class="txt_title">Déjanos conocerte</span>
  </div>
  <div class="main-container animated fadeIn fast marginTop">
    <div class="card border-0 animated fadeIn fast">
      <div class="card-body animated fadeIn">
        <label class="txt_common text-left">
          Gracias por entrar a POSiBLE, la mejor plataforma de emprendimiento, te pedímos nos proporciones estos
          datos para poder brindarte la mejor experiencia:
        </label>
        <div class="mt-2 pb-5">
          <form autocomplete="off" [formGroup]="formb" (ngSubmit)="save()">
            <div class="form-group">
              <label for="exampleFormControlTextarea2" class="text-left txt_subtitle">
                Si ya tienes una idea, proyecto, negocio o empresa, escribe aquí su nombre. Si no, deja el espacio en blanco.
              </label>
              <textarea class="form-control inpts_posible borderRad" id="exampleFormControlTextarea2" rows="4"
                aria-label="Large" name="name" formControlName="name" [class.is-invalid]="noValid"
                placeholder="Respuesta" maxlength="200"></textarea>
              <div class="txt_advice text-right" *ngIf="!diagnosticFinished">
                <p class="mt-2" *ngIf="countChars(formFields.name.value) == 0"> Máx 200 caracteres</p>
                <p class="mt-2"
                  *ngIf="countChars(formFields.name.value) >= 1 && countChars(formFields.name.value) < 200">
                  {{200 - countChars(formFields.name.value) }} caracteres faltantes.</p>
                <p class="mt-2" *ngIf="countChars(formFields.name.value) == 200"> Máximo de caracteres alcanzado.</p>
              </div>
            </div>
            <button type="submit" class="my-5 btn-lg btn btn-block btn-warning btn_style">
              <span class="btn_style_text">Siguiente</span>
            </button>
          </form>
        </div>
      </div>
      <!--card body-->
    </div> <!-- card main-->
  </div>
</div>
