import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FeedCategory } from 'src/app/models/feedCategory/feedCategory';
import { PublicationService } from 'src/app/services/publication/publication.service';
import { SnackbarService } from 'src/app/services/notifications/snackbar.service';
import { PublicationType } from 'src/app/models/publicationType/publicationType';
import { AdService } from 'src/app/services/ad/ad.service';
import { PublicationDetail } from 'src/app/models/publication-detail/publication-detail';
import { environment } from 'src/environments/environment';
import * as firebase from 'firebase/app';
import { FirebaseService } from 'src/app/services/firebase-service/firebase.service';

@Component({
  selector: 'app-feed',
  templateUrl: './feed.component.html',
  styleUrls: ['./feed.component.scss']
})
export class FeedComponent implements OnInit {
  // Enum of categories available
  categories = FeedCategory;

  // Property to identify the category selected, news category by default
  currentType: string;

  // list of publications for the posible feed
  publications: PublicationDetail[];

  // loading spinner
  isLoading = false;

  // list of all the available publication types
  types: PublicationType[];

  // list of ads
  ads: [];

  // list of all the posts that are going to be listed
  allPosts = [];

  // flag to identify if the last page have been reached
  lastPage = false;

  // Actual page
  pageIndex = 1;

  constructor(
    private publicationService: PublicationService,
    private router: Router,
    private snackBarService: SnackbarService,
    private adService: AdService,
    private firebase: FirebaseService
  ) { }

  ngOnInit(): void {
    this.getPublicationTypes();
    this.getAds();
    this.firebase.logEvent('feedPosible');
  }

  // Method to navigate to the user profile
  openProfile(): void {
    this.router.navigate(['app/perfil']);
  }

  /**
   * Method to select a category
   * @param category - type guid
   */
  selectCategory = (category): void => {
    this.currentType = category;
    this.allPosts = [];
    this.getPublicationsByType(1);
  }

  /**
   * Method to get all the publications for the POSIBLE feed
   * @param page - page number
   */
  getPublicationsByType(page): void {
    this.isLoading = true;
    this.publicationService.getFeedPosible(this.currentType, page).then(response => {
      this.publications = response['results'].map(publication => new PublicationDetail(publication));
      this.insertAds();
      this.allPosts = [...this.allPosts, ...this.publications];
      let categoryName = this.types.find(cat => cat.guid == this.currentType);
      this.firebase.logEvent('feedPosibleCategory', { feedCategory: categoryName.description });
      if (response['next'] === null) {
        this.lastPage = true;
      }
    })
      .catch(error => {
        this.snackBarService.showSnackBar('Cerrar', `${error} ⚠️`);
      })
      .finally(() => this.isLoading = false);
  }

  // Method to get all the advertisements
  getAds(): void {
    this.adService.getAll().then(response => {
      this.ads = response['data'].map(ad => new PublicationDetail(ad));
    })
      .catch(error => {
        this.snackBarService.showSnackBar('Cerrar', `${error} ⚠️`);
      });
  }

  // Method to get all the publications types
  getPublicationTypes(): void {
    this.isLoading = true;
    this.publicationService.getTypes().then(response => {
      this.types = response['data'].map(type => new PublicationType(type));
      if (this.types.length) {
        // set the first type as the current category
        this.selectCategory(this.types[0].guid);
      }
    })
      .catch(error => this.snackBarService.showSnackBar('Cerrar', `${error} ⚠️`))
      .finally(() => this.isLoading = false);
  }

  // Method to retrieve more publications when the user gets to the bottom of the available publications
  onScroll(): void {
    if (this.lastPage) {
      return;
    }
    this.pageIndex++;
    this.getPublicationsByType(this.pageIndex);
  }

  /**
   * Method to insert ads in the list of publications
   * @param startPosition - index position where the ads are going to start being displayed
   * @param itemInterval - interval of items between every ad
   */
  insertAds(startPosition = 10, itemInterval = 10): void {
    // initialize our counter of the current lap
    let currentLap = 0;
    if(this.publications.length <= this.ads.length){ // Taking small chunks that divide posts and adds
      itemInterval = startPosition = Math.ceil(this.publications.length / this.ads.length);
    }
    while (this.ads[currentLap] != undefined) {// Until all ads are added
      // verify if there is an element in the specific index, if not restart the counter to keep showing ads
      if (this.ads[currentLap] != null) {
        this.publications.splice(startPosition, 0, this.ads[currentLap]);
      } else {
        currentLap = 0;
        this.publications.splice(startPosition, 0, this.ads[currentLap]);
      }
      currentLap++;
      startPosition += itemInterval;
    }
  }

}
