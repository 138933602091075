<div class="container p-0">
  <app-dynamic-navigation [projectId]="projectId" [actualStep]="projectSteps.fifthStep"></app-dynamic-navigation>
  <app-stepsbar-progress [totalNumSteps]="totalNumSteps" [currentStep]="stepNumber" [modelProgress]="modelProgress" [stepsNav]="projectSteps"></app-stepsbar-progress>
  <div class="main-container animated fadeIn fast marginTop">
    <div class="card border-0 animated fadeIn fast" style="background-color: #F7F7F7;">
      <div class="card-body animated fadeIn">
        <span class="introduction-style">
          La propuesta de valor debe comunicar aquello que tu empresa espera hacer mejor o de manera diferente que la
          competencia para sus clientes
        </span>
        <div class="mt-3">
          <form autocomplete="off" [formGroup]="forma" (ngSubmit)="update()">
            <div class="form-group">
              <label for="exampleFormControlTextarea2" class="subtitle text-left">1. ¿Qué productos o servicios o similares a tu idea de negocio has identificado? Enlista a tu 
                competencia (600 caracteres)</label>
              <textarea class="form-control input-rounded" id="exampleFormControlTextarea" rows="4" aria-label="Large"
                formControlName="productos" maxlength="600" placeholder="Respuesta"></textarea>
              <div class="txt_advice text-right mt-2">
                <p *ngIf="countChars(formFields.productos.value) == 0"> Máx 600 caracteres</p>
                <p *ngIf="countChars(formFields.productos.value) >= 1 && countChars(formFields.productos.value) < 600">{{600 - countChars(formFields.productos.value) }} caracteres faltantes.</p>
                <p  *ngIf="countChars(formFields.productos.value) == 600"> Máximo de caracteres alcanzado.</p>
              </div>
            </div>
            <hr>
            <div class="form-group">
              <label for="exampleFormControlTextarea2" class="subtitle text-justify">2. Describe de qué forma tu cliente hoy resuelve su problema o necesidad, (lo que hoy usa tu cliente 
                para resolver ese problema, aunque no sea lo mismo que tú ofreces) EJEMPLO. Tú resuelves el problema de servicio personalizado de transporte, pero hoy tu cliente lo 
                resuelve con el transporte público.</label>
              <textarea class="form-control input-rounded" id="exampleFormControlTextarea2" rows="4" aria-label="Large"
                formControlName="client_solution" maxlength="600" placeholder="Respuesta"></textarea>
              <div class="txt_advice text-right mt-2">
                <p *ngIf="countChars(formFields.client_solution.value) == 0"> Máx 600 caracteres</p>
                <p *ngIf="countChars(formFields.client_solution.value) >= 1 && countChars(formFields.client_solution.value) < 600">{{600 - countChars(formFields.client_solution.value) }} caracteres faltantes.</p>
                <p *ngIf="countChars(formFields.client_solution.value) == 600"> Máximo de caracteres alcanzado.</p>
              </div>
            </div>
            <hr>
            <div class="form-group">
              <label for="exampleFormControlTextarea2" class="subtitle text-left">3. En un párrafo, dinos: ¿Qué distingue a tu producto, o servicio de algunos otros que ya existen? 
                ¿Qué lo hace más atractivo para los clientes o por qué lo comprarían?</label>
              <textarea class="form-control input-rounded" id="exampleFormControlTextarea3" rows="4" aria-label="Large"
                formControlName="distingue" maxlength="600" placeholder="Respuesta"></textarea>
              <div class="txt_advice text-right mt-2">
                <p *ngIf="countChars(formFields.distingue.value) == 0"> Máx 600 caracteres</p>
                <p *ngIf="countChars(formFields.distingue.value) >= 1 && countChars(formFields.distingue.value) < 600">{{600 - countChars(formFields.distingue.value) }} caracteres faltantes.</p>
                <p class="text-danger" *ngIf="countChars(formFields.distingue.value) == 600"> Máximo de caracteres alcanzado.</p>
              </div>
            </div>
            <hr>
            <div class="form-group">
              <label for="" class="subtitle text-left">4. ¿Actualmente cuentas con alianzas estratégicas que complementen tu propuesta de valor 
                (otras personas u organizaciones que puedan ayudarte a vender más)?</label>
              <div class="text-center" *ngIf="alianzasNoValido ">
                <small class="text-danger animated fadeIn">
                  ¡¡ Se debe elegir una opción !!
                </small>
              </div>
              <div class="mt-2 form-check">
                <input class="form-check-input" type="radio" name="alianzas" [value]="false" id="uno"
                  formControlName="alianzas" (change)="onAllianceChange($event)">
                <label class="form-check-label" for="uno">
                  <b> No</b>
                </label>
              </div>
              <div class="my-4 form-check">
                <input class="form-check-input" type="radio" name="alianzas" [value]="true" id="dos"
                  formControlName="alianzas" (change)="onAllianceChange($event)">
                <label class=" form-check-label" for="dos">
                  <b> Sí ¿Cuáles? (Máximo 150 caracteres)</b>
                </label>
              </div>
              <div *ngIf="visibleAllyDescr" class="animated fadeIn fast">
                <textarea class="form-control input-rounded" rows="4"
                  aria-label="Large" formControlName="alianzasDescr" maxlength="600" placeholder="Respuesta"></textarea>
                  <div class="txt_advice text-right mt-2 mb-4 d-flex align-items-center">
                    <small *ngIf="submitted && formFields.alianzasDescr.errors && formFields.alianzasDescr.errors.required" class="text-danger animated fadeIn">
                      Este campo es requerido.
                    </small>
                    <div class="ml-auto">
                      <div *ngIf="countChars(formFields.alianzasDescr.value) == 0"> Máx 30 caracteres</div>
                      <div *ngIf="countChars(formFields.alianzasDescr.value) >= 1 && countChars(formFields.alianzasDescr.value) < 30">
                        {{30 - countChars(formFields.alianzasDescr.value) }} caracteres faltantes.</div>
                      <div *ngIf="countChars(formFields.alianzasDescr.value) == 30"> Máximo de caracteres alcanzado.</div>
                    </div>
                  </div>
              </div>
            </div>
            <button type="submit" class="btn-lg btn btn-block btn-warning btn_style">
              <span class="btn_style_text">Guardar y continuar</span>
            </button>
            <br><br>
          </form>
        </div>
      </div>
      <!--card body-->

    </div> <!-- card main-->

  </div>
</div>
