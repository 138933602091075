import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../../services/auth.service';
import { SnackbarService } from '../../../services/notifications/snackbar.service';
import { ModalGenericComponent } from '../../components/modal-generic/modal-generic.component';
import { ModalGenericService } from '../../components/modal-generic/modal-generic.service';
import * as firebase from 'firebase/app';
import { FirebaseService } from 'src/app/services/firebase-service/firebase.service';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  // Recover password form
  forgetForm: FormGroup;
  // Helper that indicates form is sending
  isLoading = false;
  // Helper that indicates if form was already send
  isSubmitted =  false;
  // Reference to modal instance
  modalRef: NgbModalRef;
  // Indicates the initial configuration of modals
  configModal: NgbModalOptions = {windowClass: 'modal-holder', centered: true, backdrop : 'static'};


  constructor(  private fb: FormBuilder,
      private router: Router,
      private auth: AuthService,
      private snackbarService: SnackbarService,
      private modalGenericService: ModalGenericService,
      private modalService: NgbModal,
      private firebase: FirebaseService ) {
    this.forgetForm = this.fb.group({
      correo  : ['', [Validators.required, Validators.email, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]]
    });
   }
  ngOnInit(): void {
    this.firebase.logEvent('recoverPassword');
  }

  // Convenience getter for easy access to form fields
  get formFields() {
    return this.forgetForm.controls;
  }

  // Method to send data to store account info
  enviar(): void {
    this.isSubmitted = true;
    this.isLoading = true;
    if ( this.forgetForm.invalid ){
      this.snackbarService.showSnackBar('Cerrar', 'Ingrese un correo electrónico válido. ⚠️');
      this.isLoading = false;
      return;
    }
    this.auth.recoverPassword(this.forgetForm.value.correo).then(resp => {
      let data ={
        title: ' ¡Correo enviado! ',
        description: ' Para restablecer tu contraseña, revisa la bandeja del correo electrónico proporcionado',
        url: 'login'
      };
      this.modalRef = this.modalService.open(ModalGenericComponent, this.configModal);
      this.modalGenericService.emitChange(data);
    }).catch( err => {
      this.snackbarService.showSnackBar('Cerrar', `${err} ⚠️`);
    }).finally( () => {
      this.isSubmitted = false;
      this.isLoading = false;
    });
  }

}
