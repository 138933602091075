<div class="container privacy animated fadeIn fast">
    <div class="row">
        <div class="col-12 mt-4">
            <a class="backText text-left cursor__pointer" (click)="goBack()"><i class="fa fa-arrow-left"
          aria-hidden="true"></i>
        Regresar</a>
            <h2 class="txt_title text-left">Preguntas frecuentes</h2>
        </div>
    </div>

    <section class="pb-4 txt_common">
        <div class="animated fadeIn text-justify">
            <p><strong>Información relevante:</strong><br>- POSiBLE Kids no tiene ningún costo.
            </p>
            <p>- POSiBLE Kids no solicita dinero para participar en alguna de las etapas. </p>
            <p>- POSiBLE Kids no brinda dentro sus beneficios seguro médico en caso de accidentes o lessiones a lo largo del programa. </p>
            <p>- Los tutores de los participantes seleccionados deberán firmar una carta de uso de imagen del participante, de lo ccontraario se cancelará su inscripción al programa.</p>
            <p>- POSiBLE Kids tiene el derecho de desprender del programa a aquel o aquellos participantes que presenten comportamientos inapropiados.</p>
        </div>

        <hr class="line_txt_style_msj">
        <div class="text-center">
            <small class="txt_style_msj">
        <p><b> © 2020 POSiBLE</b></p>
      </small>
        </div>

    </section>

</div>