<div class="card publicationShared" *ngIf="publication">
    <div class="card-body">
      <div class="row clickeable-place" [routerLink]="['/app/publicacion', publication.guid]">
        <div class="col-12">
          <div class="row pb-2" >
            <div class="col-2">
              <img *ngIf="publication.userPicture else noImg" class="img-circle" height="56px" width="56px"
                [src]="publication.userPicture">
              <ng-template #noImg>
                <img class="img-circle" height="54px" width="54px" src="assets/img/home/profile_img.png" alt="">
              </ng-template>
              <span class="online" *ngIf="publication.userStatus === userStatus.Online"></span>
            </div>
            <div class="col pt-1 pl-4 d-flex flex-column">
                <span class="txt_title_feed_profile">{{ publication.company }}</span>
                <p class="txt_name">
                  {{ publication.firstName }} {{ publication.lastName }}
                </p>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
                <p class="txt_date mb-0 mt-2">
                  {{ publication.date }}
                </p>
                <div appHighlight [text]="publication.description | slice:0:300"></div>
                <span class="showMore" *ngIf="!showMore && publication.description.length >= 300"> Ver
                  más</span>
            </div>
          </div>
        </div>
      </div>
      <app-image-slider [publication]="publication"></app-image-slider>
    </div>
</div>
