<div class="main-container row">
    <div class="container-header col-12 pl-md-5 pr-md-5">
        <div class="content-header">
            <div class="row">
                <div class="col-12 col-md-4 col-lg-4 mt-5">
                    <img src="../../../../assets/img/mail/Posible_amarillo.png" alt="" class="img-fluid">
                </div>
                <div class="col-12 col-md-8 col-lg-8 mt-4 menu-landing">
                    <ul class="d-md-flex p-0">
                        <li><a href="assets/Convocatoria_Chiapas_Tierra_de_Emprendedores.pdf" target="_blank">Convocatoria</a></li>
                        <li on-click="scrollFn('pasos')">Pasos a seguir</li>
                        <li on-click="scrollFn('beneficios')">Beneficios</li>
                    </ul>
                </div>

            </div>
            <div class="row mb-4">
                <div class="col-12 col-lg-4 mt-lg-5 text-center">
                    <img src="../../../../assets/img/microsites/tierra_emprendedores/CHIAPAS_LOGO.png" alt="" class="img-fluid" style="width:250px;">
                    <!-- <h3 class="text-center mb-3 text-chiapas">CHIAPAS</h3> -->
                    <p class="txt_common">
                        Buscamos inspirar el espíritu emprendedor y las iniciativas innovadoras, a través de la selección de los 15 mejores emprendimientos que representen el talento de la población chiapaneca.
                    </p>
                    <div class="mt-5 mb-3 animated fadeIn fast d-flex justify-content-center d-md-block">
                        <a class="button-register" [routerLink]="['/crear-cuenta']">Regístrate</a>
                    </div>
                </div>
                <div class="col-12 col-lg-7 offset-lg-1 mt-3 mt-md-0">
                    <img src="../../../../assets/img/microsites/tierra_emprendedores/seccion1.jpg" alt="" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid container-convocatory">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-7 d-flex align-items-center justify-content-center">
                    <div class="row d-flex align-items-center justify-content-center">
                        <div class="col-12 col-md-6">
                            <img src="../../../../assets/img/microsites/tierra_emprendedores/CHIAPAS_LOGO.png" alt="" class="img-fluid" style="width:350px;">
                        </div>
                        <div class="col-12 col-md-6 d-flex justify-content-center">
                            <a class="button-register" href="assets/Convocatoria_Chiapas_Tierra_de_Emprendedores.pdf" target="_blank">Convocatoria</a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-5 col-info mt-4 mt-lg-0">
                    <h5>¿Qué es Chiapas, Tierra de Emprendedores?</h5>
                    <p>
                        Es una iniciativa de la <b>Secretaría de Economía y del Trabajo del Estado de Chiapas y POSiBLE</b> de Fundación Televisa, que juntos ofrecen un espacio de fomento y acompañamiento al emprendimiento innovador, proporcionando herramientas,
                        contenidos y apoyos que permitan a las y los emprendedores chiapanecos mejorar sus ideas y proyectos emprendedores, para incrementar sus posibilidades de éxito.
                    </p>
                    <h5>¿Quiénes pueden postularse a Reto POSiBLE Chiapas?</h5>
                    <p>
                        Podrán postularse a <b>Chiapas, Tierra de Emprendedores</b> las y los emprendedores que:
                    </p>
                    <ul>
                        <li>Hayan nacido y radiquen actualmente en Chiapas.</li>
                        <li>Cuenten con una idea de negocio o proyecto de emprendimiento en etapa inicial.</li>
                        <li>Sus ideas o emprendimientos deberán ser innovadores y de impacto positivo en términos económicos, sociales, ambientales y/o culturales en el estado de Chiapas.</li>
                        <li>La totalidad de postulantes se someterá a los lineamientos de evaluación y selección contenidos en esta convocatoria.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid container-steps" id="pasos">
        <div class="container mt-5 mb-5">
            <div class="row">
                <div class="col-12 text-center mb-4">
                    <h1>Pasos a seguir</h1>
                </div>
                <div class="col-12 col-md-6 col-lg-3 mb-4">
                    <div class="card">
                        <div class="card-header pt-4">
                            <div class="div-circle">
                                <img src="../../../../assets/img/microsites/tierra_emprendedores/1.png" alt="" class="img-fluid">
                            </div>
                        </div>
                        <div class="card-body">
                            <p class="card-text pl-3 pr-3 text-center">Regístrate tu idea en <b>Chiapas, Tierra de Emprendedores</b></p>

                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3 mb-4">
                    <div class="card">
                        <div class="card-header pt-4">
                            <div class="div-circle">
                                <img src="../../../../assets/img/microsites/tierra_emprendedores/2.png" alt="" class="img-fluid">
                            </div>
                        </div>
                        <div class="card-body">
                            <p class="card-text pl-3 pr-3 text-center">Si eres seleccionado, presenta tu idea en <b>páneles de emprendimiento</b></p>

                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3 mb-4">
                    <div class="card">
                        <div class="card-header pt-4">
                            <div class="div-circle">
                                <img src="../../../../assets/img/microsites/tierra_emprendedores/3.png" alt="" class="img-fluid">
                            </div>
                        </div>
                        <div class="card-body">
                            <p class="card-text pl-3 pr-3 text-center">Forma parte del <b>Campamento Virtual Emprendedor </b>de 5 días</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3 mb-4">
                    <div class="card">
                        <div class="card-header pt-4">
                            <div class="div-circle">
                                <img src="../../../../assets/img/microsites/tierra_emprendedores/4.png" alt="" class="img-fluid">
                            </div>
                        </div>
                        <div class="card-body">
                            <p class="card-text pl-3 pr-3 text-center">Presenta tu idea de negocio en el “Demo day” y se candidato a la <b>beca “Chiapas, Tierra de Emprendedores”</b></p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid container-benefits" id="beneficios">
        <div class="container pt-5 pb-5">
            <div class="row">
                <div class="col-12 mb-4 text-center">
                    <h1>Beneficios del programa</h1>
                </div>
                <div class="col-12 col-md-12 col-lg-6 col-benefits mb-3 mb-lg-0">
                    <div class="row benefits">
                        <div class="col-12 col-md-12 d-flex align-items-center">

                            <ul>
                                <li><b>Talleres de capacitación</b> durante la etapa de registro.</li>
                                <li>Invitación a los talleres sobre "propuesta de valor", "product market fit" y "pitch".</li>
                                <li>Los proyectos seleccionados al participar en los páneles de emprendimiento, <b>recibirán retroalimentación de mentores expertos.</b></li>
                                <li>Posterior a la etapa de páneles, <b>, los proyectos que no resulten seleccionados,</b> recibirán el <b>taller modelo de negocios.</b></li>
                                <li>Los <b>15 semifinalistas recibirán acompañamiento de seis meses de un consultor en negocios virtual por medio de la plataforma "IF consultor", </b>el aprovechamiento dicho beneficio es responsabilidad de cada semifinalista.</li>
                                <li>Los <b>5 proyectos finalistas participarán en el evento presencial "Demo day" </b>para ser candidatos por la "Beca Empréndete".</li>
                                <li>La SEyT entregará la <b>"Beca Chiapas, Tierra de Emprendedores" </b>a los 3 seleccionados.</li>
                            </ul>

                        </div>
                    </div>

                </div>
                <div class="col-12 col-md-12 col-lg-6 d-flex align-items-center">
                    <img src="../../../../assets/img/retoPosible/chiapas-seccion2.jpg" alt="" class="img-fluid">
                </div>
            </div>
        </div>

    </div>
    <div class="container-fluid container-conoce">
        <div class="container text-center pt-4 pb-4">
            <div class="row">
                <div class="col-12">
                    <h1>Conoce más acerca de Reto POSiBLE</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid container-video">
        <div class="container text-center pt-4 pb-4">
            <div class="row">
                <div class="col-12">
                    <iframe width="70%" height="500" src="https://www.youtube.com/embed/3Py7TFGm6aw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div class="col-12 mt-5 col-logos">
                    <img src="../../../../assets/img/microsites/tierra_emprendedores/Plecas_de_logos.png" alt="" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
    <footer class="d-flex align-items-center">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-6 offset-lg-2">
                    <a [routerLink]="['/terminos']">Términos y condiciones</a>
                    <a [routerLink]="['/aviso-privacidad']">Aviso de privacidad</a>
                    <a href="assets/Convocatoria_Chiapas_Tierra_de_Emprendedores.pdf" target="_blank">Convocatoria</a>

                </div>
                <div class="col-12 col-md-6 col-lg-4 text-md-center text-lg-left">
                    <h4 class="mb-0">Contactanos por</h4>
                    <div class="div-icons">
                        <a href="https://api.whatsapp.com/send/?phone=525566223249&text=Hola%2C+tengo+una+duda+🟡" target="_blank"><i class="fa fa-whatsapp"></i></a>
                        <a href="mailto:reto.posible@fundaciontelevisa.org"><i class="fa fa-envelope-o" aria-hidden="true"></i></a>
                    </div>

                </div>
            </div>
        </div>
    </footer>
</div>
<!-- main-container-->