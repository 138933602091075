<div class="container search-container">
  <div class="row py-1">
    <div class="col-10">
        <input type="text" inputmode="search"  class="form-control search-input" placeholder="Buscar..." [(ngModel)]="search" (keydown.enter)="startSearch()"
        (keyup)="watchContent()" id="inputSearch" >
    </div>
    <div class="col-2 d-flex justify-content-end align-items-center">
      <button *ngIf="!isLoading" type="button" class="btn btn-search" (click)="clearSearch()">
        <i class="fa" [ngClass]="{'fa-chevron-right': !haveContent, 'fa-close gray-icon': haveContent}"></i>
      </button>
      <div *ngIf="isLoading" class="d-flex">
        <mat-spinner *ngIf="isLoading" diameter="20"></mat-spinner>
      </div>
    </div>
  </div>
</div>
