<app-pending-businessmodels-modal></app-pending-businessmodels-modal>
<div class="container notifications">
  <ng-container  >
    <div class="row time-divisor" *ngIf="notificationsUnseen.length>0">
      <div class="col-12">
        <p class="mb-0 time-text">Recientes</p>
      </div>
    </div>
    <app-notification [notification]="notification" *ngFor="let notification of notificationsUnseen" ></app-notification>
  </ng-container>

  <ng-container  >
    <div class="row time-divisor" *ngIf="notificationsSeen.length>0">
      <div class="col-12">
        <p class="mb-0 time-text">Anteriores</p>
      </div>
    </div>
    <app-notification [notification]="notification" *ngFor="let notification of notificationsSeen"></app-notification>
    <p class="pt-3" *ngIf="!notificationsSeen.length && !notificationsUnseen.length">No se encontraron notificaciones</p>
  </ng-container>

</div>

<div *ngIf="isLoading" class="d-flex justify-content-center mt-5">
  <mat-spinner diameter="60"></mat-spinner>
</div>
