<div class="my-2 ml-3">

  <mat-accordion class="example-headers-align">
    <mat-expansion-panel class="mat-elevation-z0" [expanded]="step === 2" (opened)="setStep(2)" hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-description>

          <div *ngIf="actualStep.stepNumber === 0; else realStep">
            <p class="txt_title nomodule-text">
              <i class="fa fa-angle-down fa-lg mr-2 font-color" aria-hidden="true"></i> {{actualStep.name}}
            </p>
          </div>

          <ng-template #realStep>
            <span class="txt_module_style">MÓDULO {{actualStep.stepNumber}}
              <p class="txt_title">
                <i class="fa fa-angle-down fa-lg mr-2 font-color" aria-hidden="true"></i> {{actualStep.name}}
              </p>
            </span>
          </ng-template>

          <div class="grow">
            <span *ngIf="actualStep.stepNumber != 0" class="float-right mr-2">
              <a [routerLink]="['/app/material-apoyo', actualStep.stepNumber]" target="_blank">
                Ayuda
                <svg width="20" height="20" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.65 11.9H9.35V13.6H7.65V11.9ZM8.5 0C3.808 0 0 3.808 0 8.5C0 13.192 3.808 17 8.5 17C13.192 17 17 13.192 17 8.5C17 3.808 13.192 0 8.5 0ZM8.5 15.3C4.7515 15.3 1.7 12.2485 1.7 8.5C1.7 4.7515 4.7515 1.7 8.5 1.7C12.2485 1.7 15.3 4.7515 15.3 8.5C15.3 12.2485 12.2485 15.3 8.5 15.3ZM8.5 3.4C6.6215 3.4 5.1 4.9215 5.1 6.8H6.8C6.8 5.865 7.565 5.1 8.5 5.1C9.435 5.1 10.2 5.865 10.2 6.8C10.2 8.5 7.65 8.2875 7.65 11.05H9.35C9.35 9.1375 11.9 8.925 11.9 6.8C11.9 4.9215 10.3785 3.4 8.5 3.4Z" fill="#F49F00"/>
                  </svg>
              </a>
            </span>
        </div>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <ng-container *ngFor="let step of navigationSteps | keyvalue: keepOrder">
        <mat-action-row *ngIf="projectId && actualStep.name != step.value.name">
          <span class="txt_module_style">
            <span *ngIf="step.value.stepNumber != 0">MÓDULO {{step.value.stepNumber}}</span>
            <p (click)="navigateTo(step.value.url)" class="txt_subtitle_menu">
              {{step.value.name}}
            </p>
          </span>
        </mat-action-row>
      </ng-container>

    </mat-expansion-panel>

  </mat-accordion>
</div>
