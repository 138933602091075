import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Globals } from '../classes/global';
import { AuthService } from '../services/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    // Globals urls for http requests
    globals = Globals;

    // Http urls excent to send token Authorization on headers
    tokenHttpUrlExcludes = [
        `${this.globals.users.pathUsers}${this.globals.users.login}`,
    ];

    constructor(private authenticationService: AuthService, private router: Router) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        // add authorization header with jwt token if available
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser && currentUser.token && !this.tokenHttpUrlExcludes.some(e => request.url.includes(e))) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Token ${currentUser.token}`
                }
            });
        }
        return next.handle(request);
    }
}

