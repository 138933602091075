import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';
import { ProjectService } from '../../../../services/project.service';
import { Project } from '../../../../models/project/project';
import { Profile } from '../../../../models/User/profile';
import { SnackbarService } from 'src/app/services/notifications/snackbar.service';
import { ProjectStatus } from 'src/app/models/project-status/project-status';
import Swal from 'sweetalert2'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PanelService} from '../../../../services/panel/panel.service';
import { DatePipe } from '@angular/common';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmAppointmentDialogComponent } from '../../../shared/dialogs/confirm-appointment/confirm-appointment-dialog/confirm-appointment-dialog.component';


@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
  providers: [DatePipe]
})
export class ScheduleProjectComponent implements OnInit {

    // loading spinner
    isLoading = true;
    id: string;
    project: Project;
    user: Profile;

    // editable
    canEditAbout = false;
    canEditMission = false;
    canEditVision = false;

    submitted = false;

    // Project status list
    projectList = ProjectStatus;
    formSchedule: FormGroup;
    
    fecha = "fecha";
    dias;
    selectedDate;

    hora = "hora";
    tiempos;
    tiemposFinal = [];
    selectedTime;

    select1 = false;
    select2 = false;

    constructor(private router: Router,
                private fb: FormBuilder,
                private route: ActivatedRoute,
                private projectService: ProjectService,
                private authenticationService: AuthService,
                private snackbarService: SnackbarService,
                private panelService: PanelService,
                private datePipe: DatePipe,
                private dialog: MatDialog,
              ) { }

    ngOnInit(): void {
      this.id = this.route.snapshot.paramMap.get('ID');
      this.createForm();
      this.getProject();
      this.getUser();
      this.getDays();
    }

    getDays(): void {
      this.panelService.getDays().then(data => {
        this.dias = data.data;
      });
    }

    getTimes(dia): void {
      this.tiemposFinal = [];
      this.fecha = dia.dia;
      this.selectedDate = dia;
      this.select1 = true;
      this.select2 = false;
      this.panelService.getTimes(dia.id).then(data => {
        data.data.forEach(el => {
          let tokens = el.split(':');
          let time = new Date(1970,0,1, tokens[0], tokens[1], tokens[2]);
          time.setMinutes(time.getMinutes() + 20);
          this.tiemposFinal.push(time);
        });
        this.tiempos = data.data;
      });
    }

    selectTime(tiempo, index): void {
      this.selectedTime = tiempo;
      this.hora = tiempo + ' - ' + this.datePipe.transform(this.tiemposFinal[index], 'H:mm:SS');
      this.select2 =  true; 
    }

    createForm(): void {
      this.formSchedule = this.fb.group({
        //nombre: ['', [Validators.required, Validators.minLength(2)]],
        //apellido: ['', [Validators.required, Validators.minLength(2)]],
        fechaCita: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
        //correo: ['', [Validators.required, Validators.pattern('[A-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
        //telefono: ['', [Validators.required, Validators.minLength(13), Validators.maxLength(13), Validators.pattern('^[(][0-9]{3}[)][0-9]{3}[-\.][0-9]{4,6}$')]],
        //estado: [null, [Validators.required]],
        //municipio: [null, [Validators.required]],
        //genero: [null, [Validators.required]],
        //educacionNivel: [null, [Validators.required]],
        //tecnologicos: [null],
        //tipoEscuela: [''],
        //universitario: [''],
        //terminos: ['', Validators.required],
        //pass1: ['', [Validators.required, Validators.minLength(4), Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])[A-Za-z0-9!@#$%^&*.-_()+=?]*$')]],
        //pass2: ['', Validators.required]
      });
    }

    /* Method to get the current project by id
    *
    */
    getProject(){
      this.projectService.getProject(this.id)
        .then(data => {
          this.project = new Project(data.data);
          this.isLoading = false;
        })
        .catch(error => this.snackbarService.showSnackBar('Cerrar', error));
    }

    regresar(): void {
      this.router.navigate(['app/perfil/proyecto', this.id]);
    }

    agendar() {
      let cita = {
        'panel': this.selectedDate.id,
        'time': this.selectedTime,
        'bmodel': this.id
      };

      if(this.select2 && this.select1) {
      }
      else{
        return;
      }

      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.width = '450px';
      dialogConfig.data = { fecha: this.selectedDate.dia, hora: this.hora };

      let dialogRef = this.dialog.open(ConfirmAppointmentDialogComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(data => {
        if(data.success) {
          this.panelService.createAppointment(cita).then(resp => {
            if(resp.success) {
              this.router.navigate(['app/perfil/proyecto', this.id]);
              this.snackbarService.showSnackBar('Cerrar','Felicidades la cita se ha agendado correctamente');
            }
            else {
              this.snackbarService.showSnackBar('Cerrar',resp.message);
            }
          });
        }
      });
      /*this.panelService.createAppointment(cita).then(data => {
        console.log("agendado", data);
      });*/
    }

    /* Method to save the values of project
    *
    */
    save(){
      this.projectService.saveProfileProject(this.project.businessModel_guid, this.project.about, this.project.mission, this.project.vision)
        .subscribe(resp => {
          this.snackbarService.showSnackBar('Cerrar', resp['message']);
       }, (error) => this.snackbarService.showSnackBar('Cerrar', error));
    }

    /* Method to get the current user
    *
    */
    getUser(): void {
      this.authenticationService.getCurrentUser()
        .then(data => {
          this.user = data;
        })
        .catch(error => this.snackbarService.showSnackBar('Cerrar', error));
    }


    deleteProject():void{
      Swal.fire({
        title: '¿Estas seguro?',
        text: "Se eliminará el proyecto",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.value) {
          this.projectService.delete(this.id).then( resp => {
            this.snackbarService.showSnackBar('Cerrar', resp['message']);
            this.router.navigateByUrl('/perfil');
          }).catch( error => this.snackbarService.showSnackBar('Cerrar', error))
        }
      })
    }
  }
