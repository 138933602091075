<div class="container p-0">
  <app-dynamic-navigation [projectId]="projectId" [actualStep]="projectSteps.eightStep"></app-dynamic-navigation>
  <app-stepsbar-progress [totalNumSteps]="totalNumSteps" [currentStep]="stepNumber" [modelProgress]="modelProgress" [stepsNav]="projectSteps"></app-stepsbar-progress>
  <div class="main-container animated fadeIn fast marginTop">
    <div class="card border-0 animated fadeIn fast" style="background-color: #F7F7F7;">
      <div class="card-body animated fadeIn">
        <div class="mt-3">
          <form autocomplete="off" [formGroup]="forma" (ngSubmit)="update()">
            <div class="form-group">
              <label class="subtitle text-left">1. ¿Has generado empleos hasta el momento?</label>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="empleos" [value]="false" id="Personas"
                  formControlName="empleos" (change)="onJobChange($event)">
                <label class="form-check-label" for="Personas">
                  <b>No</b>
                  <br>
                  <br>
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="empleos" [value]="true" id="Empresas"
                  formControlName="empleos" (change)="onJobChange($event)">
                <label class="form-check-label" for="Empresas">
                  <b> Sí ¿Cuántos empleos?</b>
                  <br>
                </label>
              </div>
            </div>
            <div *ngIf="visible" class="input-group input-group-lg animated fadeIn d-flex flex-column">
              <select class="form-control input-rounded border-radius d-block w-100" aria-label="Large" placeholder="Elegir"
                formControlName="cantidad">
                <option value="" selected disabled>Elegir</option>
                <option *ngFor="let jobCount of jobCounts" [value]="jobCount.jobCount_guid"> {{ jobCount.description }}
                </option>
              </select>
              <div class="text-danger" *ngIf="submitted && formFields.cantidad.errors && formFields.cantidad.errors.required">Campo requerido</div>
            </div>

            <div class="form-group">
              <label class="subtitle text-left">2. ¿Tu proyecto ya está en operación?</label>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="enOperacion" [value]="false" id="nOperracion"
                  formControlName="enOperacion">
                <label class="form-check-label" for="Personas">
                  <b>No</b>
                  <br>
                  <br>
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="enOperacion" [value]="true" id="siPeracion"
                  formControlName="enOperacion">
                <label class="form-check-label" for="siPeracion">
                  <b> Sí</b>
                  <br>
                </label>
              </div>
            </div>
            
            <div class="my-4 text-center">
              <span class="subtitle_middle">Platícanos de tus logros:</span>
            </div>
            <div class="container">
              <div class="text-center">
                <label class="text-left radio-style">¿Alguien ya uso tu producto o
                  servicio?</label>
              </div>
              <div class="row">
                <div class="offset-1 col-2 text-center">
                  <input class="form-check-input" type="radio" name="enUso" id="enUsoSi" [value]="true"
                    formControlName="enUso">
                  <label class="form-check-label" for="enUsoSi">Sí</label>
                </div>
                <div class="offset-6 col-2 text-center">
                  <input class="form-check-input" type="radio" name="enUso" id="enUsoNo" [value]="false"
                    formControlName="enUso">
                  <label class="form-check-label" for="enUsoNo">No</label>
                </div>
                <div class="col-1"></div>
              </div>
              <!--row-->

              <div class="mt-4 text-center">
                <label class="radio-style">¿Alguien ya pagó por tu producto o servicio?</label>
              </div>
              <div class="row">
                <div class="offset-1 col-2 text-center">
                  <input class="form-check-input" type="radio" name="pago" id="pago" [value]="true" id="pagoProdSi"
                    formControlName="pago" (change)="onPayChange($event)">
                  <label class="form-check-label" for="pagoProdSi">Sí</label>
                </div>
                <div class="col-5 text-left">
                  <input *ngIf="visiblePay" class="txt_input_style form-control input-rounded" type="number" step="1"
                    name="cuanto" placeholder="¿cuanto?" formControlName="cuanto">
                    <div class="text-danger" *ngIf="visiblePay && submitted && formFields.cuanto.errors">
                        <div *ngIf="formFields.cuanto.errors.required">Campo requerido</div>
                        <div *ngIf="formFields.cuanto.errors.pattern">Solo números</div>
                        <div *ngIf="formFields.cuanto.errors.min">No puede ser cero</div>
                    </div>
                </div>
                <div class="offset-1 col-2 text-center">
                  <input class="form-check-input" type="radio" name="pago" id="pagoProdNo" [value]="false"
                    (change)="onPayChange($event)" formControlName="pago">
                  <label class="form-check-label" for="pagoProdNo">No</label>
                </div>
                <div class="col-1"></div>
              </div>
              <!--row-->

              <div class="mt-4 text-center">
                <label class="radio-style">¿Cuentas ya con una página web? </label>
              </div>
              <div class="row">
                <div class="offset-1 col-5 text-left">
                  <input class="form-check-input" type="radio" name="web" id="webSi" [value]="true"
                    formControlName="web" (change)="onWebChange($event)">
                  <label class="form-check-label" for="webSi">Sí</label>
                </div>
                <div class="col-5 text-right">
                  <input class="form-check-input" type="radio" name="web" id="webNo" [value]="false"
                    formControlName="web" (change)="onWebChange($event)">
                  <label class="form-check-label" for="webNo">No</label>
                </div>                
                <div class="col-12 text-right">
                  <input *ngIf="visibleWeb" class="txt_input_style form-control input-rounded  mt-3" type="text" step="1"
                    name="pagina_web" placeholder="¿cual es tu página?" formControlName="pagina_web">
                    <div class="text-danger" *ngIf="visibleWeb && submitted && formFields.pagina_web.errors">
                        <div *ngIf="formFields.pagina_web.errors.required">Campo requerido</div>
                        <div *ngIf="formFields.pagina_web.errors.min">No puede ser cero</div>
                    </div>
                </div>
                <div class="col-1"></div>
              </div>
              <!--row-->

              <div class="mt-4 text-center">
                <label class="radio-style">¿Cuentas con el dominio/licencia de uso de nombre de tu producto o
                  servicio?</label>
              </div>
              <div class="row">
                <div class="offset-1 col-2 text-center">
                  <input class="form-check-input" type="radio" name="licencia" id="dominioSi" [value]="true"
                    formControlName="licencia">
                  <label class="form-check-label" for="dominioSi">Sí</label>
                </div>
                <div class="offset-6 col-2 text-center">
                  <input class="form-check-input" type="radio" name="licencia" id="dominioNo" [value]="false"
                    formControlName="licencia">
                  <label class="form-check-label" for="dominioNo">No</label>
                </div>
                <div class="col-1"></div>
              </div>
              <!--row-->
            </div>
            <!--container-->

            <div class="form-group mt-4">
              <label class="subtitle text-left">¿Cuáles son las 3 actividades que realizarás en los
                siguientes 6 meses para impulsar o llevar a cabo tu idea de negocio?</label>
              <textarea class="form-control input-rounded" id="exampleFormControlTextarea2" rows="4" aria-label="Large"
                formControlName="actvididades" maxlength="300" placeholder="Respuesta"></textarea>
              <div class="txt_advice text-right">
                <p class="mt-2" *ngIf="countChars(formFields.actvididades.value) == 0"> Máx 300 caracteres</p>
                <p class="mt-2"
                  *ngIf="countChars(formFields.actvididades.value) >= 1 && countChars(formFields.actvididades.value) < 300">
                  {{300 - countChars(formFields.actvididades.value) }} caracteres faltantes.</p>
                <p class="mt-2" *ngIf="countChars(formFields.actvididades.value) == 300"> Máximo de caracteres
                  alcanzado.</p>
              </div>
            </div>
            <button type="submit" class="my-4 btn-lg btn btn-block btn-warning btn_style">
              <span class="btn_style_text">Guardar y continuar</span>
            </button>
            <br><br>
          </form>
        </div>

      </div>
      <!--card body-->

    </div> <!-- card main-->

  </div>
</div>