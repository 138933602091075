import { SnackbarService } from './../../../services/notifications/snackbar.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { UsuarioModel } from '../../../models/usuario.model';
import { StateService } from 'src/app/services/state/state.service';
import { minimumAge } from '../../../validators/minimum-age.validator';
import { GendersType } from 'src/app/models/gender/gender';
import { EducationType, SchoolType, Education, SpecifyPublic } from 'src/app/models/education/education';
import { debounceTime, distinctUntilChanged, tap,} from 'rxjs/operators';
import { SchoolTechService } from 'src/app/services/school-tech.service';
import { TechSchool } from '../../../models/tech-school/tech-school';
import { Subject } from 'rxjs';
import { ItemsMultiselect } from 'src/app/classes/select/item-multiselect';
import { FirebaseService } from 'src/app/services/firebase-service/firebase.service';
import * as moment from 'moment';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-create-account-women-entrepreneur',
  templateUrl: './create-account-women-entrepreneur.component.html',
  styleUrls: ['./create-account-women-entrepreneur.component.scss']
})
export class CreateAccountWomenEntrepreneurComponent implements OnInit {
  // Property to identify if the form has been already submitted
  submitted = false;
  // Property to manage the form and its attributes
  formAccount: FormGroup;
  // Property to manage the user instance
  usuario: UsuarioModel = new UsuarioModel();
  // Property to manage the states from current api in array
  states: [];
  // Property to manage the cities from current api in array
  cities: [];
  // Indicates when account is saving
  isLoading = false;
  // List of genders
  genders = GendersType.values();
  // List of educations level
  educationsType = EducationType.values();
  // List of school types
  schoolTypes = SchoolType;
  // Specify public school
  specifyPublic = SpecifyPublic;
  // List of educations level
  educations = Education;
  // Items already shown in the select, when Tecnologicos is shown
  tech_schools: ItemsMultiselect[] = [];
  // Show loading when is getting more items
  loading = false;
  // Actual page on paginate
  currentPage = 1;
  // Numebr of items to display per page
  itemsPerPage = 10;
  // Total items on API
  totalItems: number;
  // total of paginate pages
  totalPages: number;
  // Observer waiting for new
  select$ = new Subject<string>();
  // Use to search item
  searchText = '';
  // Sets the birthdate that will be shown
  _birthDate;
  // Min birth date on datepicker
  minBirthDate = moment(new Date("1950-01-01T00:00"));
  // Is loading cities
  isLoadingCities = false;
  messageDate:any = false;
  nivelSeleccionado = 0;
  retoMujer = true;
  //reto posible chiapas
  chiapas = false;
  chiapasAccept = false;
  componentsList:[];
  //Mujer Posible Zacatecas
  zacatecas = true;
  componentsListZacatecas:[];
  listMunicipalityAttend:[];
  listLocalAttend:[];
  listCampInvitation:[];
  listGiros:[];
  listDates:[];
  componentSelected = false;
  campSelected = false;
  giroSelected:boolean = false;
  listLocal = false;
  listDate = false;
  showOther = false;
  set birthDate(event){
    if(event['startDate']){
      this._birthDate = event['startDate'].format('YYYY-MM-DD');
      this.messageDate = false;
    }
  }
  get birthDate(){
    return this._birthDate;
  }

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private auth: AuthService,
    private stateService: StateService,
    private snackbarService: SnackbarService,
    private techSchoolService: SchoolTechService,
    private firebase: FirebaseService,) {
      this.crearFormulario();
     }

  ngOnInit(): void {
    
    this.educationsType.pop();
    this.onSearch();
    this.getStates();
    this.getComponentsListZacatecas();
    this.getMunicipalityAttend();
    this.getListLocalAttend();
    this.getListCampInvitation();
    this.getListGiro();
    this.getListDate();
    this.firebase.logEvent('createAccount');
  }

  // Execute on typing to search
  onSearch(): void {
    this.select$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      tap(() => this.loading = true))
      .subscribe( data => {
        this.searchText = data;
        this.fetchMore();
      });
  }

  // Get next data to display with infinity scroll
  fetchMore(): void {
    this.loading = true;
    let queryString = this.getQueryParameters();

    this.techSchoolService.getTechnologics(queryString).then( data => {
      this.loadData(data);
    }).catch(error => {
      this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error al consultar tecnológicos. ⚠️');
    }).finally(() => this.loading = false );
  }

  // Return all query parameters into json object
  getQueryParameters(): string {
    const searchParams = new URLSearchParams();
    const queryParams: object = {
      description: this.searchText || '',
      current_page: this.currentPage,
      per_page: this.itemsPerPage
    };
    Object.keys(queryParams).forEach(key => searchParams.append(key, queryParams[key]));
    return searchParams.toString();
  }

  // Retrieve the first page of results
  resetQuery(): void {
    this.tech_schools = [];
    this.currentPage = 1;
    this.searchText = '';
    this.fetchMore();
  }

  // Execute when scroll bar is on bottom
  onScrollToEnd(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.fetchMore();
    }
  }
  
  /**
   * Set retrieved data  to select and paginations options
   * @param data Info requested
   */
   loadData(data: object): void{
    this.currentPage = data['current_page'];
    this.totalPages = data['total_pages'];
    this.totalItems = data['total'];
    if (this.currentPage == 1) {
      this.tech_schools = [];
    }
    let newData = data['data'].map(tech => new TechSchool(tech));
    this.tech_schools = this.tech_schools.concat(newData);
  }

  /**
   * Method to manage the valid inputs with a css color
   */
   get terminosNoValido(): boolean {
    return this.formAccount.get('terminos').invalid && this.formAccount.get('terminos').touched;
  }
  get avisoNoValido(): boolean {
    return this.formAccount.get('avisoPrivacidad').invalid && this.formAccount.get('avisoPrivacidad').touched;
  }
  get acuerdoNoValido(): boolean {
    return this.formAccount.get('acuerdoConformidad').invalid && this.formAccount.get('acuerdoConformidad').touched;
  }

  // Convenience getter for easy access to form fields
  get formFields() {
    return this.formAccount.controls;
  }

  // method to force the email input to lowercase and trim
  mailHelperInput = (evt) => {
    this.formAccount.patchValue({ correo: evt.target.value.toLowerCase() });
    this.formAccount.patchValue({ correo: evt.target.value.trim() });
  }

  // Method to get the states list
  getStates(): void {
    this.stateService.getAll()
      .then(data => this.states = data)
      .catch(error => {
        this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error al consultar los estados. ⚠️');
      }
      );
  }
  /**
   * Method to get the cities based on the state id received
   * @param id - state id
   */
   getCities(): void {
    /* this.zacatecas = true;
      this.formFields.mujerPosible.setValidators([Validators.required]);
      this.formFields.mujerPosible.updateValueAndValidity(); */
    /* if(this.formFields.estado.value == '00000000-0000-0000-0000-000000000007') { 
      this.chiapas = true;
      this.formFields.participated.setValidators([Validators.required]);
      this.formFields.participated.updateValueAndValidity();
      
    }else{
      this.chiapas = false;
      this.chiapasAccept = false;
      this.sede = false;
      this.formFields.participated.clearValidators();
      this.formFields.participated.updateValueAndValidity();
      this.formFields.participated.reset();
      this.formFields.componentsListParticipated.clearValidators();
      this.formFields.componentsListParticipated.updateValueAndValidity();
      this.formFields.componentsListParticipated.reset();
      this.formFields.sedesList.clearValidators();
      this.formFields.sedesList.updateValueAndValidity();
      this.formFields.sedesList.reset();
    } */
    /* if(this.formFields.estado.value == '00000000-0000-0000-0000-000000000020'){
      this.zacatecas = true;
      this.formFields.mujerPosible.setValidators([Validators.required]);
      this.formFields.mujerPosible.updateValueAndValidity();
    }else{
      this.zacatecas = false;
      this.componentSelected = false;
      this.formFields.mujerPosible.clearValidators();
      this.formFields.mujerPosible.updateValueAndValidity();
      this.formFields.mujerPosible.reset();
      this.formFields.municipalityAttend.clearValidators();
      this.formFields.municipalityAttend.updateValueAndValidity();
      this.formFields.municipalityAttend.reset();
    } */


    // Reset cities when state changes
    this.formAccount.patchValue({ municipio: null });
    this.isLoadingCities = true;
    this.stateService.getCities(this.formFields.estado.value)
      .then(data => {this.cities = data; this.isLoadingCities = false})
      .catch(error => {
        this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error al consultar las ciudades. ⚠️');
        this.isLoadingCities = false;
      }
      );
  }

  /**
   * Method that add format to phone number input
   * @param field Phone number input
   */
   validatePhoneNo(field: { value: string }): void {
    let phoneNumDigits = field.value.replace(/\D/g, '');
    let formattedNumber = phoneNumDigits;
    if (phoneNumDigits.length > 6)
      formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ')' + phoneNumDigits.substring(3, 6) + '-' + phoneNumDigits.substring(6);
    else if (phoneNumDigits.length > 3)
      formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ')' + phoneNumDigits.substring(3);

    field.value = formattedNumber;
    this.formAccount.patchValue({ telefono: formattedNumber });
  }

  crearFormulario(): void {
    this.formAccount = this.fb.group({
      nombre: ['', [Validators.required, Validators.minLength(2)]],
      apellido: ['', [Validators.required, Validators.minLength(2)]],
      apellidoMat: [''],
      fechaNac: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), minimumAge(18)]],
      correo: ['', [Validators.required, Validators.pattern('[A-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      telefono: ['', [Validators.required, Validators.minLength(13), Validators.maxLength(13), Validators.pattern('^[(][0-9]{3}[)][0-9]{3}[-\.][0-9]{4,6}$')]],
      estado: [null, [Validators.required]],
      municipio: [null, [Validators.required]],
      genero: [null, [Validators.required]],
      educacionNivel: [null, [Validators.required]],
      mujerPosible: [null, Validators.required],
      terminos: ['', Validators.required],
      avisoPrivacidad: ['', Validators.required],
      acuerdoConformidad: ['', Validators.required],
      isCliente: ['', Validators.required],
      municipalityAttend:[null],
      checkLocal1: [''],
      checkLocal2: [''],
      checkLocal3: [''],
      campInvitation:[null],
      other: [''],
      giroParticipate:[null],
      businessName:[''],
      checkDate1: [''],
      checkDate2: [''],
      checkDate3: [''],
    });
  }

  getAccount(): object {
    return {
      first_name: this.formFields.nombre.value,
      last_name: this.formFields.apellido.value,
      second_last_name: this.formFields.apellidoMat.value,
      birth_date: this.birthDate,
      email: this.formFields.correo.value,
      phonenumber: this.formFields.telefono.value.replace(/([(]|[)]|-)/g , ''),
      state: this.formFields.estado.value,
      municipality: this.formFields.municipio.value,
      gender: this.formFields.genero.value,
      education: this.formFields.educacionNivel.value,
      componentParticipate: this.formFields.mujerPosible.value === "" ? null : this.formFields.mujerPosible.value,
      municipalityAttend: this.formFields.municipalityAttend.value === "" ? null : this.formFields.municipalityAttend.value,
      isCliente: this.formFields.isCliente.value,
      checkLocal1:  this.formFields.checkLocal1.value === "" ? null : this.formFields.checkLocal1.value,
      checkLocal2: this.formFields.checkLocal2.value === "" ? null : this.formFields.checkLocal2.value,
      checkLocal3: this.formFields.checkLocal3.value === "" ? null : this.formFields.checkLocal3.value,
      campInvitation: this.formFields.campInvitation.value === "" ? null : this.formFields.campInvitation.value,
      other: this.formFields.other.value === "" ? null : this.formFields.other.value,
      giroParticipate:  this.formFields.giroParticipate.value === "" ? null : this.formFields.giroParticipate.value,
      businessName: this.formFields.businessName.value === "" ? null : this.formFields.businessName.value,
      checkDate1:  this.formFields.checkDate1.value === "" ? null : this.formFields.checkDate1.value,
      checkDate2: this.formFields.checkDate2.value === "" ? null : this.formFields.checkDate2.value,
      checkDate3: this.formFields.checkDate3.value === "" ? null : this.formFields.checkDate3.value,
    };
  }

  onClickDate(){
    this.messageDate = true;
  }

  // Method to send data to store account info
  save(): void {
    
    this.submitted = true;
    this.isLoading = true;
   
    if (this.formAccount.invalid) {
      this.isLoading = false;
      this.snackbarService.showSnackBar('Cerrar', 'Verifique que los datos estén completos. ⚠️');
      return;
    }
    let account = this.getAccount();
    this.auth.nuevaMujerEmprendedora(account).then((resp:any) => {
      if(resp.success == true){
        this.router.navigateByUrl('/felicidadesmujeremprendedora')
        /* Swal.fire({
          title: '¡Registro exitoso! 🎉',
          imageUrl: '/assets/img/Identidad_FME_2022-01.png',
          imageWidth: 300,
          imageAlt: 'Mujer Posible',
          showConfirmButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            location.reload()
            
          }
        }) */
      }
      if(resp.success == false){
        Swal.fire({
          title: '¡Fecha de exposición llena!',
          html:
          '<ul class="limit-full">' +
            resp.message.join("")+
          '</ul>',
          imageUrl: '/assets/img/Identidad_FME_2022-01.png',
          imageWidth: 300,
          imageAlt: 'Mujer Posible',
          showConfirmButton: true,
        }).then((result) => {
          /* if (result.isConfirmed) {
            location.reload()
            
          } */
        })
      }
      if(resp.empty == true){
        Swal.fire({
          title: '¡Ha ocurrido un error!',
          text: resp.message,
          imageUrl: '/assets/img/Identidad_FME_2022-01.png',
          imageWidth: 300,
          imageAlt: 'Mujer Posible',
          showConfirmButton: true,
        }).then((result) => {
          /* if (result.isConfirmed) {
            location.reload()
            
          } */
        })
      }
    }).catch(err => {
      if (Object.keys(err.error).length > 1) {
        this.snackbarService.showSnackBar('Cerrar', `${err.message}. ⚠️`);
      } else {
        let nameError = Object.keys(err.error)[0];
        this.snackbarService.showSnackBar('Cerrar', `${err.error[nameError]} ⚠️`);
      }
    }).finally(() => {
      this.isLoading = false;
      this.submitted = false;
    });
  }

 
  componentsZacatecas(event){
    if(event.id == 1){
      this.listLocal = true;
      /* this.formFields.checkLocal1.setValidators([Validators.required]);
      this.formFields.checkLocal1.updateValueAndValidity();
      this.formFields.checkLocal1.setValidators([Validators.required]);
      this.formFields.checkLocal1.updateValueAndValidity(); */
    }else{
      this.listLocal = false;
      /* this.formFields.municipalityAttend.clearValidators();
      this.formFields.municipalityAttend.updateValueAndValidity();
      this.formFields.municipalityAttend.reset(); */
    }
    if(event.id == 2){
      this.componentSelected = true;
      this.formFields.municipalityAttend.setValidators([Validators.required]);
      this.formFields.municipalityAttend.updateValueAndValidity();
    }else{
      this.componentSelected = false;
      this.formFields.municipalityAttend.clearValidators();
      this.formFields.municipalityAttend.updateValueAndValidity();
      this.formFields.municipalityAttend.reset();
    }
    if(event.id == 3){
      this.campSelected = true;
      this.formFields.campInvitation.setValidators([Validators.required]);
      this.formFields.campInvitation.updateValueAndValidity();
      
    }else{
      this.campSelected = false;
      this.formFields.campInvitation.clearValidators();
      this.formFields.campInvitation.updateValueAndValidity();
      this.formFields.campInvitation.reset();
    }
    
    if(event.id == 4){
      this.giroSelected = true;
      this.listDate= true;
      this.formFields.businessName.setValidators([Validators.required]);
      this.formFields.businessName.updateValueAndValidity();
      this.formFields.giroParticipate.setValidators([Validators.required]);
      this.formFields.giroParticipate.updateValueAndValidity();
      
      
    }else{
      this.giroSelected = false;
      this.listDate = false;
      this.formFields.businessName.clearValidators();
      this.formFields.businessName.updateValueAndValidity();
      this.formFields.businessName.reset();
      this.formFields.giroParticipate.clearValidators();
      this.formFields.giroParticipate.updateValueAndValidity();
      this.formFields.giroParticipate.reset();
    }
  }

  getComponentsListZacatecas():void{
    this.auth.componentsListEntrepreneur()
      .then(data => this.componentsListZacatecas = data)
      .catch(error => {
        this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error al consultar la lista de componentes de Mujer POSiBLE. ⚠️');
      }
      );
  }
  getMunicipalityAttend():void{
    this.auth.municipalityAttendEntrepreneur()
      .then(data => this.listMunicipalityAttend = data)
      .catch(error => {
        this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error al consultar la lista de municipios a acudir. ⚠️');
      }
      );
  }
  getListLocalAttend():void{
    this.auth.listLocalAttend()
      .then(data => this.listLocalAttend = data)
      .catch(error => {
        this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error al consultar la lista de consumo local. ⚠️');
      }
      );
  }

  getListCampInvitation():void{
    this.auth.listCampInvitation()
      .then(data => this.listCampInvitation = data)
      .catch(error => {
        this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error al consultar la lista de consumo local. ⚠️');
      }
      );
  }

  getListGiro():void{
    this.auth.listGiro().then(data => this.listGiros = data)
    .catch(error => {
      this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error al consultar la lista de giros. ⚠️');
    }
    );
  }
  getListDate():void{
    this.auth.listDate().then(data => this.listDates = data)
    .catch(error => {
      this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error al consultar la lista de giros. ⚠️');
    }
    );
  }
  campInvitation(event){
    if(event.id == 3){
      this.showOther = true;
      this.formFields.other.setValidators([Validators.required]);
      this.formFields.other.updateValueAndValidity();
    }else{
      this.showOther = false;
      this.formFields.other.clearValidators();
      this.formFields.other.updateValueAndValidity();
      this.formFields.other.reset();
    }
  }

}
