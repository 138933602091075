import * as moment from 'moment';
// Class to implement attributes of a short publication
export class ShortPublication {
    guid: string;
    company: string;
    description: string;
    date: any;
    hashtag: string;

    constructor(data: any, hashtag: string) {
        // setup moment language and set the time passed of this publication
        moment.locale('es');

        this.guid = data.publication_guid;
        this.company = data.user.company;
        this.description = data.description;
        this.date = moment(data.createDate).format('LL');
        this.hashtag = `#${hashtag}`;
    }
}
