import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { TopicService } from '../../../../services/topic.service';
import { Topic } from '../../../../models/topic/topic';
import { SnackbarService } from 'src/app/services/notifications/snackbar.service';
import { Router } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';
import { FirebaseService } from 'src/app/services/firebase-service/firebase.service';
@Component({
  selector: 'app-topics',
  templateUrl: './topics.component.html',
  styleUrls: ['./topics.component.scss']
})
export class ProfileTopicsComponent implements OnInit {

// property to manage if there is a current petition in progress
  isLoading       = true;
  // property to store the topic list
  topics          = [];
  // property to manage the current selected topics in a list
  topicsSelected  = [];
// property to manage the prev url
  fromDiagnostic: boolean = false;
  // Indicates if diagnostic is finished
  diagnosticFinished = false;

  constructor(private location: Location,
              private topicService: TopicService,
              private snackbarService: SnackbarService,
              private authService: AuthService,
              private router: Router,
              private firebase: FirebaseService
            ) {}

  ngOnInit(): void {
    this.diagnosticFinished = this.authService.diagnosticValue.diagnosticFinished;
    this.getUserTopics();
    if (this.diagnosticFinished) {
      this.fromDiagnostic = true;
    }
  }

  /* Method to go back
  */
  back(): void{
    this.location.back();
  }

  /* Method to get all topics ProfileTopicsComponent
  *
  */
  getUserTopics(): void{
    this.topicService.getUserTopics()
      .then(data => {
        data.data.forEach(topic => {
          this.topicsSelected.push(new Topic(topic));
        });
        this.getTopics();
      })
      .catch(error => this.snackbarService.showSnackBar('Cerrar', error));
  }

  // Method to get all topics ProfileTopicsComponent
  getTopics(): void{
    this.topicService.getActiveTopics()
      .then(data => {
        let checked = false;
        data.forEach(topic => {
          let tempTopic = new Topic(topic);
          this.topicsSelected.forEach(topicSelected => {
            if(topic.topic_guid == topicSelected.topic_guid){
              tempTopic.checked = true;
            }
          });
          this.topics.push(tempTopic);
        });
        this.isLoading = false;
      })
      .catch(error => this.snackbarService.showSnackBar('Cerrar', error));
  }

  /* Method to get all topics ProfileTopicsComponent
  * @param index - number, index of the topic on the array
  */
  select(index:number): void{
    this.topics[index].checked = !this.topics[index].checked;
    let current = this.topics[index];

    let alreadyAdded = false;
    let alreadyAddedIndex = null;

    this.topicsSelected.forEach((topic, topicIndex) => {
      if(current.topic_guid == topic.topic_guid){
        if(!current.checked){
          alreadyAdded = true;
          alreadyAddedIndex = topicIndex;
          return;
        }
      }
    });

    if (alreadyAdded){
      if (!current.checked){
        this.topicsSelected.splice(alreadyAddedIndex, 1);
      }
    }else{
      this.topicsSelected.push(current);

    }
  }

  // Method to save the user topics
  save(): void{
    if(this.topicsSelected.length == 0){
      this.snackbarService.showSnackBar('Cerrar', 'Se requiere al menos un interés seleccionado.');
      return;
    }
    this.topicService.save(this.topicsSelected)
     .subscribe(resp => {
      this.snackbarService.showSnackBar('Cerrar', `Intereses guardados`);
      this.firebase.logEvent('topicsCompleted');
      this.router.navigate(['app/perfil']);
    }, (error) => this.snackbarService.showSnackBar('Cerrar', error));
  }

}
