import { environment } from '../../environments/environment';
export const Globals = {
    urlPosibleBase: `${environment.apiUrl}api/`,
    urlPosibleBaseFiles: environment.apiUrl,
    chatSocket: {
        chatSocketPath: environment.apiChat,
        history: '/api/rooms/',
        image: 'image/',
        user: '/api/users/',
        notifications: '/notifications'
    },
    chatroom: {
        room: 'rooms/',
        image: 'image/'
    },
    users: {
        pathUsers: 'users/',
        login: 'login/',
        imageProfile: 'imageProfile/',
        deleteImageProfile: 'removeImageProfile/',
        search: 'search/',
        searchCompany: 'searchByCompnay/',
        contacts: 'myContacts/',
        topics: '/interests/',
        pathContacts: 'contacts/',
        chatroom: 'chatRoom/',
        password: 'password/',
        heardFrom: 'getHeardSource/',
        saveSurvey: 'saveSurvey/',
        entrepreneurships: 'getEntrepreneurship/',
        nodes: 'getNodesList/',
        components: 'getComponentsList/',
        createMujer: 'createMujer/',
        componentsParticipated: 'getComponentsListParticipated/',
        sedesList: 'getSedesList/',
        componentsListZacatecas: 'getComponentsListZacatecas/',
        municipalityAttend: 'getMunicipalityAttend/',
        componentsListEntrepreneur: 'getComponentsListEntrepreneur/',
        municipalityAttendEntrepreneur: 'getMunicipalityAttendEntrepreneur/',
        listLocalAttend: 'getListLocalAttend',
        listCampInvitation: 'getListCampInvitation',
        listGiros: 'getGiroList',
        listDate: 'getDateList',
        createMujerEmprendedora: 'createMujerEmprendedora/'
    },
    states: {
        pathStates: 'states/',
        municipalities: '/municipalities/'
    },
    panel: {
        pathPanel: 'panel/',
        days: 'daysAvailables/',
        agendar: 'createAppointment/',
        times: '/timeUnavailables/',
        ppt: '/uploadPPT/',
        status: '/appointmentStatus/',
        enlace: '/uploadVideoLink/',
        detalles: '/appointmentByModel/'
    },
    projects: {
        categories: 'projectCategories/',
        incomes: 'incomeTypes/',
        publicity: 'publicityTypes/',
        sells: 'sellTypes/',
        agesRanges: 'ageRanges/',
        supports: 'supportTypes/',
        optionsSolutions: 'optionsSolutions/',
        clientTypes: 'clientTypes/',
        genders: 'genders/',
        acquisitionLevels: 'acquisitionLevels/',
        jobCounts: 'jobCounts/',
        projects: 'projects/',
        myProjects: 'myProjects',
        pathProjects: 'projects',
        save: 'profileData/',
        changeStatus: 'changeStatus/',
        currentAnnouncements: 'convocatorias/actives/',
        businessModelStatus: 'businessModelStatus/'
    },
    publications: {
        pathPublications: 'publications/',
        home: 'home/',
        feedPosible: 'feedPosible/',
        likes: '/likes/',
        comments: '/comments/',
        search: 'search/',
        share: '/share/'
    },
    ads: {
        pathAds: 'highlights/',
        list: 'listAvailables/'
    },
    diagnostic: {
        businessIdea: 'businessIdea/',
        diagnosticCategories: 'diagnosticCategories/',
        statusPosible: 'statusPosible/',
        stages: 'stages/',
        timesInvested: 'timesInvested/',
        diagnostic: 'diagnostic/',
        diagnosticDetail: 'diagnostic/myDiagnostic/',
        diagnosticUpdate: 'diagnostic/user/'
    },
    types: {
        pathTypes: 'publicationTypes/'
    },
    topics: {
        pathTopics: 'topics/',
        userTopics: 'UserDiagnosticCategories/',
        activeTopics: 'availables'
    },
    contacts: {
        pathContacts: 'contacts/',
        addFriend: '/sendRequest/',
        removeFriend: '/removeFriend/',
        acceptRequest: '/confirmRequest/',
        cancelRequest: '/declineRequest/',
        list: 'receivedRequests/',
        haveRequest: '/requestStatus/'
    },
    notifications: {
        pathNotifications: 'notifications/',
    },
    institutions: {
        pathInstitutions: 'institutions/'
    }
};
