
<div id="mySidenav2" class="sidenav2">
    <a style="float: left;" style="color: #252525;"><i (click)="openNav()" class="fa fa-angle-down" aria-hidden="true" style="margin-right: 10px;"></i></a> 
    <span class="txt_title">Describe el problema</span> 
        




     <a style="float: left;" href="javascript:void(0)" (click)="openNav()" ><i class="fa fa-angle-down" aria-hidden="true" style="margin-right: 10px;"></i> open</a>
     <a href="javascript:void(0)" style="float: right" class="closebtn" (click)="closeNav()">&times;</a>
     <br>
      <a href="#">About</a>     
      <a href="#">Services</a>  
      <a href="#">Clients</a>   
      <a href="#">Contact</a>   
   
  </div>
