<div class="container terms animated fadeIn fast">
  <div class="row">
    <div class="col-12 mt-4">
      <a class="text-left backText cursor__pointer" (click)="goBack()"><i class="fa fa-arrow-left"
          aria-hidden="true"></i> Regresar</a>
      <h2 class="txt_title text-left">Términos y condiciones</h2>
    </div>
  </div>

  <section class="pb-4 txt_common">
    <div class="animated fadeIn text-justify">
      <p><b>Descripción del programa</b></p>

      <p>
        POSIBLE está dirigido a personas mayores de 18 años, con residencia en México, que desean convertirse en emprendedores, y ofrece capacitación, herramientas, conocimientos y contactos clave para darle forma a una iniciativa de negocio de manera exitosa.
      </p>

      <p>
          El programa consta de cinco etapas:
      </p>
      <ol>
        <li>Convocatoria: Se invita a las personas a que desarrollen su idea de negocio en  <a href="https://www.posible.org.mx">www.posible.org.mx</a> </li>
        <li>Evaluación: Los modelos de negocio terminados son revisados y calificados por expertos aliados de POSiBLE, quienes les envían retroalimentación a los emprendedores e identifican a los de mayor potencial.</li>
        <li>Paneles de selección: Se invita a los proyectos mejor calificados de la evaluación en línea, a exponer su idea de manera presencial o virtual, frente a un jurado de evaluadores voluntarios y donde reciben retroalimentación al momento de su exposición adicional a un correo electrónico con más comentarios.</li>
        <li>Campamento POSiBLE: Hasta 100 proyectos podrán ser seleccionados para participar en un programa de cinco días enfocado en validar su modelo de negocio, afinar sus estrategias de captación de clientes, identificar fuentes de financiamiento y prepararse para hacer un pitch. El último día se realiza una sesión de networking para que los participantes se puedan vincular con diferentes aliados que les puedan dar el apoyo necesario para seguir creciendo su emprendimiento.</li>
        <li>Comunidad POSiBLEr: todos los proyectos que llegan al campamento cada año se integran a una comunidad donde apoyamos con difusión, acceso a financiamiento, relaciones estratégicas y mentores, además de interactuar con los emprendedores de otras generaciones.</li>
      </ol>

      <p>
        Los emprendedores participantes en el Campamento POSIBLE podrán recibir:
      </p>
      <ul>
        <li>Visibilidad para dar a conocer su idea de negocio en medios masivos y eventos especiales. </li>
        <li>Enriquecimiento profesional con posibilidad de acceder a oportunidades a nivel internacional. </li>
        <li>Capacitación, cursos y contenidos formativos para fortalecer sus conocimientos.</li>
        <li>Mentorías especializadas presenciales y en línea.</li>
        <li>Contactos con inversionistas y potenciales socios de negocio.</li>
      </ul>

    </div>

    <div class="animated fadeIn text-justify">
      <p><b>Términos y condiciones de la Convocatoria POSiBLE 2022 que usted expresamente entiende y acepta </b></p>

      <p>
          Sobre el proceso de participación:
      </p>
      <ul>
        <li>POSiBLE no tiene fines de lucro, no proporciona créditos y no solicita dinero para participar, en ninguna de las etapas.  </li>
        <li>La aplicación para la Convocatoria de POSiBLE 2022 estará abierta del <b>15 de febrero al 16 de mayo de 2022</b> hasta las 23:59 horas (tiempo del Centro de México). No habrá prórrogas. </li>
        <li>La única forma de enviar documentos para su consideración es a través de esta plataforma. No se considerarán documentos enviados por correo electrónico o físicamente. </li>
        <li>Los resultados del proceso de Convocatoria en sus diferentes etapas son irrevocables.</li>
        <li>La selección de los proyectos para participar en POSiBLE es decisión exclusiva de sus directivos de acuerdo con el perfil que busca impulsar el programa.</li>
        <li>Los participantes de los proyectos seleccionados entienden que no obtendrán dinero como premio, ni bajo ningún otro concepto. De igual forma, POSiBLE no ofrece premios, reconocimientos, ayudas en numerario ni en especie. Cualquier ofrecimiento realizado durante los eventos preparados por POSiBLE serán a título personal y no a cargo de este programa.</li>
        <li>POSiBLE no garantiza resultados ni cumplimiento de objetivos de cada proyecto, únicamente encausa y orienta sobre el desarrollo de los mismos.</li>
        <li>Las fechas, plazos, sedes y apoyos son susceptibles de cambios o cancelación en cualquier momento hasta 72 horas antes de su realización.</li>
      </ul>

      <p>
          Sobre los proyectos:
      </p>
      <ul>
        <li>Acepto la obligación de proveer información verídica, confiable, completa y exacta para la postulación en la Convocatoria POSiBLE 2022. </li>
        <li>El proyecto que presento es titularidad del suscrito y no se encuentra registrado ante ninguna instancia pública o privada por un tercero distinto.  </li>
        <li>La información proporcionada por el emprendedor durante el llenado de su modelo de negocio es confidencial. Dicha información será exclusiva para los efectos de la convocatoria, proceso de selección y en ningún caso será divulgada.</li>
        <li>La información solicitada sobre el proyecto es utilizada para fines de evaluación y objetivos que el programa POSIBLE persigue. </li>
        <li>La información del proyecto es privada y solo tiene acceso a ella el equipo POSiBLE. </li>
        <li>La propiedad intelectual del proyecto registrado en POSIBLE, pertenece totalmente al emprendedor que participa en el programa. POSIBLE no tomará propiedad del proyecto, ni participación en él. </li>
      </ul>

      <p>
          Sobre los participantes:
      </p>
      <ul>
        <li>Aquellos emprendedores que lleguen a la etapa de paneles, podrán ser sujetos de toma de fotografías para difusión en redes sociales para evidenciar la realización de esta etapa, por lo que como participante no tengo reclamación presente o futura que hacer sobre regalías o pagos presentes o futuros del uso de mi imagen.</li>
        <li>POSiBLE no es responsable de los daños y/o pérdidas materiales que sufran los emprendedores durante los eventos que prepare y de igual forma sobre los daños que puedan sufrir personalmente los emprendedores durante los eventos y la estadía en alguna entidad donde estos se realicen. </li>
      </ul>

    </div>
    <hr class="line_txt_style_msj">
    <div class="text-center">
      <small class="txt_style_msj">
        <p><b> © 2022 POSiBLE</b></p>
      </small>
    </div>

  </section>





</div>
