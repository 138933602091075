<div class="container project-completed-dialog">
  <div class="row">
    <div class="col-12 text-center">
      <img src="assets/img/board.png" class="img-fluid img-project mb-3">
      <h2 class="title">Verifica los datos</h2>
      <div class="text-description mt-3">
        <p>
          Estas a un paso de agendar tu cita de paneles estatales. <br>
          Favor de verificar la informacion y confirmar tu cita.
        </p>
      </div>
    </div>
  </div>

  <div class="row mt-4">
      <div class="col-12">
        <label>Dia:</label>
        <p>{{data.fecha}}</p>
        <label>Hora:</label>
        <p>{{data.hora}}</p>
        <div class="col-5" style="display: inline-block;">
          <button type="button" class="btn-lg btn btn-block btn-warning btn_send_review mt-2" (click)="agendar(true)">
            <span class="btn_continuar_text">CONFIRMAR CITA </span>
          </button>
        </div>
        <div class="col-5" style="display: inline-block;">
          <button type="button" class="btn-lg btn btn-block btn-warning btn_send_review mt-2" (click)="agendar(false)">
            <span class="btn_continuar_text">CAMBIAR DATOS</span>
          </button>
        </div>
    </div>
  </div>

  <div class="row mt-2 mb-3">
    <div class="col-12">


    </div>
  </div>
</div>
