export class UploadingFile {
    private id: number;
    private type: uploadingFileType;
    private porcentage: number;

    // Optional Values
    // File value binary
    private file: File;
    // Base 64 result
    private rawResult: string;

    constructor(type: uploadingFileType){
        this.id = Date.now();
        this.type = type;
        this.porcentage = 0;
    }

    // Returns the identifier of the UploadingFile
    public getId(): number {
        return this.id;
    }

    // Sets the new porcentage for the file
    public setPorcentage(porcentage): void {
        this.porcentage = Math.ceil(porcentage);
    }

    // Returns the file type
    public getType(): uploadingFileType {
        return this.type;
    }

    // Sets the new file value
    public setFile(file: File): void {
        this.file = file;
    }

    // Sets the new Base64 value
    public setResult(rawResult: string): void {
        // TODO : Validate that raw result is a valid base64 value
        this.rawResult = rawResult;
    }

    // Gets the file value
    public getFile(): File {
        return this.file;
    }

    // Gets the Base64 value
    public getResult(): string {
        return this.rawResult;
    }

    // Returns if the file is an image
    public isImage(): boolean {
        return this.type === uploadingFileType.IMAGE;
    }

    // Returns if the file is a video
    public isVideo(): boolean {
        return this.type === uploadingFileType.VIDEO;
    }
}

// List of valid file types
export enum uploadingFileType {
    IMAGE = 1,
    VIDEO = 2
}
