import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DiagnosticService } from '../../../services/diagnostic/diagnostic.service';
import { SnackbarService } from 'src/app/services/notifications/snackbar.service';
import { AuthService } from '../../../services/auth.service';
import { DiagnosticSteps } from 'src/app/classes/diagnosticSteps';
@Component({
  selector: 'app-create-project5',
  templateUrl: './create-project5.component.html',
  styleUrls: ['./create-project5.component.scss']
})
export class CreateProject5Component implements OnInit {
  // var to store timeInvested of diagnostic
  timeInvested: string;
   // var to store the auth token
  token: string;
  // var to store info from api-service list
  options: [];
  // current step number of this page
  stepNumber = 5;
  // total number of steps
  totalNumSteps = 6;
   // Property to identify the form
  formb: FormGroup;
  // Load steps of diagnostic in order to display navbar
  diagnosticSteps = DiagnosticSteps;
  // Show the states of the steps
  modelProgress = [];

  constructor(private fb: FormBuilder,
              private router: Router,
              private dgService: DiagnosticService,
              private snackbarService: SnackbarService,
              private authService: AuthService) {

    this.timeInvested = this.authService.diagnosticValue.timeInvested || '';
    this.modelProgress = this.authService.diagnosticValue.modulesCompleted || [];
    this.formb = this.fb.group({
      timeInvestedfb: [this.timeInvested, [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.getTimesInvested();
  }

  // Method to get the project categories list
  getTimesInvested():void {
    this.dgService.getTimesInvested()
      .then(data => {
        this.options = data.data;
      });
  }
  /**
   * Method to manage the valid inputs with a css color
   */
  get noValid():boolean {
    return this.formb.get('timeInvestedfb').invalid && this.formb.get('timeInvestedfb').touched;
  }

  // Method to store the time invested on the posible diagnostic info
  save():void {
    if (this.formb.invalid) {
      return Object.values(this.formb.controls).forEach(control => {
        control.markAllAsTouched();
      });
    }
    const timeInvested = this.formb.value.timeInvestedfb;
    this.authService.updateDiagnosticData({ timeInvested }).then( result => {
      this.snackbarService.showSnackBar('Cerrar', "Se actualizó diagnóstico satisfactoriamente");
      this.router.navigate(['app/perfil/intereses']);
    }).catch( err =>  { this.snackbarService.showSnackBar('Cerrar', err )});
  }

    /**
   * Receives the url to the empty question
   * @param $event - Url
   */
  redirectToEmptyQuestion($event): void{
    this.router.navigate([$event]);
  }
}
