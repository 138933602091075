import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SearchTypes } from 'src/app/models/search-types/search-types.enum';
import { ShortPublication } from 'src/app/models/short-publication/short-publication';
import { UserSearch } from 'src/app/models/user-search/user-search';
import { SnackbarService } from 'src/app/services/notifications/snackbar.service';
import { PublicationService } from 'src/app/services/publication/publication.service';
import { UserService } from 'src/app/services/user/user.service';
import { FirebaseService } from 'src/app/services/firebase-service/firebase.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  // types of search
  searchTypes = SearchTypes;

  // Property to identify if the search box has text inside
  haveContent = false;

  // Property to identify the search input value
  search = '';

  // Property to identify the type of search
  private _type: SearchTypes;

  // property to identify if a request is in progress
  isLoading = false;

  // Method that listens to the type property and cleans the results every time that change
  @Input() set type(value: SearchTypes) {
    this._type = value;
    this.searchResults.emit([]);
    this.clearSearch();
  }

  @Output() searchResults = new EventEmitter();

  constructor(
    private userService: UserService,
    private snackBarService: SnackbarService,
    private publicationService: PublicationService,
    private firebase: FirebaseService
  ) { }

  ngOnInit(): void {
  }

  // Method to create the search request
  startSearch(): void {
    if (!this.search.trim().length) {
      return;
    }
    this.searchHandler();
  }

  // method to identify what kind of data type we are going to search for
  searchHandler(): void {
    this.watchContent();
    switch (this._type) {
      case this.searchTypes.Person: {
        this.firebase.logEvent('searchPerson', { searchText: this.search });
        this.getPersons();
        break;
      }
      case this.searchTypes.Company: {
        this.firebase.logEvent('searchCompany', { searchText: this.search });
        this.getCompanies();
        break;
      }
      case this.searchTypes.Hashtag: {
        this.firebase.logEvent('searchHashTag', { searchText: this.search });
        this.getHashtags();
        break;
      }
    }
  }

  // Method to identify if the search input have content
  watchContent(): boolean {
    return this.haveContent = (this.search.trim().length) ? true : false;
  }

  // Method to clear the search input and the results list
  clearSearch(): void {
    this.search = '';
    if (this.haveContent) {
      this.watchContent();
      this.searchResults.emit([]);
    }
  }

  // Method to get search for persons
  getPersons(): void {
    this.isLoading = true;
    this.userService.search(this.search.trim()).then(response => {
      if (response['data'].length) {
        this.searchResults.emit(response['data'].map(person => new UserSearch(person)));
      } else {
        this.searchResults.emit([]);
      }
    })
      .catch(error => {
        this.snackBarService.showSnackBar('Cerrar', `${error} ⚠️`);
      })
      .finally(() => { this.isLoading = false; });
  }

  // Method to get search for companies
  getCompanies(): void {
    this.isLoading = true;
    this.userService.searchCompanies(this.search.trim()).then(response => {
      if (response['data'].length) {
        this.searchResults.emit(response['data'].map(person => new UserSearch(person)));
      } else {
        this.searchResults.emit([]);
      }
    })
      .catch(error => {
        this.snackBarService.showSnackBar('Cerrar', `${error} ⚠️`);
      })
      .finally(() => { this.isLoading = false; });
  }

  // Method to get search for hashtags
  getHashtags(): void {
    this.isLoading = true;
    this.publicationService.searchHashtag(this.search.trim()).then(response => {
      if (response['data'].length) {
        this.searchResults.emit(response['data'].map(publication => new ShortPublication(publication, this.search)));
      } else {
        this.searchResults.emit([]);
      }
    })
      .catch(error => {
        this.snackBarService.showSnackBar('Cerrar', `${error} ⚠️`);
      })
      .finally(() => { this.isLoading = false; });
  }

}
