import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-mediafile',
  templateUrl: './modal-mediafile.component.html',
  styleUrls: ['./modal-mediafile.component.scss']
})
export class ModalMediafileComponent implements OnInit {
  
  // Used to pass file to chat
  @Output() fileToUpload = new EventEmitter<FileList>();
  
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }  
  
  /**
   * Pass file to chat
   * @param files List of files to upload
   */
  addFile(files: FileList){
    this.fileToUpload.emit(files);
  }

  closeModal(): void{
    this.activeModal.close();
  }

}
