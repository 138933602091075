import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { Globals } from '../classes/global';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    // Globals urls for http requests
    globals = Globals;

    // Http urls excent to send token Authorization on headers
    tokenHttpUrlExcludes = [
        `${this.globals.users.pathUsers}${this.globals.users.login}`,
        `${this.globals.users.pathUsers}${this.globals.users.password}`,
    ];

    constructor(private authenticationService: AuthService) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401 && !this.tokenHttpUrlExcludes.some(e => request.url.includes(e))) {
                // auto logout if 401 response returned from api
                this.authenticationService.logout();
                location.reload();
            }

            let error = err.error.message || err.statusText;
            if(typeof(err.error['error']) === "object" && Object.keys(err.error['error']).length == 1){
                error = err.error;
            }
            return throwError(error);
        }));
    }
}
