export const microsites = {
  "no-edo":{
    logo: '',
    header: false,
    agenda: false,
    bg: '/assets/img/landing/background.png',
    name: '',
    text: 'No tengas miedo de hacer las cosas diferentes, es lo que cambia el mundo. <br><br>Únete a nuestra comunidad de emprendedores y encuentra las herramientas, alianzas y conocimientos necesarios para que tu idea se convierta en una empresa exitosa.'
  },
  "edomex": {
    logo: 'assets/img/microsites/edomex.png',
    header: true,
    agenda: false,
    bg: 'assets/img/microsites/edomex_bg.png',
    name: '',
    text: 'Si eres del <b>Estado de México</b>, únete a nuestra comunidad de emprendedores y encuentra las herramientas, alianzas y conocimientos necesarios para que tu idea se convierta en una empresa exitosa.<br>¡Tú puedes ser el próximo emprendedor!'
  },
  "puebla": {
    logo: '',
    header: false,
    agenda: false,
    bg: 'assets/img/microsites/puebla_bg.png',
    name: 'Amiel Rosales - Nubix',
    text: 'Si eres del Estado de <b>Puebla</b>, únete a nuestra comunidad de emprendedores y encuentra las herramientas, alianzas y conocimientos necesarios para que tu idea se convierta en una empresa exitosa.'
  },
  "zacatecas": {
    logo: 'assets/img/microsites/zacatecas.png',
    header: true,
    agenda: true,
    agendaUrl: 'assets/talleres-zacatecas.pdf',
    bg: 'assets/img/microsites/zacatecas_bg.png',
    name: '',
    text: 'Si eres del <b>Estado de Zacatecas</b>, únete a nuestra comunidad de emprendedores y encuentra las herramientas, alianzas y conocimientos necesarios para que tu idea se convierta en una empresa exitosa.<br>¡Tú puedes ser el próximo emprendedor!'
  },
  "retoOaxaca": {
    logo: 'assets/img/microsites/logo_iodemc.jpg',
    header: false,
    agenda: false,
    bg: 'assets/img/microsites/reto_bg.png',
    name: '',
    text: '<b>Amplia tus conocimientos y dale a tus alumnos la oportunidad de aprender acerca del emprendimiento de alto impacto.</b>'
    //text: 'Si eres <b>docente universitario</b> y vives en el <b>Estado de Oaxaca,</b> únete a nuestra comunidad y encuentra las herramientas, alianzas y aprendizajes necesarios para fortalecer tus conocimientos en la enseñanza del emprendimiento de alto impacto.'
  },
  "hackaton": {
    logo: 'assets/img/microsites/edomex_new.PNG',
    header: false,
    agenda: false,
    bg: 'assets/img/microsites/hackaton_bg.png',
    name: '',
    text: 'POSiBLE, el programa de emprendimiento de Fundación Televisa y el Instituto Mexiquense del Emprendedor, se unen a la Expo Talento del Colegio Nacional de Educación Profesional Técnica, en su edición 2021, para realizar el Hackatón IA CONALEP “aprende, desarrolla y construye”'
    //text: 'Si eres <b>docente universitario</b> y vives en el <b>Estado de Oaxaca,</b> únete a nuestra comunidad y encuentra las herramientas, alianzas y aprendizajes necesarios para fortalecer tus conocimientos en la enseñanza del emprendimiento de alto impacto.'
  },
  "retoChiapas": {
    logo: 'assets/img/microsites/logo_iodemc.jpg',
    header: false,
    agenda: false,
    bg: 'assets/img/microsites/reto_bg.png',
    name: '',
    text: '<b>Amplia tus conocimientos y dale a tus alumnos la oportunidad de aprender acerca del emprendimiento de alto impacto.</b>'
    //text: 'Si eres <b>docente universitario</b> y vives en el <b>Estado de Oaxaca,</b> únete a nuestra comunidad y encuentra las herramientas, alianzas y aprendizajes necesarios para fortalecer tus conocimientos en la enseñanza del emprendimiento de alto impacto.'
  },
  "retoZacatecas": {
    logo: 'assets/img/microsites/logo_iodemc.jpg',
    header: false,
    agenda: false,
    bg: 'assets/img/microsites/reto_bg.png',
    name: '',
    text: 'Reto POSiBLE Zacatecas es para ti si: <br> Eres profesionista y tienes vinculación con la enseñanza o promoción del emprendimiento en instituciones de nivel superior y buscas ampliar tus conocimientos para inspirar a nuevos emprendedores a generar cambios a partir del emprendimiento de alto impacto.<br> Eres estudiante o recién egresado de una institución de nivel superior y tienes una idea o proyecto de emprendimiento que solucione problemáticas en tu entorno, y buscas adquirir conocimientos y herramientas para mejorarlo.'
    //text: 'Si eres <b>docente universitario</b> y vives en el <b>Estado de Oaxaca,</b> únete a nuestra comunidad y encuentra las herramientas, alianzas y aprendizajes necesarios para fortalecer tus conocimientos en la enseñanza del emprendimiento de alto impacto.'
  },
}