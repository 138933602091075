import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { microsites } from 'src/app/classes/microsites';

@Component({
  selector: 'app-reto-chiapas-emprendedores',
  templateUrl: './reto-chiapas-emprendedores.component.html',
  styleUrls: ['./reto-chiapas-emprendedores.component.scss']
})
export class RetoChiapasEmprendedoresComponent implements OnInit {


  constructor(private _vps: ViewportScroller) { }

  ngOnInit(): void {
  }
  scrollFn(anchor: string): void{
  	this._vps.scrollToAnchor(anchor)
  }

}
