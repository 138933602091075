import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SnackbarService } from 'src/app/services/notifications/snackbar.service';
import { ProjectService } from 'src/app/services/project.service';
import { CongratulationsDialogComponent } from '../../congratulations-dialog/congratulations-dialog/congratulations-dialog.component';
import { TrackingPixelService } from 'src/app/services/facebook/tracking-pixel.service';

@Component({
  selector: 'app-confirm-appointment-dialog',
  templateUrl: './confirm-appointment-dialog.component.html',
  styleUrls: ['./confirm-appointment-dialog.component.scss']
})
export class ConfirmAppointmentDialogComponent implements OnInit {

  // Property to identify the form
  form: FormGroup;
  // Property to identify the announcement selected (select control)
  announcementGuid: string = '';
  // Property to store the current project announcements
  Announcements: [];
  // Property to control if the announcements select is to be shown
  announcementsVisible: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<ConfirmAppointmentDialogComponent>,
    private route: Router,
    private fb: FormBuilder,
    private projectService: ProjectService,
    private snackbarService: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private fbpixel: TrackingPixelService
  ) {
    this.form = this.fb.group({
      announcementGuid: ['']
    });
  }

  ngOnInit(): void {
    
  }

  agendar(confirm) {
    this.dialogRef.close({ success: confirm });
  }

  

  // open congratulations dialog
  

}
