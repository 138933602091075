import { NotificationsComponent } from './components/pages/notifications/notifications.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FrequentQuestionsComponen } from './components/pages/frequent-questions/frequent-questions.component';
import { PrivacyAdviceComponent } from './components/pages/privacy-advice/privacy-advice.component';
import { PrivacyAdviceKidsZacatecasComponent } from './components/pages/privacy-advice-kids-zacatecas/privacy-advice-kids-zacatecas.component';
import { ForgotPasswordComponent } from './components/pages/forgot-password/forgot-password.component';
import { CreateAcountComponent } from './components/pages/create-acount/create-acount.component';
import { CreateProjectComponent } from './components/pages/create-project/create-project.component';
import { CreateProject2Component } from './components/pages/create-project2/create-project2.component';
import { LoginComponent } from './components/pages/login/login.component';
import { TermsComponent } from './components/pages/terms/terms.component';
import { FAQComponent } from './components/pages/faq/faq.component';
import { AuthGuard } from './auth.guard';
import { HomeComponent } from './components/pages/home/home.component';
import { NewProjectComponent } from './components/pages/new-project/new-project.component';
import { DescribeProblemComponent } from './components/pages/describe-problem/describe-problem.component';
import { ExplainIdeaComponent } from './components/pages/explain-idea/explain-idea.component';
import { DescribeClientsComponent } from './components/pages/describe-clients/describe-clients.component';
import { ValueProposalComponent } from './components/pages/value-proposal/value-proposal.component';
import { DefineAdvertisingComponent } from './components/pages/define-advertising/define-advertising.component';
import { GenerateIncomeComponent } from './components/pages/generate-income/generate-income.component';
import { TractionGrowthComponent } from './components/pages/traction-growth/traction-growth.component';
import { LoggedInAuthGuard } from './guards/loggedIn-auth.guard';
import { ConfirmPasswordComponent } from './components/pages/confirm-password/confirm-password.component';
import { ProjectSuccessComponent } from './components/pages/project-success/project-success.component';
import { NewPostComponent } from './components/pages/new-post/new-post.component';
import { ProfileComponent } from './components/pages/profile/profile.component';
import { ProfileDetailComponent } from './components/pages/profile/detail/detail.component';
import { ProfileProjectComponent } from './components/pages/profile/project/project.component';
import { ScheduleProjectComponent } from './components/pages/profile/schedule/schedule.component';
import { AppointmentDetailComponent } from './components/pages/profile/appointment/appointment.component';
import { FeedComponent } from './components/pages/feed/feed.component';
import { SupportModuleComponent } from './components/pages/support-module/support-module.component';
import { SearchPanelComponent } from './components/pages/search-panel/search-panel.component';
import { ProfileTopicsComponent } from './components/pages/profile/topics/topics.component';
import { PublicationDetailComponent } from './components/pages/publication-detail/publication-detail.component';

import { ContactsComponent } from './components/pages/contacts/contacts.component';
import { ChatComponent } from './components/pages/contacts/chat/chat.component';
import { NetworkComponent } from './components/pages/network/network.component';
import { UserProfileComponent } from './components/pages/user-profile/user-profile.component';
import { HomeappComponent } from './components/components/homeapp/homeapp.component';
import { LandingComponent } from './components/pages/landing/landing.component';
import { RetoOaxacaComponent } from './components/pages/retoOaxaca/retoOaxaca.component';
import { KidsZacatecasComponent } from './components/pages/kidsZacatecas/kidsZacatecas.component';
import { HackatonComponent } from './components/pages/hackaton/hackaton.component';
import { RetoChiapasComponent } from './components/pages/reto-chiapas/reto-chiapas.component';
import { CreateAccountWomanComponent } from './components/pages/create-account-woman/create-account-woman.component';
import { PrivacyAdviceWomanComponent } from './components/pages/privacy-advice-woman/privacy-advice-woman.component';
import { RetoZacatecasComponent } from './components/pages/reto-zacatecas/reto-zacatecas.component';
import { RetoChiapasEmprendedoresComponent } from './components/pages/reto-chiapas-emprendedores/reto-chiapas-emprendedores.component';
import { CreateAccountWomenEntrepreneurComponent } from './components/pages/create-account-women-entrepreneur/create-account-women-entrepreneur.component';
import { CongratulationWomenEntrepreneurComponent } from './components/pages/congratulation-women-entrepreneur/congratulation-women-entrepreneur.component';

const routes: Routes = [
  { path: 'preguntas-frecuentes', component: FrequentQuestionsComponen },
  { path: 'aviso-privacidad', component: PrivacyAdviceComponent },
  { path: 'aviso-privacidad-mujer-posible', component: PrivacyAdviceWomanComponent },
  { path: 'terminos', component: TermsComponent },
  { path: 'preguntas', component: FAQComponent },
  { path: 'recuperar-contrasena', component: ForgotPasswordComponent },
  { path: 'confirmar-contrasena', component: ConfirmPasswordComponent },
  { path: 'crear-cuenta', component: CreateAcountComponent, canActivate: [LoggedInAuthGuard] },
  { path: 'login', component: LoginComponent, canActivate: [LoggedInAuthGuard] },
  { path: 'tv', component: LandingComponent, canActivate: [LoggedInAuthGuard]},
  { path: 'retoposibleoax',pathMatch: 'full', component: LandingComponent, canActivate: [LoggedInAuthGuard], redirectTo: ''},
  { path: 'retoposiblechiapas', component: RetoChiapasComponent, canActivate: [LoggedInAuthGuard],  redirectTo: '', pathMatch: 'full'},
  { path: 'retoposiblezacatecas', component: RetoZacatecasComponent, canActivate: [LoggedInAuthGuard]},
  { path: 'chiapasemprendedores', component: RetoChiapasEmprendedoresComponent, canActivate: [LoggedInAuthGuard]},
  
  { path: 'hackatonIA', component: HackatonComponent, canActivate: [LoggedInAuthGuard]},
  { path: 'posiblekids', component: KidsZacatecasComponent, canActivate: [LoggedInAuthGuard]},
  { path: 'mujerposible',  canActivate: [LoggedInAuthGuard], redirectTo: '/mujeremprendedora', pathMatch: "full"},//component: CreateAccountWomanComponent,
  { path: 'mujeremprendedora', component: CreateAccountWomenEntrepreneurComponent, canActivate: [LoggedInAuthGuard]},
  { path: 'felicidadesmujeremprendedora', component: CongratulationWomenEntrepreneurComponent, canActivate: [LoggedInAuthGuard]},
  { path: 'hackatonia', component: HackatonComponent, canActivate: [LoggedInAuthGuard]},
  { path: 'app', component: HomeappComponent, canActivate: [AuthGuard] , children: [
    { path: '', redirectTo:"feed", pathMatch: "full" },
    { path: 'feed', component: FeedComponent },
    { path: 'dejanos-conocerte', component: CreateProjectComponent },// 'crear-proyecto'
    { path: 'diagnostico', component: CreateProject2Component },// 'crear-proyecto2'
    { path: 'nuevo-proyecto', component: NewProjectComponent },
    { path: 'nuevo-proyecto/:id', component: NewProjectComponent },
    { path: 'describe-problema/:id', component: DescribeProblemComponent },
    { path: 'idea-negocio/:id', component: ExplainIdeaComponent },
    { path: 'describe-clientes/:id', component: DescribeClientsComponent },
    { path: 'propuesta-valor/:id', component: ValueProposalComponent },
    { path: 'publicidad-distribucion/:id', component: DefineAdvertisingComponent },
    { path: 'generar-ingreso/:id', component: GenerateIncomeComponent },
    { path: 'traccion-crecimiento/:id', component: TractionGrowthComponent },
    { path: 'proyecto-creado/:id', component: ProjectSuccessComponent },
    { path: 'nueva-publicacion', component: NewPostComponent },
    { path: 'perfil', component: ProfileComponent },
    { path: 'perfil/detalles', component: ProfileDetailComponent },
    { path: 'perfil/intereses', component: ProfileTopicsComponent },
    { path: 'perfil/proyecto/:ID', component: ProfileProjectComponent },
    { path: 'perfil/agendar/:ID', component: ScheduleProjectComponent },
    { path: 'perfil/cita/:ID', component: AppointmentDetailComponent },
    { path: 'perfil/:id', component: UserProfileComponent },
    { path: 'contactos', component: ContactsComponent },
    { path: 'chat/:id', component: ChatComponent},
    { path: 'inicio', component: HomeComponent },
    { path: 'red', component: NetworkComponent },
    { path: 'busqueda', component: SearchPanelComponent },
    { path: 'publicacion/:id', component: PublicationDetailComponent },
    { path: 'material-apoyo/:moduleId', component: SupportModuleComponent },
    { path: 'compartir-publicacion/:id', component: PublicationDetailComponent },
    { path: 'notificaciones', component: NotificationsComponent },
  ]},

  { path: '', pathMatch: 'full', component: LandingComponent, canActivate: [LoggedInAuthGuard], redirectTo: '' },
  { path: '**', pathMatch: 'full', redirectTo: 'landing', canActivate: [LoggedInAuthGuard] },
  { path: 'estados/:edo', pathMatch: 'full', component: LandingComponent, canActivate: [LoggedInAuthGuard] }
];


@NgModule({
  imports: [RouterModule.forRoot(routes , {scrollPositionRestoration: 'enabled'} )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
