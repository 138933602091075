<div class="row img-row" *ngIf="publication?.url">
  <div class="col">
    <div class="animated fadeIn">
      <iframe width="100%" height="216px" [src]="urlVideo" frameborder="0"></iframe>
    </div>
  </div>
</div>

<div class="row img-row" *ngIf="publication.files.length">
  <div class="col">         
    <div class="animated fadeIn" *ngIf="publication.files.length == 1; else carousel">
      <div *ngIf="publication.files[0].type === 'image'; else videoItem">
        <img [src]="publication.files[0].image" width="100%" height="auto" class="img-size">
      </div>
      <ng-template #videoItem  >
        <video width="100%" height="216px" controls>
          <source [src]="publication.files[0].video" type="video/mp4">
        </video>
      </ng-template>
    </div>


    <ng-template #carousel>
      <ng-image-slider [images]="publication.files" [infinite]="true"
        [imageSize]="{width: '100%', height: '216px', space: 0}" [showArrow]="false" #nav>
      </ng-image-slider>
    </ng-template>
    <div *ngIf="publication.files.length > 1">
      <span class="slider__btns previous-btn cursor__pointer d-flex align-items-center justify-content-center"
        (click)="prevImageClick()">
        <i class="fa fa-chevron-left text-white pr-2"></i>
      </span>
      <span class="slider__btns next-btn cursor__pointer d-flex align-items-center justify-content-center"
        (click)="nextImageClick()">
        <i class="fa fa-chevron-right text-white pl-2"></i>
      </span>
    </div>
  </div>
</div>
