import { NavigationSteps } from 'src/app/classes/navigationSteps';
import { ProjectService } from './../../../services/project.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SnackbarService } from '../../../services/notifications/snackbar.service';
import { BusinessModel } from '../../../models/businessModel/businessModel';
import { ProjectCompletedDialogComponent } from '../../shared/dialogs/project-completed-dialog/project-completed-dialog/project-completed-dialog.component';
import { environment } from 'src/environments/environment';
import * as firebase from 'firebase/app';
import { FirebaseService } from 'src/app/services/firebase-service/firebase.service';
import { adsParams } from 'src/app/classes/ads-params';

@Component({
  selector: 'app-new-project',
  templateUrl: './new-project.component.html',
  styleUrls: ['./new-project.component.scss']
})
export class NewProjectComponent implements OnInit {
  // Property to identify the form
  forma: FormGroup;
  // Categories list from db
  listCategories: any;
  // Property to identify if the form has been already submitted
  submitted = false;
  // Property to identify the current project
  currentProject: BusinessModel;
  // Property to identify the project id based on a url param
  projectId: string;
  //show info
  show: boolean = false;
  // Property to identify the textarea count word
  minWordsCompleted = false;
  // property to manage the dispayed steps of nabvar
  navigationSteps = NavigationSteps;
  // Controls if users comes from facebook ad about start MDN
  isFacebookMdnEvent: boolean = false;
  // Params ads campaings
  adsParams = adsParams;
  showOthers:boolean = false;
  constructor(
    private snackbarService: SnackbarService,
    private fb: FormBuilder,
    private router: Router,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private firebase: FirebaseService,
  ) {
    this.forma = this.fb.group({
      nombre: ['', [Validators.required, Validators.maxLength(200), Validators.minLength(2)]],
      descripcion: ['', Validators.maxLength(600)],
      checkCategories: this.fb.array([]),
      customCategories: [''],
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: Params) => {
      if (params.utm_campaign) {
        // Validates if Ad FB params are expected for this event
        if (params['utm_source'] == this.adsParams.startProject.utm_source &&
            params['utm_medium'] == this.adsParams.startProject.utm_medium &&
            params['utm_campaign'] == this.adsParams.startProject.utm_campaign &&
            params['utm_content'] == this.adsParams.startProject.utm_content) {
              this.isFacebookMdnEvent = true;
        }
      }
    });

    this.route.params.subscribe((params: any) => {
      if (params.id) {
        this.projectId = params.id;
        this.setProjectValues();
      } else {
        this.getCategories();
      }
    });
  }

  // Method to set the current form fields data from  api's database according to the current model_guid
  setProjectValues(): void {
    this.projectService.getProjectStageData(this.projectId)
      .then(data => {
        this.currentProject = new BusinessModel(data.data);
        this.formFields.nombre.setValue(data.data.name);
        this.formFields.descripcion.setValue(data.data.description);
        this.formFields.customCategories.setValue(data.data.customCategories);
        
        const checkArray: FormArray = this.forma.get('checkCategories') as FormArray;
        data.data.categories.forEach(element => {
          if(element.description === 'Otro'){
            this.showOthers = true;
          }
          checkArray.push(new FormControl(element.category_guid));
        });
        this.getCategories();
      })
      .catch(error => {
        this.router.navigate(['app/inicio']);
        this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error. ⚠️');
      }
      );
  }

  // Method to add/remove value of a checkbox into the checkCategories formcontrol value
  onCheckboxChange(e, isLast): void {  
    const checkArray: FormArray = this.forma.get('checkCategories') as FormArray;

    if (e.target.checked) {
      if(e.target.name == 'Otro' || isLast){
        this.showOthers = true;
        this.formFields.customCategories.setValidators([Validators.required]);
      }
      checkArray.push(new FormControl(e.target.value));
    } else {
      let i = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {
          if(e.target.name == 'Otro' || isLast){
            this.showOthers = false;
            this.formFields.customCategories.setValue(null);
            this.formFields.customCategories.setValidators([]);
          }
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }


  /* Method to get the options to be displayed as checked
  * @param guid - generated guid from api
  */
  checkedGenerator(guid) {    
    return this.formFields.checkCategories.value.includes(guid);
  }
  /**
   * Validator method to disable all the other checkbox when there is 2 already checked.
   * @param categoryGuid - category guid to verify if is inside the array of checked values
   */
  validatorCheckbox(categoryGuid: string): boolean {
    const control = this.forma.get('checkCategories').value;
    return control.length === 2 && !control.includes(categoryGuid);
  }

  // Convenience getter for easy access to form fields
  get formFields() {
    return this.forma.controls;
  }

  // Method to get the project categories list
  getCategories(): void {
    this.projectService.getCategories()
      .then(data => {
        this.listCategories = data.data;
      })
      .catch(error => {
        this.router.navigate(['app/inicio']);
        this.snackbarService.showSnackBar('Cerrar', `${error} ⚠️`);
      }
      );
  }
  /**
   * Method to return the lenght of a textfield
   * @param str - string to look for the chars
   * Return - number of chars
   */
  countChars(str): number {
    if (str) {
      const chars = str.length;
      return chars;
    } else {
      return 0;
    }
  }
  /**
   * Method to valide if the num of words entered are the minimum
   * @param str - string to look for the words
   */
  wordCountHandler(str: string): void {
    const words = str.length;
    if (words >= 30) {
      this.minWordsCompleted = true;
    } else {
      this.minWordsCompleted = false;
    }
  }

  // Method to send data to store new project info
  generateProject(): void {
    if (!this.projectId) {
      this.currentProject = new BusinessModel(
        {
          name: this.formFields.nombre.value !== null ? this.formFields.nombre.value.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '') : null,
          description: this.formFields.descripcion.value !== null ? this.formFields.descripcion.value.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '') : null,
          customCategories: this.formFields.customCategories.value !== null ? this.formFields.customCategories.value.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '') : null,
        }
      );
      this.currentProject.categories = this.formFields.checkCategories.value;
    } else {
      this.currentProject.name =  this.formFields.nombre.value !== null ? this.formFields.nombre.value.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '') : null;
      this.currentProject.description = this.formFields.descripcion.value !== null ? this.formFields.descripcion.value.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '') : null;
      this.currentProject.categories = this.formFields.checkCategories.value;
      this.currentProject.customCategories = this.formFields.customCategories.value !== null ? this.formFields.customCategories.value.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '') : null;
    }
  }

  // Method to send data to store page info
  save(): void {
    this.submitted = true;
    
    if (this.forma.invalid) {
      this.snackbarService.showSnackBar('Cerrar', 'Verifique que los datos estén completos. ⚠️');
      window.scroll(0,0);
      return;
    }
    this.generateProject();
    const project = this.currentProject;
    
    if (this.projectId) {
      this.projectService.updateProject(
        project,
        this.projectId
      ).subscribe(data => {
          this.snackbarService.showSnackBar('Cerrar', 'Datos guardados! 🎉');
          this.router.navigate(['app/describe-problema', this.projectId]);
      }, (err) => { this.snackbarService.showSnackBar('Cerrar', `${err} ⚠️`); });
    } else {
      this.projectService.newProject(
        project
      ).subscribe(data => {
        this.snackbarService.showSnackBar('Cerrar', 'Nuevo proyecto creado! 🎉');
        // Track event when user comes from Facebook MDN Ad
        if (this.isFacebookMdnEvent) {
            
        }
        this.firebase.logEvent('ProjectNameCompleted');
        this.router.navigate(['app/describe-problema', data['data'].businessModel_guid]);
      }, (err) => {
        this.snackbarService.showSnackBar('Cerrar', `${err} ⚠️`);
      });
    }
  }
}
