// Class that creates an instance with the Diagnostic data
export class Diagnostic{

    diagnostic_guid: string;
    name: string;
    statusPosible = [];
    diagnosticFinished = false;

    constructor(data){
        this.diagnostic_guid = data.diagnostic_guid;
        this.name = data.name;
        this.statusPosible = data.statusPosible;
        this.diagnosticFinished = data.statusPosible && data.statusPosible.length>0 ? true : false;
    }

}