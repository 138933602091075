import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModelProgress } from 'src/app/models/modelProgress/modelProgress';
import { SnackbarService } from '../../../services/notifications/snackbar.service';

@Component({
  selector: 'app-stepsbar-progress',
  templateUrl: './stepsbar-progress.component.html',
  styleUrls: ['./stepsbar-progress.component.scss']
})
export class StepsbarProgressComponent implements OnInit {
  // steps list received from the parent component
  stepsList = [];
  // total number of steps received from the parent component
  @Input() totalNumSteps;
  // Current step received from the parent component
  @Input() currentStep;
  // In case this is a project
  projectId;
  // Indicates if steps are saved in order to show a style
  @Input() modelProgress: ModelProgress[] = [];
  // Steps available to move through
  @Input() stepsNav = []; 
  // If previous step is not completed then force user to fill it first before the current step
  @Output() fillPreviousQuestionFirst = new EventEmitter<number>();

  constructor(private route: ActivatedRoute,
    private router: Router,
    private snackbarService: SnackbarService) {
    this.route.params.subscribe( params => {
      this.projectId = params['id'];
    })
  }

  ngOnInit(): void { }
  
  // Check the step is already complete, this will indicates if a style should be shown
  isCompleted(stepNumber: number): boolean{
    if(!this.modelProgress) return false;
    const step = this.modelProgress.find( item => item.module_number == stepNumber);
    return step ? step.is_completed : false;
  }

  // Listen for changes on variable model progress
  ngOnChanges(): void {
    let steps = Object.values(this.stepsNav);
    let lastAvailable;
    if(this.modelProgress){
      steps = steps.splice(1,this.totalNumSteps);      
      lastAvailable = this.modelProgress.filter( page => {
        return page.is_completed == null
      })[0];
    }

    if(!this.projectId && lastAvailable && !this.isCompleted(this.currentStep) && lastAvailable.module_number != this.currentStep){
      this.fillPreviousQuestionFirst.emit(lastAvailable['url']);
      this.snackbarService.showSnackBar("Cerrar","Es necesario responder esta pregunta primero");
    }
    this.stepsList = steps.sort((a, b) => a.id - b.id);
  }

  /**
   * Redirects to the next page, but id checks if it's necessary to send a param
   * @param url - Url to redirect 
   * @param step - Step user pretends to enter
   */
  navigateTo(url: string, step: number): void {
    let nextStep = this.modelProgress.find( page => page.module_number == step);
    let lastAvailable = this.modelProgress.filter( page => page.is_completed == null)[0];
    if( !this.projectId && !nextStep.is_completed && step != lastAvailable.module_number){ 
      return; 
    }

    if (this.projectId) {
      this.router.navigate([`app/${url}`, this.projectId]);
    } else {
      this.router.navigate([`app/${url}`]);
    }
  }

}
