<div class="row person py-3 align-items-center">
  <div class="col-2">
    <img *ngIf="person.image else noImg" class="picture" height="42px" width="42px" [src]="person.image" alt="" [routerLink]="['/app','perfil', person.guid]">
    <ng-template #noImg>
      <img class="picture" height="42px" width="42px" src="assets/img/home/profile_img.png" alt="" [routerLink]="['/app','perfil', person.guid]">
    </ng-template>
    <span class="{{person.activityStatus}}"></span>
  </div>
  <div class="col-7">
    <p class="name">{{person.name}}</p>
    <p class="position">{{person.company}}</p>
  </div>
  <div class="col-3 d-flex justify-content-center">
    <button class="btn actions-btn text-center mt-2 mb-2" [disabled]="isLoading" (click)="friendAction(person)"
      [ngClass]="{'add-friend': person.status === friendShipStatus.NotFriends, 'remove-friend': person.status === friendShipStatus.Friends, 'request-sent': person.status === friendShipStatus.RequestSent}">
      <img src="../../../../assets/icons/reloj.png" class="clock-img" alt=""
        *ngIf="person.status === friendShipStatus.RequestSent">
      <i class="fa status-icon" *ngIf="person.status !== friendShipStatus.RequestSent"
        [ngClass]="{'fa-plus': person.status === friendShipStatus.NotFriends, 'fa-check': person.status === friendShipStatus.Friends}"></i>
      <i class="fa fa-user user-icon" *ngIf="!isLoading"></i>
      <i class="fa fa-spin fa-spinner" *ngIf="isLoading"></i>
    </button>

  </div>
</div>
