import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { microsites } from 'src/app/classes/microsites';
@Component({
  selector: 'app-reto-chiapas',
  templateUrl: './reto-chiapas.component.html',
  styleUrls: ['./reto-chiapas.component.scss']
})
export class RetoChiapasComponent implements OnInit {

  // Edo microsite info
  currentMicroSiteStateInfo = {}
  microsites = microsites;

  constructor(private _vps: ViewportScroller, private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.currentMicroSiteStateInfo = this.microsites['no-edo']

    // Get microsite param
    this.route.params.subscribe((params: any) => {
      this.currentMicroSiteStateInfo = this.microsites['retoChiapas']
    });
  }

  scrollFn(anchor: string): void{
  	this._vps.scrollToAnchor(anchor)
  }

}
