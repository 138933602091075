<div class="row friend-request py-3 align-items-center">
  <div class="col-2">
    <img *ngIf="request.image else noImg" class="picture" [src]="request.image" alt="">
    <ng-template #noImg>
      <img class="picture" src="assets/img/home/profile_img.png" alt="">
    </ng-template>
    <span class="{{request.activityStatus}}"></span>
  </div>
  <div class="col-10">
    <p class="name">{{ request.firstName }} {{request.lastName}}</p>
    <div class="btn-container d-flex justify-content-between">
      <div class="d-flex">
        <button class="btn action-btn mr-2 order-2" (click)="declineFriend()" [disabled]="isLoading">
          <i class="fa fa-times small-icon reject"></i>
          <i class="fa fa-user big-icon reject pr-1"></i>
          Rechazar
        </button>
        <button class="btn action-btn order-1" (click)="acceptFriend()" [disabled]="isLoading">
          <i class="fa fa-check small-icon approve"></i>
          <i class="fa fa-user big-icon approve pr-1"></i>
          Aceptar
        </button>
      </div>
      <i class="fa fa-chevron-right cursor__pointer" [routerLink]="['/app/perfil', request.fromUserid]"></i>
    </div>
  </div>
</div>
