<div class="container p-0">
  <div class="header">
    <a class="linkColor" [routerLink]="['/app/crear-proyecto6']">
      <i class="fa fa-angle-left mr-2" aria-hidden="true"></i>
    </a>
    <span class="txt_title">Diagnóstico</span>
  </div>
  <app-stepsbar-progress [totalNumSteps]="totalNumSteps" [currentStep]="stepNumber"
      [stepsNav]="diagnosticSteps" [modelProgress]="modelProgress" (fillPreviousQuestionFirst)="redirectToEmptyQuestion($event)"></app-stepsbar-progress>
  <div class="main-container animated fadeIn fast marginTop">
    <div class="card text-center border-0 animated fadeIn fast">
      <div class="card-body animated fadeIn text-left">
        <div class="mt-2 pb-5">
          <form autocomplete="off" [formGroup]="formb" (ngSubmit)="save()">
            <div class="form-group">
              <label for="exampleFormControlTextarea2" class="text-left txt_common">¿Sobre qué temas te gustaría
                platicar con nosotros? <br><small> <i>Selecciona hasta 2 categorías</i> </small> </label>
            </div>
            <div class="my-3 form-check" *ngFor="let option of options; let i=index">
              <input class="form-check-input" type="checkbox" [checked]="checkedGenerator(option.category_guid)"
                (change)="onCheckboxChange($event)" [disabled]="validatorCheckbox(option.category_guid)"
                [value]="option.category_guid" [id]="option.category_guid">
              <label class="form-check-label" [for]="option.category_guid">
                <span class="txt_common_bold"> {{ option.description }} </span>
              </label>
              <div class="text-left txt_advice">
                <p> {{ option.detail }} </p>
              </div>
            </div>
            <div *ngIf="submitted && formFields.checkCategories.errors" class="text-center errors pt-1 pb-3">
              <small *ngIf="formFields.checkCategories.errors.required" class=" text-danger animated fadeIn">
                Debe seleccionar mínimo una categoria.
              </small>
            </div>
            <br>
            <button type="submit" class="btn-lg btn btn-block btn-warning btn_style mb-5">
              <span class="btn_style_text">Siguiente</span>
            </button>
          </form>
        </div>

      </div>
      <!--card body-->

    </div> <!-- card main-->

  </div>
</div>
