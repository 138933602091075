export class Friend {
    guid: string;
    company: string;
    image: string;
    name: string;
    status = 3;
    activityStatus: string;

    constructor(data: any) {
        this.guid = data.contact;
        this.company = data.company;
        this.image = data.image_path;
        this.name = data.completed_name;
        this.activityStatus = data.status || 'Offline';
    }
}
