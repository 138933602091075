
<div *ngIf="isLoading; else elseLoading" class="d-flex justify-content-center mt-5">
  <mat-spinner diameter="60"></mat-spinner>
</div>

<ng-template  #elseLoading>

  <div class="main-content animated fadeIn" >
    <div class="container title">
      <div class="row">
        <div  class="col-auto align-self-center" >
          <i *ngIf="!fromDiagnostic" class="fa fa-chevron-left header__icons--size header__icons" (click)="back()"></i>
        </div>
        <div class="col-auto p-0">
          <span class="text">Tus intereses</span>
        </div>
      </div>
    </div>
    <div class="container py-2 topic-list">
      <div class="row topic" *ngFor="let topic of topics; index as i" [ngClass]="{'checked': topic.checked}" (click)="select(i)">
        <div class="col-10 name">
          {{ topic.description }}
        </div>
        <div class="col-2 align-self-center check-icon">
          <span class="checkbox"></span>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-12 p-0 mt-2 mb-5">
          <button type="button" class="btn btn-save btn-block" (click)="save()">Guardar</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
