import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChatService } from 'src/app/services/chat/chat.service';
import { AuthService } from '../../../services/auth.service';
import { FirebaseService } from 'src/app/services/firebase-service/firebase.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {

  // loading spinner
  isLoading = true;

  searchText: string;

  contacts = [];

  constructor(private router: Router,
              private authenticationService: AuthService,
              private chatService: ChatService,
              private firebase: FirebaseService
            ) { }

  ngOnInit(): void {
    this.getContacts();
    this.firebase.logEvent('pageContactMessages');
  }

  /* Method to open a chat by id
  * @param id - string chat id
  */
  openChat(id:string): void{
    this.router.navigate(['app/chat', id]);
  }

  /* Method to get the current user contacts
  *
  */
  getContacts(): void{
    this.authenticationService.contacts()
     .subscribe(resp => {
      let currentUser = this.authenticationService.getDecodeTokenInfo();

       Object.values(resp).forEach(element => {
         if(element.roomObject.viewed == false){
          if (element.roomObject.last_user_guid == currentUser.user_id ){
            element.roomObject.viewed  = true;
          }

         }

         if(element.roomObject.last_message_type)
         {
            if (element.roomObject.last_message_type.includes("image"))
            {
              element.roomObject.last_message = 'Imagen'
            }
            else if (element.roomObject.last_message_type.includes("video"))
            {
              element.roomObject.last_message = 'Video'
            }
         }

         this.contacts.push(element);
         this.chatService.onEvent("messageNotification")
          .subscribe(( msg)=> {

            let userindex = this.contacts.findIndex( c=> c.contact === msg.from_user)
            if(userindex>=0){
               this.contacts[userindex].roomObject.viewed  = false;
               this.contacts[userindex].roomObject.last_message  = msg.message;
               this.contacts.unshift(this.contacts.splice(userindex, 1)[0]);
            }
          });

       });
       this.isLoading = false;
    }, (error) => console.log(error));
  }

}
