import { Component, OnInit } from '@angular/core';
import { SearchTypes } from 'src/app/models/search-types/search-types.enum';

@Component({
  selector: 'app-search-panel',
  templateUrl: './search-panel.component.html',
  styleUrls: ['./search-panel.component.scss']
})
export class SearchPanelComponent implements OnInit {
  // types of search
  searchTypes = SearchTypes;

  // filter type
  type = this.searchTypes.Person;

  // list of results
  results = [];

  isLoading = false;

  constructor() { }

  ngOnInit(): void {
  }

  // Method to change the filter type
  changeType(type: SearchTypes): SearchTypes {
    return this.type = type;
  }

  /// Method to assign the results received from a child search component
  showResults(results: []) {
    this.results = results;
  }

}
