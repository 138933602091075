<div
  *ngIf="isLoading; else elseLoading"
  class="d-flex justify-content-center mt-5"
>
  <mat-spinner diameter="60"></mat-spinner>
</div>
<ng-template #elseLoading>
  <div class="container contain-data">
    <div class="row header">
      <div class="col-12 d-flex flex-column align-items-center justify-content-center">
        <span class="title pt-3">{{ project.name }}</span>
        <span class="folio text-center" *ngIf="project.status != projectList.Nuevo">
          Folio: <strong>#{{ project.invoice }}</strong>
        </span>
        <div class="row">
          <div class="col-12">
            <button class="btn btn-lg btn btn-block btn-warning btn_continuar" style="height: 25px; line-height: 0px;" (click)="regresar()">Regresar</button>
          </div>
        </div>
      </div>
    </div>
    <form autocomplete="off" [formGroup]="formSchedule">
      <div class="row mt-5">
        <div class="col-4">
          <div class="mt-4 input-group input-group-lg">
            <div ngbDropdown class="d-inline-block">
              <button class="btn drop_color btn-lg btn btn-block btn-warning" [ngClass]="{'drop_color': !select1, 'drop_color_select':select1}" id="sortMenu" ngbDropdownToggle>{{fecha}}</button>
              <div class="dropdown-menu" aria-labelledby="sortMenu" style="height: auto; max-height:200px; overflow-y: scroll;" ngbDropdownMenu>
                <button class="dropdown-item" *ngFor="let dia of dias" (click)="getTimes(dia)" >{{dia.dia}} {{dia.description}}</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="mt-4 input-group input-group-lg">
            <div ngbDropdown class="d-inline-block">
              <button class="btn btn-lg btn btn-block btn-warning" [ngClass]="{'drop_color': !select2, 'drop_color_select':select2}" id="sortMenu" ngbDropdownToggle>{{hora}}</button>
              <div class="dropdown-menu" aria-labelledby="sortMenu" style="height: auto; max-height:200px; overflow-y: scroll;" ngbDropdownMenu>
                <button class="dropdown-item" *ngFor="let tiempo of tiempos; let i = index" (click)="selectTime(tiempo, i)" >{{tiempo}} - {{tiemposFinal[i] | date:'H:mm:SS'}}</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="mt-4 ">
            <button (click)="agendar()" class="btn-lg btn btn-block btn-warning btn_continuar">Agendar</button>
          </div>
        </div>
      </div>
    </form>
    <div class="row button-place" *ngIf="project.status === projectList['Nuevo']">
      <button class="btn btn-block btn-default" #btnDeleteProject (click)="deleteProject()">
        <img src="../../../../assets/img/icons/eliminar.svg" class="mr-2"/>
        <div class="text">Eliminar proyecto</div>
      </button>
    </div>
  </div>
</ng-template>
