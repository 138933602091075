<div class="container p-0">
  <div class="header">
    <a class="linkColor" [routerLink]="['/app/dejanos-conocerte']">
      <i class="fa fa-angle-left mr-2" aria-hidden="true"></i>
    </a>
    <span class="txt_title">Diagnóstico</span>
  </div>
  <app-stepsbar-progress [totalNumSteps]="totalNumSteps" [currentStep]="stepNumber"
  [stepsNav]="diagnosticSteps" [modelProgress]="modelProgress" (fillPreviousQuestionFirst)="redirectToEmptyQuestion($event)"></app-stepsbar-progress>
  <div class="main-container animated fadeIn fast marginTop">
    <div class="card text-center border-0 animated fadeIn fast">
      <div class="card-body animated fadeIn text-left pb-5">
        <div class="mt-2 pb-5">
          <form autocomplete="off" [formGroup]="formb" (ngSubmit)="save()">
            <div class="form-group">
              <label for="exampleFormControlTextarea2" class="text-left txt_common my-1">
                Tu idea de negocio es:
              </label>

              <div class="my-5 form-check" *ngFor="let option of options; let i=index">
                <input class="form-check-input" type="radio" name="idea" [value]="option.businessIdea_guid" [(ngModel)]="businessIdea"
                  [id]="option.businessIdea_guid" [class.is-invalid]="noValid" formControlName="idea">
                <label class="form-check-label" [for]="option.businessIdea_guid">
                  <span class="txt_common_bold"> {{ option.description }}</span>
                </label>
              </div>
            </div>
            <div class="text-center" *ngIf="noValid">
              <small class="text-danger animated fadeIn">Elige una opción</small>
            </div>
            <br>
            <button type="submit" class="btn-lg btn btn-block btn-warning btn_style mb-5">
              <span class="btn_style_text">Siguiente</span>
            </button>
          </form>
        </div>
      </div>
      <!--card body-->
    </div> <!-- card main-->
  </div>
</div>
