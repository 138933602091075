import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
@Component({
  selector: 'app-pending-business-models',
  templateUrl: './pending-business-models.component.html',
  styleUrls: ['./pending-business-models.component.scss']
})
export class PendingBusinessModelsComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  goToProjects(){
    this.router.navigate(['/app/perfil']);
  }

}
