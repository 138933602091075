<div class="container p-0">
  <app-dynamic-navigation [projectId]="projectId" [actualStep]="navigationSteps.seventhStep"></app-dynamic-navigation>
  <app-stepsbar-progress [totalNumSteps]="totalNumSteps" [currentStep]="stepNumber" [modelProgress]="modelProgress" [stepsNav]="projectSteps"></app-stepsbar-progress>
  <div class="main-container animated fadeIn fast marginTop">
    <div class="card border-0 animated fadeIn fast cardColor">
      <div class="card-body animated fadeIn">
        <div class="mt-3">
          <form autocomplete="off" [formGroup]="forma" (ngSubmit)="update()">
            <label class="subtitle text-left">1. ¿Cómo generarás ingresos a partir de tu producto o 
              servicio? (Seleccionar máximo 2)</label>
            <div class="mt-3 mb-3 form-check" *ngFor="let type of incomeTypes">
              <input class="form-check-input" [checked]="checkedGenerator(type.incomeType_guid)"
                [disabled]="validatorCheckbox(type.incomeType_guid)" type="checkbox" [value]="type.incomeType_guid"
                (change)="onCheckboxChange($event, 'checkArray')" [id]="type.incomeType_guid">
              <label class="form-check-label" [for]="type.incomeType_guid">
                <span class=" txt_common_bold">{{type.description}}</span>
              </label>
            </div>
            <ng-container *ngIf="showOtherIncome" class="animated fadeIn">
              <textarea formControlName="otherIncomes" (keyup)="wordCountHandler(formFields.otherIncomes.value)"
                class="form-control input-rounded" rows="4" aria-label="Large"
                placeholder="Respuesta"></textarea>
                <div class="txt_advice text-right mt-2 mb-4 d-flex align-items-center">
                  <small *ngIf="submitted && formFields.otherIncomes.errors && formFields.otherIncomes.errors.required" class="text-danger animated fadeIn">
                    Este campo es requerido.
                  </small>
                  <div class="ml-auto">
                    <div *ngIf="countChars(formFields.otherIncomes.value) == 0"> Máx 30 caracteres</div>
                    <div *ngIf="countChars(formFields.otherIncomes.value) >= 1 && countChars(formFields.otherIncomes.value) < 30">
                      {{30 - countChars(formFields.otherIncomes.value) }} caracteres faltantes.</div>
                    <div *ngIf="countChars(formFields.otherIncomes.value) == 30"> Máximo de caracteres alcanzado.</div>
                  </div>
                </div>
            </ng-container>
            <hr>
            <div class="form-group">
              <label class="subtitle text-left">2. ¿Tienes definido el precio en el que venderás tu 
                producto o servicio?</label>
              <small *ngIf="precioDefinidoNoValido" class="text-danger animated fadeIn">
                Dato requerido
              </small>
              <br>
              <div class="form-check mt-3">
                <input class="form-check-input" type="radio" name="precioDefinido" [value]="false" id="fem"
                  formControlName="precioDefinido" (change)="onPriceDescChange($event)">
                <label class="form-check-label" for="fem">
                  <b> No</b>
                </label>
              </div>
              <div class="form-check mt-3 mb-3">
                <input class="form-check-input" type="radio" name="precioDefinido" [value]="true" id="mas"
                  formControlName="precioDefinido" (change)="onPriceDescChange($event)">
                <label class="form-check-label" for="mas">
                  <b> Sí ¿Cuál es? (Máx 600 caracteres)</b>

                </label>
              </div>
              <div *ngIf="visiblePdescriptionCost" class="animated fadeIn fast">
                <textarea class="form-control input-rounded" id="exampleFormControlTextarea2"
                  formControlName="descriptionCost" rows="4" aria-label="Large" maxlength="600"
                  placeholder="Indica sólo el precio del producto/servicio que te genera mayores ingresos."></textarea>
                <div class="txt_advice text-right mt-2">
                  <div class="d-flex align-items-center justify-content-between">                    
                    <small  
                      *ngIf="submitted && formFields.descriptionCost.errors && formFields.descriptionCost.errors.required" 
                      class="text-danger animated fadeIn">
                        Este campo es requerido.
                    </small>
                    <p class="mb-0 ml-auto" *ngIf="countChars(formFields.descriptionCost.value) == 0"> Máx 600 caracteres</p>
                  </div>
                  <p class="mt-2"
                    *ngIf="countChars(formFields.descriptionCost.value) >= 1 && countChars(formFields.descriptionCost.value) < 600">
                    {{600 - countChars(formFields.descriptionCost.value) }} caracteres faltantes.</p>
                  <p class="mt-2 text-danger" *ngIf="countChars(formFields.descriptionCost.value) == 600"> Máximo de
                    caracteres alcanzado.</p>
                </div>
              </div>
            </div>
            <hr>
            <label class="text-left subtitle">3. ¿Has recibido apoyo económico de alguna fuente?</label>
            <div class="my-3 form-check" *ngFor="let supports of SupportTypes; let i=index">
              <input class="form-check-input" type="checkbox" [checked]="checkedGenerator2(supports.supportType_guid)"
                (change)="onCheckboxChange($event, 'supportTypes')" [id]="supports.supportType_guid"
                [value]="supports.supportType_guid">
              <label class="form-check-label" [for]="supports.supportType_guid">
                <span class=" txt_common_bold">{{supports.description}}</span>
              </label>
            </div>
            <div *ngIf="visibleSupportDescr" class="animated fadeIn">
              <textarea class="form-control input-rounded" id="exampleFormControlTextarea2" rows="4"
                formControlName="otherSupport" aria-label="Large" maxlength="30" placeholder="Respuesta"></textarea>
              <div class="txt_advice text-right mt-2 mb-4 d-flex align-items-center">
                <small *ngIf="submitted && formFields.otherSupport.errors && formFields.otherSupport.errors.required" class="text-danger animated fadeIn">
                  Este campo es requerido.
                </small>
                <div class="ml-auto">
                  <div *ngIf="countChars(formFields.otherSupport.value) == 0"> Máx 30 caracteres</div>
                  <div *ngIf="countChars(formFields.otherSupport.value) >= 1 && countChars(formFields.otherSupport.value) < 30">
                    {{30 - countChars(formFields.otherSupport.value) }} caracteres faltantes.</div>
                  <div *ngIf="countChars(formFields.otherSupport.value) == 30"> Máximo de caracteres alcanzado.</div>
                </div>
              </div>
            </div>
            <button type="submit" class="btn-lg btn btn-block btn-warning btn_style">
              <span class="btn_style_text">Guardar y continuar</span>
            </button>
            <br><br>
          </form>
        </div>

      </div>
      <!--card body-->

    </div> <!-- card main-->

  </div>
</div>


