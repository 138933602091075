<app-pending-businessmodels-modal></app-pending-businessmodels-modal>
<div class="container">
  <div class="row">
    <div class="col-4">
      <p class="section-title cursor__pointer title--active" [ngClass]="{'title--active': section === 'personas'}"
        (click)="changeSection('personas')">
        PERSONAS
      </p>
      <hr class="hr-title" [ngClass]="{'hr--active': section === 'personas'}">
    </div>
    <div class="col-4">
      <p class="section-title cursor__pointer" [ngClass]="{'title--active': section === 'solicitudes'}"
        (click)="changeSection('solicitudes')">
        SOLICITUDES
      </p>
      <hr class="hr-title" [ngClass]="{'hr--active': section === 'solicitudes'}">
    </div>
    <div class="col-4">
      <p class="section-title cursor__pointer">
      </p>
      <hr class="hr-title">
    </div>
  </div>
</div>

<div class="container remove-margin-top" *ngIf="section === 'personas' && !isLoading">
  <app-person *ngFor="let friend of friends" [person]="friend"></app-person>
  <div class="row mt-3" *ngIf="!friends?.length">
    <div class="col">
      <span>Aun no has agregado a nadie a tu lista de contactos.</span>
    </div>
  </div>
</div>

<div class="container remove-margin-top" *ngIf="section === 'solicitudes' && !isLoading">
  <app-friend-request *ngFor="let request of requests;  index as idx" [request]="request" [index]="idx"
    (requestUpdated)='removeRequest($event)'>
  </app-friend-request>
  <div class="row mt-3" *ngIf="!requests.length">
    <div class="col">
      <span>No se encontraron solicitudes de amistad pendientes.</span>
    </div>
  </div>
</div>

<div *ngIf="isLoading" class="d-flex justify-content-center mt-5">
  <mat-spinner diameter="60"></mat-spinner>
</div>
