export class UserSearch {
    guid: string;
    company: string;
    image: string;
    name: string;
    status: number;
    activityStatus: number;

    constructor(data: any) {
        this.guid = data.user_guid;
        this.company = data.company;
        this.image = data.image_path;
        this.name = data.name;
        this.status = data.status;
        this.activityStatus = data.activityStatus || 'Offline';
    }
}
