import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../../classes/global';

@Injectable({
  providedIn: 'root'
})
export class DiagnosticService {
  globals = Globals;
  constructor(private http: HttpClient) { }

  /*
  * Method to create a new diagnostic in the app
  * @param project - project
  */
  async newDiagnostic(project){
    const url = `${this.globals.urlPosibleBase}${this.globals.diagnostic.diagnostic}`;
    return await this.http.post(url, project).toPromise();
  }

  async updateDiagnostic(project){
    const url = `${this.globals.urlPosibleBase}${this.globals.diagnostic.diagnostic}`;
    return await this.http.post(url, project).toPromise();
  }

  // get all business-Ideas
  async getBusinessIdea(): Promise<any> {
    const url = `${this.globals.urlPosibleBase}${this.globals.diagnostic.businessIdea}`;
    return await this.http.get(url).toPromise();
  }

  // get all diagnostic Categories
  async getDiagnosticCategories(): Promise<any> {
    const url = `${this.globals.urlPosibleBase}${this.globals.diagnostic.diagnosticCategories}`;
    return await this.http.get(url).toPromise();
  }

  // get all diagnostic status
  async getStatusPosible(): Promise<any> {
    const url = `${this.globals.urlPosibleBase}${this.globals.diagnostic.statusPosible}`;
    return await this.http.get(url).toPromise();
  }

  // get all diagnostic initial stages 
  async getStages(): Promise<any> {
    const url = `${this.globals.urlPosibleBase}${this.globals.diagnostic.stages}`;
    return await this.http.get(url).toPromise();
  }

  // get all diagnostic initial invested times
  async getTimesInvested(): Promise<any> {
    const url = `${this.globals.urlPosibleBase}${this.globals.diagnostic.timesInvested}`;
    return await this.http.get(url).toPromise();
  }
  
  // Gets diagnostic data
  async getDiagnosticDetail(): Promise<any>{
    const url = `${this.globals.urlPosibleBase}${this.globals.diagnostic.timesInvested}`;
    return await this.http.get(url).toPromise();
  }

}
