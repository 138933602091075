import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class LoggedInAuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private authenticationService: AuthService
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const diagnosticUser = this.authenticationService.diagnosticValue;
        if (diagnosticUser) {
            this.redirectByDiagnostic(diagnosticUser.diagnosticFinished);
            // not authenticated so return false
            return false;
        }

        return true;
    }

    /**
     * Method to identify the route to redirect if the user tries to enter a route only allowed for unauthenticated users
     * @param diagnosticFinished - actual status of the diagnostic of this user
     */
    redirectByDiagnostic = (diagnosticFinished: boolean) => diagnosticFinished ? this.router.navigate(['/app/inicio']) : this.router.navigate(['/app/dejanos-conocerte']);

}
