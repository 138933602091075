
// Class to extend propierties
export abstract class SupportMaterialBaseClass {
    public id: string;
    public title: string;
    public url: string;
    public isVideo: boolean = false;
    constructor(id: any, title: string, url: string, isVideo: boolean = false) {
        this.id = id;
        this.title = title;
        this.url = url;
        this.isVideo = isVideo;
    }
}
