import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import * as firebase from 'firebase/app';
import { FirebaseService } from 'src/app/services/firebase-service/firebase.service';

@Component({
  selector: 'app-frequent-questions',
  templateUrl: './frequent-questions.component.html',
  styleUrls: ['./frequent-questions.component.scss']
})
export class FrequentQuestionsComponen implements OnInit {

  constructor(private location: Location,
    private firebase: FirebaseService) { }

  ngOnInit(): void {
    this.firebase.logEvent('frequent-questions');
  }

  // Go to previous page
  goBack(): void {
    this.location.back();
  }

}
