import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor() { }

  /**
   * Sends a text to firebase
   * @param message Description text
   */
  logEvent(message: string, options? :object){
    (environment.production === true) && firebase.analytics().logEvent(message, options);
  }
}
