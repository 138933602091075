<div class="container p-0">
  <app-dynamic-navigation [projectId]="projectId" [actualStep]="projectSteps.secondStep"></app-dynamic-navigation>
  <app-stepsbar-progress [totalNumSteps]="totalNumSteps" [currentStep]="stepNumber" [modelProgress]="modelProgress" [stepsNav]="projectSteps"></app-stepsbar-progress>
  <div class="main-container animated fadeIn fast  marginTop">
    <div class="card border-0 animated fadeIn fast bgcolor-gray">
      <div class="card-body animated fadeIn">

        <div class="mt-2">
          <form autocomplete="off" [formGroup]="forma" (ngSubmit)="update()">
            <div class="form-group">
              <label for="necesidad" class="subtitle text-left">¿Cuál es la necesidad o problema que resolverás con tu idea de negocio? (Por ejemplo, qué les molesta a las personas, que no tienen hoy disponible, les sale muy caro, es poco accesible o sin acceso, que desean o quieren tener, que hoy no tienen o no solucionan con lo que hay). (600 caracteres)</label>
              <textarea class="form-control input-rounded" id="necesidad" rows="4" aria-label="Large"
                formControlName="necesidad" placeholder="Respuesta" maxlength="600"></textarea>
              <div class="text-right txt_advice">
                <p class="mt-2" *ngIf="countChars(formFields.necesidad.value) == 0"> Máx 600 caracteres</p>
                <p class="mt-2"
                  *ngIf="countChars(formFields.necesidad.value) >= 1 && countChars(formFields.necesidad.value) < 600">
                  {{600 - countChars(formFields.necesidad.value) }} caracteres faltantes.</p>
                <p class="mt-2" *ngIf="countChars(formFields.necesidad.value) == 600"> Máximo de caracteres
                  alcanzado.</p>
              </div>
            </div>
            <hr>
            <div class="form-group">
              <label for="comunidad" class="subtitle text-left">¿Tu comunidad, tu ecosistema ambiental o la sociedad en general recibirá algún beneficio no económico de tu idea de negocio? (Por ejemplo, vas a emplear personas en situación vulnerable, tu producto cuida el medio ambiente, ayudará a tu comunidad a solucionar algún problema social, etc). (600 caracteres)</label>
              <textarea class="form-control input-rounded" id="comunidad" rows="4" aria-label="Large"
                formControlName="comunidad" placeholder="Respuesta" maxlength="600"></textarea>
              <div class="text-right txt_advice">
                <p class="mt-2" *ngIf="countChars(formFields.comunidad.value) == 0"> Máx 600 caracteres</p>
                <p class="mt-2"
                  *ngIf="countChars(formFields.comunidad.value) >= 1 && countChars(formFields.comunidad.value) < 600">
                  {{600 - countChars(formFields.comunidad.value) }} caracteres faltantes.</p>
                <p class="mt-2 text-danger" *ngIf="countChars(formFields.comunidad.value) == 600"> Máximo de caracteres
                  alcanzado.</p>
              </div>
            </div>
            <hr>
            <br>
            <button type="submit" class=" mb-5 btn-lg btn btn-block btn-warning btn_style">
              <span class="btn_style_text">Guardar y continuar</span>
            </button>
          </form>
        </div>

      </div>
      <!--card body-->

    </div> <!-- card main-->

  </div>
  <!--main container-->
  <div class="fixed-bottom">

  </div>


</div>
