<div class="row">
  <div class="col-12">
    <div class="header">
      <span class="circle"><img src="assets/img/spinner.gif"></span>
      <a class="" mat-button mat-dialog-close>
        <i class="fa fa-times"></i>
      </a>
    </div>
    <div class="content">
      <h3>¡Completa tu modelo de negocios!</h3>
      <p>Al completarlo <b>le das estructura a tu proyecto, participas en nuestra convocatoria 2022</b> y puedes ser candidato para estar en nuestro <b>campamento nacional.</b>
      <a class="btn-complete-model" mat-dialog-close (click)="goToProjects()">¡Complétalo ahora!</a>
    </div>
  </div>
</div>
