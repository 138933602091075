<div class="container p-0">
  <div class="header">
    <a class="linkColor" [routerLink]="['/app/diagnostico']">
      <i class="fa fa-angle-left mr-2" aria-hidden="true"></i>
    </a>
    <span class="txt_title">Diagnóstico</span>
  </div>
  <app-stepsbar-progress [totalNumSteps]="totalNumSteps" [currentStep]="stepNumber"
   [stepsNav]="diagnosticSteps" [modelProgress]="modelProgress" (fillPreviousQuestionFirst)="redirectToEmptyQuestion($event)"></app-stepsbar-progress>
  <div class="main-container animated fadeIn fast marginTop">
    <div class="card text-center border-0 animated fadeIn fast">
      <div class="card-body animated fadeIn text-left">
        <div class="mt-2 pb-5">
          <form autocomplete="off" [formGroup]="formb" (ngSubmit)="save()">
            <div class="form-group">
              <label for="exampleFormControlTextarea2" class="text-left txt_common">¿En qué etapa del
                desarrollo de tu idea de negocio te encuentras? </label>
            </div>
            <div class="my-4 form-check" *ngFor="let option of options; let i=index" >
              <input  class="form-check-input"
                      type="radio"
                      name="stagefb"
                      [value]="option.stage_guid"
                      [id]="option.stage_guid"
                      [class.is-invalid]="noValid"
                      formControlName="stagefb"
                      >
              <label class="form-check-label" [for]="option.stage_guid">
                <span class="txt_common_bold"> {{ option.description }} </span>
              </label>
              <div class="text-left txt_advice">
                <p> {{ option.detail }}</p>
              </div>
            </div>
            <div class="text-left" *ngIf="noValid">
              <small class="text-danger animated fadeIn">Elige una opción</small>
            </div>
            <button type="submit" class="my-5 btn-lg btn btn-block btn-warning btn_style">
              <span class="btn_style_text">Siguiente</span>
            </button>
          </form>
        </div>

      </div>
      <!--card body-->

    </div> <!-- card main-->

  </div>
</div>
