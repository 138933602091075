import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, ChildActivationEnd } from '@angular/router';
import { ChatService } from '../../../../services/chat/chat.service';
import { AuthService } from '../../../../services/auth.service';
import { SnackbarService } from 'src/app/services/notifications/snackbar.service';
import { HttpEventType } from '@angular/common/http';
import { LoadingFilesComponent } from './loading-files/loading-files.component';
import { UploadingFile } from 'src/app/classes/fileList/uploading-file';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ContactService } from 'src/app/services/contact/contact.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalMediafileComponent } from './modal-mediafile/modal-mediafile.component';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {

  @ViewChild('messagesContent') private myScrollContainer: ElementRef;
  @ViewChild('loadingFiles') private loadingFiles: LoadingFilesComponent;
  @ViewChild('closeModal') closeModal:ElementRef;
  // Array of all the messages
  messages = [];
  // New message Text
  newMessage: string;
  // Type of the new message default text
  typeNewMessage = 'text';
  // Id of the contact row
  id:string;
  // Id of the chatroom
  idRoom: string;
  // Id of the current user
  idUser: string;
  // Id of the other user on the chatroom
  idContact: string;
  // Last day message
  lastDay:number;
  contact:any;
  currentPage = 1;
  cancelEvent = false;

  isloadingMessages = true;

  subscribed:Subscription = new Subscription();
  // Indicates the initial configuration of modals
  configModal: NgbModalOptions = {windowClass: 'modal-holder file-management', centered: true, backdrop : 'static'};

  constructor(private router: Router,
              private route: ActivatedRoute,
              private chatService: ChatService,
              private authenticationService: AuthService,
              private snackbarService: SnackbarService,
              private contactService: ContactService,
              private modalService: NgbModal
            ) { }

  // Initializes the Chat component
  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');

    //Check if the room exists
    this.authenticationService.getChatroom(this.id).then( response => {

      this.idRoom = response.data.chat_room_guid;
      this.contactService.ViewMessages(this.idRoom)
        .then(()=> {

        });

      this.getContact();
    }).catch(error => {
      // Redirect to contacts
      this.router.navigate(['app/contactos']);
    });

  }

  /* Method to get the contact row information
  *
  */
  getContact(): void{
    this.authenticationService.getContact(this.id)
     .subscribe(resp => {
       this.idUser = resp['user'];
       this.idContact = resp['contact'];
       this.initChat();
       this.getHistory();
       this.getContactUser();
    }, (error) => this.snackbarService.showSnackBar('Cerrar', error));
  }

  /* Method to init the chat WebSocket service and hear for new messages
  *
  */
  initChat(): void{

    if(this.chatService.JoinRoom(this.idRoom)){
      this.subscribed.add(
        this.chatService.onNewMessage()
          .subscribe(msg => {
            let message = JSON.parse(msg);
            message.date = message.date ?? Date.now();
            if(message.toUser == this.idUser){
              this.messages.push(message);
            }
            this.scrollToBottom();
          })
      );
  }
}

  /* Method to load all the chatroom history
  *
  */
  getHistory(): void{
    let user = this.authenticationService.getDecodeTokenInfo();
    this.chatService.getHistory(this.idRoom, this.currentPage, user.user_id)
      .subscribe(resp => {
        let prevArray = this.messages;
        let newArray = [];
        Object.values(resp['result']).forEach(element => {
          let date = new Date(element['date']);
          if(this.lastDay != date.getDate()){
            this.lastDay = date.getDate();
            newArray.push({ division: true, date: date });
          }
          newArray.push(element);
        });
        this.messages = newArray;
        prevArray.forEach(element => {
          this.messages.push(element);
        });
        let _self = this;

        this.chatService.raiseMessageSeen();

        this.isloadingMessages = false;
        // Wait 1sec to scroll to bottom
        if(this.cancelEvent){
          setTimeout(function(){
            _self.cancelEvent = false;
          }, 1000);
        }else{
          setTimeout(function(){
            _self.scrollToBottom();
          }, 500);
        }
     }, (error) => this.snackbarService.showSnackBar('Cerrar', error));
  }

  /* Method to get the other user information
  *
  */
  getContactUser(): void{
    this.chatService.getUserInfo(this.idContact)
     .subscribe(resp => {
       this.contact = resp;
    }, (error) => this.snackbarService.showSnackBar('Cerrar', error));
  }

  /* Method to add a message to the conversation
  *
  */
  addMessage(): void{
    this.messages.push({fromUser: this.idUser, message: this.newMessage, type: this.typeNewMessage, toUser: this.idContact, date: Date.now()});

    this.chatService.sendMessage(
      this.newMessage,
      this.idRoom,
      this.idUser,
      this.idContact,
      this.typeNewMessage
    );

    this.newMessage = '';
    this.typeNewMessage = 'text';
    this.scrollToBottom();
  }

  /* Method to get load and send the new picture to the chat
  * @param files - FileList from image input
  */
  handleFileInput(files: FileList): void {
    let image = files.item(0);
    let formData:FormData = new FormData();

    formData.append('file', image, image.name);

    // Add file to the upload list
    let fileAdded: UploadingFile = this.loadingFiles.addNewElement(image.type);

    // Send image to the Service
    this.chatService.uploadFile(this.idRoom, formData)
     .subscribe( event => {

      // Upload Progress
      if(event.type == HttpEventType.UploadProgress){
        // Calculate porcentage
        const progress = event.loaded / event.total * 100;
        fileAdded.setPorcentage(progress);
      }

      // Response completed
      if(event.type == HttpEventType.Response){
        const data = event.body['data'];
        this.newMessage = data['file'];
        this.typeNewMessage = image['type'];
        this.addMessage();
      }

    }, (error) => this.snackbarService.showSnackBar('Ocurrió un error', error)
    ).add(() => this.loadingFiles.removeElement(fileAdded));
  }

  /* Method to scroll the app to bottom
  *
  */
  scrollToBottom(): void {
    window.scroll(0, this.myScrollContainer.nativeElement.scrollHeight + 1500);
  }

  /* Method to verify if the scroll is on the top to load the next page of messages
  *
  */
  onScroll(): void{
    if(!this.cancelEvent){
      if(window.pageYOffset <= 90){
        this.cancelEvent = true;
        this.currentPage ++;
        this.getHistory();
      }
    }
  }

  // Hide modal, reference close button
  hideModal(): void{
    this.closeModal.nativeElement.click();
  }
  
  // Open modal and waits for a new file to be uploaded
  openModal():void{
    const modalRef = this.modalService.open(ModalMediafileComponent, this.configModal);
    modalRef.componentInstance.fileToUpload.subscribe( files => {
      this.handleFileInput(files);
      modalRef.componentInstance.closeModal();
    })
  }

  ngOnDestroy(){
    this.subscribed.unsubscribe();
    this.chatService.leaveRoom(this.idRoom);
  }

}
