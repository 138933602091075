// Class to implement attributes of a Topic
export class Topic {
    
    topic_guid: string;
    description: string;
    checked: boolean;

    constructor(data: any) {                 
        this.topic_guid =  data.category ? data.category: data.topic_guid;
        this.description = data.description;
        this.checked = false;
    }

}
