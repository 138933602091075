import { SupportMaterialBaseClass } from './SupportMaterialBaseClass';

// Class to implement all content of support material of videos
export class SupportVideo extends SupportMaterialBaseClass {
    video: any;
    typeSrc: number; // this is only while the api is ready, then it will be change to class according to the backend says
    constructor(data: any) {
        super(data.id,data.title,data.video);
        this.video = data.video;
        this.typeSrc = data.typeSrc;
    }
}