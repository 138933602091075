<div class="container bgcolor-gray py-5">
  <div class="main-container animated fadeIn fast">
    <div class="card text-center border-0 animated fadeIn fast">
      <app-project-completed></app-project-completed>
      <div class="py-5 text-center px-5 bgcolor-gray">
        <button
          type="button"
          class="btn-lg btn btn-block btn-warning btn_continuar"
          (click)="goToLastStep()"
        >
          <span class="btn_continuar_text">Continuar</span>
        </button>
      </div>
    </div>
    <!-- card main-->
  </div>
</div>
