import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-congratulations-dialog',
  templateUrl: './congratulations-dialog.component.html',
  styleUrls: ['./congratulations-dialog.component.scss']
})
export class CongratulationsDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<CongratulationsDialogComponent>, private route: Router, 
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    
  }

  // close the dialog
  close() {
    this.dialogRef.close();
  }

  // Method to navigate to the profile page
  navigateToProfile(): void {
    if(this.data.fromProfile == true){
      window.location.reload();
    }else{
      this.close();
      this.route.navigate(['app','perfil']);
    }
  }

  completeProfile(): void {
    this.close();
    this.route.navigate(['app/dejanos-conocerte']);
  }

}
