import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ChatService } from 'src/app/services/chat/chat.service';
import { FriendRequest } from 'src/app/models/friend-request/friend-request';
import { ContactService } from 'src/app/services/contact/contact.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  // identify if the user is already logged in
  isLoggedIn = false;

  haveFriendRequest :boolean = false;

  havePublicationNotifications :boolean = false;

  haveMessageNotifications :boolean = false;

  subscriptions : Subscription[] = []

  constructor(
    private router: Router,
    private authenticationService: AuthService,
    private chatService: ChatService,
    private contactService: ContactService,
  ) { }

  ngOnInit(): void {
    this.isLoggedIn = this.authenticationService.currentUserValue;

    this.getFriendRequests();

    this.getMessageNotifications();

    this.getPublicationNotifications();

    if(this.chatService.initSocket2()){
      this.chatService.onEvent('disconnect')
        .subscribe(msg => {
           console.log('socket disconnected');
        });
      this.chatService.onEvent('reconnect')
        .subscribe(msg => {
           console.log('socket reconnected');
           this.getMessageNotifications();

           this.getPublicationNotifications();
        });

        this.chatService.onEvent('connect')
        .subscribe(msg => {
           console.log('soccket connected');

           this.getMessageNotifications();

           this.getPublicationNotifications();
        });


      this.chatService.onEvent('addcontact')
        .subscribe(msg => {
          this.haveFriendRequest = true;
        });
      this.chatService.onEvent('cancelFriendRequest')
        .subscribe(msg => {
          this.getFriendRequests();
        });

        this.chatService.onEvent('actionpublication')
        .subscribe(msg => {
          this.havePublicationNotifications = msg.haveNotifications;
        });

        this.chatService.onEvent('deleteactionpublication')
        .subscribe(msg => {
          this.havePublicationNotifications = msg.haveNotifications;
        });

        this.chatService.onEvent('messageNotification')
        .subscribe(msg => {
          this.haveMessageNotifications = true;
        });
    }
    else
    {

    }

    this.contactService.friendRequestChanged
    .subscribe(friendRequestsList => {
      this.getFriendRequests();
    });

    this.chatService.messageSeen
    .subscribe(friendRequestsList => {
      this.getMessageNotifications();
    });

    this.chatService.publicationsSeen
      .subscribe(()=>{
        this.havePublicationNotifications = false;
      });




  }

  addStickyStyle(): string {
    let classListSticky = '';
    if (this.router.url === '/nuevo-proyecto'
      || this.router.url === '/idea-negocio'
      || this.router.url === '/describe-clientes'
      || this.router.url === '/propuesta-valor'
      || this.router.url === '/publicidad-distribucion'
      || this.router.url === '/generar-ingreso'
      || this.router.url === '/traccion-crecimiento'
    ) {
      classListSticky = 'footer_sticky';
    }
    return classListSticky;

  }

    // Method to get the list of friend requests
    getFriendRequests(): void {
      this.contactService.friendRequests().then(response => {
        let friendRequests = response['data'].map(request => new FriendRequest(request));

        this.haveFriendRequest = (friendRequests && friendRequests.length>0);
      })
      .catch(error => {
      });

    }


    // Method to get the list of friend requests
    getMessageNotifications(): void {
      let currentUser = this.authenticationService.getDecodeTokenInfo();
      this.chatService.getHaveNotifications(currentUser.user_id, 'message')
      .subscribe((response: any)=>{
        this.haveMessageNotifications = response.data || false ;
      });
    }

     /* Method to get the list of publications
     */
     getPublicationNotifications(): void {
      let currentUser = this.authenticationService.getDecodeTokenInfo();
      this.chatService.getHaveNotifications(currentUser.user_id, 'Publication')
      .subscribe((response: any)=>{
        this.havePublicationNotifications = response.data || false ;
      });
    }

    sendWhatsapp(){
      window.open('https://api.whatsapp.com/send/?phone=525549130130&text=Hola%2C+tengo+una+duda+📮', '_system')
    }
}
