import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from 'src/app/classes/global';
import { Publication } from 'src/app/models/publication/publication';

@Injectable({
  providedIn: 'root'
})
export class PublicationService {

  // variable that store the import of Globals Class that will help us to retrieve the urls for every service
  globals = Globals;

  // base url to use in this service
  baseUrl = `${this.globals.urlPosibleBase}${this.globals.publications.pathPublications}`;

  constructor(private http: HttpClient) { }

  /**
   * Method to create a new post in the app
   * @param description - text
   */
  async newPost(description: string): Promise<object> {
    return await this.http.post(this.baseUrl, { description, categories: [] }).toPromise();
  }

  /**
   * Method to create a comment
   * @param description - description of the comment
   * @param publicationGuid - publication that the user comments
   */
  async createComment(description: string, publicationGuid: string): Promise<object> {
    const url = `${this.baseUrl}${publicationGuid}${this.globals.publications.comments}`;
    return await this.http.post(url, { description }).toPromise();
  }

  // Method to get all the publications
  async getAll(page: string): Promise<object> {
    const url = `${this.baseUrl}${this.globals.publications.home}`;
    const params = new HttpParams().set('page', page);
    return await this.http.get<Publication[]>(url, { params }).toPromise();
  }

  // Method to get the details of a publication
  async getDetails(id: string): Promise<object> {
    const url = `${this.baseUrl}${id}/`;
    return await this.http.get<Publication>(url).toPromise();
  }

  /**
   * Method to get all the publications for the posible feed based on a type guid
   * @param type - publication type guid
   * @param page - page number
   */
  async getFeedPosible(type: string, page: string): Promise<object> {
    const url = `${this.baseUrl}${this.globals.publications.feedPosible}`;
    const params = new HttpParams().set('type', type).set('page', page);

    return await this.http.get<Publication[]>(url, { params }).toPromise();
  }

  // Method to get all the publication types
  async getTypes(): Promise<object> {
    const url = `${this.globals.urlPosibleBase}${this.globals.types.pathTypes}`;
    return await this.http.get(url).toPromise();
  }

  /**
   * Search publications that contains a specific hashtag
   * @param search - hashtag to search for
   */
  async searchHashtag(search: string): Promise<object> {
    const url = `${this.baseUrl}${this.globals.publications.search}`;
    return await this.http.post(url, { search }).toPromise();
  }

  /**
   * Method to like/dislike a publication
   * @param guid - publication guid
   */
  async like(guid: string): Promise<object> {
    const url = `${this.baseUrl}${guid}${this.globals.publications.likes}`;
    return await this.http.post(url, {}).toPromise();
  }

  /**
   * Method to share publication
   * @param guid - publication guid
   * @param text - publication text
   */
  async share(text = '', publicationGuid: string): Promise<object> {
    const url = `${this.baseUrl}${publicationGuid}${this.globals.publications.share}`;
    return await this.http.post(url, { description: text }).toPromise();
  }


  /**
   * Method that upload a new file with an specific publication
   * @param publication
   * @param file
   */
  uploadMedia(publication_id: string, file){
    return this.http.post(`${this.globals.urlPosibleBase}${this.globals.publications.pathPublications}${publication_id}/fileUpload/`,file,
     {
      reportProgress: true,
      observe: 'events'
     });
  }

}
