<div class="container p-0">
  <app-dynamic-navigation [projectId]="projectId" [actualStep]="projectSteps.fourthStep"></app-dynamic-navigation>
  <app-stepsbar-progress [totalNumSteps]="totalNumSteps" [currentStep]="stepNumber" [modelProgress]="modelProgress" [stepsNav]="projectSteps"></app-stepsbar-progress>
  <div class="main-container animated fadeIn fast marginTop">
    <div class="card border-0 animated fadeIn fast" style="background-color: #F7F7F7;">
      <div class="card-body animated fadeIn">
        <div class="mt-2">
          <form autocomplete="off" [formGroup]="forma" (ngSubmit)="update()">
            <div class="form-group">
              <label for="" class="subtitle text-left">1. ¿A qué tipo de cliente te diriges?</label>
              <div class="my-4 form-check" *ngFor="let ClientType of ClientTypes; let i=index">
                <input class="form-check-input" type="radio" name="cliente" [id]="ClientType.clientType_guid"
                  [value]="ClientType.clientType_guid" formControlName="cliente"
                  (change)="onClientChange()">
                <label class="form-check-label" [for]="ClientType.clientType_guid">
                  <b> {{ ClientType.description }}</b>
                </label>
              </div>
            </div>
            <!--form group-->
            <div style="text-align: center;" *ngIf="clienteNoValido ">
              <small class="text-danger animated fadeIn">
                ¡¡ Se debe elegir una opción !!
              </small>
            </div>
            <div *ngIf="visibleClientOpt" class="animated fadeIn ">
              <hr>
              <div class="form-group">
                <label for="" class="subtitle text-left">2. Género</label>
                <br>
                <div class="form-check" *ngFor="let gender of genders; let i=index">
                  <input class="form-check-input" type="radio" name="genero" [id]="gender.gender_guid"
                    [value]="gender.gender_guid" formControlName="genero">
                  <label class="form-check-label" [for]="gender.gender_guid">
                    <b> {{ gender.description }}</b>
                    <br><br>
                  </label>
                </div>
                <div class="text-center" *ngIf="GeneroNoValido">
                  <small class="text-danger animated fadeIn">
                    ¡¡ Se debe elegir una opción !!
                  </small>
                </div>
              </div>
              <!--form-group-->
            </div>

            <div *ngIf="visibleCompanyOpt" class="animated fadeIn ">
              
              <label for="empresa" class="subtitle text-left">2. Estratificación principal de la empresa</label>
              <div class="input-group input-group-lg">
                <!--api/acquisitionLevels/-->
                <select class="form-control" style="border-radius: 7px;" class="form-control input-rounded"
                  aria-label="Large" placeholder="Elegir" name="acquisitionsLevel" formControlName="acquisitionsLevel">
                  <option value="" selected >Elegir</option>
                  <option *ngFor="let AcquisitionLevel of AcquisitionLevels"
                    [value]="AcquisitionLevel.acquisitionsLevel_guid"> {{ AcquisitionLevel.description }} </option>
                </select>
              </div>
            </div>
            <div *ngIf="visibleAgeOpt" class="animated fadeIn ">
              <hr>
              <label for="" class="subtitle text-left">
                <span *ngIf="visibleCompanyOpt || visibleClientOpt else noSelections">3.</span>
                <ng-template #noSelections>2.</ng-template>
                Segmento de edad (Seleccionar máximo 2)
              </label>
              <div class="my-3 form-check" *ngFor="let ageRange of ageRanges; let i=index">
                <input class="form-check-input" type="checkbox" [checked]="checkedGenerator(ageRange.ageRange_guid)"
                  [id]="ageRange.ageRange_guid" [value]="ageRange.ageRange_guid" (change)="onCheckboxChange($event)"
                  [disabled]="validatorAgesCheckboxes(ageRange.ageRange_guid)">
                <label class="form-check-label" [for]="ageRange.ageRange_guid ">
                  <span class=" txt_common_bold"> {{ ageRange.description }}</span>
                </label>
              </div>
            </div>

            <div *ngIf="submitted && formFields.checkArray.errors" class="text-center errors pt-1 pb-3">
              <small *ngIf="formFields.checkArray.errors.required" class="text-danger animated fadeIn">
                Debe seleccionar mínimo una opción.
              </small>
            </div>

            <hr>
            <div class="form-group">
              <label for="cars-cliente" class="subtitle text-left"> 
                <span *ngIf="visibleClientOpt === visibleCompanyOpt">2.</span>               
                <span *ngIf="!visibleClientOpt && visibleCompanyOpt">3.</span>
                <span *ngIf="visibleClientOpt && !visibleCompanyOpt">4.</span>
                Delimita las características de tu cliente ideal. (600 caracteres)
              </label>
              <textarea class="form-control input-rounded" id="cars-cliente" rows="4" aria-label="Large"
                formControlName="caracteristicas" maxlength="600" placeholder="Respuesta"></textarea>
              <div class="txt_advice text-right">
                <p class="mt-2" *ngIf="countChars(formFields.caracteristicas.value) == 0"> Máx 600 caracteres</p>
                <p class="mt-2"
                  *ngIf="countChars(formFields.caracteristicas.value) >= 1 && countChars(formFields.caracteristicas.value) < 600">
                  {{600 - countChars(formFields.caracteristicas.value) }} caracteres faltantes.</p>
                <p class="mt-2 text-danger" *ngIf="countChars(formFields.caracteristicas.value) == 600"> Máximo de
                  caracteres alcanzado.</p>
              </div>
            </div>
            <div class="my-5">
              <button type="submit" class="btn-lg btn btn-block btn-warning btn_style">
                <span class="btn_style_text">Guardar y continuar</span>
              </button>
            </div>

          </form>
          <br>
        </div>
      </div>
      <!--card body-->

    </div> <!-- card main-->
  </div>
</div>
<!--main-container-->


