import { Component, OnInit, ViewChild, Input, ViewChildren } from '@angular/core';
import { NgImageSliderComponent } from 'ng-image-slider';
import { PublicationDetail } from 'src/app/models/publication-detail/publication-detail';
import { DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.scss']
})
export class ImageSliderComponent implements OnInit {

  @Input() publication: PublicationDetail;
  @ViewChild('nav') slider: NgImageSliderComponent;
  urlVideo: any;

  constructor( private sanitize: DomSanitizer) { }

  ngOnInit(): void {
    if(this.publication?.url) {
      this.urlVideo = this.sanitize.bypassSecurityTrustResourceUrl(this.publication?.url);
    }
  }

  // slide to the previous img/video
  prevImageClick(): void {
    this.slider.prev();
  }

  // slide to the next img/video
  nextImageClick(): void {
    this.slider.next();
  }

}
