import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from 'src/app/classes/global';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  globals = Globals;
  baseUrl = `${this.globals.urlPosibleBase}${this.globals.users.pathUsers}`;

  constructor(private http: HttpClient) { }

  /**
   * Search for a user
   * @param search - user to search for
   */
  async search(search: string): Promise<object> {
    const url = `${this.baseUrl}${this.globals.users.search}`;
    return await this.http.post(url, { search }).toPromise();
  }

  /**
   * Search for a company
   * @param search - company to search for
   */
  async searchCompanies(search: string): Promise<object> {
    const url = `${this.baseUrl}${this.globals.users.searchCompany}`;
    return await this.http.post(url, { search }).toPromise();
  }

  // Get friends list
  async getFriends(): Promise<any> {
    const url = `${this.baseUrl}${this.globals.users.contacts}`;
    return await this.http.get(url).toPromise();
  }

  /**
   * Get the details of a user
   * @param id - user id
   */
  async getUser(id: string): Promise<object> {
    const url = `${this.baseUrl}${id}/`;
    return await this.http.get(url).toPromise();
  }

  /**
   * Get the topics of interest of a user
   * @param id - user id
   */
  async getTopics(id: string): Promise<object> {
    const url = `${this.baseUrl}${id}${this.globals.users.topics}`;
    return await this.http.get(url).toPromise();
  }
  /**
   * save user survey
   * @param id - user id
   */
   async saveSurvey(survey: any): Promise<object> {
    const url = `${this.baseUrl}${this.globals.users.saveSurvey}`;
    return await this.http.post(url, survey).toPromise();
  }
}
