<div class="main-container row">
    <div class="container-header col-12 pl-md-5 pr-md-5">
        <div class="content-header">
            <div class="row">
                <div class="col-12 col-md-5 col-lg-4 mt-3 d-flex align-items-center">
                    <p class="logo-posible">POSiBLE</p>
                </div>
                <div class="col-12 col-md-7 col-lg-8 mt-4 menu-landing">
                    <ul class="d-lg-flex p-0">
                        <li>
                            <div class="dropdown">
                                <button class="dropdown-toggle p-0" type="button" (click)="show=!show" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Convocatoria
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" id="myLinks" [ngClass]="{'d-block': show}">
                                    <a class="dropdown-item" href="assets/Convocatoria_kids.pdf" target="_blank">Convocatoria Kids</a>
                                    <a class="dropdown-item" href="assets/convocatoria_facilitadores.pdf" target="_blank">Convocatoria Facilitadores</a>
                                </div>
                            </div>

                        </li>

                        <li on-click="scrollFn('pasos')">Pasos a seguir</li>
                        <li on-click="scrollFn('beneficios')">Beneficios</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 lines"></div>

    <div class="container-fluid container-convocatory">
        <div class="row">
            <div class="col-12 col-lg-6 col-info col-background">
                <div class="container d-flex align-items-center">
                    <h5 style="font-weight: bold;line-height: 15vh;letter-spacing: 5px;">POSiBLE Kids</h5>
                    <p style="font-size: 28px;">
                        Potencializa las habilidades de las niñas y niños a través del emprendimiento de alto impacto con base tecnologíca.
                    </p>

                    <div class="col-12 col-lg-12 col-xl-6 d-flex justify-content-center mt-5">
                        <a class="button-register" href="/crear-cuenta">Postula a tu hijo</a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6 d-flex align-items-center justify-content-center">
                <div class="row d-flex">
                    <div class="col-12 col-md-6">
                        <img src="../../../../assets/img/microsites/kids_zacatecas/3(1).png" alt="" style="width: 45vw;">
                    </div>

                </div>
            </div>
        </div>

    </div>
    <div class="container-fluid container-convocatory">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-7">
                    <div class="row">
                        <div class="col-md-12 d-flex justify-content-center">
                            <img src="../../../../assets/img/microsites/kids_zacatecas/POSIBLE_COOP.png" alt="" style="width: 85%;" class="img-fluid">
                        </div>
                        <div class="col-md-12 d-flex justify-content-center mt-4">
                            <div class="row">
                                <div class="col-12">
                                    <h1>ZACATECAS</h1>
                                    <a class="button-register" style="background-color: #c0e179; margin: 0 auto;" href="assets/Convocatoria_kids.pdf" target="_blank">Convocatoria</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-5 col-info pt-4 mt-lg-0 mt-5 mt-mb-0">

                    <h5>¿Qué es Reto POSiBLE kids zacatecas?</h5>
                    <p>
                        Es un programa de fomento al emprendimiento de alto impacto con desarrollo tecnológico que busca detonar en niños y niñas de 8 y 12 años el interés por atender problemas sociales de su entorno mediante soluciones innovadoras.
                    </p>
                    <h5>¿Quiénes pueden participar en POSiBLE Kids?</h5>
                    <p>
                        Niñas y niños entre los 8 y 12 años de edad residentes de las siguientes entidades del estado de Zacatecas.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid container-steps" id="pasos">
        <div class="container mt-5 mb-5">
            <div class="row">
                <div class="col-12 text-center mb-4">
                    <h1>Pasos a seguir</h1>
                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-4 " style="display: flex;">

                    <div class="card card-postulation w-100">
                        <div class="card-header pt-4">
                            <div class="div-img">
                                <img src="../../../../assets/img/microsites/kids_zacatecas/cv.png" alt="" class="img-fluid">
                            </div>
                        </div>
                        <div class="card-body d-block text-center">
                            <h5 class="card-title">Postulación de niños y niñas</h5>
                            <i class="fa fa-chevron-down chevron-card" aria-hidden="true"></i>
                            <p class="card-text pl-3 pr-3">Los padres de familia o tutores postulan a los menores a POSiBLE Kids Zacatecas 2022.
                                <br>
                            </p>
                        </div>
                    </div>
                    <div style="position: relative; margin-right: 5%;" class="d-none d-lg-block">
                        <i class="fa fa-arrow-right" aria-hidden="true" style="position: absolute; top: 50%; left: 50%; transform: translate(25%, -25%); font-size: 40px;"></i>
                    </div>

                </div>
                <div class="col-12 col-md-6 col-lg-4 mb-4 " style="display: flex;">

                    <div class="card card-selection w-100">
                        <div class="card-header pt-4">
                            <div class="div-img">
                                <img src="../../../../assets/img/microsites/kids_zacatecas/trabajo-en-equipo.png" alt="" class="img-fluid">
                            </div>
                        </div>
                        <div class="card-body d-block text-center">
                            <h5 class="card-title">Selección</h5>
                            <i class="fa fa-chevron-down chevron-card" aria-hidden="true"></i>
                            <p class="card-text pl-3 pr-3">Los seleccionados serán notificados por correo electrónico.
                                <br>
                            </p>
                        </div>
                    </div>
                    <div style="position: relative; margin-right: 5%;" class="d-none d-lg-block">
                        <i class="fa fa-arrow-right" aria-hidden="true" style="position: absolute; top: 50%; left: 50%; transform: translate(25%, -25%); font-size: 40px;"></i>
                    </div>

                </div>

                <div class="col-12 col-md-6 col-lg-4 mb-4 " style="display: flex;">

                    <div class="card card-camp w-100">
                        <div class="card-header pt-4">
                            <div class="div-img">
                                <img src="../../../../assets/img/microsites/kids_zacatecas/charla.png" alt="" class="img-fluid">
                            </div>
                        </div>
                        <div class="card-body d-block text-center">
                            <h5 class="card-title">campamento de verano híbrido</h5>
                            <i class="fa fa-chevron-down chevron-card" aria-hidden="true"></i>
                            <p class="card-text pl-3 pr-3">sesiones virtuales y presenciales.
                                <br>
                            </p>
                        </div>
                    </div>

                </div>
                <div class="col-12 col-md-6 col-lg-4 offset-lg-8 mb-4">
                    <div class="card card-presentation">
                        <div class="card-header pt-4">
                            <div class="div-img">
                                <img src="../../../../assets/img/microsites/kids_zacatecas/graduado.png" alt="" class="img-fluid">
                            </div>
                        </div>
                        <div class="card-body d-block">
                            <h5 class="card-title">presentación de proyectos (demo day)</h5>
                            <i class="fa fa-chevron-down chevron-card" aria-hidden="true"></i>
                            <p class="card-text pl-3 pr-3">Evento precencial donde los menores presentarán sus proyectos al ecosistema local. <br></p>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="container-fluid container-benefits" id="beneficios">
        <div class="row">

            <div class="col-12 col-md-12 col-lg-5 d-flex align-items-center p-0">
                <img src="../../../../assets/img/microsites/kids_zacatecas/kids-2.png" alt="" class="img-fluid" style="height: 100% !important;">
            </div>
            <div class="col-12 col-md-12 col-lg-7 col-benefits mb-3 mb-lg-0 pt-5 pb-5">
                <h3 class="text-center mb-4">¿Qué van aprender tus hijos en POSiBLE Kids?</h3>
                <div class="row align-items-center">
                    <div class="col-12 col-md-2 text-center d-md-flex align-items-center mb-3 div-img">
                        <img src="../../../../assets/img/microsites/kids_zacatecas/mujer-de-negocios.png" alt="" class="img-fluid">
                    </div>
                    <div class="col-12 col-md-10 d-flex align-items-center benefits" style="background-color: #4ecdc4;">
                        <p>Emprendimiento de alto impacto: Aprenderán a crear ideas que solucionen problemáticas reales de su comunidad.</p>
                    </div>
                </div>
                <div class="row mt-4 align-items-center">
                    <div class="col-12 col-md-2 text-center d-md-flex align-items-center mb-3 div-img">
                        <img src="../../../../assets/img/microsites/kids_zacatecas/fontanero.png" alt="" class="img-fluid">
                    </div>
                    <div class="col-12 col-md-10 d-flex align-items-center benefits" style="background-color: #c0e179;">
                        <p>Makers: Llevarán sus ideas a un prototipo real apoyado por la electronica basica.</p>
                    </div>
                </div>
                <div class="row mt-4 align-items-center">
                    <div class="col-12 col-md-2 text-center d-md-flex align-items-center mb-3 div-img">
                        <img src="../../../../assets/img/microsites/kids_zacatecas/ordenador-portatil.png" alt="" class="img-fluid">
                    </div>
                    <div class="col-12 col-md-10 d-flex align-items-center benefits" style="background-color: #e27570;">
                        <p>Programación: Desarrollarán habilidades digitales básicas comom una herramienta para plasmar con ayuda de la tecnología sus soluciones.</p>
                    </div>
                </div>

            </div>

        </div>

    </div>
    <div class="container-fluid container-logos mt-5 mb-5">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center mb-5">
                    <h1>En colaboración con</h1>
                </div>
                <div class="col-12 text-center col-logos">
                    <img src="../../../../assets/img/microsites/kids_zacatecas/Economía.png" alt="" class="img-fluid mr-lg-5">
                    <img src="../../../../assets/img/microsites/kids_zacatecas/logo_FUTEL.png" alt="" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid container-conoce d-none">
        <div class="container text-center pt-4 pb-4">
            <div class="row">
                <div class="col-12">
                    <h1>Conoce más acerca de Kids Zacatecas</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid container-video d-none">
        <div class="container text-center pt-4 pb-4">
            <div class="row">
                <div class="col-12">
                    <iframe width="70%" height="500" src="https://www.youtube.com/embed/UQSR77JAeVk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div class="col-12 mt-5 col-logos">
                    <img src="../../../../assets/img/microsites/kids_zacatecas/patrocinios.png" alt="" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid contact-us">
        <div class="row">
            <div class="col-12 col-md-12 col-lg-3 text-md-center">
                <h4 class="mb-0">Contactanos por:</h4>
            </div>
            <div class="col-12 col-md-4 col-lg-3 text-md-center">
                <a href="https://api.whatsapp.com/send/?phone=5566223249&text=Hola%2C+tengo+una+duda+🟡" target="_blank">
                    <a href="https://api.whatsapp.com/send/?phone=525549130130&text=Hola%2C+tengo+una+duda+🟡" target="_blank" class="link">
                        <h4>WhatsApp</h4>
                    </a>
                </a>
            </div>
            <div class="col-12 col-md-8 col-lg-6 text-md-center">
                <a href="mailto:posible.kids@fundaciontelevisa.org" class="link">
                    <h4>Correo: posible.kids@fundaciontelevisa.org</h4>
                </a>
            </div>
        </div>
    </div>
    <footer class="d-flex align-items-center">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <a [routerLink]="['/terminos']" class="text-center">Términos y condiciones</a>
                    <a [routerLink]="['/preguntas-frecuentes']" class="text-center">Preguntas frecuentes</a>
                    <a [routerLink]="['/aviso-privacidad']" class="text-center">Aviso de privacidad</a>
                </div>
            </div>
        </div>
    </footer>
</div>
<!-- main-container-->