<div class="modal-content">
    <div class="modal-body">
        <ngb-carousel #loginCarousel>
            <ng-template ngbSlide >
              <div class="img-slider">
                <img src="assets/img/escalera.jpg">
              </div>
              <div class="description">
                <h4>¿Qué es POSiBLE?</h4>
                <p class="text">
                    Es un programa de Fundación Televisa y Nacional Monte de Piedad, totalmente gratuito, que apoya a las personas para que puedan aprovechar su talento, energía y compromiso para crear empresas escalables y de alto impacto, basadas en innovación y que generen un beneficio a la sociedad.
                </p>
              </div>
            </ng-template>
            <ng-template ngbSlide >
              <div class="img-slider">
                <img src="assets/img/grupo.jpg">
              </div>
              <div class="description">
                <h4>¿Quiénes pueden participar?</h4>
                <p class="text">Personas mayores de edad que vivan en la república mexicana y tengan una idea de negocio.</p>
              </div>
            </ng-template>
            <ng-template ngbSlide >
              <div class="img-slider">
                <img src="assets/img/posible.jpg">
              </div>
              <div class="description">
                <h4>¿Por qué registrarse?</h4>
                <ul class="text">
                    <li>Aprenderás conceptos básicos de emprendimiento.</li>
                    <li>Aterrizarás de forma clara y sencilla tu modelo de negocio.</li>
                    <li>Recibirás noticias e información sobre convocatorias y temas de interés.</li>
                    <li>Participarás para ser uno de los 100 mejores proyectos del país que reciban mentoría especializada durante 5 días en el Campamento POSiBLE.</li>
                </ul>
              </div>
            </ng-template>
            <ng-template ngbSlide >
            <div class="img-slider">
                <img src="assets/img/mesa-trabajo.jpg">
            </div>
            <div class="description">
                <h4>Etapas de participación en POSiBLE</h4>
                <ul class="text">
                    <li>Regístrate</li>
                    <li>Llena tu modelo de negocio, termínalo y será evaluado. Los mejores pasan a la siguiente etapa.</li>
                    <li>Paneles Estatales: asiste, presenta tu proyecto que será evaluado y los mejores avanzan a la última etapa.</li>
                    <li>Los 100 mejores proyectos calificados asisten al Campamento Nacional de Posible.</li>
                </ul>
            </div>
            </ng-template>
            <ng-template ngbSlide >
                <div class="img-slider">
                    <img src="assets/img/benefits.png" >
                </div>
                <div class="description">
                    <h4>Beneficios</h4>
                    <ul class="text">
                        <li>Formarás parte de una comunidad de emprendimiento que te ofrecerá alianzas de crecimiento.</li>
                        <li>Gran red de aliados, contactos y mentores que enriquecerán tu idea de negocio.</li>
                        <li>Difusión de tu idea de negocio en redes sociales y medios masivos.</li>
                        <li>Invitación a eventos especiales para platicar de tu experiencia como emprendedor.</li>
                    </ul>
                </div>
            </ng-template>
            <ng-template ngbSlide >
                <div class="img-slider">
                    <img src="assets/img/benefits.png" >
                </div>
                <div class="description">
                    <h4>Nuestros logros</h4>
                    ¡Conócenos! En ocho convocatorias hemos logrado lo siguiente:
                    <ul class="text">
                      <li>495 mil personas se han registrado en posible.org.mx.</li>
                      <li>40% de todos los registros son mujeres.</li>
                      <li>Se han desarrollado más de 65 mil modelos de negocio en línea.</li>
                      <li>Más de 600 empresas han recibido incubación, de las cuales 45% siguen en operación y generan más de 1,200 mdp en ventas anuales.</li>
                    </ul>
                  </div>
            </ng-template>
        </ngb-carousel>
    </div>
    <div class="modal-footer">
        <button class="btn" (click)="closeModal()"> Cerrar</button>
    </div>
  </div>