<div [formGroup]="shareForm" class="share-post">
    <div class="info">
        <h5>Compartir publicación:</h5>
        <p>Añade un comentario a continuación</p>
    </div>
    <textarea rows="4" class="form-control" formControlName="shareText"></textarea>
    <div class="group-buttons">
        <button type="submit" class="btn btn-block share__publish mt-4" (click)="sharePublication()" [disabled]="isLoading">Publicar</button>
        <button class="btn btn-return-back btn-block" (click)="goBack()"><img src="../../../../assets/icons/arrow-left.svg" class="mr-2" height="15px"/>Regresar</button>
    </div>
</div>
