
// 1. Nuevo - Indicado con la etiqueta: Editar modelo de negocio
// 2. En revisión - Indicado con la etiqueta: Tu modelo de negocio fue enviado a revisión
// 3. Aprobado -  Indicado con la etiqueta: Tu modelo avanzó a paneles
// 4. Completado - Indicado con la etiqueta: Tu modelo avanzó a campamento nacional
// 5. Rechazado - Indicado con la etiqueta: Tu modelo fue rechazado

export const ProjectStatus = {
    'Nuevo': 1,
    'Revision': 2,
    'Aprobado': 3,
    'Completado': 4,
    'Rechazado': 5,
    'Asignado': 6,
    'Evaluado': 7,
    'Agendado': 8,
    'EvaluadoP': 9,
    'RechazadoP': 10,
    'CampamentoE': 11,
    'CampamentoN': 12,
    'NoAsistioEstatal': 13,
    'AsistioEstatal': 14,
    'NoAsistioNacional': 15,
    'AsistioNacional': 16,
};
