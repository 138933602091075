<div class="container p-0">
    <div class="main-container animated fadeIn fast">
        <div class="color-background card text-center border-0 animated fadeIn fast">
            <div class="card-body animated fadeIn">
                <span class="txt_title">
          Crea tu cuenta
        </span>
                <p class="txt_common mt-1">
                    Comienza a darle forma a tu sueño
                </p>
                <div class="mt-2">
                    <form autocomplete="off" [formGroup]="formAccount">
                        <div class="input-group input-group-lg">
                            <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large" placeholder="Nombre" formControlName="nombre">
                        </div>
                        <div *ngIf="submitted && formFields.nombre.errors" class="errors pt-1">
                            <small *ngIf="formFields.nombre.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
                            <small *ngIf="formFields.nombre.errors.minlength" class="text-danger animated fadeIn">
                Al menos dos caracteres
              </small>
                        </div>
                        <div class="mt-4 input-group input-group-lg">
                            <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large" placeholder="Primer Apellido" formControlName="apellido">
                        </div>
                        <div *ngIf="submitted && formFields.apellido.errors" class="errors pt-1">
                            <small *ngIf="formFields.apellido.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
                            <small *ngIf="formFields.apellido.errors.minlength" class="text-danger animated fadeIn">
                Al menos dos caracteres
              </small>
                        </div>
                        <div class="mt-4 input-group input-group-lg">
                            <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large" placeholder="Segundo Apellido" formControlName="apellidoMat">
                        </div>
                        <div class="mt-4 input-group input-group-lg">
                            <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large" placeholder="Correo electrónico" formControlName="correo" (change)="mailHelperInput($event)">
                        </div>
                        <div *ngIf="submitted && formFields.correo.errors" class="errors pt-1">
                            <small *ngIf="formFields.correo.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
                            <small *ngIf="formFields.correo.errors.pattern" class="text-danger animated fadeIn">
                Correo inválido.
              </small>
                        </div>
                        <div class="mt-4 input-group input-group-lg">
                            <label for="date" *ngIf="messageDate == true" class="label-date"><b>Favor de seleccionar en el orden: Mes, Año y Día</b></label>
                            <input type="text" #date ngxDaterangepickerMd (click)="onClickDate()" class="form-control inpts_posible border-rounded datePlaceholder datepicker-calendar-icon" name="fechaNac" formControlName="fechaNac" [closeOnAutoApply]="true" [autoApply]="true" [showDropdowns]="true"
                                (change)="birthDate = $event" [singleDatePicker]="true" placeholder="Fecha de nacimiento" class="datepicker-calendar-icon" [minDate]="minBirthDate" />
                        </div>
                        <div *ngIf="submitted && formFields.fechaNac.errors" class="errors pt-1">
                            <small *ngIf="formFields.fechaNac.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
                            <small *ngIf="formFields.fechaNac.errors.minimumAge" class="text-danger animated fadeIn">
                Debe ser mayor de 18 años.
              </small>
                            <small *ngIf="formFields.fechaNac.errors?.maxlength" class="text-danger animated fadeIn">
                Fecha inválida.
              </small>
                        </div>
                        <div class="mt-4 input-group input-group-lg">
                            <ng-select class="form-control" placeholder="Género" formControlName="genero" [clearable]="false" notFoundText="No se encontraron resultados" loadingText="Cargando...">
                                <ng-option *ngFor="let gender of genders | keyvalue" [value]="gender.key">{{gender.value}}</ng-option>
                            </ng-select>
                        </div>
                        <div *ngIf="submitted && formFields.genero.errors" class="errors pt-1">
                            <small *ngIf="formFields.genero.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
                        </div>
                        <div class="mt-4 input-group input-group-lg">
                            <input type="text" (keyup)="validatePhoneNo($event.target)" (paste)="validatePhoneNo($event.target)" onpaste="return false" class="form-control inpts_posible border-rounded" aria-label="Large" placeholder="Teléfono" formControlName="telefono" maxlength="13"
                                autocomplete="not-autocomplete">
                        </div>
                        <div *ngIf="submitted && formFields.telefono.errors" class="errors pt-1">
                            <small *ngIf="formFields.telefono.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
                            <small *ngIf="formFields.telefono.errors.pattern" class="text-danger animated fadeIn">
                Teléfono inválido.
              </small>
                            <small *ngIf="formFields.telefono.errors.minlength || formFields.telefono.errors.maxlength" class="text-danger animated fadeIn">
                10 dígitos requeridos
              </small>
                        </div>
                        <div class="mt-4 input-group input-group-lg">
                            <ng-select [items]="states" bindLabel="description" class="form-control" (change)="getCities()" placeholder="Estado" [clearable]="false" formControlName="estado" notFoundText="No se encontraron resultados" loadingText="Cargando..." bindValue="state_guid">
                            </ng-select>
                        </div>
                        <div *ngIf="submitted && formFields.estado.errors" class="errors pt-1">
                            <small *ngIf="formFields.estado.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
                        </div>

                        <div class="mt-4 input-group input-group-lg">
                            <ng-select [items]="cities" bindLabel="description" class="form-control" [placeholder]="isLoadingCities ? 'Cargando...' : 'Municipio'" formControlName="municipio" [clearable]="false" notFoundText="No se encontraron resultados" loadingText="Cargando..."
                                bindValue="municipality_guid">
                            </ng-select>
                        </div>
                        <div *ngIf="submitted && formFields.municipio.errors" class="errors pt-1">
                            <small *ngIf="formFields.municipio.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
                        </div>
                        <!-- Hackaton Conalep -->
                        <!-- <div
            *ngIf="hackaton"
              class="pt-2">
              <p class="" style="font-size: 1rem; text-align: left; margin-bottom: 0.25rem;">
                Quieres participar en el HackatónIA Conalep?
              </p>
            </div>
            <div class="input-group input-group-lg"
              *ngIf="hackaton">
              <div class="d-flex flex-row">
                <div class="form-check mr-4">
                  <input class="form-check-input" type="radio" name="hackaton" [value]="true"
                    formControlName="hackaton" (change)="hackAccept(true)">
                  <label class="form-check-label">
                    <span class="txt_common_bold"> Si </span>
                  </label>
                </div>
                <div class="form-check mr-4">
                  <input class="form-check-input" type="radio" name="hackaton" [value]="false"
                    formControlName="hackaton" (change)="hackAccept(false)">
                  <label class="form-check-label">
                    <span class="txt_common_bold"> No </span>
                  </label>
                </div>
              </div>
            </div>

            <div class="mt-4 input-group input-group-lg" *ngIf="hackatonAccept">
              <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large"
                placeholder="CONALEP de procedencia" formControlName="conalep">
            </div>
            <div *ngIf="submitted && formFields.conalep.errors" class="errors pt-1">
              <small *ngIf="formFields.conalep.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
            </div>

            <div class="mt-4 input-group input-group-lg" *ngIf="hackatonAccept">
              <ng-select
                class="form-control inpts_posible border-rounded"
                placeholder="Número de integrantes de equipo"
                formControlName="integrantes"
                [clearable] = "false"
                notFoundText="No se encontraron resultados"
                loadingText="Cargando...">
                <ng-option *ngFor="let puesto of participantes" [value]="puesto.id">{{puesto.descripcion}}</ng-option>
              </ng-select>
            </div>
            <div *ngIf="submitted && formFields.integrantes.errors" class="errors pt-1">
              <small *ngIf="formFields.integrantes.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
            </div>

            <div class="mt-4 input-group input-group-lg" *ngIf="hackatonAccept">
              <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large"
                placeholder="Nombre de tu proyecto" formControlName="proyecto">
            </div>
            <div *ngIf="submitted && formFields.proyecto.errors" class="errors pt-1">
              <small *ngIf="formFields.proyecto.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
            </div>

            <div class="mt-4 input-group input-group-lg" *ngIf="hackatonAccept">
              <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large"
                placeholder="Describe tu proyecto (qué es y qué resuelve)" formControlName="descripcion">
            </div>
            <div *ngIf="submitted && formFields.descripcion.errors" class="errors pt-1">
              <small *ngIf="formFields.descripcion.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
            </div>

            <div class="mt-4 input-group input-group-lg" *ngIf="hackatonAccept">
              <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large"
                placeholder="¿De qué manera implementarás la IA en tu proyecto?" formControlName="implementacion">
            </div>
            <div *ngIf="submitted && formFields.implementacion.errors" class="errors pt-1">
              <small *ngIf="formFields.implementacion.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
            </div> -->
                        <!-- Reto Posible EdoMex -->
                        <div *ngIf="edoMex" class="pt-2">
                            <p class="" style="font-size: 1rem; text-align: left; margin-bottom: 0.25rem;">
                                <b>¿Quieres participar en Reto POSiBLE EdoMéx 2022?</b><br>
                                <span>
                                  Reto POSiBLE EdoMéx es un programa que ofrece los conocimientos, las herramientas y capacitación a docentes, funcionario en cualquier dependencia del Estado de México o sus municipios, para impulsarlos en la formación de emprendedores de alto impacto. 
                                  <a href="assets/RETO_POSiBLE_EdoMex2.pdf" target="_blank" rel="noopener noreferrer"><b>Conoce la convocatoria aquí</b></a>
                  </span>
                            </p>
                        </div>
                        <div class="input-group input-group-lg" *ngIf="edoMex">
                            <div class="d-flex flex-row">
                                <div class="form-check mr-4">
                                    <input class="form-check-input" type="radio" name="retoEdoMex" [value]="true" formControlName="retoEdoMex" (change)="retoEdoMexAccept(true)">
                                    <label class="form-check-label">
    <span class="txt_common_bold"> Si </span>
  </label>
                                </div>
                                <div class="form-check mr-4">
                                    <input class="form-check-input" type="radio" name="retoEdoMex" [value]="false" formControlName="retoEdoMex" (change)="retoEdoMexAccept(false)">
                                    <label class="form-check-label">
    <span class="txt_common_bold"> No </span>
  </label>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="submitted && edoMex && formFields.retoEdoMex.errors" class="errors pt-1">
                            <small *ngIf="formFields.retoEdoMex.errors.required" class="text-danger animated fadeIn">
Campo requerido.
</small>
                        </div>
                        <div class="mt-4 input-group input-group-lg" *ngIf="edoMexAccept">
                            <label for="empleoReto"><b>¿Actualmente a qué te dedicas?</b></label>
                            <ng-select class="form-control inpts_posible border-rounded" placeholder="Selecciona la que sea más similar a tus funciones." formControlName="empleoReto" [clearable]="false" notFoundText="No se encontraron resultados" loadingText="Cargando..." (change)="empleoReto()">
                                <ng-option *ngFor="let puesto of empleo" [value]="puesto.id">{{puesto.descripcion}}</ng-option>
                            </ng-select>
                        </div>
                        <div *ngIf="submitted && edoMexAccept &&formFields.empleoReto.errors" class="errors pt-1">
                            <small *ngIf="formFields.empleoReto.errors.required" class="text-danger animated fadeIn">
            Campo requerido.
          </small>
                        </div>
                        <div class="mt-4 input-group input-group-lg" *ngIf="(empleoSelect == 4) && edoMexAccept">
                            <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large" placeholder="¿A qué te dedicas?" formControlName="otroEmpleoReto">
                        </div>
                        <div *ngIf="submitted && edoMexAccept && formFields.otroEmpleoReto.errors" class="errors pt-1">
                            <small *ngIf="formFields.otroEmpleoReto.errors.required" class="text-danger animated fadeIn">
            Campo requerido.
          </small>
                        </div>
                        <div class="mt-4 input-group input-group-lg" *ngIf="edoMexAccept">
                            <label for="institutoReto" class="form-label"><b>¿Qué institución representas?</b></label>
                            <input type="text" class="form-control inpts_posible border-rounded w-100" aria-label="Large" placeholder="Indica el nombre de tu institución, escuela, universidad, dependencia del Estado de México o sus municipios, aceleradora, etc." formControlName="institutoReto">
                        </div>
                        <div *ngIf="submitted && edoMexAccept && formFields.institutoReto.errors" class="errors pt-1">
                            <small *ngIf="formFields.institutoReto.errors.required" class="text-danger animated fadeIn">
          Campo requerido.
        </small>
                        </div>
                        <!-- Fin Reto Posible EdoMex -->
                        <!-- Reto posible zacatecas -->

                        <div *ngIf="zacatecas" class="pt-3">
                            <p class="" style="font-size: 1rem; text-align: left; margin-bottom: 0.25rem;">
                                <b>¿Quieres postular a Reto POSiBLE Zacatecas? </b><br>
                                <span>
                                    Es un programa que busca inspirar y apoyar a formadores y emprendedores potenciales dentro 
                                    de la comunidad universitaria y centros de incubación en el Estado de Zacatecas. 
                                    Convocatoria:  <b><a href="assets/RP_Zacatecas_Convocatoria_Alumnos.pdf" target="_blank">Estudiantes</a></b>
                                </span>
                            </p>
                        </div>
                        <div class="input-group input-group-lg" *ngIf="zacatecas">
                            <div class="d-flex flex-row">
                                <div class="form-check mr-4">
                                    <input class="form-check-input" type="radio" name="retoAccept" [value]="true" (change)="retoZacatecasAccept(true)">
                                    <label class="form-check-label">
                <span class="txt_common_bold"> Si </span>
              </label>
                                </div>
                                <div class="form-check mr-4">
                                    <input class="form-check-input" type="radio" name="retoAccept" [value]="false" (change)="retoZacatecasAccept(false)">
                                    <label class="form-check-label">
                <span class="txt_common_bold"> No </span>
              </label>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="form-group form-group-lg" *ngIf="zacatecas && retoAccept">
                            <p class="text-left mt-3 mb-1">
                                <b>Selecciona tu perfil</b>
                            </p>
                            <div class="d-flex flex-row">
                                <div class="form-check mr-4">
                                    <input class="form-check-input" type="radio" name="selectedProfileZacatecas" [value]="true" formControlName="selectedProfileZacatecas" (change)="selectedProfileZacatecasAccept(true)">
                                    <label class="form-check-label">
                  <span class="txt_common_bold"> Docente </span>
                </label>
                                </div>
                                <div class="form-check mr-4">
                                    <input class="form-check-input" type="radio" name="selectedProfileZacatecas" [value]="false" formControlName="selectedProfileZacatecas" (change)="selectedProfileZacatecasAccept(false)">
                                    <label class="form-check-label">
                  <span class="txt_common_bold"> Estudiante </span>
                </label>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="submitted && formFields.selectedProfileZacatecas.errors" class="errors pt-1">
                            <small *ngIf="formFields.selectedProfileZacatecas.errors.required" class="text-danger animated fadeIn">
              Campo requerido.
            </small>
                        </div> -->

                        <div class="mt-4 input-group input-group-lg" *ngIf="profile">
                            <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large" placeholder="¿Institución educativa a la que perteneces?" formControlName="institutoEducativo">
                        </div>
                        <div *ngIf="submitted && profile && formFields.institutoEducativo.errors" class="errors pt-1">
                            <small *ngIf="formFields.institutoEducativo.errors.required" class="text-danger animated fadeIn">
            Campo requerido.
          </small>
                        </div>

                        <!-- <div class="mt-4 input-group input-group-lg" *ngIf="zacatecas && docente">
                            <ng-select class="form-control inpts_posible border-rounded" placeholder="Ocupación" formControlName="ocupacionReto" [clearable]="false" notFoundText="No se encontraron resultados" loadingText="Cargando..." (change)="ocupacionReto()">
                                <ng-option *ngFor="let puesto of ocupacion" [value]="puesto.id">{{puesto.descripcion}}</ng-option>
                            </ng-select>
                        </div>
                        <div *ngIf="submitted && zacatecas && docente &&formFields.ocupacionReto.errors" class="errors pt-1">
                            <small *ngIf="formFields.ocupacionReto.errors.required" class="text-danger animated fadeIn">
              Campo requerido.
            </small>
                        </div> -->
                        <div class="mt-4 input-group input-group-lg" *ngIf="zacatecas && estudiante">
                            <ng-select class="form-control inpts_posible border-rounded" placeholder="Ocupación actual " formControlName="ocupacionEstudiante" [clearable]="false" notFoundText="No se encontraron resultados" loadingText="Cargando...">
                                <ng-option *ngFor="let ocupacion of ocupacionEstudiante" [value]="ocupacion.id">{{ocupacion.descripcion}}</ng-option>
                            </ng-select>
                        </div>
                        <div *ngIf="submitted && zacatecas && estudiante &&formFields.ocupacionEstudiante.errors" class="errors pt-1">
                            <small *ngIf="formFields.ocupacionEstudiante.errors.required" class="text-danger animated fadeIn">
              Campo requerido.
            </small>
                        </div>

                        <div class="mt-4 input-group input-group-lg" *ngIf="(ocupacionSelect == 1) && zacatecasAccept">
                            <ng-select class="form-control inpts_posible border-rounded" placeholder="Horas frente a grupo a la semana" formControlName="horasReto" [clearable]="false" notFoundText="No se encontraron resultados" loadingText="Cargando...">
                                <ng-option *ngFor="let hora of horas" [value]="hora.id">{{hora.descripcion}}</ng-option>
                            </ng-select>
                        </div>
                        <div *ngIf="submitted && zacatecasAccept && formFields.horasReto.errors" class="errors pt-1">
                            <small *ngIf="formFields.horasReto.errors.required" class="text-danger animated fadeIn">
              Campo requerido.
            </small>
                        </div>

                        <div class="mt-4 input-group input-group-lg" *ngIf="(ocupacionSelect > 1) && zacatecasAccept">
                            <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large" placeholder="Puesto" formControlName="puestoReto">
                        </div>
                        <div *ngIf="submitted && zacatecasAccept && formFields.puestoReto.errors" class="errors pt-1">
                            <small *ngIf="formFields.puestoReto.errors.required" class="text-danger animated fadeIn">
              Campo requerido.
            </small>
                        </div>

                        <div class="mt-4 input-group input-group-lg" *ngIf="(ocupacionSelect > 0) && zacatecasAccept">
                            <ng-select class="form-control inpts_posible border-rounded" placeholder="Antigüedad en este puesto" formControlName="antiguedadReto" [clearable]="false" notFoundText="No se encontraron resultados" loadingText="Cargando...">
                                <ng-option *ngFor="let rango of antiguo" [value]="rango.id">{{rango.descripcion}}</ng-option>
                            </ng-select>
                        </div>
                        <div *ngIf="submitted && zacatecasAccept && formFields.antiguedadReto.errors" class="errors pt-1">
                            <small *ngIf="formFields.antiguedadReto.errors.required" class="text-danger animated fadeIn">
              Campo requerido.
            </small>
                        </div>

                        <div class="mt-4 input-group input-group-lg" *ngIf="(ocupacionSelect == 1) && zacatecasAccept">
                            <ng-select class="form-control inpts_posible border-rounded" placeholder="Materia que impartes" formControlName="materiaReto" [clearable]="false" notFoundText="No se encontraron resultados" loadingText="Cargando..." (change)=materiaReto()>
                                <ng-option *ngFor="let materia of tema" [value]="materia.id">{{materia.descripcion}}</ng-option>
                            </ng-select>
                        </div>
                        <div *ngIf="submitted && zacatecasAccept && formFields.materiaReto.errors" class="errors pt-1">
                            <small *ngIf="formFields.materiaReto.errors.required" class="text-danger animated fadeIn">
              Campo requerido.
            </small>
                        </div>

                        <div class="mt-4 input-group input-group-lg" *ngIf="(ocupacionSelect == 1) && (materiaSelect == 4) && zacatecasAccept">
                            <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large" placeholder="Materia" formControlName="otraMateriaReto">
                        </div>
                        <div *ngIf="submitted && zacatecasAccept && formFields.otraMateriaReto.errors" class="errors pt-1">
                            <small *ngIf="formFields.otraMateriaReto.errors.required" class="text-danger animated fadeIn">
              Campo requerido.
            </small>
                        </div>

                        <div class="mt-4 input-group input-group-lg" *ngIf="profile  && zacatecas">
                            <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large" placeholder="¿Por qué quieres ser parte de reto POSiBLE?" formControlName="interesReto">
                        </div>
                        <div *ngIf="submitted && formFields.interesReto.errors" class="errors pt-1">
                            <small *ngIf="formFields.interesReto.errors.required" class="text-danger animated fadeIn">
              Campo requerido.
            </small>
                        </div>
                        <!-- <div class="mt-4 input-group input-group-lg" *ngIf="zacatecas && docente">
                            <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large" placeholder="¿Cómo impactará Reto POSiBLE en tu desempeño profesional?" formControlName="professionalPerformance">
                        </div>
                        <div *ngIf="submitted && zacatecas && docente && formFields.professionalPerformance.errors" class="errors pt-1">
                            <small *ngIf="formFields.professionalPerformance.errors.required" class="text-danger animated fadeIn">
              Campo requerido.
            </small>
                        </div> -->
                        <!-- <div class="mt-4 input-group input-group-lg" *ngIf="zacatecas && docente">
                            <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large" placeholder="¿Cómo replicarás lo aprendido en Reto POSiBLE?" formControlName="replicateLearned">
                        </div>
                        <div *ngIf="submitted && zacatecas && docente && formFields.replicateLearned.errors" class="errors pt-1">
                            <small *ngIf="formFields.replicateLearned.errors.required" class="text-danger animated fadeIn">
              Campo requerido.
            </small>
                        </div> -->

                        <div class="mt-4 input-group input-group-lg" *ngIf="zacatecas && estudiante">
                            <!-- <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large" placeholder="¿Cuéntanos sobre tu idea? " formControlName="ideaStudent"> -->
                            <textarea type="text" class="form-control inpts_posible border-rounded" name="ideaStudent" id="" rows="4" maxlength="200" placeholder="¿Cuéntanos sobre tu idea?" formControlName="ideaStudent"></textarea>
                        </div>
                        <div class="txt_advice text-right" *ngIf="zacatecas && estudiante">
                            <p class="mt-2" *ngIf="countChars(formFields.ideaStudent.value) == 0"> Máx 200 caracteres</p>
                            <p class="mt-2" *ngIf="countChars(formFields.ideaStudent.value) >= 1 && countChars(formFields.ideaStudent.value) < 200">
                                {{200 - countChars(formFields.ideaStudent.value) }} caracteres faltantes.</p>
                            <p class="mt-2 text-danger" *ngIf="countChars(formFields.ideaStudent.value) == 200"> Máximo de caracteres alcanzado.
                            </p>
                        </div>
                        <div *ngIf="submitted && zacatecas && estudiante && formFields.ideaStudent.errors" class="errors pt-1">
                            <small *ngIf="formFields.ideaStudent.errors.required" class="text-danger animated fadeIn">
              Campo requerido.
            </small>
                        </div>
                        <!-- Reto posible zacatecas -->
                        <!-- Reto posible kids zacatecas -->
                        <div *ngIf="kidsZacatecas" class="pt-2">
                            <p class="" style="font-size: 1rem; text-align: left; margin-bottom: 0.25rem;">
                                <b>¿Quieres realizar una postulación en POSiBLE Kids Zacatecas 2022?</b><br>
                                <span>
                                POSiBLE Kids es un programa de fomento al emprendimiento de alto impacto con desarrollo tecnológico que busca detonar en niños y niñas de 8 a 12 años el interés por atender problemas sociales de su entorno mediante soluciones innovadoras. Convocatoria: 
                              Convocatoria: <b><a href="assets/Convocatoria_kids.pdf" target="_blank">Convocatoria POSiBLE Kids</a></b>
                            </span>
                            </p>
                        </div>
                        <div class="input-group input-group-lg" *ngIf="kidsZacatecas">
                            <div class="d-flex flex-row">
                                <div class="form-check mr-4">
                                    <input class="form-check-input" type="radio" name="kidsZacatecas" [value]="true" formControlName="kidsZacatecas" (change)="retokidsZacatecasAccept(true)">
                                    <label class="form-check-label">
                  <span class="txt_common_bold"> Si </span>
                </label>
                                </div>
                                <div class="form-check mr-4">
                                    <input class="form-check-input" type="radio" name="kidsZacatecas" [value]="false" formControlName="kidsZacatecas" (change)="retokidsZacatecasAccept(false)">
                                    <label class="form-check-label">
                  <span class="txt_common_bold"> No </span>
                </label>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="submitted && formFields.kidsZacatecas.errors" class="errors pt-1">
                            <small *ngIf="formFields.kidsZacatecas.errors.required" class="text-danger animated fadeIn">
              Campo requerido.
            </small>
                        </div>
                        <div *ngIf="kidsZacatecasAccept" class="pt-2">
                            <p class="" style="font-size: 1rem; text-align: left; margin-bottom: 0.25rem;">
                                <b>¿A quien quieres postular en POSiBLE Kids Zacatecas?</b><br>
                                <span>
                              Se podrán postular menores de manera individual o  equipos de dos a cinco participantes que tengan la disponibilidad para asistir a la misma sede de manera presencial. Convocatoria: <b><a href="assets/Convocatoria_kids.pdf" target="_blank">Convocatoria POSiBLE Kids</a></b>
                          </span>
                            </p>
                        </div>

                        <div class="mt-4 input-group input-group-lg" *ngIf="kidsZacatecasAccept">
                            <div class="d-flex flex-row">
                                <div class="form-check mr-4">
                                    <input class="form-check-input" type="radio" name="kidsregister" [value]="true" formControlName="kidsregister" (change)="retokidsregister('son')">
                                    <label class="form-check-label">
              <span class="txt_common_bold"> Mi hijo (a) </span>
            </label>
                                </div>
                                <div class="form-check mr-4">
                                    <input class="form-check-input" type="radio" name="kidsregister" [value]="false" formControlName="kidsregister" (change)="retokidsregister('team')">
                                    <label class="form-check-label">
              <span class="txt_common_bold"> Un equipo </span>
            </label>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="submitted && formFields.kidsregister.errors" class="errors pt-1">
                            <small *ngIf="formFields.kidsregister.errors.required" class="text-danger animated fadeIn">
            Campo requerido.
          </small>
                        </div>
                        <div class="mt-4 input-group input-group-lg" *ngIf="registerSon && kidsZacatecasAccept">
                            <label>Edad</label>
                        </div>
                        <div class="input-group input-group-lg" *ngIf="registerSon && kidsZacatecasAccept">
                            <input type="number" class="form-control inpts_posible border-rounded" min="1" max="100" aria-label="Large" formControlName="kidAge">
                        </div>
                        <div *ngIf="submitted && kidsZacatecasAccept && registerSon && formFields.kidAge.errors" class="errors pt-1">
                            <small *ngIf="formFields.kidAge.errors.required" class="text-danger animated fadeIn">
            Campo requerido.
          </small>
                        </div>
                        <div class="mt-4 input-group input-group-lg" *ngIf="registerSon && kidsZacatecasAccept">
                            <label>Grado de estudios</label>
                        </div>
                        <div class="input-group input-group-lg" *ngIf="registerSon && kidsZacatecasAccept">
                            <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large" formControlName="levelStudy">
                        </div>
                        <div *ngIf="submitted && kidsZacatecasAccept && registerSon && formFields.levelStudy.errors" class="errors pt-1">
                            <small *ngIf="formFields.levelStudy.errors.required" class="text-danger animated fadeIn">
          Campo requerido.
        </small>
                        </div>

                        <div class="mt-4 input-group input-group-lg" *ngIf="registerTeam && kidsZacatecasAccept">
                            <label *ngIf="registerTeam && kidsZacatecasAccept">Nombre del equipo</label>
                        </div>
                        <div class="input-group input-group-lg" *ngIf="registerTeam && kidsZacatecasAccept">
                            <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large" formControlName="nameTeam">
                        </div>
                        <div *ngIf="submitted && kidsZacatecasAccept && registerTeam && formFields.nameTeam.errors" class="errors pt-1">
                            <small *ngIf="formFields.nameTeam.errors.required" class="text-danger animated fadeIn">
        Campo requerido.
      </small>
                        </div>

                        <div class="mt-4 input-group input-group-lg" *ngIf="registerTeam && kidsZacatecasAccept">
                            <label *ngIf="registerTeam && kidsZacatecasAccept">Número de integrantes</label>
                        </div>
                        <div class="input-group input-group-lg" *ngIf="registerTeam && kidsZacatecasAccept">
                            <ng-select [items]="numberParticipants" bindLabel="name" class="form-control" [clearable]="false" placeholder="Seleccionar" formControlName="numberParticipants" (change)="onParticipants()" notFoundText="No se encontraron resultados" loadingText="Cargando..."
                                bindValue="id">
                            </ng-select>
                        </div>
                        <div *ngIf="submitted && kidsZacatecasAccept && registerTeam && formFields.numberParticipants.errors" class="errors pt-1">
                            <small *ngIf="formFields.numberParticipants.errors.required" class="text-danger animated fadeIn">
        Campo requerido.
      </small>
                        </div>
                        <!-- Integrante 1 -->
                        <div class="mt-4 input-group input-group-lg" *ngIf="registerTeam && participants > 0 ">
                            <label *ngIf="registerTeam && kidsZacatecasAccept">Edad del integrante 1</label>
                        </div>
                        <div class="input-group input-group-lg" *ngIf="registerTeam && participants > 0 ">
                            <input type="number" class="form-control inpts_posible border-rounded" aria-label="Large" formControlName="ageParticipant1">
                        </div>
                        <div *ngIf="submitted && kidsZacatecasAccept && registerTeam && formFields.ageParticipant1.errors" class="errors pt-1">
                            <small *ngIf="formFields.ageParticipant1.errors.required" class="text-danger animated fadeIn">
        Campo requerido.
      </small>
                        </div>

                        <div class="mt-2 input-group input-group-lg" *ngIf="registerTeam && participants > 0 ">
                            <label *ngIf="registerTeam && kidsZacatecasAccept">Grado de estudios</label>
                        </div>
                        <div class="input-group input-group-lg" *ngIf="registerTeam && participants > 0 ">
                            <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large" formControlName="levelStudyTeam1">
                        </div>
                        <div *ngIf="submitted && kidsZacatecasAccept && registerTeam && formFields.levelStudyTeam1.errors" class="errors pt-1">
                            <small *ngIf="formFields.levelStudyTeam1.errors.required" class="text-danger animated fadeIn">
        Campo requerido.
      </small>
                        </div>
                        <div class="mt-2 input-group input-group-lg" *ngIf="registerTeam && participants > 0 ">
                            <label *ngIf="registerTeam && kidsZacatecasAccept">Escuela</label>
                        </div>
                        <div class="input-group input-group-lg" *ngIf="registerTeam && participants > 0 ">
                            <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large" formControlName="school1">
                        </div>
                        <div *ngIf="submitted && kidsZacatecasAccept && registerTeam && formFields.school1.errors" class="errors pt-1">
                            <small *ngIf="formFields.school1.errors.required" class="text-danger animated fadeIn">
        Campo requerido.
      </small>
                        </div>

                        <!-- Fin Integrante 1 -->
                        <!-- Integrante 2 -->
                        <div class="mt-4 input-group input-group-lg" *ngIf="registerTeam && participants >= 2">
                            <label *ngIf="registerTeam && kidsZacatecasAccept">Edad del integrante 2</label>
                        </div>
                        <div class="input-group input-group-lg" *ngIf="registerTeam && participants >= 2">
                            <input type="number" class="form-control inpts_posible border-rounded" aria-label="Large" formControlName="ageParticipant2">
                        </div>
                        <div *ngIf="submitted && kidsZacatecasAccept && registerTeam && formFields.ageParticipant2.errors" class="errors pt-1">
                            <small *ngIf="formFields.ageParticipant2.errors.required" class="text-danger animated fadeIn">
                        Campo requerido.
                        </small>
                        </div>

                        <div class="mt-2 input-group input-group-lg" *ngIf="registerTeam && participants >= 2">
                            <label *ngIf="registerTeam && kidsZacatecasAccept">Grado de estudios</label>
                        </div>
                        <div class="input-group input-group-lg" *ngIf="registerTeam && participants >= 2">
                            <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large" formControlName="levelStudyTeam2">
                        </div>
                        <div *ngIf="submitted && kidsZacatecasAccept && registerTeam && formFields.levelStudyTeam2.errors" class="errors pt-1">
                            <small *ngIf="formFields.levelStudyTeam2.errors.required" class="text-danger animated fadeIn">
                        Campo requerido.
                        </small>
                        </div>
                        <div class="mt-2 input-group input-group-lg" *ngIf="registerTeam && participants >= 2">
                            <label *ngIf="registerTeam && kidsZacatecasAccept">Escuela</label>
                        </div>
                        <div class="input-group input-group-lg" *ngIf="registerTeam && participants >= 2">
                            <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large" formControlName="school2">
                        </div>
                        <div *ngIf="submitted && kidsZacatecasAccept && registerTeam && formFields.school2.errors" class="errors pt-1">
                            <small *ngIf="formFields.school2.errors.required" class="text-danger animated fadeIn">
                        Campo requerido.
                        </small>
                        </div>

                        <!-- Fin Integrante 2 -->
                        <!-- Integrante 3 -->
                        <div class="mt-4 input-group input-group-lg" *ngIf="registerTeam && participants >= 3">
                            <label *ngIf="registerTeam && kidsZacatecasAccept">Edad del integrante 3</label>
                        </div>
                        <div class="input-group input-group-lg" *ngIf="registerTeam && participants >= 3">
                            <input type="number" class="form-control inpts_posible border-rounded" aria-label="Large" formControlName="ageParticipant3">
                        </div>
                        <div *ngIf="submitted && kidsZacatecasAccept && registerTeam  && participants >= 3 && formFields.ageParticipant3.errors" class="errors pt-1">
                            <small *ngIf="formFields.ageParticipant3.errors.required" class="text-danger animated fadeIn">
                        Campo requerido.
                        </small>
                        </div>

                        <div class="mt-2 input-group input-group-lg" *ngIf="registerTeam && participants >= 3">
                            <label *ngIf="registerTeam && kidsZacatecasAccept">Grado de estudios</label>
                        </div>
                        <div class="input-group input-group-lg" *ngIf="registerTeam && participants >= 3">
                            <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large" formControlName="levelStudyTeam3">
                        </div>
                        <div *ngIf="submitted && kidsZacatecasAccept && registerTeam && participants >= 3 && formFields.levelStudyTeam3.errors" class="errors pt-1">
                            <small *ngIf="formFields.levelStudyTeam3.errors.required" class="text-danger animated fadeIn">
                        Campo requerido.
                        </small>
                        </div>
                        <div class="mt-2 input-group input-group-lg" *ngIf="registerTeam && participants >= 3">
                            <label *ngIf="registerTeam && kidsZacatecasAccept">Escuela</label>
                        </div>
                        <div class="input-group input-group-lg" *ngIf="registerTeam && participants >= 3">
                            <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large" formControlName="school3">
                        </div>
                        <div *ngIf="submitted && kidsZacatecasAccept && registerTeam && participants >= 3 && formFields.school3.errors" class="errors pt-1">
                            <small *ngIf="formFields.school3.errors.required" class="text-danger animated fadeIn">
                        Campo requerido.
                        </small>
                        </div>

                        <!-- Fin Integrante 3 -->
                        <!-- Integrante 4 -->
                        <div class="mt-4 input-group input-group-lg" *ngIf="registerTeam && participants >= 4">
                            <label *ngIf="registerTeam && kidsZacatecasAccept">Edad del integrante 4</label>
                        </div>
                        <div class="input-group input-group-lg" *ngIf="registerTeam && participants >= 4">
                            <input type="number" class="form-control inpts_posible border-rounded" aria-label="Large" formControlName="ageParticipant4">
                        </div>
                        <div *ngIf="submitted && kidsZacatecasAccept && registerTeam&& participants >= 4 && formFields.ageParticipant4.errors" class="errors pt-1">
                            <small *ngIf="formFields.ageParticipant4.errors.required" class="text-danger animated fadeIn">
                        Campo requerido.
                        </small>
                        </div>

                        <div class="mt-2 input-group input-group-lg" *ngIf="registerTeam && participants >= 4">
                            <label *ngIf="registerTeam && kidsZacatecasAccept">Grado de estudios</label>
                        </div>
                        <div class="input-group input-group-lg" *ngIf="registerTeam && participants >= 4">
                            <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large" formControlName="levelStudyTeam4">
                        </div>
                        <div *ngIf="submitted && kidsZacatecasAccept && registerTeam && participants >= 4 && formFields.levelStudyTeam4.errors" class="errors pt-1">
                            <small *ngIf="formFields.levelStudyTeam4.errors.required" class="text-danger animated fadeIn">
                        Campo requerido.
                        </small>
                        </div>
                        <div class="mt-2 input-group input-group-lg" *ngIf="registerTeam && participants >= 4">
                            <label *ngIf="registerTeam && kidsZacatecasAccept">Escuela</label>
                        </div>
                        <div class="input-group input-group-lg" *ngIf="registerTeam && participants >= 4">
                            <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large" formControlName="school4">
                        </div>
                        <div *ngIf="submitted && kidsZacatecasAccept && registerTeam && participants >= 4 && formFields.school4.errors" class="errors pt-1">
                            <small *ngIf="formFields.school4.errors.required" class="text-danger animated fadeIn">
                        Campo requerido.
                        </small>
                        </div>

                        <!-- Fin Integrante 4 -->
                        <!-- Integrante 5 -->
                        <div class="mt-4 input-group input-group-lg" *ngIf="registerTeam && participants == 5">
                            <label *ngIf="registerTeam && kidsZacatecasAccept">Edad del integrante 5</label>
                        </div>
                        <div class="input-group input-group-lg" *ngIf="registerTeam && participants == 5">
                            <input type="number" class="form-control inpts_posible border-rounded" aria-label="Large" formControlName="ageParticipant5">
                        </div>
                        <div *ngIf="submitted && kidsZacatecasAccept && registerTeam && participants == 5 && formFields.ageParticipant5.errors" class="errors pt-1">
                            <small *ngIf="formFields.ageParticipant5.errors.required" class="text-danger animated fadeIn">
                        Campo requerido.
                        </small>
                        </div>

                        <div class="mt-2 input-group input-group-lg" *ngIf="registerTeam && participants == 5">
                            <label *ngIf="registerTeam && kidsZacatecasAccept">Grado de estudios</label>
                        </div>
                        <div class="input-group input-group-lg" *ngIf="registerTeam && participants == 5">
                            <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large" formControlName="levelStudyTeam5">
                        </div>
                        <div *ngIf="submitted && kidsZacatecasAccept && registerTeam && participants == 5 && formFields.levelStudyTeam5.errors" class="errors pt-1">
                            <small *ngIf="formFields.levelStudyTeam5.errors.required" class="text-danger animated fadeIn">
                        Campo requerido.
                        </small>
                        </div>
                        <div class="mt-2 input-group input-group-lg" *ngIf="registerTeam && participants == 5">
                            <label *ngIf="registerTeam && kidsZacatecasAccept">Escuela</label>
                        </div>
                        <div class="input-group input-group-lg" *ngIf="registerTeam && participants == 5">
                            <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large" formControlName="school5">
                        </div>
                        <div *ngIf="submitted && kidsZacatecasAccept && registerTeam && participants == 5 && formFields.school5.errors" class="errors pt-1">
                            <small *ngIf="formFields.school5.errors.required" class="text-danger animated fadeIn">
                        Campo requerido.
                        </small>
                        </div>

                        <!-- Fin Integrante 5 -->

                        <div class="mt-4 input-group input-group-lg" *ngIf="(registerSon || registerTeam)">
                            <label *ngIf="registerSon && kidsZacatecasAccept">¿Qué temas le apasionan a tu hijo (a)?</label>
                            <label *ngIf="registerTeam && kidsZacatecasAccept">¿Qué temas le apasionan al equipo?</label>
                        </div>
                        <div class="input-group input-group-lg" *ngIf="registerSon || registerTeam">
                            <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large" formControlName="theme">
                        </div>
                        <div *ngIf="submitted && kidsZacatecasAccept && (registerSon || registerTeam) && formFields.theme.errors" class="errors pt-1">
                            <small *ngIf="formFields.theme.errors.required" class="text-danger animated fadeIn">
        Campo requerido.
      </small>
                        </div>

                        <div class="mt-4 input-group input-group-lg" *ngIf="(registerSon || registerTeam)">
                            <label *ngIf="registerSon && kidsZacatecasAccept">¿Porque estás interesado en este programa para tu hijo (a)?:</label>
                            <label *ngIf="registerTeam && kidsZacatecasAccept">¿Porque estás interesado en este programa para el equipo?</label>
                        </div>
                        <div class="input-group input-group-lg" *ngIf="registerSon || registerTeam">
                            <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large" formControlName="interest">
                        </div>
                        <div *ngIf="submitted && kidsZacatecasAccept && (registerSon || registerTeam) && formFields.interest.errors" class="errors pt-1">
                            <small *ngIf="formFields.interest.errors.required" class="text-danger animated fadeIn">
          Campo requerido.
        </small>
                        </div>
                        <div class="mt-4 input-group input-group-lg" *ngIf="(registerSon || registerTeam)">
                            <label for="" *ngIf="registerSon && kidsZacatecasAccept">Actualmente tu hijo (a) ¿tiene algún emprendimiento?</label>
                            <label for="" *ngIf="registerTeam && kidsZacatecasAccept">Actualmente el equipo ¿tiene algún emprendimiento?</label>
                        </div>
                        <div class="input-group input-group-lg" *ngIf="registerSon || registerTeam">
                            <ng-select [items]="entrepreneurshipsList" bindLabel="name" class="form-control" [clearable]="false" placeholder="Seleccionar" (change)="onEntrepreneurshipDetail()" formControlName="entrepreneurship" notFoundText="No se encontraron resultados" loadingText="Cargando..."
                                bindValue="id">
                            </ng-select>
                        </div>
                        <div *ngIf="submitted && kidsZacatecasAccept && (registerSon || registerTeam) && formFields.entrepreneurship.errors" class="errors pt-1">
                            <small *ngIf="formFields.entrepreneurship.errors.required" class="text-danger animated fadeIn">
          Campo requerido.
        </small>
                        </div>
                        <div class="mt-4 input-group input-group-lg" *ngIf="(registerSon || registerTeam) && showTextareaDetail">
                            <label for="entrepreneurshipDetail">Platicamos brevemente de qué trata: (máximo 250 caracteres )</label>
                        </div>
                        <div class="input-group input-group-lg" *ngIf="(registerSon || registerTeam) && showTextareaDetail">
                            <textarea type="text" class="form-control inpts_posible border-rounded" aria-label="Large" maxlength="250" rows="5" formControlName="entrepreneurshipDetail"></textarea>
                        </div>
                        <div class="txt_advice text-right" *ngIf="(registerSon || registerTeam) && showTextareaDetail">
                            <p class="mt-2" *ngIf="countChars(formFields.entrepreneurshipDetail.value) == 0"> Máx 250 caracteres</p>
                            <p class="mt-2" *ngIf="countChars(formFields.entrepreneurshipDetail.value) >= 1 && countChars(formFields.entrepreneurshipDetail.value) < 250">
                                {{250 - countChars(formFields.entrepreneurshipDetail.value) }} caracteres faltantes.</p>
                            <p class="mt-2 text-danger" *ngIf="countChars(formFields.entrepreneurshipDetail.value) == 250"> Máximo de caracteres alcanzado.
                            </p>
                        </div>
                        <div *ngIf="submitted && kidsZacatecasAccept && (registerSon || registerTeam) && formFields.entrepreneurshipDetail.errors" class="errors pt-1">
                            <small *ngIf="formFields.entrepreneurshipDetail.errors.required" class="text-danger animated fadeIn">
          Campo requerido.
        </small>
                        </div>
                        <div class="mt-4 input-group input-group-lg text-left" *ngIf="(registerSon || registerTeam)">
                            <label for="">¿Cual de los siguientes NODOS es el más cercano a su entidad
                          para asistir al programa de manera presencial?</label>
                        </div>
                        <div class="input-group input-group-lg" *ngIf="(registerSon || registerTeam)">
                            <ng-select [items]="nodesList" bindLabel="name" class="form-control" [clearable]="false" placeholder="Seleccionar" formControlName="assistanceNode" notFoundText="No se encontraron resultados" loadingText="Cargando..." bindValue="id">
                            </ng-select>
                        </div>
                        <div *ngIf="submitted && kidsZacatecasAccept && (registerSon || registerTeam) && formFields.assistanceNode.errors" class="errors pt-1">
                            <small *ngIf="formFields.assistanceNode.errors.required" class="text-danger animated fadeIn">
        Campo requerido.
      </small>
                        </div>


                        <!-- fin reto kids zacatecas-->

                        <!-- Reto posible chiapas -->
                        <!-- <div *ngIf="chiapas" class="pt-2">
                            <p class="" style="font-size: 1rem; text-align: left; margin-bottom: 0.25rem;">
                                <b>¿Quieres postular a Chiapas, Tierra de Emprendedores ?</b><br>
                                <span>
                                    Buscamos inspirar el espíritu emprendedor y las iniciativas innovadoras,
                                     a través de la selección de los 15 mejores emprendimientos que representen
                                      el talento de la población chiapaneca. <b><a href="assets/Convocatoria_Chiapas_Tierra_de_Emprendedores.pdf" target="_blank">Consulta la convocatoria</a></b>
                                </span>
                            </p>
                        </div>
                        <div class="input-group input-group-lg" *ngIf="chiapas">
                            <div class="d-flex flex-row">
                                <div class="form-check mr-4">
                                    <input class="form-check-input" type="radio" name="retoChiapasEmprendedores" [value]="true" formControlName="retoChiapasEmprendedores" (change)="retoChiapasAccept(true)">
                                    <label class="form-check-label">
                  <span class="txt_common_bold"> Si </span>
                </label>
                                </div>
                                <div class="form-check mr-4">
                                    <input class="form-check-input" type="radio" name="retoChiapasEmprendedores" [value]="false" formControlName="retoChiapasEmprendedores" (change)="retoChiapasAccept(false)">
                                    <label class="form-check-label">
                  <span class="txt_common_bold"> No </span>
                </label>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="submitted && formFields.retoChiapasEmprendedores.errors" class="errors pt-1">
                            <small *ngIf="formFields.retoChiapasEmprendedores.errors.required" class="text-danger animated fadeIn">
              Campo requerido.
            </small>
                        </div>

                        <div class="mt-4 input-group input-group-lg" *ngIf="chiapasAccept">
                            <textarea type="text" class="form-control inpts_posible border-rounded" name="tellEntrepreneurship" id="" rows="4" maxlength="250" placeholder="Cuéntanos brevemente sobre tu emprendimiento" formControlName="tellEntrepreneurship"></textarea>
                        </div>
                        <div class="txt_advice text-right" *ngIf="chiapasAccept">
                            <p class="mt-2" *ngIf="countChars(formFields.tellEntrepreneurship.value) == 0"> Máx 250 caracteres</p>
                            <p class="mt-2" *ngIf="countChars(formFields.tellEntrepreneurship.value) >= 1 && countChars(formFields.tellEntrepreneurship.value) < 250">
                                {{250 - countChars(formFields.tellEntrepreneurship.value) }} caracteres faltantes.</p>
                            <p class="mt-2 text-danger" *ngIf="countChars(formFields.tellEntrepreneurship.value) == 250"> Máximo de caracteres alcanzado.
                            </p>
                        </div>
                        <div *ngIf="submitted && chiapasAccept &&formFields.tellEntrepreneurship.errors" class="errors pt-1">
                            <small *ngIf="formFields.tellEntrepreneurship.errors.required" class="text-danger animated fadeIn">
            Campo requerido.
          </small>
                        </div>
                        <div class="mt-4 input-group input-group-lg" *ngIf="chiapasAccept">
                            <label for="useScholarship" class="text-left">En caso de ser seleccionado para la beca “Chiapas, Tierra de Emprendedores” ¿Cómo la utilizarías para impulsar tu emprendimiento? </label>
                            <textarea type="text" class="form-control inpts_posible border-rounded" name="useScholarship" id="" rows="4" maxlength="250" placeholder="" formControlName="useScholarship"></textarea>
                        </div>
                        <div class="txt_advice text-right" *ngIf="chiapasAccept">
                            <p class="mt-2" *ngIf="countChars(formFields.useScholarship.value) == 0"> Máx 250 caracteres</p>
                            <p class="mt-2" *ngIf="countChars(formFields.useScholarship.value) >= 1 && countChars(formFields.useScholarship.value) < 250">
                                {{250 - countChars(formFields.useScholarship.value) }} caracteres faltantes.</p>
                            <p class="mt-2 text-danger" *ngIf="countChars(formFields.useScholarship.value) == 250"> Máximo de caracteres alcanzado.
                            </p>
                        </div>
                        <div *ngIf="submitted && chiapasAccept &&formFields.useScholarship.errors" class="errors pt-1">
                            <small *ngIf="formFields.useScholarship.errors.required" class="text-danger animated fadeIn">
            Campo requerido.
          </small>
                        </div> -->

                        <!-- <div class="mt-4 input-group input-group-lg" *ngIf="chiapasAccept">
                            <ng-select class="form-control inpts_posible border-rounded" placeholder="Ocupación" formControlName="ocupacionReto" [clearable]="false" notFoundText="No se encontraron resultados" loadingText="Cargando..." (change)="ocupacionReto()">
                                <ng-option *ngFor="let puesto of ocupacion" [value]="puesto.id">{{puesto.descripcion}}</ng-option>
                            </ng-select>
                        </div>
                        <div *ngIf="submitted && chiapasAccept &&formFields.ocupacionReto.errors" class="errors pt-1">
                            <small *ngIf="formFields.ocupacionReto.errors.required" class="text-danger animated fadeIn">
              Campo requerido.
            </small>
                        </div>

                        <div class="mt-4 input-group input-group-lg" *ngIf="(ocupacionSelect == 1) && chiapasAccept">
                            <ng-select class="form-control inpts_posible border-rounded" placeholder="Horas frente a grupo a la semana" formControlName="horasReto" [clearable]="false" notFoundText="No se encontraron resultados" loadingText="Cargando...">
                                <ng-option *ngFor="let hora of horas" [value]="hora.id">{{hora.descripcion}}</ng-option>
                            </ng-select>
                        </div>
                        <div *ngIf="submitted && chiapasAccept && formFields.horasReto.errors" class="errors pt-1">
                            <small *ngIf="formFields.horasReto.errors.required" class="text-danger animated fadeIn">
              Campo requerido.
            </small>
                        </div>

                        <div class="mt-4 input-group input-group-lg" *ngIf="(ocupacionSelect > 1) && chiapasAccept">
                            <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large" placeholder="Puesto" formControlName="puestoReto">
                        </div>
                        <div *ngIf="submitted && chiapasAccept && formFields.puestoReto.errors" class="errors pt-1">
                            <small *ngIf="formFields.puestoReto.errors.required" class="text-danger animated fadeIn">
              Campo requerido.
            </small>
                        </div>

                        <div class="mt-4 input-group input-group-lg" *ngIf="(ocupacionSelect > 0) && chiapasAccept">
                            <ng-select class="form-control inpts_posible border-rounded" placeholder="Antigüedad en este puesto" formControlName="antiguedadReto" [clearable]="false" notFoundText="No se encontraron resultados" loadingText="Cargando...">
                                <ng-option *ngFor="let rango of antiguo" [value]="rango.id">{{rango.descripcion}}</ng-option>
                            </ng-select>
                        </div>
                        <div *ngIf="submitted && chiapasAccept && formFields.antiguedadReto.errors" class="errors pt-1">
                            <small *ngIf="formFields.antiguedadReto.errors.required" class="text-danger animated fadeIn">
              Campo requerido.
            </small>
                        </div>

                        <div class="mt-4 input-group input-group-lg" *ngIf="(ocupacionSelect == 1) && chiapasAccept">
                            <ng-select class="form-control inpts_posible border-rounded" placeholder="Materia que impartes" formControlName="materiaReto" [clearable]="false" notFoundText="No se encontraron resultados" loadingText="Cargando..." (change)=materiaReto()>
                                <ng-option *ngFor="let materia of tema" [value]="materia.id">{{materia.descripcion}}</ng-option>
                            </ng-select>
                        </div>
                        <div *ngIf="submitted && chiapasAccept && formFields.materiaReto.errors" class="errors pt-1">
                            <small *ngIf="formFields.materiaReto.errors.required" class="text-danger animated fadeIn">
              Campo requerido.
            </small>
                        </div>

                        <div class="mt-4 input-group input-group-lg" *ngIf="(ocupacionSelect == 1) && (materiaSelect == 4) && chiapasAccept">
                            <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large" placeholder="Materia" formControlName="otraMateriaReto">
                        </div>
                        <div *ngIf="submitted && chiapasAccept && formFields.otraMateriaReto.errors" class="errors pt-1">
                            <small *ngIf="formFields.otraMateriaReto.errors.required" class="text-danger animated fadeIn">
              Campo requerido.
            </small>
                        </div>

                        <div class="mt-4 input-group input-group-lg" *ngIf="(ocupacionSelect > 0) && chiapasAccept">
                            <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large" placeholder="¿Por qué te interesa ser parte de Reto POSIBLE Chiapas?" formControlName="interesReto">
                        </div>
                        <div *ngIf="submitted && chiapasAccept && formFields.interesReto.errors" class="errors pt-1">
                            <small *ngIf="formFields.interesReto.errors.required" class="text-danger animated fadeIn">
              Campo requerido.
            </small>
                        </div> -->


                        <!-- Fin reto posible chiapas -->
                        <!-- Reto posible oaxaca -->
                        <!-- <div *ngIf="oaxaca" class="pt-2">
                            <p class="" style="font-size: 1rem; text-align: left; margin-bottom: 0.25rem;">
                                <b>¿Quieres participar en Reto POSiBLE Oaxaca 2022?</b><br>
                                <span>
                                Reto POSiBLE Oaxaca es un programa que ofrece los conocimientos, 
                                las herramientas y capacitación a formadores y personal vinculado a 
                                incubadoras a nivel estatal, para impulsarlos en la formación de emprendedores de alto impacto. 
                                Convocatoria: <b><a href="assets/CONVOCATORIA_RETO_DOCENTES.pdf" target="_blank">Convocatoria Reto Docentes</a></b>
                              </span>
                            </p>
                        </div>
                        <div class="input-group input-group-lg" *ngIf="oaxaca">
                            <div class="d-flex flex-row">
                                <div class="form-check mr-4">
                                    <input class="form-check-input" type="radio" name="retoOaxaca" [value]="true" formControlName="retoOaxaca" (change)="retoAccept(true)">
                                    <label class="form-check-label">
                    <span class="txt_common_bold"> Si </span>
                  </label>
                                </div>
                                <div class="form-check mr-4">
                                    <input class="form-check-input" type="radio" name="retoOaxaca" [value]="false" formControlName="retoOaxaca" (change)="retoAccept(false)">
                                    <label class="form-check-label">
                    <span class="txt_common_bold"> No </span>
                  </label>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="submitted && formFields.retoOaxaca.errors" class="errors pt-1">
                            <small *ngIf="formFields.retoOaxaca.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
                        </div>
                        <div class="mt-4 input-group input-group-lg" *ngIf="oaxacaAccept">
                            <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large" placeholder="¿De qué Instituto educativo formas parte?" formControlName="institutoEducativo">
                        </div>
                        <div *ngIf="submitted && oaxacaAccept && formFields.institutoEducativo.errors" class="errors pt-1">
                            <small *ngIf="formFields.institutoEducativo.errors.required" class="text-danger animated fadeIn">
              Campo requerido.
            </small>
                        </div>

                        <div class="mt-4 input-group input-group-lg" *ngIf="oaxacaAccept">
                            <ng-select class="form-control inpts_posible border-rounded" placeholder="Ocupación" formControlName="ocupacionReto" [clearable]="false" notFoundText="No se encontraron resultados" loadingText="Cargando..." (change)="ocupacionReto()">
                                <ng-option *ngFor="let puesto of ocupacion" [value]="puesto.id">{{puesto.descripcion}}</ng-option>
                            </ng-select>
                        </div>
                        <div *ngIf="submitted && oaxacaAccept && formFields.ocupacionReto.errors" class="errors pt-1">
                            <small *ngIf="formFields.ocupacionReto.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
                        </div>

                        <div class="mt-4 input-group input-group-lg" *ngIf="(ocupacionSelect == 1) && oaxacaAccept">
                            <ng-select class="form-control inpts_posible border-rounded" placeholder="Horas frente a grupo a la semana" formControlName="horasReto" [clearable]="false" notFoundText="No se encontraron resultados" loadingText="Cargando...">
                                <ng-option *ngFor="let hora of horas" [value]="hora.id">{{hora.descripcion}}</ng-option>
                            </ng-select>
                        </div>
                        <div *ngIf="submitted && oaxacaAccept && formFields.horasReto.errors" class="errors pt-1">
                            <small *ngIf="formFields.horasReto.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
                        </div>

                        <div class="mt-4 input-group input-group-lg" *ngIf="(ocupacionSelect > 1) && oaxacaAccept">
                            <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large" placeholder="Puesto" formControlName="puestoReto">
                        </div>
                        <div *ngIf="submitted && oaxacaAccept && formFields.puestoReto.errors" class="errors pt-1">
                            <small *ngIf="formFields.puestoReto.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
                        </div>

                        <div class="mt-4 input-group input-group-lg" *ngIf="(ocupacionSelect > 0) && oaxacaAccept">
                            <ng-select class="form-control inpts_posible border-rounded" placeholder="Antigüedad en este puesto" formControlName="antiguedadReto" [clearable]="false" notFoundText="No se encontraron resultados" loadingText="Cargando...">
                                <ng-option *ngFor="let rango of antiguo" [value]="rango.id">{{rango.descripcion}}</ng-option>
                            </ng-select>
                        </div>
                        <div *ngIf="submitted && oaxacaAccept && formFields.antiguedadReto.errors" class="errors pt-1">
                            <small *ngIf="formFields.antiguedadReto.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
                        </div>

                        <div class="mt-4 input-group input-group-lg" *ngIf="(ocupacionSelect == 1) && oaxacaAccept">
                            <ng-select class="form-control inpts_posible border-rounded" placeholder="Materia que impartes" formControlName="materiaReto" [clearable]="false" notFoundText="No se encontraron resultados" loadingText="Cargando..." (change)=materiaReto()>
                                <ng-option *ngFor="let materia of tema" [value]="materia.id">{{materia.descripcion}}</ng-option>
                            </ng-select>
                        </div>
                        <div *ngIf="submitted && oaxacaAccept && formFields.materiaReto.errors" class="errors pt-1">
                            <small *ngIf="formFields.materiaReto.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
                        </div>

                        <div class="mt-4 input-group input-group-lg" *ngIf="(ocupacionSelect == 1) && (materiaSelect == 4) && oaxacaAccept">
                            <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large" placeholder="Materia" formControlName="otraMateriaReto">
                        </div>
                        <div *ngIf="submitted && oaxacaAccept && formFields.otraMateriaReto.errors" class="errors pt-1">
                            <small *ngIf="formFields.otraMateriaReto.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
                        </div>

                        <div class="mt-4 input-group input-group-lg" *ngIf="(ocupacionSelect > 0) && oaxacaAccept">
                            <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large" placeholder="¿Por qué te interesa ser parte de Reto POSIBLE Oaxaca?" formControlName="interesReto">
                        </div>
                        <div *ngIf="submitted && formFields.interesReto.errors" class="errors pt-1">
                            <small *ngIf="formFields.interesReto.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
                        </div> -->
                        <!-- fin reto oaxaca -->


                        <div class="mt-4 input-group input-group-lg">
                            <ng-select class="form-control inpts_posible border-rounded" placeholder="Educación" formControlName="educacionNivel" [clearable]="false" notFoundText="No se encontraron resultados" loadingText="Cargando...">
                                <ng-option *ngFor="let education of educationsType | keyvalue" [value]="educations[education.value]">{{education.value}}</ng-option>
                            </ng-select>
                        </div>
                        <div *ngIf="submitted && formFields.educacionNivel.errors" class="errors pt-1">
                            <small *ngIf="formFields.educacionNivel.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
                        </div>

                        <div class="mt-4 input-group input-group-lg" *ngIf="formFields.educacionNivel.value == educations['Licenciatura'] || formFields.educacionNivel.value == educations['Posgrado']">
                            <div class="d-flex flex-row">
                                <div class="form-check mr-4" *ngFor="let type of schoolTypes">
                                    <input class="form-check-input" type="radio" name="tipoEscuela" [value]="type.id" formControlName="tipoEscuela">
                                    <label class="form-check-label" [for]="type.description">
                    <span class="txt_common_bold"> {{ type.description }} </span>
                  </label>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="submitted && formFields.tipoEscuela.errors" class="errors pt-1">
                            <small *ngIf="formFields.tipoEscuela.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
                        </div>
                        <div class="mt-4 input-group input-group-lg" *ngIf="((formFields.tipoEscuela.value == false) && (formFields.educacionNivel.value == educations['Licenciatura'] || formFields.educacionNivel.value == educations['Posgrado']) && formFields.tipoEscuela.pristine == false)">
                            <div class="d-flex flex-row">
                                <div class="form-check mr-4" *ngFor="let type of specifyPublic">
                                    <input class="form-check-input" type="radio" name="especificaEscuela" [value]="type.id" formControlName="especificaEscuela">
                                    <label class="form-check-label" [for]="type.description">
                    <span class="txt_common_bold"> {{ type.description }} </span>
                  </label>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="submitted && formFields.especificaEscuela.errors" class="errors pt-1">
                            <small *ngIf="formFields.especificaEscuela.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
                        </div>

                        <div class="mt-4 input-group input-group-lg" *ngIf="formFields.educacionNivel.value == educations['Tecnológico Nacional de México'] || ((formFields.especificaEscuela.value == true) && (formFields.educacionNivel.value == educations['Licenciatura'] || formFields.educacionNivel.value == educations['Posgrado']) && formFields.especificaEscuela.pristine == false)">
                            <ng-select [items]="tech_schools" formControlName="tecnologicos" class="form-control inpts_posible border-rounded" [virtualScroll]="true" [loading]="loading" notFoundText="No se encontraron resultados" loadingText="Cargando..." placeholder="Elija una institución"
                                [clearOnBackspace]="false" clearAllText="Quitar" (open)="resetQuery()" (scrollToEnd)="onScrollToEnd()" bindValue="id" bindLabel="name" [typeahead]="select$" (clear)="resetQuery()">
                            </ng-select>
                        </div>
                        <div *ngIf="submitted && formFields.tecnologicos.errors" class="errors pt-1">
                            <small *ngIf="formFields.tecnologicos.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
                        </div>

                        <div class="mt-4 input-group input-group-lg" *ngIf="(((formFields.especificaEscuela.value == false) && (formFields.especificaEscuela.pristine == false)) || ((formFields.tipoEscuela.value == true) && (formFields.tipoEscuela.pristine == false))) && (formFields.educacionNivel.value == educations['Licenciatura'] || formFields.educacionNivel.value == educations['Posgrado'])">
                            <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large" placeholder="Institución" formControlName="institucion">
                        </div>
                        <div *ngIf="submitted && formFields.institucion.errors" class="errors pt-1">
                            <small *ngIf="formFields.institucion.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
                        </div>

                        <div *ngIf="formFields.educacionNivel.value == educations['Tecnológico Nacional de México'] || ((formFields.tipoEscuela.value == false) && formFields.educacionNivel.value == educations['Licenciatura'] && formFields.tipoEscuela.pristine == false)" class="pt-2">
                            <p class="" style="font-size: 1rem; text-align: left; margin-bottom: 0.25rem;">
                                ¿Eres estudiante universitario?
                            </p>
                        </div>
                        <div class="input-group input-group-lg" *ngIf="formFields.educacionNivel.value == educations['Tecnológico Nacional de México'] || ((formFields.tipoEscuela.value == false) && formFields.educacionNivel.value == educations['Licenciatura'] && formFields.tipoEscuela.pristine == false)">
                            <div class="d-flex flex-row">
                                <div class="form-check mr-4">
                                    <input class="form-check-input" type="radio" name="universitario" [value]="true" formControlName="universitario">
                                    <label class="form-check-label">
                    <span class="txt_common_bold"> Sí </span>
                  </label>
                                </div>
                                <div class="form-check mr-4">
                                    <input class="form-check-input" type="radio" name="universitario" [value]="false" formControlName="universitario">
                                    <label class="form-check-label">
                    <span class="txt_common_bold"> No </span>
                  </label>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="submitted && formFields.universitario.errors && (formFields.educacionNivel.value == educations['Licenciatura'] || formFields.educacionNivel.value == educations['Tecnológico Nacional de México'])" class="errors pt-1">
                            <small *ngIf="formFields.universitario.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
                        </div>

                        <div class="mt-4 input-group input-group-lg">
                            <ng-select [items]="heardSource" bindLabel="name" class="form-control" placeholder="¿Cómo conociste POSIBLE?" [clearable]="false" formControlName="heardFrom" notFoundText="No se encontraron resultados" loadingText="Cargando..." bindValue="id">
                            </ng-select>
                        </div>
                        <div *ngIf="submitted && formFields.heardFrom.errors" class="errors pt-1">
                            <small *ngIf="formFields.heardFrom.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
                        </div>

                        <div class="mt-4 input-group input-group-lg" *ngIf="formFields.heardFrom.value == 9">
                            <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large" placeholder="¿Dónde?" formControlName="otroHeard">
                        </div>
                        <div *ngIf="submitted && formFields.otroHeard.errors" class="errors pt-1">
                            <small *ngIf="formFields.otroHeard.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
                        </div>


                        <div class="mt-3 input-group input-group-lg">
                            <input [type]="show_pass ? 'text': 'password' " class="form-control inpts_posible border-rounded" aria-label="Large" placeholder="Contraseña" formControlName="pass1" (click)="showMessage(1)">
                            <div class="input-group-append">
                                <span class="input-group-text" (click)="show_pass = !show_pass">
                  <i class="fa" [ngClass]="show_pass? 'fa-eye': 'fa-eye-slash'"></i>
                </span>
                            </div>
                            <label for="pass1" *ngIf="messagePass == true" class="text-left mb-0 mt-1 text-danger"><b>La contraseña es solo para uso de la plataforma, no estamos solicitando ninguna contraseña de correo electrónico ni de ninguna red social.</b></label>
                        </div>
                        <div *ngIf="submitted && formFields.pass1.errors" class="errors pt-1">
                            <small *ngIf="formFields.pass1.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
                            <small *ngIf="formFields.pass1.errors.minlength" class="text-danger animated fadeIn">
                Debe de contener mínimo 4 caracteres.
              </small>
                            <small *ngIf="formFields.pass1.errors.pattern" class="text-danger animated fadeIn">
                              Debe contener solo letras mayúsculas, minúsculas y números.
              </small>
                        </div>

                        <div class="mt-4 input-group input-group-lg">
                            <input [type]="show_repeatpass ? 'text': 'password' " class="form-control inpts_posible border-rounded" aria-label="Large" placeholder="Repetir contraseña" formControlName="pass2" (click)="showMessage(2)">
                            <div class="input-group-append">
                                <span class="input-group-text" (click)="show_repeatpass = !show_repeatpass">
                  <i class="fa" [ngClass]="show_repeatpass? 'fa-eye': 'fa-eye-slash'"></i>
                </span>
                            </div>
                        </div>
                        <div *ngIf="submitted && formFields.pass2.errors" class="errors pt-1">
                            <small *ngIf="formFields.pass2.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
                            <small *ngIf="formFields.pass2.errors.confirmedValidator" class="text-danger animated fadeIn">
                Las contraseñas no coinciden.
              </small>
                        </div>

                        <p class="text-left mt-4 pb-0">
                            <small>
                <input type="checkbox" class="form-input" name="terminos" formControlName="terminos"
                  [class.is-invalid]="terminosNoValido">
                <span class="txt_terms">
                  Al continuar, aceptas ser mayor de 18 años y haber leído y aceptado los <b>
                    <a class="link-style" [routerLink]="['/terminos']" target="_blank">Términos y
                      Condiciones</a> </b> y el <b>
                    <a class="link-style" [routerLink]="['/aviso-privacidad']" target="_blank">Aviso de privacidad</a> </b>.</span>
              </small>
                        </p>
                        <div *ngIf="submitted && formFields.terminos.errors" class="errors mb-1">
                            <small *ngIf="formFields.terminos.errors.required" class="text-danger animated fadeIn">
                ¡Debes de aceptar los términos y condiciones!
              </small>
                        </div>

                        <div class="form-group row mt-3">
                            <div class="input-group col">
                                <button type="submit" class="btn-lg btn btn-block btn-warning btn_continuar" (click)="save()" [disabled]="isLoading"> <span class="txt_btn_continuar"> <b> Continuar</b> </span></button>
                            </div>
                        </div>
                    </form>
                    <a class="text-center nav-link backText" [routerLink]="['/login']"><i class="fa fa-arrow-left"
              aria-hidden="true"></i> Regresar</a>
                    <img class="rounded mx-auto d-block" src="assets/img/company.png" alt="">
                </div>
            </div>
            <!--card body-->
        </div>
        <!-- card main-->
    </div>
</div>