import { Component, Input, OnInit } from '@angular/core';
import { ShortPublication } from 'src/app/models/short-publication/short-publication';

@Component({
  selector: 'app-short-publication',
  templateUrl: './short-publication.component.html',
  styleUrls: ['./short-publication.component.scss']
})
export class ShortPublicationComponent implements OnInit {
  @Input() publication: ShortPublication;
  constructor() { }

  ngOnInit(): void {
  }

}
