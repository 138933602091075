import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SupportMaterialService } from 'src/app/services/support-material/support-material.service';
import { SnackbarService } from 'src/app/services/notifications/snackbar.service';
import { SupportMaterial } from 'src/app/models/SupportMaterial/support-material';
import { Location } from '@angular/common';

@Component({
  selector: 'app-support-module',
  templateUrl: './support-module.component.html',
  styleUrls: ['./support-module.component.scss']
})
export class SupportModuleComponent implements OnInit{
  // Used for get support material by module
  moduleId: any;
  // All data to display support material on view
  supportMaterialData: SupportMaterial;


  constructor(private route: ActivatedRoute, private supportMaterialService: SupportMaterialService, private snackBarService: SnackbarService, private _location: Location) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      if(params.get("moduleId") ) {
        this.moduleId = params.get("moduleId");
        this.getSupportMaterial();
      }
    });
  }

  // Http request to get support material by id module
  getSupportMaterial(): void {
    this.supportMaterialService.getSupportMaterial(this.moduleId).then(data => {
      if(data['results']) {
        this.supportMaterialData = new SupportMaterial(data['results']);
      }
    }).catch(error =>{
      this.snackBarService.showSnackBar('Cerrar', `${error} ⚠️`);
      this.returnToBack();
    });
  }

  // Return to the previous page
  returnToBack(): void {
    this._location.back();
  }

  /**
  * Method to open in new tab a psot url
  * @param url - Url to open on new tab
  */
  goToPost(url: string ): void {
    window.open(url, '_blank');
  }




}
