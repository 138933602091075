<div class="main-container row">
    <div class="container-header px-4 col-12" [ngClass]="{'bg-movil': isEdomex}" style="background-image: url({{currentMicroSiteStateInfo['bg']}})">
        <div class="content-header">
            <header>
                <img [src]="currentMicroSiteStateInfo['logo']" class="col-sm-12 col-md-3 header-img mb-5" *ngIf="currentMicroSiteStateInfo['header']">
                <div class="txt_title">¡Tú puedes ser el próximo emprendedor!</div>
            </header>
            <section class="mt-3">
                <p class="txt_common" [innerHTML]="currentMicroSiteStateInfo['text']"></p>
                <div class="mt-5 animated fadeIn fast">
                    <a class="button-register" [routerLink]="['/crear-cuenta']">¡Regístrate ahora!</a>
                </div>
                <div class="mt-5 animated fadeIn fast">
                    <a class="button-login" [routerLink]="['/login']">Inicia sesión aquí</a>
                </div>
                <div class="row mt-5">
                    <div class="col-12 col-md-6 col-lg-5 col-xl-3">
                        <a class="btn btn-paneles pt-3 pb-3" href="assets/Folios_seleccionados_para_paneles_estatales_2022.pdf" target="_blank">Proyectos seleccionados para paneles</a>
                    </div>
                </div>
            </section>
        </div>
        <h3 class="name-project">{{currentMicroSiteStateInfo['name']}}</h3>
    </div>
    <section id="logos">
        <div class="row align-items-center">
            <div class="col-sm-12 pt-4 pb-4" [ngClass]="{'col-md-3': currentMicroSiteStateInfo['logo'], 'col-md-4': !currentMicroSiteStateInfo['logo']}">
                <img src="assets/img/landing/logo-monte-red.png" class="logo-img-monte">
            </div>
            <div class="col-sm-12 pt-4 pb-4" [ngClass]="{'col-md-3': currentMicroSiteStateInfo['logo'], 'col-md-4': !currentMicroSiteStateInfo['logo']}">
                <img src="assets/img/landing/logo-posible.png" class="logo-img-posible">
            </div>
            <div class="col-md-3 col-sm-12 pt-4 pb-4" *ngIf="currentMicroSiteStateInfo['logo']">
                <img [src]="currentMicroSiteStateInfo['logo']" class="logo-img-edo">
            </div>
            <div class="col-sm-12 pt-4 pb-4" [ngClass]="{'col-md-3': currentMicroSiteStateInfo['logo'], 'col-md-4': !currentMicroSiteStateInfo['logo']}">
                <img src="assets/img/landing/logo-televisa.png" class="logo-img-televisa">
            </div>
        </div>
    </section>

    <section id="campaings" class="mt-4">
        <h2>Conoce nuestros diferentes programas</h2>
        <div class="row">
            <div class="col-6 mt-3" *ngFor="let data of benefitsCampaings" (click)="selectCampaign(data['title'], data['slide'])">
                <img [src]="data['backgroundImg']" class="container-campaing" [ngClass]="{'opacity': selectedCampaing != data['title'], 'selected-campaing-shadow': selectedCampaing == data['title']}">
            </div>
        </div>
        <div class="row campaing-detail" [ngClass]="{'campaing-zacatecas': isZacatecas}" *ngFor="let data of benefitsCampaings" [hidden]="selectedCampaing != data['title']">
            <div class="border"></div>
            <div class="col mx-2">
                <h2 class="title">{{data['title']}}</h2>
                <h3 class="subtitle">{{data['subtitle']}}</h3>
                <p class="description">{{data['description']}}</p>
                <div class="col-12 mt-4" *ngIf="data['agenda']">
                    <a [href]="data['agendaUrl']" target="_blank" class="col-3" [ngClass]="{'taller-zacatecas': isZacatecas}">Agenda de Talleres 2022</a>
                </div>
                <h3 [ngClass]="{'mt-4': data['agenda']}" class="benefits-title">Beneficios del programa</h3>
                <div class="benefits-container">
                    <div class="row mt-3" *ngFor="let benefit of data['benefits']">
                        <div class="col-md-4 text-center">
                            <img [src]="benefit['img']" class="benefits-img">
                        </div>
                        <div class="col-md-8">
                            <div class="benefits-text">
                                <h2 style="color: black !important;">{{benefit['title']}}</h2>
                                <span [innerHTML]="benefit['text']"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section id="process">
        <ngb-carousel #processCarousel interval="0">
            <ng-template ngbSlide *ngFor="let process of processCampaings" [id]="process['title']">
                <div class="row">
                    <h2 class="text-center">Proceso de aplicación {{process['campaing']}}</h2>
                    <div class="col-md-6 col-xs-12 mt-5" *ngFor="let processItem of process['process']">
                        <div class="process-item">
                            <span>{{processItem['number']}}</span>
                            <img [src]="processItem['img']" class="process-image">
                            <h3>{{processItem['title']}}</h3>
                            <p [innerHTML]="processItem['text']"></p>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ngb-carousel>
    </section>
    <section id="videos">
        <h2>Videos</h2>
        <div class="videos-container">
            <div class="row video-item">
                <div class="col-xs-12 col-md-12 col-lg-6">
                    <div class="video-youtube">
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/6tiT8NojKi8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
                <div class="col-xs-12 col-md-12 col-lg-6">
                    <p>¡Registrarte en nuestra plataforma es muy sencillo! Este video corto te mostrará paso a paso como hacerlo</p>
                </div>
            </div>
            <div class="row video-item mt-4">
                <div class="col-xs-12 col-md-12 col-lg-6">
                    <div class="video-youtube">
                        <iframe width="100%" height="100%" class="video-youtube" src="https://www.youtube.com/embed/lElP-GHnqeU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
                <div class="col-xs-12 col-md-12 col-lg-6">
                    <p>Este video te muestra como dar de alta tu idea o proyecto. ¡Es esencial para participar en nuestra convocatoria Posible 2022!</p>
                </div>
            </div>
        </div>
        <div class="row video-item mt-5">
            <div class="col">
                <a class="button" href="https://youtube.com/playlist?list=PLUO2cKLu8xMDwg4aLK3cO3Ltad9qk3iow" target="_blank">Ver todos los videos</a>
            </div>
        </div>
    </section>
    <footer>
        <div class="row">
            <a [routerLink]="['/terminos']">Términos y condiciones</a>
            <a [routerLink]="['/preguntas']">Preguntas frecuentes</a>
            <a [routerLink]="['/aviso-privacidad']">Aviso de privacidad</a>
            <a href="assets/convocatoria-2022.pdf" target="_blank">Convocatoria</a>
        </div>
        <div class="row mt-5">
            <div class="col-12">
                <img src="assets/img/landing/logo-monte.png">
                <img src="assets/img/landing/logo-televisa-blanco.png">
            </div>
        </div>
    </footer>
</div>
<!-- main-container-->