<div class="card-body comment">
  <div class="row">
    <div class="col-2">
      <img *ngIf="comment.userPicture && !comment.feedPosible else noImg" class="img-circle" height="45px" width="45px"
        [src]="comment.userPicture">
      <ng-template #noImg>
        <img class="img-circle" *ngIf="!comment.feedPosible" height="45px" width="45px" src="assets/img/home/profile_img.png" alt="">
        <img class="img-circle" *ngIf="comment.feedPosible" height="45px" width="45px" src="assets/img/home/posibleAdmin.png" alt="">
      </ng-template>
      <span class="online"></span>
    </div>
    <div class="col-10" *ngIf="comment.feedPosible">
      <span class="comments__company mb-0">Team Posible</span>
    </div>
    <div class="col-10" *ngIf="!comment.feedPosible">
      <span class="comments__company">{{comment.company}}</span>
      <p class="comments__user">
        {{comment.username}}
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <p class="comments__date mb-0">
        {{comment.createdAt}}
      </p>
      <p class="comments__description">
        {{ (showMore) ? comment.description : comment.description | slice:0:300}}
        <span class="font-weight-bold cursor__pointer d-block" *ngIf="!showMore && comment.description.length >= 300"
          (click)="toggleShowMore()">
          Ver más
        </span>
      </p>
    </div>
  </div>
</div>
