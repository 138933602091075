<div class="container p-0">
  <app-dynamic-navigation [projectId]="projectId" [actualStep]="projectSteps.thirdStep"></app-dynamic-navigation>
  <app-stepsbar-progress [totalNumSteps]="totalNumSteps" [currentStep]="stepNumber" [modelProgress]="modelProgress" [stepsNav]="projectSteps"></app-stepsbar-progress>
  <div class="main-container animated fadeIn fast marginTop">
    <div class="card border-0 animated fadeIn fast" style="background-color: #F7F7F7;">
      <div class="card-body animated fadeIn">
        <div class="mt-2">
          <form autocomplete="off" [formGroup]="forma" (ngSubmit)="update()">
            <div class="form-group">
              <label for="ideaNegocio" class="subtitle text-left">1. Describe tu producto y/ o servicio y cómo funciona de manera general (no es necesario poner ningún dato confidencial, sino la información que consideres necesaria para que tengamos una idea clara del mismo). (600 caracteres) </label>
              <textarea class="form-control input-rounded" id="ideaNegocio" rows="3" aria-label="Large"
                formControlName="proceso" placeholder="Respuesta" maxlength="600"></textarea>
              <div class="txt_advice text-right">
                <p class="mt-2" *ngIf="countChars(formFields.proceso.value) == 0"> Máx 600 caracteres</p>
                <p class="mt-2"
                  *ngIf="countChars(formFields.proceso.value) >= 1 && countChars(formFields.proceso.value) < 600">
                  {{600 - countChars(formFields.proceso.value) }} caracteres faltantes.</p>
                <p class="mt-2" *ngIf="countChars(formFields.proceso.value) == 600"> Máximo de caracteres alcanzado.</p>
              </div>
            </div>
            <hr>
            <div class="form-group">
              <label for="" class="subtitle text-left">2. ¿Tu idea de negocio implica alguna de las siguientes opciones?</label>
              <br>
              <div class="my-4 form-check" *ngFor="let option of options; let i=index">
                <input class="form-check-input" type="radio" name="opciones" [value]="option.optionSolution_guid"
                  [id]="option.optionSolution_guid" [class.is-invalid]="opcionesNoValido" formControlName="opciones">

                <label class="form-check-label" [for]="option.optionSolution_guid">
                  <b> {{option.description.replace(regExp, '')}}</b>
                  <span *ngIf="option.description.match(regExp) != null">{{regExp.exec(option.description)[0]}}</span>
                </label>
              </div>
              <div style="text-align: center;">
                <small class="text-danger animated fadeIn" *ngIf="opcionesNoValido">
                  ¡¡ Se debe elegir una opción !!
                </small></div>
            </div>
            <button type="submit" class="btn-lg btn btn-block btn-warning btn_style">
              <span class="btn_style_text">Guardar y continuar</span>
            </button>
            <br><br>
          </form>
          <br>
        </div>
      </div>
      <!--card body-->
    </div> <!-- card main-->
  </div>
</div>
<!--main-container-->