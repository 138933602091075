<app-pending-businessmodels-modal></app-pending-businessmodels-modal>
<div *ngIf="isLoading; else elseLoading" class="d-flex justify-content-center mt-5">
  <mat-spinner diameter="60"></mat-spinner>
</div>
<ng-template #elseLoading>
  <div class="container px-0">
    <div class="container-fluid header_feed search">
      <div class="row align-items-center">
        <div class="col-10">
          <input type="text" name="search" id="search" placeholder="Buscar persona ..." [(ngModel)]="searchText">
        </div>
        <div class="col-2">
          <img src="assets/icons/times.png" (click)="searchText = ''">
        </div>
      </div>
    </div>
    <div class="container-fluid header_feed">
      <div class="row align-items-center">
        <div class="col-12 pt-4 text-center"
          *ngIf="(contacts | filter: ['first_name', 'last_name'] : searchText).length == 0">
          No se encontraron contactos
        </div>
        <div class="col-12 contact" *ngFor="let contact of contacts | filter: ['first_name', 'last_name'] : searchText"
          (click)="openChat(contact.contact_id)">
          <div class="row">
            <div class="col-2">
              <div *ngIf="!contact.image_path" class="img-placeholder"></div>
              <img *ngIf="contact.image_path" clang-template class="profile-image" height="56px" width="56px"
                [src]="contact.image_path">
                <span class="{{contact.status}}"></span>
            </div>
            <div class="col-10 align-self-center">
              <div class="d-flex">
                <p class="name">{{ contact.first_name }} {{ contact.last_name }}</p>
                <span class="date" *ngIf="contact.roomObject.last_message" >{{ contact.roomObject.last_activity | date: 'dd/MM' }} </span>
              </div>
              <p class="{{contact.roomObject.viewed==false ? 'new-message':'last-message'}}" >{{ (contact.roomObject.last_message || 'Iniciar conversación') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template></ng-template>
