import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from '../classes/global';

@Injectable({
  providedIn: 'root'
})
export class SchoolTechService {
  
  globals = Globals
  constructor(private httpClient: HttpClient) { }

  /**
   * Method that search for the available tech schools
   * @param searchText String that will be search 
   */
  async getTechnologics(searchText: string){
    return await this.httpClient.get(`${this.globals.urlPosibleBase}${this.globals.institutions.pathInstitutions}?${searchText}`).toPromise();
  }
}
