import { UploadingFile, uploadingFileType } from './fileList/uploading-file';

export class FileList {
    uploadingFiles: UploadingFile[] = [];

    constructor(){}

    /**
     * Add new file to the array
     * @returns the Id of the created file
     */
    addNewFile(type: uploadingFileType): UploadingFile {
        let newFile = new UploadingFile(type);
        this.uploadingFiles.push(newFile);
        return newFile;
    }

    // Removes an element from the list
    remove(file: UploadingFile): void {
        let removeFile = this.uploadingFiles.findIndex( value =>
            value.getId() == file.getId() );
        
        if(removeFile >= 0){
            this.uploadingFiles.splice(removeFile, 1);
        }
    }
}
