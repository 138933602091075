import { Directive, Input, OnInit, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appHighlight]'
})
export class HighlightDirective implements OnInit {
  // Value that receives a string to evaluate
  @Input() text: string;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    const div = this.el.nativeElement;
    const p = this.renderer.createElement('p');
    p.innerHTML = this.identifyHashtags();
    p.setAttribute('class', 'card-text txt_post mt-2');
    this.renderer.appendChild(div, p);
  }

  // Method that will find and replace all the hashtags for an html span element
  identifyHashtags = (): string => {
    const regex = /(^|\s)(#[a-z\d-]+)/gi;
    const str = this.text;
    const subst = `$1<span class="hashtags">$2</span>`;

    // The substituted value will be contained in the result variable
    const result = str.replace(regex, subst);
    return result;
  }
}
