import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { DiagnosticService } from '../../../services/diagnostic/diagnostic.service';
import { ProjectService } from './../../../services/project.service';
import { AuthService } from 'src/app/services/auth.service';
import { SnackbarService } from 'src/app/services/notifications/snackbar.service';
import { DiagnosticSteps } from 'src/app/classes/diagnosticSteps';
@Component({
  selector: 'app-create-project3',
  templateUrl: './create-project3.component.html',
  styleUrls: ['./create-project3.component.scss']
})
export class CreateProject3Component implements OnInit {
  // Property to store the category of the diagnostic project
  //diagnosticCategory: string;
  diagnosticCategory: any;
  // Property to identify the form
  formb: FormGroup;
  // var to store info from api-service list
  options: [];
  // current step number of this page
  stepNumber = 2;
  // total number of steps
  totalNumSteps = 6;
  // Property to identify if the form has been already submitted
  submitted = false;
  // Load steps of diagnostic in order to display navbar
  diagnosticSteps = DiagnosticSteps;
  // Show the states of the steps
  modelProgress = [];

  constructor(private fb: FormBuilder,
     private router: Router,
     private projectService :ProjectService,
     private authService: AuthService,
     private snackbarService: SnackbarService) {

    this.options = this.authService.diagnosticValue.categories || [];
    this.modelProgress = this.authService.diagnosticValue.modulesCompleted || [];
    this.formb = this.fb.group({
        checkCategories: this.fb.array(this.options, [Validators.required])
    });
  }


  // Method to add/remove value of a checkbox into the checkCategories formcontrol value
  onCheckboxChange(e) {
    const checkArray: FormArray = this.formb.get('checkCategories') as FormArray;
    if (e.target.checked) {
      checkArray.push(new FormControl(e.target.value));
    } else {
      let i = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  /* Method to get the options to be displayed as checked
  * @param guid - generated guid from api
  */
 checkedGenerator(guid) {
  return this.formFields.checkCategories.value.includes(guid);
}

  /**
   * Validator method to disable all the other checkbox when there is 2 already checked.
   * @param categoryGuid - category guid to verify if is inside the array of checked values
   */
  validatorCheckbox(categoryGuid: string): boolean {
    const control = this.formb.get('checkCategories').value;
    return control.length === 2 && !control.includes(categoryGuid);
  }

    // Convenience getter for easy access to form fields
    get formFields() {
      return this.formb.controls;
    }

  ngOnInit(): void {
    this.getDiagnosticCategories();
  }

  // Method to get the diagnostic categories-list
  getDiagnosticCategories(): void {
    this.projectService.getCategories()
      .then(results => {
        this.options = results.data;
      });
  }

  // Method to store categories diagnostic info
  save(): void {
      this.submitted = true;
      if (this.formb.invalid) {
        return;
      }
      const categories = this.formFields.checkCategories.value;
      this.authService.updateDiagnosticData({ categories }).then( result => {

        this.snackbarService.showSnackBar('Cerrar', "Se actualizó diagnóstico satisfactoriamente");
        this.router.navigate(['app/crear-proyecto2']);
      }).catch( err =>  { this.snackbarService.showSnackBar('Cerrar', err )});

  }

    /**
   * Receives the url to the empty question
   * @param $event - Url
   */
  redirectToEmptyQuestion($event): void{
    this.router.navigate([$event]);
  }

}
