import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { adsParams } from 'src/app/classes/ads-params';
import { FirebaseService } from 'src/app/services/firebase-service/firebase.service';
import { microsites } from 'src/app/classes/microsites';
import { Router } from '@angular/router';
import { NgbCarousel} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  benefitsCampaings = [];
  processCampaings = [];
  selectedCampaing = 'POSiBLE';
  adsParams = adsParams;
  microsites = microsites;
  // Edo microsite info
  currentMicroSiteStateInfo = {}
  isZacatecas = false;
  isEdomex:boolean = false;

  @ViewChild('processCarousel', {static : true}) carousel: NgbCarousel;

  constructor(
    private route: ActivatedRoute,
    private firebase: FirebaseService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.currentMicroSiteStateInfo = this.microsites['no-edo']
    this.firebase.logEvent('PrincialLandingView');
    // get return url from route parameters or default to 'feed'
    this.route.queryParams.subscribe((params: Params) => {
      if (params.utm_campaign) {
        // Validates if Ad FB params are expected for this event
        if (params['utm_source'] == this.adsParams.pageView.utm_source &&
            params['utm_medium'] == this.adsParams.pageView.utm_medium &&
            params['utm_campaign'] == this.adsParams.pageView.utm_campaign &&
            params['utm_content'] == this.adsParams.pageView.utm_content) {
              
        }
      }
    })

    // Get microsite param
    this.route.params.subscribe((params: any) => {
      if (params.edo && this.microsites[params.edo]){
        this.currentMicroSiteStateInfo = this.microsites[params.edo]
        this.isEdomex = params.edo == 'edomex' ? true : false;
        this.isZacatecas = params.edo == 'zacatecas' ? true : false;
      } else {
        this.router.navigate(['/']);
      }
    });
    let campaignUrl = [];
    if(this.isZacatecas) {
      campaignUrl = ['assets/img/landing/process/zacatecas/7.png',
      'assets/img/landing/process/zacatecas/7.png',
      'assets/img/landing/process/zacatecas/7.png',
      'assets/img/landing/process/zacatecas/1.png',
      'assets/img/landing/process/zacatecas/2.png',
      'assets/img/landing/process/zacatecas/3.png',
      'assets/img/landing/process/zacatecas/4.png',
      'assets/img/landing/process/zacatecas/5.png',
      'assets/img/landing/process/zacatecas/6.png'];
    }
    else {
      campaignUrl = ['assets/img/landing/icon-hands.png',
      'assets/img/landing/icon-comunity.png',
      'assets/img/landing/icon-target.png',
      'assets/img/landing/process/posible/1.png',
      'assets/img/landing/process/posible/2.png',
      'assets/img/landing/process/posible/3.png',
      'assets/img/landing/process/posible/4.png',
      'assets/img/landing/process/posible/5.png',
      'assets/img/landing/process/posible/6.png'];
    }
    this.benefitsCampaings = [
      {
        backgroundImg: '/assets/img/landing/back-posible.png',
        slide: 'posible',
        title: 'POSiBLE',
        subtitle: '',
        agenda: this.currentMicroSiteStateInfo['agenda'],
        agendaUrl: this.currentMicroSiteStateInfo['agenda'] ? this.currentMicroSiteStateInfo['agendaUrl'] : '',
        description: 'Posible es el programa de emprendimiento que te apoya para crear una empresa escalable, de alto impacto y que genere un beneficio a la sociedad, sin importar donde estés.',
        benefits: [
          {img: campaignUrl[0], title: 'Comunidad POSiBLE', text: 'Formarás parte de la comunidad más grande de emprendimiento donde podrás intercambiar aprendizajes y conocer nuevos aliados.'},
          {img: campaignUrl[1], title: 'Red de soporte', text: 'Aliados, mentores y otros emprendedores que enriquecerán tu proyecto de negocio durante todo el proceso.'},
          {img: campaignUrl[2], title: 'Visibilidad', text: 'Difusión de tu proyecto, invitación a eventos para hablar de tu experiencia y proyección para que más personas te conozcan.'}
        ]
      },
      {
        backgroundImg: '/assets/img/landing/back-coop.png',
        slide: 'coop',
        title: 'POSiBLE COOP',
        subtitle: '',
        agenda: false,
        description: 'Es un programa de aceleración para cooperativas y colectivos que busquen capacitarse, desarrollar nuevas habilidades, crecer sus ventas y conocer a otros cooperativistas.',
        benefits: [
          {img: 'assets/img/landing/icon-profile.png', title: 'Mentoría', text: 'Acceso a mentorías virtuales y presenciales durante todo el programa.'},
          {img: 'assets/img/landing/icon-class.png', title: 'Acompañamiento', text: 'Seguimiento personalizado durante 6 meses.'},
          {img: 'assets/img/landing/icon-target.png', title: 'Comunidad COOP', text: 'Comunidad digital de cooperativas y colectivos.'},
          {img: 'assets/img/landing/icon-youtube.png', title: 'Recursos', text: 'Impresos, cápsulas y cursos abiertos para todos los miembros de las cooperativas y colectivos participantes.'}
        ]
      },
      {
        backgroundImg: '/assets/img/landing/back-reto.png',
        slide: 'reto',
        title: 'Reto POSiBLE',
        subtitle: '',
        agenda: false,
        description: 'Es un programa que busca inspirar a docentes y alumnos de nivel medio superior y superior a emprender proyectos de negocio escalables y con innovación.',
        benefits: [
          {img: 'assets/img/landing/icon-hands.png', title: 'Recursos de aprendizaje', text: 'MOOC enfocado a docentes de 15 horas <br>MOOC enfocado a alumnos y emprendedores primerizos de 20 horas <br>Disponibles en mexicox.gob.mx'},
          {img: 'assets/img/landing/icon-youtube.png', title: 'Comunidad Reto', text: 'Comunidad digital donde pueden intercambiar contenidos, tomar cursos y actualizaciones.'},
          {img: 'assets/img/landing/icon-target.png', title: 'Campamento de estudiantes', text: 'Oportunidad de vivir un campamento con la metodología POSIBLE tanto para docentes como para estudiantes.'}
        ]
      },
      {
        backgroundImg: '/assets/img/landing/back-max.png',
        slide: 'max',
        title: 'POSiBLE MAX',
        subtitle: '',
        agenda: false,
        description: 'Es un programa 100% digital con duración de 6 meses enfocado en impulsar y acompañas a las empresas de reciente creación con alto potencial de escalamiento, a cumplir sus objetivos de crecimiento, rentabilidad y eficiencia.',
        benefits: [
          {img: 'assets/img/landing/icon-videocall.png', title: 'Mentoría', text: 'Más de 20 sesiones de acompañamiento personalizado con mentores expertos.'},
          {img: 'assets/img/landing/icon-class.png', title: 'Consultoría', text: 'Consultorías especializadas impartidas por empresarios y mentores expertos en temas específicos del rubro de cada empresa.'},
          {img: 'assets/img/landing/icon-tablet.png', title: 'Crecimiento', text: 'Talleres relevantes para las diferentes áreas de tu negocio'}
        ]
      }
    ];

    // Process campaings
    this.processCampaings =[
      {
        campaing: "POSiBLE",
        title: 'posible',
        process: [{ number: 1, img: campaignUrl[3], title: "Convocatoria", text: "Cada año abrimos esta oportunidad para ti, regístrate y llena tu modelo de negocios" },
        { number: 2, img: campaignUrl[4], title: "Recursos", text: "Encuentra artículos, videos, ejemplos en la plataforma donde veas este signo “?” para ayudarte y participa en los talleres que tendremos para ti." },
        { number: 3, img: campaignUrl[5], title: "Evaluación", text: "Espera tu resultado vía correo electrónico para avanzar a la siguiente etapa. Si no pasas, no te preocupes, tendremos otras herramientas para apoyar tu emprendimiento." },
        { number: 4, img: campaignUrl[6], title: "Presenta tu pitch", text: "Si fuiste seleccionado, participa en nuestros paneles de selección, donde tendrás la oportunidad de platicarnos de qué trata tu proyecto." },
        { number: 5, img: campaignUrl[7], title: "Evaluación final", text: "Si hiciste un buen pitch, tu proyecto fue bien evaluado e identificamos que estás listo para ser un POSIBLER, checa tu correo electrónico para saber si fuiste seleccionado para nuestro Campamento Nacional." },
        { number: 6, img: campaignUrl[8], title: "Campamento Nacional POSIBLE", text: "Si ya llegaste hasta aquí, prepárate para 5 días de intensidad emprendedora, donde recibirás mentorías, talleres, contactos y conocerás a otros emprendedores como tú." }]
      },
      {
        campaing: "Posible COOP",
        title: 'coop',
        process: [{ number: 1, img: 'assets/img/landing/process/coop/1.png', title: "Convocatoria", text: "Posible Coop funciona por estados y regiones, espera la convocatoria para ti en coop.posible.org.mx" },
        { number: 2, img: 'assets/img/landing/process/coop/2.png', title: "Registro", text: "Selecciona tu estado, inicia sesión y llena cuidadosamente tu aplicación." },
        { number: 3, img: 'assets/img/landing/process/coop/3.png', title: "Evaluación", text: "Espera tu resultado, se te notificará por correo electrónico y por teléfono, por lo que será importante captures correctamente tus datos de contacto." },
        { number: 4, img: 'assets/img/landing/process/coop/4.png', title: "Aceleración", text: "Durante 3 meses te invitaremos a 3 campamentos donde recibirás capacitación y mentoría y trabajaras en la implementación de tu trabajo  otros 3 meses de la mano de tu mentor." }]
      },
      {
        campaing: "POSiBLE MAX",
        title: 'max',
        process: [{ number: 1, img: 'assets/img/landing/process/max/1.png', title: "Convocatoria", text: "Espera la convocatoria en tu estado o tu sector, si ya has participado en POSIBLE tendrás preferencia al momento de aplicar." },
        { number: 2, img: 'assets/img/landing/process/max/2.png', title: "Diagnóstico", text: "Si eres seleccionado, trabajaremos contigo para identificar cuáles son las áreas personales y de tu empresa en las que debemos trabajar" },
        { number: 3, img: 'assets/img/landing/process/max/3.png', title: "Talleres", text: "Durante todo el proceso recibirás capacitación de expertos, donde podrás compartir tus puntos de vista y aprender de otros." },
        { number: 4, img: 'assets/img/landing/process/max/4.png', title: "Mentoría", text: "Te asignaremos un mentor experto en tu sector, que estará trabajando contigo durante todo el proceso." },
        { number: 5, img: 'assets/img/landing/process/max/5.png', title: "Advisory", text: "Te presentaremos con expertos en diferentes áreas que contribuyan al crecimiento de las diferentes áreas de tu negocio." },
        { number: 6, img: 'assets/img/landing/process/max/6.png', title: "Indicadores", text: "Mensualmente estaremos definiendo y midiendo diferentes indicadores cualitativas y cuantitativos para que tanto tu como nosotros sepamos que estamos avanzando." }]
      },
      {
        campaing: "Reto POSiBLE",
        title: 'reto',
        process: [{ number: 1, img: 'assets/img/landing/process/reto/1.png', title: "Convocatoria", text: "Reto POSIBLE en campamentos funciona por estado, espera la convocatoria en tu región." },
        { number: 2, img: 'assets/img/landing/process/reto/2.png', title: "Registro", text: "La convocatoria estará habilitada en posible.org.mx como Reto POSIBLE." },
        { number: 3, img: 'assets/img/landing/process/reto/3.png', title: "Capacitación", text: 'Para que llegues mejor preparado toma el curso de México X antes del campamento.' },
        { number: 4, img: 'assets/img/landing/process/reto/4.png', title: "Constancia", text: "No olvides emitir tu constancia también en la plataforma de México X" },
        { number: 5, img: 'assets/img/landing/process/reto/5.png', title: "Evaluación", text: "Espera tu resultado para saber si fuiste seleccionado para uno de nuestros campamentos." },
        { number: 6, img: 'assets/img/landing/process/reto/6.png', title: "Campamento Reto", text: 'Participa en nuestros campamentos digitales y presenciales, especiales para docentes y alumnos que estaremos haciendo durante todo el año.' }]
      },
    ]
  }

  selectCampaign(title, slide) {
    this.selectedCampaing = title;
    this.carousel.select(slide);
  }
  
}
