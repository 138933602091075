import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SnackbarService } from '../../../services/notifications/snackbar.service';
import { AuthService } from '../../../services/auth.service';
import { DiagnosticSteps } from 'src/app/classes/diagnosticSteps';

@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.scss']
})
export class CreateProjectComponent implements OnInit {
  // property to store the diagnostic name
  diagnoticName: string;
  // property to manage the current form
  formb: FormGroup;
    // property to set the current max length
  diagnosticNameMaxLength = 200;
  // current step number of this page
  stepNumber = 0;
  // total number of steps
  totalNumSteps = 6;
  // Load steps of diagnostic in order to display navbar
  diagnosticSteps = DiagnosticSteps;
  // This question could be answer one time, if user return to this page values will be disable
  diagnosticFinished = false;

  constructor(private fb: FormBuilder,
     private router: Router,
     private authService: AuthService,
     private snackbarService: SnackbarService) {

    this.diagnoticName = this.authService.diagnosticValue['name'] || '';
    this.diagnosticFinished = this.authService.diagnosticValue.diagnosticFinished;
    this.formb = this.fb.group({
      name: [{value: this.diagnoticName, disabled: this.diagnosticFinished}, [Validators.minLength(2), Validators.maxLength(this.diagnosticNameMaxLength)]]
    });
  }

  ngOnInit(): void {
  }
  // Convenience getter for easy access to form fields
  get formFields() {
    return this.formb.controls;
  }
  get noValid(): boolean {
    return this.formb.get('name').invalid && this.formb.get('name').touched;
  }
/**
 * Method to return the length of a textfield
 * @param str - string to look for the chars
 * Return - number of chars
 */
countChars(str): number{
  if (str) {
    const chars = str.length;
    return chars;
  }else{
    return 0;
  }
}
  // Method to handle diagnoticName data
  save(): void {
    if(this.diagnosticFinished){
      this.router.navigate(['app/diagnostico']);
    }else{
      if (this.formb.invalid) {
        return Object.values(this.formb.controls).forEach(control => {
          control.markAllAsTouched();
        });
      }
  
      const name = this.formb.value.name;
      this.authService.updateDiagnosticData({ name }).then( result => {
  
        this.snackbarService.showSnackBar('Cerrar', "Se actualizó diagnóstico satisfactoriamente");
      }).catch( err =>  {
        this.snackbarService.showSnackBar('Cerrar', err );
      }).finally( ()=> {
        this.router.navigate(['app/diagnostico']);
      });
    }
  }

}
