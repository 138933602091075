// Class to implement attributes of Project
export class Project {
  businessModel_guid: string;
  acquisitionsLevel: AcquisitionsLevel;
  activitiesImpulse: string;
  ageRanges: AgeRange[];
  allianceDescription: string;
  categories: Category[];
  clientTypes: ClientType[];
  customerDescription: string;
  description: string;
  descriptionCost: string;
  descriptionProduct: string;
  genders: Gender[];
  generateJob: boolean;
  haveAlliance: boolean;
  haveCost: boolean;
  haveName: boolean;
  haveWeb: boolean;
  incomeTypes: IncomeType[];
  jobCount: boolean;
  name: string;
  optionSolution: OptionSolution;
  otherIncomes: string;
  otherPublicityType: boolean;
  otherSellType: boolean;
  otherSupport: boolean;
  pay: boolean;
  problem: string;
  productPay: boolean;
  productUsed: boolean;
  publicityTypes: PublicityType[];
  reasonRejection: string;
  sellTypes: SellType[];
  similarSolutions: string;
  solution: string;
  solutionAdditions: string;
  supportTypes: SupportType[];
  about: string;
  mission: string;
  vision: string;
  status: number;
  percentage: number;
  invoice: string;

    constructor(data: any) {
      this.businessModel_guid = data.businessModel_guid;
      this.acquisitionsLevel = data.acquisitionsLevel;
      this.activitiesImpulse = data.activitiesImpulse;
      this.ageRanges = data.ageRanges;
      this.allianceDescription = data.allianceDescription;
      this.categories = data.categories;
      this.clientTypes = data.clientTypes;
      this.customerDescription = data.customerDescription;
      this.description = data.description;
      this.descriptionCost = data.descriptionCost;
      this.descriptionProduct = data.descriptionProduct;
      this.genders = data.genders;
      this.generateJob = data.generateJob;
      this.haveAlliance = data.haveAlliance;
      this.haveCost = data.haveCost;
      this.haveName = data.haveName;
      this.haveWeb = data.haveWeb;
      this.incomeTypes = data.incomeTypes;
      this.jobCount = data.jobCount;
      this.name = data.name;
      this.optionSolution = data.optionSolution;
      this.otherIncomes = data.otherIncomes;
      this.otherPublicityType = data.otherPublicityType;
      this.otherSellType = data.otherSellType;
      this.otherSupport = data.otherSupport;
      this.pay = data.pay;
      this.problem = data.problem;
      this.productPay = data.productPay;
      this.productUsed = data.productUsed;
      this.publicityTypes = data.publicityTypes;
      this.reasonRejection = data.reasonRejection;
      this.sellTypes = data.sellTypes;
      this.similarSolutions = data.similarSolutions;
      this.solution = data.solution;
      this.solutionAdditions = data.solutionAdditions;
      this.supportTypes = data.supportTypes;
      this.about = data.about;
      this.mission = data.mission;
      this.vision = data.vision;
      this.status = data.statusBusinessModel;
      this.percentage = data.percentaje;
      this.invoice = data.folio;
    }
}

// Class to implement attributes of Acquisition Level
export class AcquisitionsLevel {
  acquisitionsLevel_guid: string;
  description: string;
  constructor(data) {
    this.acquisitionsLevel_guid = data.acquisitionsLevel_guid;
    this.description = data.description;
  }
}

// Class to implement attributes of Age Range
export class AgeRange {
  ageRange_guid: string;
  description: string;
  constructor(data) {
    this.ageRange_guid = data.ageRange_guid;
    this.description = data.description;
  }
}

// Class to implement attributes of Project Category
export class Category {
  category_guid: string;
  description: string;
  constructor(data) {
    this.category_guid = data.category_guid;
    this.description = data.description;
  }
}

// Class to implement attributes of Client Type
export class ClientType {
  clientType_guid: string;
  description: string;
  constructor(data) {
    this.clientType_guid = data.clientType_guid;
    this.description = data.description;
  }
}

// Class to implement attributes of Gender
export class Gender {
  gender_guid: string;
  description: string;
  constructor(data) {
    this.gender_guid = data.gender_guid;
    this.description = data.description;
  }
}

// Class to implement attributes of Income Type
export class IncomeType {
  incomeType_guid: string;
  description: string;
  constructor(data) {
    this.incomeType_guid = data.incomeType_guid;
    this.description = data.description;
  }
}

// Class to implement attributes of Option solution
export class OptionSolution {
  optionSolution_guid: string;
  description: string;
  constructor(data) {
    this.optionSolution_guid = data.optionSolution_guid;
    this.description = data.description;
  }
}

// Class to implement attributes of Publicity Type
export class PublicityType {
  publicityType_guid: string;
  description: string;
  constructor(data) {
    this.publicityType_guid = data.publicityType_guid;
    this.description = data.description;
  }
}

// Class to implement attributes of Sell Type
export class SellType {
  sellType_guid: string;
  description: string;
  detail: string;
  constructor(data) {
    this.sellType_guid = data.sellType_guid;
    this.description = data.description;
    this.detail = data.detail;
  }
}

// Class to implement attributes of Support Type
export class SupportType {
  supportType_guid: string;
  description: string;
  constructor(data) {
    this.supportType_guid = data.supportType_guid;
    this.description = data.description;
  }
}
