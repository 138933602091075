import { Topic } from './../topic/topic';
export class UserProfile {
    guid: string;
    description: string;
    education: string;
    name: string;
    image: string;
    status: string;
    topics: Topic[];
    schoolType: any;

    constructor(data: any, topics: Topic[]) {
        this.guid = data.user_guid;
        this.name = `${data.first_name} ${data.last_name}`;
        this.description = data.description;
        this.education = data.education;
        this.status = data.status;
        this.image = data.image_path;
        this.topics = topics;
        this.schoolType = data.is_public;
    }
}
