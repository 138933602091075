export class FriendRequest {
    guid: string;
    fromUserid: string;
    toUserid: string;
    firstName: string;
    lastName: string;
    image: string;
    confirmed: boolean;
    activityStatus: string;

    constructor(data: any) {
        this.guid = data.request_guid;
        this.fromUserid = data.from_user.user_guid;
        this.toUserid = data.to_user.user_guid;
        this.firstName = data.from_user.first_name;
        this.lastName = data.from_user.last_name;
        this.image = data.from_user.image_path;
        this.confirmed = data.confirmed;
        this.activityStatus = data.activityStatus || data.from_user.status ||  'Offline';
    }
}
