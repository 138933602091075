import { Injectable, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import * as io from 'socket.io-client';
import { Globals } from '../../classes/global';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})

export class ChatService {

  @Output() publicationsSeen = new EventEmitter<any>();
  @Output() messageSeen = new EventEmitter<any>();

  // Socket instance--ngSocket
  private socket: SocketIOClient.Socket;
  // variable that store the import of Globals Class that will help us to retrieve the urls for every service
  globals = Globals;

  constructor(private http: HttpClient) { }

  /* Method to init the socket service
  * @param chatroom:string - chatroom id
  */

  initSocket2 ():boolean{
    let user = JSON.parse(localStorage.getItem('currentUser'));
    let token = user.token;
    this.socket = io(this.globals.chatSocket.chatSocketPath, { query: 'user='+JSON.stringify({username: user.username, token: user.token}) });
    return true;
  }

  /** Method to leave a chat room
    @param chatroom:string Number of Room to Leave
   */
  JoinRoom(chatroom:string): boolean{
    let user = JSON.parse(localStorage.getItem('currentUser'));
    let _self = this;
    this.socket.emit('join', chatroom, user.username);
    return true;
  }

  /** Method to leave a chat room
    @param chatroom:string Number of Room to Leave
   */
  leaveRoom(chatroom:string): boolean{
    let user = JSON.parse(localStorage.getItem('currentUser'));
    if( user){
      let _self = this;
      this.socket.emit('leave', chatroom, user.username);
    }
    return true;
  }

  /* Method to get a chatroom history by id
  * @param chamtroom:string - chatroom id
  */
  getHistory(chatroom:string, page:number, userid: string){
    const url = `${this.globals.chatSocket.chatSocketPath}${this.globals.chatSocket.history}${chatroom}/page/${page}?userId=${userid}`;
    return this.http.get(url);
  }

  /* Method to upload a file to a chatroom
  * @param chamtroom:string - chatroom id
  * @param data:FormData - form data that contains the file
  */
  uploadFile(chatroom:string, data:FormData){
    const url = `${this.globals.urlPosibleBase}${this.globals.chatroom.room}${chatroom}/${this.globals.chatroom.image}`;
    return this.http.post(url, data, { reportProgress: true, observe: 'events' });
  }

  /* Method to send a new message to the socket
  * @param message:string - Message text or file
  * @param chamtroom:string - chatroom id
  * @param user:string - Current user id
  * @param contact:string - Other user id
  * @param type:string - Type of the message
  */
  sendMessage(message: string, chatroom:string, user: string, contact:string, type:string): void {
    let msg = {
      room: chatroom,
      toUser: contact,
      fromUser: user,
      message: message,
      type: type
    };
    this.socket.emit('message', msg);
  }

  /**  Method to send a message that indicate that a new notification of contact request
  * @param data: any the data for the notification of contact request
  * @exmple
   * // let msg = {
     //   contact: id,
     //   toUser: 'contact',
     //   fromUser: 'user',
     //   message: 'message',
     //   type: 'AddContact'
     // };
  */
 SendAddContactRequest(data:any): void {

  this.socket.emit('addcontact', data);
}

SendCancelFriendRequest(data:any): void {
  this.socket.emit('cancelFriendRequest', data);
}

  /* Method to send a new actionTo publication
  * @param message:string - Message text or file
  */
 SendActionPublicationRequest(publication_guid,
    from_user_guid,
    from_user_name,
    from_user_image,
    to_user_guid,
    to_user_name,
    type,
    isDelete?,
    ): void {
  let msg = {
    "publication_guid": publication_guid,
    "from_user": from_user_guid,
    "from_user_name": from_user_name,
    "from_user_image": from_user_image,
    "to_user": to_user_guid,
    "to_user_name": to_user_name,
    "publication_action": type, //-- (Like,Comentar,Compartir): ,
    "created_date": moment.utc(),
    "type": "Publication",
    "viewed" : false,
    "isDelete" : isDelete
  };
  this.socket.emit('actionpublication', msg);
}

  /* Method to get the other user info by id
  * @param user:string - User id
  */
  getUserInfo(user:string){
    const url = `${this.globals.urlPosibleBase}${this.globals.users.pathUsers}${user}/`;
    return this.http.get(url);
  }

  /* Method event that load when arrives a new message
  *
  */
  onNewMessage(){
    return Observable.create(observer => {
      this.socket.on('message', msg => {
        observer.next(msg);
      });
    })
    ;
  }

  onEvent(eventName){
    return Observable.create(observer => {
      this.socket.on(eventName, msg => {
        observer.next(msg);
      });
    })
    ;
  }

  onCancel(eventName){
    return Observable.create(observer => {
      this.socket.on(eventName, msg => {
        observer.next(msg);
      });
    })
    ;
  }

  /* Method event that load when a new user join to the chatroom
  *
  */
  onContactJoined() {
    return Observable.create(observer => {
      this.socket.on('joined', msg => {
        observer.next(msg);
      });
    });
  }

  /** This method Raise that the user already seen the publication notifications
  */
  raisefriendRequestChanged():void
  {
    this.publicationsSeen.emit();
  }

  /** This method Raise that the user already seen the message notifications
  */
  raiseMessageSeen():void
  {
    this.messageSeen.emit();
  }

  /** Method to get if a User have notifications of certain
  * @param chamtroom:string - chatroom id
  */
  getHaveNotifications(userId:string, notificationType:string){
    const url = `${this.globals.chatSocket.chatSocketPath}${this.globals.chatSocket.user}${userId}/haveNotifications/${notificationType}/`;
    return this.http.get(url);
  }
}

