import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators } from '@angular/forms';
import { ProjectService } from './../../../services/project.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BusinessModel } from '../../../models/businessModel/businessModel';
import { NavigationSteps } from 'src/app/classes/navigationSteps';
import { SnackbarService } from '../../../services/notifications/snackbar.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ProjectCompletedDialogComponent } from '../../shared/dialogs/project-completed-dialog/project-completed-dialog/project-completed-dialog.component';
import { ModelProgress } from 'src/app/models/modelProgress/modelProgress';
@Component({
  selector: 'app-define-advertising',
  templateUrl: './define-advertising.component.html',
  styleUrls: ['./define-advertising.component.css']
})
export class DefineAdvertisingComponent implements OnInit {

  // current step number of this page
  stepNumber = 5;
  // total number of steps
  totalNumSteps = 7;
  navigationSteps = NavigationSteps;
  // Property to identify the form
  forma: FormGroup;
  // Property to identify if the form has been already submitted
  submitted = false;
  // Publicities list
  publicities: [];
  // Sells list
  sellTypes: [];
  // Property to identify the current project
  currentProject: BusinessModel;
  // Property to identify the project id based on a url param
  projectId: string;
  // Property to manage the project select disabled for last option
  visible: boolean = false;
  // Property to manage the project select disabled for last option
  visible_publicity: boolean = false;
  // property to store guid value
  otros_value: string = '';
  // property to store guid value
  otros_publicity: string = '';
  // Indicates with colors the steps that are already done
  modelProgress: ModelProgress[];
  // Load steps of diagnostic in order to display navbar
  projectSteps = NavigationSteps;

  constructor(
    private snackbarService: SnackbarService,
    private fb: FormBuilder,
    private router: Router,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {
    this.forma = this.fb.group({
      checksProducts: this.fb.array([]),
      checksLocations: this.fb.array([]),
      otrosDescr: [''],
      otrosPublicity: ['']
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      if (params.id) {
        this.projectId = params.id;
        this.setProjectValues();
      } else {
        this.getpublicityTypes();
        this.getSellTypes();
      }
    });
  }

  // Method to set the current form fields data from  api's database according to the current model_guid
  setProjectValues(): void {
    this.projectService.getProjectStageData(this.projectId)
      .then(data => {
        this.currentProject = new BusinessModel(data.data);
        this.modelProgress = this.currentProject.modelProgress;
        const checksProducts: FormArray = this.forma.get('checksProducts') as FormArray;
        data.data.publicityTypes.forEach(element => {
          checksProducts.push(new FormControl(element.publicityType_guid));
        });
        const checksLocations: FormArray = this.forma.get('checksLocations') as FormArray;
        data.data.sellTypes.forEach(element => {
          checksLocations.push(new FormControl(element.sellType_guid));
        });
        this.getpublicityTypes();
        this.getSellTypes();
        this.visible = this.toggleCheck('checksLocations', this.visible, this.otros_value, this.formFields.otrosDescr, 'otrosDescr');
        this.formFields.otrosDescr.setValue(this.currentProject.otherSellType);
        this.visible_publicity = this.toggleCheck('checksProducts', this.visible, this.otros_publicity, this.formFields.otrosPublicity, 'otrosPublicity');
        this.formFields.otrosPublicity.setValue(this.currentProject.otherPublicityType);
      });
  }

  /**
   * Method to return the lenght of a textfield
   * @param str - string to look for the chars
   * Return - number of chars
   */
  countChars(str: string): number {
    if (str) {
      const chars = str.length;
      return chars;
    } else {
      return 0;
    }
  }


  toggleCheck(control_name, visibility, value_check, validator, validator_name): boolean {
    /*const checksLocations: FormArray = this.forma.get('checksLocations') as FormArray;
    if (checksLocations.value.includes(this.otros_value)) {
      this.visible = true;
      this.formFields.otrosDescr.setValidators([Validators.required]);
    } else {
      this.visible = false;
      this.formFields.otrosDescr.setValue(null);
      this.formFields.otrosDescr.setValidators([]);
    }
    this.forma.get('otrosDescr').setErrors(null);
    this.forma.updateValueAndValidity();*/
    const checksLocations: FormArray = this.forma.get(control_name) as FormArray;
    if (checksLocations.value.includes(value_check)) {
      visibility = true;
      validator.setValidators([Validators.required]);
    } else {
      visibility = false;
      validator.setValue(null);
      validator.setValidators([]);
    }
    this.forma.get(validator_name).setErrors(null);
    this.forma.updateValueAndValidity();
    return visibility;
  }

  /* Method to get the options to be displayed as checked
  * @param guid - generated guid from api
  */
  checkedGenerator(guid: string) {
    return this.formFields.checksProducts.value.includes(guid);
  }
  /* Method to get the options to be displayed as checked for second component
  * @param guid - generated guid from api
  */
  checkedGenerator2(guid: string) {
    return this.formFields.checksLocations.value.includes(guid);
  }

  // Method to get the project sells-list
  getSellTypes(): void {
    this.projectService.getSellTypes()
      .then(data => {
        this.sellTypes = data.data;
        data.data.forEach(item => {
          if (item.description === 'Otros') {
            this.otros_value = item.sellType_guid;
          }
        });
        this.visible = this.toggleCheck('checksLocations', this.visible, this.otros_value, this.formFields.otrosDescr, 'otrosDescr');
      })
      .catch(error => {
        this.router.navigate(['app/inicio']);
        this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error. ⚠️');
      }
      );
  }

  // Method to get the project publicities list
  getpublicityTypes(): void {
    this.projectService.getpublicityTypes()
      .then(data => {
        this.publicities = data.data;
        data.data.forEach(item => {
          if (item.description === 'Otros') {
            this.otros_publicity = item.publicityType_guid;
          }
        });
        this.visible_publicity = this.toggleCheck('checksProducts', this.visible, this.otros_publicity, this.formFields.otrosPublicity, 'otrosPublicity');
      })
      .catch(error => {
        this.router.navigate(['app/inicio']);
        this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error. ⚠️');
      }
      );
  }

  // Method to add/remove value of a checkbox into the checkArray formcontrol value
  /**
   * Validator method to disable all the other checkbox when there is 2 already checked.
   * @param evt - change event to get access to the checkbox native properties
   * @param formControl - name of the form control to validate
   */
  onCheckboxChange(evt, formControl: string): void {
    const checkArray: FormArray = this.forma.get(formControl) as FormArray;
    if (evt.target.checked) {
      checkArray.push(new FormControl(evt.target.value));
    } else {
      let i = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value == evt.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
    if(formControl == "checksLocations"){
      this.visible = this.toggleCheck('checksLocations', this.visible, this.otros_value, this.formFields.otrosDescr, 'otrosDescr');
    }
    else if(formControl == "checksProducts") {
      this.visible_publicity = this.toggleCheck('checksProducts', this.visible, this.otros_publicity, this.formFields.otrosPublicity, 'otrosPublicity');
    }
  }

  /**
   * Validator method to disable all the other checkbox when there is 2 already checked.
   * @param guid - guid to verify if is inside the array of checked values
   * @param formControl - name of the form control to validate
   */
  validatorCheckboxes(guid: string, formControl: string): boolean {
    const control = this.forma.get(formControl).value;
    return control.length === 2 && !control.includes(guid) ? true : false;
  }

  // Convenience getter for easy access to form fields
  get formFields() {
    return this.forma.controls;
  }

  // Method to set the current project data from the current page's form
  generateProject(): void {
    if (this.projectId) {
      this.currentProject.publicityTypes = this.formFields.checksProducts.value;
      this.currentProject.sellTypes = this.formFields.checksLocations.value;
      this.currentProject.otherSellType = (this.formFields.otrosDescr.value !== null ? this.formFields.otrosDescr.value.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '') : null);
      this.currentProject.otherPublicityType = (this.formFields.otrosPublicity.value !== null ? this.formFields.otrosPublicity.value.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '') : null);
    }
  }

  // Method to send data to store account info to be updated
  update(): void {
    this.submitted = true;
    if (this.forma.invalid) {
      this.snackbarService.showSnackBar('Cerrar', 'Revise los campos requeridos ⚠️');
      return;
    }
    this.generateProject();
    if (this.projectId) {
      this.projectService.updateProject( this.currentProject,this.projectId).subscribe(data => {
          this.snackbarService.showSnackBar('Cerrar', 'Datos guardados! 🎉');
          this.router.navigate(['app/generar-ingreso', this.projectId]);
      }, (err) => { this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error. ⚠️'); },
      () => { this.submitted = false; });
    }
  }
}
