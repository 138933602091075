import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DiagnosticService } from '../../../services/diagnostic/diagnostic.service';
import { AuthService } from 'src/app/services/auth.service';
import { SnackbarService } from '../../../services/notifications/snackbar.service';
import { DiagnosticSteps } from 'src/app/classes/diagnosticSteps';

@Component({
  selector: 'app-create-project6',
  templateUrl: './create-project6.component.html',
  styleUrls: ['./create-project6.component.scss']
})
export class CreateProject6Component implements OnInit {
   // Property to store the name of the diagnostic project
  businessIdea: any;
   // Property to identify the form
  formb: FormGroup;
  // var to store info from api-service list
  options: [];
  // current step number of this page
  stepNumber = 1;
  // total number of steps
  totalNumSteps = 6;
  // Load steps of diagnostic in order to display navbar
  diagnosticSteps = DiagnosticSteps;
  // Show the states of the steps
  modelProgress = [];

  constructor(private fb: FormBuilder,
     private router: Router,
     private dgService: DiagnosticService,
     private authService: AuthService,
     private snackbarService: SnackbarService) {

    this.businessIdea = this.authService.diagnosticValue['businessIdea'] || '';
    this.modelProgress = this.authService.diagnosticValue['modulesCompleted'] || [];
    this.formb = this.fb.group({
      idea: [this.businessIdea, [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.getBusinessIdea();
    this.formb.patchValue({ idea: (this.businessIdea) });
  }

  // Method to get the project categories list
  getBusinessIdea(): void{
    this.dgService.getBusinessIdea()
      .then(data => {
        this.options = data.data;
      });
  }

  /**
   * Method to manage the valid inputs with a css color
   */
  get noValid(): boolean {
    return this.formb.get('idea').invalid && this.formb.get('idea').touched;
  }

  // Method to store the posible idea of the diagnostic info
  save(): void {
    if (this.formb.invalid) {
      return Object.values(this.formb.controls).forEach(control => {
        control.markAllAsTouched();
      });
    }
    const businessIdea = this.formb.value.idea;
    this.authService.updateDiagnosticData({ businessIdea }).then( result => {
      this.snackbarService.showSnackBar('Cerrar', "Se actualizó diagnóstico satisfactoriamente");
      this.router.navigate(['app/crear-proyecto3']);
    }).catch( err =>  { this.snackbarService.showSnackBar('Cerrar', err )});
  }

    /**
   * Receives the url to the empty question
   * @param $event - Url
   */
  redirectToEmptyQuestion($event): void{
    this.router.navigate([$event]);
  }
}
