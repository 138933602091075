import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { DiagnosticService } from '../../../services/diagnostic/diagnostic.service';
import { AuthService } from '../../../services/auth.service';
import { SnackbarService } from '../../../services/notifications/snackbar.service';
import { DiagnosticSteps } from 'src/app/classes/diagnosticSteps';
import { environment } from 'src/environments/environment';
import * as firebase from 'firebase/app';
import { FirebaseService } from 'src/app/services/firebase-service/firebase.service';
@Component({
  selector: 'app-create-project2',
  templateUrl: './create-project2.component.html',
  styleUrls: ['./create-project2.component.scss']
})
export class CreateProject2Component implements OnInit {
  // Property to store the posible status of the diagnostic
  statusPosible: number[] = [];
     // Property to identify the form
  formb: FormGroup;
  // var to store info from api-service list
  options: [];
  // current step number of this page 
  stepNumber = 3;
  // total number of steps
  totalNumSteps = 6;
  // Load steps of diagnostic in order to display navbar
  diagnosticSteps = DiagnosticSteps;
  // Indicates if form was sent
  submitted = false;
  // This question could be answer one time, if user return to this page values will be disable
  diagnosticFinished = false;

  constructor(private fb: FormBuilder,
    private router: Router,
    private dgService: DiagnosticService,
    private authService:AuthService,
    private snackbarService: SnackbarService,
    private firebase: FirebaseService) {

    this.statusPosible = this.authService.diagnosticValue.statusPosible.map(item => item['statusPosible_guid']) || [];
    this.diagnosticFinished = this.authService.diagnosticValue.diagnosticFinished;
    this.getStatusPosible();
    this.formb = this.fb.group({
      statusp: this.fb.array([])
    });
     const statusSelected: FormArray = this.formb.get('statusp') as FormArray;
     this.statusPosible.forEach(element => statusSelected.push(new FormControl(element)));
  }

  ngOnInit(): void { }

  // Convenience getter for easy access to form fields
  get formFields() {
    return this.formb.controls;
  }
  
  /**
   * Method that adds or remove options from statusPosible variable
   * @param $event Receives option selected
   */
  addItem($event){
    let value = parseInt($event.target.value);
    let index = this.statusPosible.indexOf(value);
    if( index > -1 ){// Removes option
      this.statusPosible.splice(index,1);
    }else{ // if option doesnt exists then added      
      this.statusPosible.push(value);
    }
  }
  
  /* Method to get the options to be displayed as checked
  * @param guid - generated guid from api
  */
  checkedGenerator(guid: string) {
    return this.formFields.statusp.value.includes(guid);
  }

  // Method to get the project status list
  getStatusPosible(): void {
    this.dgService.getStatusPosible().then(data => {
      this.options = data.data;
    });
  }
  
  // Method to store the curent status posible diagnostic info
  save(): void {    
    // if this question was saved before it cant be updated so let it go to next step
    if(this.diagnosticFinished){
      this.router.navigate(['app/perfil/intereses']);
    }else{      
      this.submitted = true;
      if (this.statusPosible.length == 0) {
        this.snackbarService.showSnackBar('Cerrar', "Campo requerido")
        return;
      }
      this.authService.updateDiagnosticData({ statusPosible: this.statusPosible })
      .then( result => {
        this.firebase.logEvent('expectsPosibleFinished');
        this.snackbarService.showSnackBar('Cerrar', "Se actualizó diagnóstico satisfactoriamente");
        this.router.navigate(['app/perfil/intereses']);
      }).catch( err =>  { this.snackbarService.showSnackBar('Cerrar', err )});
    }
  }

}
