<!--main-container--> 
<div class="modal-all">  
  <div class="modal-body text-center">
    <div class="my-4">
      <h3> Publicación creada </h3>
    </div>
    <div class="animated fadeIn fast px-4">
      <span class="common_txt_italic" *ngIf="isLoading">
          Subiendo: <b>{{ filesUploading }}</b> de <b>{{ totalFiles }}</b>
          {{ totalFiles == 1 ? 'archivo': 'archivos'}}
        </span>
      <span class="common_txt" *ngIf="!isLoading">
        Su publicación ahora podrá visualizarse en la pantalla principal
      </span>
    </div>
  </div>
    <div class="modal-footer border-0">
      <button   class="btn-lg btn btn-block btn-warning btn_continuar modal-button" 
                (click)="redirect()" 
                data-dismiss="modal"
                [disabled]="isLoading ? true : null"
                >
              <span *ngIf="isLoading"> <i class="fa fa-spinner fa-spin" style="font-size:24px"></i> {{uploadPercent}}   </span>
                <span *ngIf="!isLoading" class="btn_continuar_text">  Continuar </span>
    </button>
  </div>
</div>