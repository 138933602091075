import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class TableFilterPipe implements PipeTransform {

  /* This pipe transform the the array of objects to search from specific fields
  * @param items:any[] - Array of items
  * @param field : string | string[] - field or fields, to filter by
  * @param value: string - text value to search
  */
  transform(items: any[], field: string  | string[], value: string): any[] {
    if (!items) {
      return [];
    }
    if (!field || !value) {
      return items;
    }

    if(typeof field == "string"){
      return items.filter(singleItem =>
        singleItem[field].toLowerCase().includes(value.toLowerCase())
      );
    }else{

    }

    return items.filter(singleItem => {
      if(typeof field == "string"){
        return singleItem[field].toLowerCase().includes(value.toLowerCase())
      }else{
        let result = false;
        field.forEach(element => {
          if(singleItem[element].toLowerCase().includes(value.toLowerCase())){
            result = true;
          }
        });
        return result;
      }
    });

    return items;

  }

}
