import { SupportGlossary } from './SupportGlossary';
import { SupportVideo } from './SupportVideo';
import { SupportPost } from './SupportPost';

// Class to implement all content of support material of modules
export class SupportMaterial {
    videos: SupportVideo;
    posts: SupportPost;
    glossary: SupportGlossary;
    constructor(data: any) {
        this.videos = data.videos.map(video => new SupportVideo (video));         
        this.posts = data.posts.map(post => new SupportPost (post)); 
        this.glossary = data.glossary.map(glossary => new SupportGlossary (glossary)); 
    }
}
