import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from 'src/app/classes/global';

@Injectable({
  providedIn: 'root'
})
export class AdService {
  // variable that store the import of Globals Class that will help us to retrieve the urls for every service
  globals = Globals;

  // base url to use in this service
  baseUrl = `${this.globals.urlPosibleBase}${this.globals.ads.pathAds}`;

  constructor(private http: HttpClient) { }

  // Method to get all the ads available
  async getAll(): Promise<object> {
    const url = `${this.baseUrl}${this.globals.ads.list}`;
    return await this.http.get(url).toPromise();
  }
}
