<app-search [type]="type" (searchResults)="showResults($event)"></app-search>
<div class="container">
  <div class="row">
    <div class="col-4">
      <p class="section-title cursor__pointer title--active" [ngClass]="{'title--active': type === searchTypes.Company}"
        (click)="changeType(searchTypes.Company)">
        ORGANIZACIÓN
      </p>
      <hr class="hr-title" [ngClass]="{'hr--active': type === searchTypes.Company}">
    </div>
    <div class="col-4">
      <p class="section-title cursor__pointer" [ngClass]="{'title--active': type === searchTypes.Person}"
        (click)="changeType(searchTypes.Person)">
        PERSONAS
      </p>
      <hr class="hr-title" [ngClass]="{'hr--active': type === searchTypes.Person}">
    </div>
    <div class="col-4">
      <p class="section-title cursor__pointer" [ngClass]="{'title--active': type === searchTypes.Hashtag}"
        (click)="changeType(searchTypes.Hashtag)">
        HASHTAG
      </p>
      <hr class="hr-title" [ngClass]="{'hr--active': type === searchTypes.Hashtag}">
    </div>
  </div>
</div>

<div class="container remove-margin-top" *ngIf="type === searchTypes.Hashtag && results?.length">
  <app-short-publication *ngFor="let publication of results" [publication]="publication"></app-short-publication>
</div>

<div class="container remove-margin-top" *ngIf="type === searchTypes.Person && results?.length">
  <app-person *ngFor="let person of results" [person]="person"></app-person>
</div>

<div class="container remove-margin-top" *ngIf="type === searchTypes.Company && results?.length">
  <app-person *ngFor="let company of results" [person]="company"></app-person>
</div>

<div class="container" *ngIf="!results?.length">
  <div class="row">
    <div class="col">
      <span class="pl-2">No se encontraron resultados...</span>
    </div>
  </div>
</div>

<div *ngIf="isLoading" class="d-flex justify-content-center mt-5">
  <mat-spinner diameter="60"></mat-spinner>
</div>


