import { ProjectService } from './../../../services/project.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { BusinessModel } from '../../../models/businessModel/businessModel';
import { SnackbarService } from '../../../services/notifications/snackbar.service';
import { NavigationSteps } from 'src/app/classes/navigationSteps';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ProjectCompletedDialogComponent } from '../../shared/dialogs/project-completed-dialog/project-completed-dialog/project-completed-dialog.component';
import { ModelProgress } from 'src/app/models/modelProgress/modelProgress';

@Component({
  selector: 'app-describe-problem',
  templateUrl: './describe-problem.component.html',
  styleUrls: ['./describe-problem.component.css']
})
export class DescribeProblemComponent implements OnInit {

  // current step number of this page
  stepNumber = 1;
  // total number of steps
  totalNumSteps = 7;
  // Property to identify the current project
  currentProject: BusinessModel;
  // Property to identify the project id based on a url param
  projectId: string;
  // Property to identify the form
  forma: FormGroup;
  // Property to identify the textarea count word
  minWordsCompleted = false;
  // Indicates with colors the steps that are already done
  modelProgress: ModelProgress[];
  // Load steps of diagnostic in order to display navbar
  projectSteps = NavigationSteps;

  constructor(
    private snackbarService: SnackbarService,
    private fb: FormBuilder,
    private router: Router,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {
    // set up the form
    this.forma = this.fb.group({
      necesidad: ['', [Validators.maxLength(600)]],
      comunidad: ['', [Validators.maxLength(600)]]
    });
  }

  ngOnInit(): void {
    //this.scrollUpNavigation();
    this.route.params.subscribe((params: any) => {
      if (params.id) {
        this.projectId = params.id;
        this.setProjectValues();
      }
    });
  }

  // Method to set the current page at the top when navigation through submit buttons
  scrollUpNavigation(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  /**
   * Method to return the lenght of a textfield
   * @param str - string to look for the chars
   * Return - number of chars
   */
  countChars(str): number {
    if (str) {
      const chars = str.length;
      return chars;
    } else {
      return 0;
    }
  }

  // Method to set the current form fields data from  api's database according to the current model_guid
  setProjectValues(): void {
    this.projectService.getProjectStageData(this.projectId)
      .then(data => {
        this.currentProject = new BusinessModel(data.data);
        this.modelProgress = this.currentProject.modelProgress;
        this.formFields.necesidad.setValue(data.data.problem);
        this.formFields.comunidad.setValue(data.data.solution);
      })
      .catch(error => {
        this.router.navigate(['app/inicio']);
        this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error. ⚠️');
      }
      );
  }

  // Convenience getter for easy access to form fields
  get formFields() {
    return this.forma.controls;
  }

  /**
   * Method to manage the valid inputs with a css color
   */
  get comunidadNoValido() {
    return this.forma.get('comunidad').invalid && this.forma.get('comunidad').touched;
  }

  // Method to set the current project data from the current page's form
  generateProject(): void {
    if (this.projectId) {
      this.currentProject.problem = (this.formFields.necesidad.value!== null ? this.formFields.necesidad.value.replace(/(?:[\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, " ") : null) || null;
      this.currentProject.solution = (this.formFields.comunidad.value !== null ? this.formFields.comunidad.value.replace(/(?:[\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, " ") : null ) || null;
    } else {
      return;
    }
  }

  // Method to send data to store account info to be updated
  update(): void {
    if (this.forma.invalid) {
      this.snackbarService.showSnackBar('Cerrar', 'Revise los campos requeridos ⚠️');
      return;
    }
    this.generateProject();
    if (this.projectId) {
      this.projectService.updateProject(this.currentProject,this.projectId).subscribe(data => {
          this.snackbarService.showSnackBar('Cerrar', 'Datos guardados! 🎉');
          this.router.navigate(['app/idea-negocio', this.projectId]);
      }, (err) => { this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error. ⚠️'); });
    }
  }

}
