import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PendingBusinessModelsComponent } from '../../shared/dialogs/pending-business-models/pending-business-models.component';
import { ProjectService } from 'src/app/services/project.service';

@Component({
  selector: 'app-pending-businessmodels-modal',
  templateUrl: './pending-businessmodels-modal.component.html',
  styleUrls: ['./pending-businessmodels-modal.component.css']
})
export class PendingBusinessmodelsModalComponent implements OnInit {

  constructor(
    private projectService: ProjectService,
    private dialog: MatDialog) { }

  async ngOnInit() {
    await this.getBusinessModelsStatus();
  }

  /**
   * Method to get business model status
   */
  getBusinessModelsStatus(){
    this.projectService.getBusinessModelsStatus().then(response => {
      if (response['data'] && response['data']['isPendingBusinessModel']) {
        this.openModalPendingBusinessModel();
      }
    }).catch(err => {console.log(err)})
  }

  // open project completed dialog
  openModalPendingBusinessModel(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '550px';
    dialogConfig.data = { };

    //this.dialog.open(PendingBusinessModelsComponent, dialogConfig);
  }

}
