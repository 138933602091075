import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FirebaseService } from 'src/app/services/firebase-service/firebase.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FAQComponent implements OnInit {

  constructor(private location: Location,
    private firebase: FirebaseService) { }

  ngOnInit(): void {
  }

  // Go to previous page
  goBack(): void {
    this.location.back();
  }

}
