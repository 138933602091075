
export class ModelProgress{
    is_completed: boolean;
    module_number: number;
    percentage: number;
    total_questions: number;
    total_questions_answers: number;  
    
    constructor(data){
        this.is_completed = data.is_completed;
        this.module_number = data.module_number;
        this.percentage = data.percentage;
        this.total_questions = data.total_questions;
        this.total_questions_answers = data.total_questions_answers;
    }
}