import { Component, OnInit } from '@angular/core';
import { FileList } from 'src/app/classes/file-list';
import { UploadingFile, uploadingFileType } from 'src/app/classes/fileList/uploading-file';

@Component({
  selector: 'app-loading-files',
  templateUrl: './loading-files.component.html',
  styleUrls: ['./loading-files.component.scss']
})
export class LoadingFilesComponent implements OnInit {

  // List to control all the Uploading files
  fileList: FileList;

  constructor() { 
    this.fileList = new FileList();
  }

  ngOnInit(): void {
  }

  /**
   * Adds a new element to the list
   * @returns The Id of the element added
   */
  addNewElement(fileType: string): UploadingFile {
    let type: uploadingFileType;
    
    if( fileType.match("image") != null ){
      type = uploadingFileType.IMAGE;
    }
    else if( fileType.match("video") != null ){
      type = uploadingFileType.VIDEO;
    }
    else {
      throw new Error("Tipo de archivo no permitido");
    }

    return this.fileList.addNewFile(type);
  }

  // Removes an element from the list
  removeElement(element: UploadingFile): void {
    this.fileList.remove(element);
  }

  // Gets the class icon for the uploaded file
  getElementIcon(file: UploadingFile): string {
    if(file.getType() == uploadingFileType.IMAGE){
      return "fa-image";
    }
    else if(file.getType() == uploadingFileType.VIDEO){
      return "fa-video-camera";
    }
    return "";
  }
}
