import { Education } from '../education/education';
// Class to implement attributes of User
export class Profile {
    user_guid: string;
    first_name: string;
    last_name: string;
    readonly username: string;
    gender: string;
    phonenumber: string;
    birth_date: string;
    description: string;
    education: number;
    state: string;
    image_path: string;
    municipality: string;
    is_public: boolean;
    institution: object = null;

    constructor(data: any) {
      this.user_guid = data.user_guid;
      this.first_name = data.first_name;
      this.last_name = data.last_name;
      this.username = data.username;
      this.gender = data.gender;
      this.phonenumber = data.phonenumber || '';
      this.birth_date = data.birth_date;
      this.description = data.description;
      this.education = data.education_id;
      this.state = data.state.state_guid;
      this.municipality = data.municipality.municipality_guid;
      this.image_path = data.image_path;
      this.is_public = data.is_public;
      if(data.education_id == Education['Tecnológicos']){
        this.institution = { id: data.institution['institution_guid'], name: data.institution['description'] };
      }
    }
}
