import { Topic } from '../../../../models/topic/topic';
import { UserProfile } from '../../../../models/user-profile/user-profile';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Profile } from 'src/app/models/User/profile';
import { ContactService } from 'src/app/services/contact/contact.service';
import { SnackbarService } from 'src/app/services/notifications/snackbar.service';
import { UserService } from 'src/app/services/user/user.service';
import { UserStatus } from 'src/app/models/user-status/user-status.enum';
import { ReactSurveyModel, SurveyNG, StylesManager, surveyLocalization} from 'survey-angular';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit {
  // identify if there is a petition in progress
  isLoading = false;

  // user profile
  user: UserProfile;

  // Property to identify the whole list of user status
  userStatus = UserStatus;

  // identify if there is a petition in progress
  haveRequest = false;

  // identify if there is a petition in progress
  requestId: string;

  surveyJson = {
    "title": "Ayudanos a mejorar",
    "logoPosition": "right",
    "completedHtml": "Gracias por llenar la encuesta",
    "pages": [
     {
      "name": "page1",
      "elements": [
       {
        "type": "rating",
        "name": "q1",
        "isRequired": true,
        "title": "Del 1 al 7 ¿Qué tan útil te fue el formato de modelo de negocio para aterrizar mejor tu proyecto?",
        "rateMax": 7,
        "minRateDescription": "Nada útil",
        "maxRateDescription": "Completamente útil"
       },
       {
        "type": "rating",
        "name": "q2",
        "isRequired": true,
        "title": "Del 1 al 7 ¿Cómo describirías la experiencia de completar nuestro modelo de negocio?",
        "rateMax": 7,
        "minRateDescription": "Muy difícil",
        "maxRateDescription": "Muy fácil"
       },
       {
        "type": "boolean",
        "name": "q3",
        "isRequired": true,
        "title": "¿Usaste las herramientas de apoyo para el llenado del modelo de negocio POSiBLE?",
        "labelTrue": "Si"
       },
       {
        "type": "matrix",
        "name": "q4",
        "visibleIf": "{q3} = true",
        "title": "¿Cómo calificarías el contenido de cada módulo?",
        "isAllRowRequired": true, 
        "columns": [
         {
          "value": "1",
          "text": "Bueno"
         },
         {
          "value": "2",
          "text": "Regular"
         },
         {
          "value": "3",
          "text": "Malo"
         }
        ],
        "rows": [
          {
            "value": "videos",
            "text": "Videos animados"
          },
          {
            "value": "clase",
            "text": "Mini clase"
          },
          {
            "value": "articulos",
            "text": "Artículos"
          }
        ]
       },
       {
        "type": "radiogroup",
        "name": "q6",
        "visibleIf": "{q3} = true",
        "isRequired": true,
        "title": "Si no las usaste, dinos por qué:",
        "choices": [
         {
          "value": "1",
          "text": "No las encontré"
         },
         {
          "value": "2",
          "text": "El contenido no me pareció relevante"
         },
         {
          "value": "3",
          "text": "Poco tiempo disponible"
         },
         {
          "value": "4",
          "text": "No las necesité"
         }
        ]
       },
       {
        "type": "rating",
        "name": "q5",
        "isRequired": true,
        "title": "Del 1 al 7 ¿Te fueron útiles las herramientas de apoyo?",
        "rateMax": 7,
        "minRateDescription": "Nada útiles",
        "maxRateDescription": "Muy útiles"
       },
       {
        "type": "text",
        "name": "q7",
        "isRequired": true,
        "title": "¿Cómo podríamos mejorar tu experiencia en el llenado de tu modelo de negocios?"
       },
       {
        "type": "text",
        "name": "q8",
        "title": "Recibiste alguna ayuda de algun profesor, asesor o mentor para llenar tu modelo de negocio. En caso de afirmativo ¿Cuál es su nombre?"
       }
      ]
     }
    ]
   };

  constructor(
    private userService: UserService,
    private dialogRef: MatDialogRef<SurveyComponent>
  ) { }

  ngOnInit(): void {
    StylesManager.applyTheme('modern');
    let survey = new ReactSurveyModel(this.surveyJson);
    survey.onComplete.add(this.onComplete.bind(this));
    surveyLocalization.locales[surveyLocalization.defaultLocale].requiredError = "Este campo es obligatorio";
    SurveyNG.render("surveyElement", { model: survey });
  }

  onComplete(data): void {
    this.userService.saveSurvey(data.data).then(data => {
      this.dialogRef.close(true);
    });
  }

  

}
