<div *ngIf="isLoading; else elseLoading" class="d-flex justify-content-center mt-5">
    <mat-spinner diameter="60"></mat-spinner>
</div>
<ng-template #elseLoading>
    <div class="container header animated fadeIn fast">
        <div class="row align-items-center">
            <div class="col-3">
                <img *ngIf="!user.image_path" clang-template class="img-circle" height="56px" width="56px" src="assets/img/home/profile_img.png">
                <img *ngIf="user.image_path" clang-template class="img-circle" height="56px" width="56px" [src]="user.image_path">
                <span class="Online"></span>
            </div>
            <div class="col-9 d-flex flex-column animated fadeIn fast">
                <div *ngIf="!editable">
                    <p class="name">{{ user.first_name }} {{ user.last_name }}</p>
                    <button class="edit" (click)="editable = true">
            <i class="fa fa-edit"></i> Editar perfil
          </button>
                </div>
                <div *ngIf="editable">
                    <input #image #Image type="file" (change)="handleFileInput($event.target.files)" style="display:none;" accept="image/*" />
                    <button class="btn new-photo" (click)="image.click()">
            <i class="fa fa-edit"></i> Subir nueva foto de perfil
          </button>
                    <button class="btn delete-photo" *ngIf="user.image_path" (click)="deleteImage()">
            Eliminar
          </button>
                    <button class="btn edit" (click)="editable = false">
            <i class="fa fa-cancel"></i> Cancelar
          </button>
                </div>
            </div>
        </div>
    </div>
    <div id="no-editable" *ngIf="!editable">
        <div class="container about-user animated fadeIn">
            <div class="row align-items-center">
                <div class="col-12 mt-2">
                    <div class="field">
                        <div class="title">
                            <i class="icon icon-user"></i> <span class="label">Acerca de mi:</span>
                        </div>
                        <p class="value">{{ user.description }}</p>
                    </div>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-12 mt-2">
                    <div class="field">
                        <div class="title">
                            <i class="icon icon-study"></i><span class="label">Estudios:</span>
                        </div>
                        <p class="value">{{ educations[user.education] }} {{ getSchoolType(user.is_public) }} {{getSchoolTech()}}</p>
                    </div>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-12 mt-2">
                    <div class="field">
                        <div class="title">
                            <i class="icon icon-star"></i> <span class="label">Intereses:</span>
                        </div>
                        <div class="container-fluid">
                            <p class="value">
                                <span *ngFor="let topic of topics; index as i">
                  {{ topic.description }}{{ (i < (topics.length - 1)) ? ', ' : ''  }}
                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="editable" *ngIf="editable">
        <div class="container header animated fadeIn">
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="row align-items-center">
                    <div class="col-12 section">
                        <div class="form-group">
                            <label for="first_name">Nombre(s):</label>
                            <input type="text" formControlName="first_name" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.controls.first_name.errors }">
                            <div *ngIf="submitted && form.controls.first_name.errors" class="invalid-feedback">
                                <div *ngIf="form.controls.first_name.errors.required">Nombre(s) requerido(s)</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="last_name">Apellidos:</label>
                            <input type="text" formControlName="last_name" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.controls.last_name.errors }">
                            <div *ngIf="submitted && form.controls.last_name.errors" class="invalid-feedback">
                                <div *ngIf="form.controls.last_name.errors.required">Apellido(s) requerido(s)</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="email">Email:</label>
                            <input type="text" class="form-control" value="{{ user.username }}" disabled>
                        </div>
                        <div class="form-group">
                            <label for="gender">Genero:</label>
                            <select type="text" formControlName="gender" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.controls.gender.errors }">
                <option value="" selected disabled>Género</option>
                <option [value]="gender.key" *ngFor="let gender of genders | keyvalue">{{gender.value}}</option>
              </select>
                            <div *ngIf="submitted && form.controls.gender.errors" class="invalid-feedback">
                                <div *ngIf="form.controls.gender.errors.required">Genero requerido</div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="phonenumber">Teléfono:</label>
                            <input maxlength="13" (input)="validatePhoneNo($event.target)" type="text" formControlName="phonenumber" class="form-control" placeholder="Teléfono" [ngClass]="{ 'is-invalid': submitted && form.controls.phonenumber.errors }">
                            <div *ngIf="submitted && form.controls.phonenumber.errors" class="invalid-feedback">
                                <div *ngIf="form.controls.phonenumber.errors.required">Teléfono requerido</div>
                                <div *ngIf="formFields.phonenumber.errors.minlength || formFields.phonenumber.errors.maxlength">10 dígitos requeridos</div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="birth_date">Cumpleaños:</label>
                            <input type="date" formControlName="birth_date" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.controls.birth_date.errors }">
                            <div *ngIf="submitted && form.controls.birth_date.errors" class="invalid-feedback">
                                <div *ngIf="form.controls.birth_date.errors.required">Cumpleaños requerido</div>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="!statesLoading">
                            <label for="state">Estado:</label>
                            <select formControlName="state" class="form-control" (change)="getMunicipalities()" [ngClass]="{ 'is-invalid': submitted && form.controls.state.errors }">
                <option *ngFor="let state of states" [ngValue]="state.state_guid">{{ state.description }}</option>
              </select>
                            <div *ngIf="submitted && form.controls.state.errors" class="invalid-feedback">
                                <div *ngIf="form.controls.state.errors.required">Estado requerido</div>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="!municipalityLoading">
                            <label for="municipality">Municipio:</label>
                            <select formControlName="municipality" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.controls.municipality.errors }">
                              <option *ngFor="let municipality of municipalities" [ngValue]="municipality.municipality_guid">
                                {{ municipality.description }}</option>
                            </select>
                            <div *ngIf="submitted && form.controls.municipality.errors" class="invalid-feedback">
                                <div *ngIf="form.controls.municipality.errors.required">Municipio requerido</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <a href="app/perfil/intereses" type="button" class="btn btn-topics btn-block">
                                <i class="icon icon-star"></i> Editar intereses
                            </a>
                        </div>
                    </div>
                    <div class="col-12 section">
                        <div class="form-group">
                            <label for="description">Acerca de mí:</label>
                            <textarea type="text" formControlName="description" class="form-control" maxlength="250"></textarea>
                            <div class="txt_advice text-right">
                                <p class="mt-2" *ngIf="countChars(formFields.description.value) == 0"> Máx 250 caracteres</p>
                                <p class="mt-2" *ngIf="countChars(formFields.description.value) >= 1 && countChars(formFields.description.value) < 250">
                                    {{250 - countChars(formFields.description.value) }} caracteres faltantes.</p>
                                <p class="mt-2 text-danger" *ngIf="countChars(formFields.description.value) == 250"> Máximo de caracteres alcanzado.
                                </p>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="education">Estudios:</label>
                            <select formControlName="education" class="form-control" [ngClass]="{ 'is-invalid': submitted && form.controls.education.errors }" (change)="educationChange($event)">
                              <option [value]="education.key" *ngFor="let education of educationsTypes | keyvalue">{{education.value}}
                              </option>
                            </select>
                            <div *ngIf="submitted && form.controls.education.errors" class="invalid-feedback">
                                <div *ngIf="form.controls.education.errors.required">Educación requerido</div>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="form.controls.education.value == educations['Licenciatura']">
                            <label for="is_public">Tipo:</label>
                            <div class="d-flex flex-row">
                                <div class="form-check mr-4" *ngFor="let type of schoolTypes">
                                    <input class="form-check-input" type="radio" name="is_public" [value]="type.id" formControlName="is_public">
                                    <label class="form-check-label" [for]="type.description">
                    <span class="txt_common_bold"> {{ type.description }} </span>
                  </label>
                                </div>
                            </div>
                        </div>
                        <div class="form-group" *ngIf="form.controls.education.value == educations['Tecnológicos']">
                            <label for="">Tecnológicos</label>
                            <div class="d-flex flex-row">
                                <ng-select [items]="tech_schools" formControlName="institution" [virtualScroll]="true" [loading]="loading" [ngClass]="{ 'is-invalid': submitted && form.controls.institution.errors }" notFoundText="No se encontraron resultados" loadingText="Cargando..."
                                    placeholder="Elija una institución" [clearOnBackspace]="false" clearAllText="Quitar" (open)="resetQuery()" (scrollToEnd)="onScrollToEnd()" bindLabel="name" [typeahead]="select$" [(ngModel)]="selectedInstitution" (clear)="resetQuery()">
                                </ng-select>
                            </div>
                            <div *ngIf="submitted && form.controls.institution.errors" class="text-error">
                                <div *ngIf="form.controls.institution.errors.required">Institución requerida</div>
                            </div>
                        </div>
                        <div class="form-group mb-5">
                            <button type="submit" class="btn btn-save btn-block">Guardar</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>