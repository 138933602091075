import { Component, OnInit, Input } from '@angular/core';
import { Comment } from 'src/app/models/comment/comment';
import { UserStatus } from 'src/app/models/user-status/user-status.enum';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit {
  @Input() comment: Comment;

  // property to display the whole text
  showMore = false;

  // Property to identify the whole list of user status
  userStatus = UserStatus;

  constructor() { }

  ngOnInit(): void {
  }

  // Method to toggle between showing only 300 characteres or the whole text
  toggleShowMore = (): boolean => this.showMore = !this.showMore;

}
