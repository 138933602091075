import { ModelProgress } from '../modelProgress/modelProgress';
export class BusinessModel {
    name: string;
    description: string;
    categories: [];
    problem: string;
    solution: string;
    descriptionProduct: string;
    optionSolution: string;
    clientTypes: any;
    genders: any;
    acquisitionsLevel: string;
    activitiesImpulse: string;
    ageRanges: [];
    customerDescription: string;
    similarSolutions: string;
    client_solution: string;
    solutionAdditions: string;
    haveAlliance: boolean;
    allianceDescription: string;
    publicityTypes: [];
    otherPublicityType: string;
    sellTypes: [];
    otherSellType: string;
    incomeTypes: [];
    otherIncomes: string;
    haveCost: boolean;
    descriptionCost: string;
    supportTypes: [];
    otherSupport: string;
    generateJob: boolean;
    jobCount: string;
    operating: boolean;
    productUsed: boolean;
    productPay: boolean;
    pay: string;
    haveWeb: string;
    haveName: boolean;
    modelProgress: ModelProgress[];
    web_page: string;
    announcement_id: string;
    customCategories: string;

    constructor(data: any) {
        this.name = data.name || null;
        this.description = data.description || null;
        this.categories = (data.categories) ? data.categories.map(category => category.category_guid) : [];
        this.problem = data.problem || null;
        this.solution = data.solution || null;
        this.descriptionProduct = data.descriptionProduct || null;
        this.optionSolution = data.optionSolution?.optionSolution_guid || null;
        this.clientTypes = (data.clientTypes) ? data.clientTypes.map(type => type.clientType_guid) : [];
        this.genders = (data.genders) ? data.genders.map(gender => gender.gender_guid) : [];
        this.acquisitionsLevel = data.acquisitionsLevel?.acquisitionsLevel_guid || null;
        this.activitiesImpulse = data.activitiesImpulse || null;
        this.ageRanges = (data.ageRanges) ? data.ageRanges.map(age => age.ageRange_guid) : [];
        this.customerDescription = data.customerDescription || null;
        this.similarSolutions = data.similarSolutions || null;
        this.client_solution = data.client_solution || null;
        this.solutionAdditions = data.solutionAdditions || null;
        this.haveAlliance = data.haveAlliance || false;
        this.allianceDescription = data.allianceDescription || null;
        this.publicityTypes = (data.publicityTypes) ? data.publicityTypes.map(type => type.publicityType_guid) : [];
        this.otherPublicityType = data.otherPublicityType || null;
        this.sellTypes = (data.sellTypes) ? data.sellTypes.map(type => type.sellType_guid) : [];
        this.otherSellType = data.otherSellType || null;
        this.incomeTypes = (data.incomeTypes) ? data.incomeTypes.map(type => type.incomeType_guid) : [];
        this.otherIncomes = data.otherIncomes || null;
        this.haveCost = data.haveCost || false;
        this.descriptionCost = data.descriptionCost || null;
        this.supportTypes = (data.supportTypes) ? data.supportTypes.map(type => type.supportType_guid) : [];
        this.otherSupport = data.otherSupport || null;
        this.generateJob = data.generateJob || false;
        this.jobCount = data.jobCount?.jobCount_guid || null;
        this.operating = data.operating || false;
        this.productUsed = data.productUsed || false;
        this.productPay = data.productPay || false;
        this.pay = data.pay || null;
        this.haveWeb = data.haveWeb || false;
        this.haveName = data.haveName || false;
        this.modelProgress = data.modelprogress ? data.modelprogress.map( model => new ModelProgress(model)) : [];
        this.web_page = data.web_page || null;
        this.customCategories = data.customCategories || null;
    }
}
