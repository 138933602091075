export const NavigationSteps = {
    firstStep: { id: 0, name: 'Registra tu proyecto', url: '/nuevo-proyecto', urlBack: '/inicio', stepNumber: 0 },
    secondStep: { id: 1, name: 'Describe el problema', url: '/describe-problema', urlBack: '/nuevo-proyecto', stepNumber: 1 },
    thirdStep: { id: 2, name: 'Idea de negocio', url: '/idea-negocio', urlBack: '/describe-problema', stepNumber: 2 },
    fourthStep: { id: 3, name: 'Describe a tus clientes', url: '/describe-clientes', urlBack: '/idea-negocio', stepNumber: 3 },
    fifthStep: { id: 4, name: 'Propuesta de valor', url: '/propuesta-valor', urlBack: '/describe-clientes', stepNumber: 4 },
    sixthStep: { id: 5, name: 'Publicidad y distribucion', url: '/publicidad-distribucion', urlBack: '/propuesta-valor', stepNumber: 5},
    seventhStep: { id: 6, name: 'Como generas ingresos', url: '/generar-ingreso', urlBack: '/publicidad-distribucion', stepNumber: 6 },
    eightStep: { id: 7, name: 'Tracción y crecimiento', url: '/traccion-crecimiento', urlBack: '/generar-ingreso', stepNumber: 7 }
};
