<div class="main-container row">
    <div class="container-header col-12 pl-md-5 pr-md-5">
        <div class="content-header">
            <div class="row">
                <div class="col-12 col-md-4 col-lg-4 mt-4 mb-4 d-flex align-items-center">
                    <img src="../../../../assets/img/mail/Posible_amarillo.png" alt="" class="img-fluid">
                </div>
                <div class="col-12 col-md-8 col-lg-8 mt-4 menu-landing d-flex align-items-center justify-content-lg-end">
                    <ul class="d-md-flex p-0">
                        <li on-click="scrollFn('convocatorias')">Convocatoria</li>
                        <li on-click="scrollFn('pasos')">Pasos a seguir</li>
                        <li on-click="scrollFn('beneficios')">Beneficios</li>
                    </ul>
                </div>

            </div>
            <!-- <div class="row mb-4">
                <div class="col-12 col-lg-4 mt-5 text-center">
                    <img src="../../../../assets/img/microsites/logo_reto.png" alt="" style="width: 250px;">
                    <h3 class="text-center mb-3 text-chiapas">ZACATECAS</h3>
                    <p class="txt_common" [innerHTML]="currentMicroSiteStateInfo['text']"></p>
                    <div class="mt-5 mb-3 animated fadeIn fast d-flex justify-content-center d-md-block">
                        <a class="button-register" [routerLink]="['/crear-cuenta']">Regístrate</a>
                    </div>
                </div>
                <div class="col-12 col-lg-7 offset-lg-1 mt-3 mt-md-0">
                    <img src="../../../../assets/img/retoPosible/chiapas-seccion1.jpg" alt="" class="img-fluid">
                </div>
            </div> -->
        </div>
    </div>
    <div class="container-fluid container-logo col-12 pl-md-5 pr-md-5">
        <div class="container">
            <div class="row mb-4">
                <div class="col-12 col-lg-4 mt-5 text-center">
                    <img src="../../../../assets/img/microsites/Logotipo_RP_Zacatecas.png" alt="" style="width: 250px;" class="mb-3">
                    <!-- <h3 class="text-center mb-3 text-zacatecas">ZACATECAS</h3> -->
                    <p class="txt_common" [innerHTML]="currentMicroSiteStateInfo['text']"></p>
                    <div class="mt-5 mb-3 animated fadeIn fast d-flex justify-content-center d-md-block">
                        <a class="button-register" [routerLink]="['/crear-cuenta']">Regístrate</a>
                    </div>
                </div>
                <div class="col-12 col-lg-7 offset-lg-1 mt-3 mt-md-0">
                    <img src="../../../../assets/img/retoPosible/chiapas-seccion1.jpg" alt="" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid container-convocatory">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-6 col-info mt-4 mt-lg-0">
                    <h5>¿Qué es Reto POSiBLE Zacatecas?</h5>
                    <p>
                        Es un programa que busca inspirar y apoyar a formadores y emprendedores potenciales dentro de la comunidad universitaria y centros de incubación en el Estado de Zacatecas, por lo cual tiene dos modalidades de participación:
                    </p>
                    <p>
                        Modalidad 1: Formadores, perfil que incluye a: personas vinculadas con la enseñanza del emprendimiento en instituciones de nivel superior, personas cuyo impacto profesional es a través de centros de incubación, considerados perfiles clave en la formación
                        de emprendedores de alto impacto a quienes Reto POSiBLE ofrece capacitación para adquirir conocimientos y herramientas para la enseñanza del emprendimiento de alto impacto.
                    </p>
                    <p>
                        Modalidad 2: Estudiantes y recién egresados de nivel superior de instituciones públicas y privadas, a quienes Reto POSiBLE ofrece capacitación para adquirir conocimientos y herramientas, cuya aplicación incrementa el nivel de éxito al emprender ideas
                        de negocio.
                    </p>
                </div>
                <div class="col-12 col-lg-6 col-info mt-4 mt-lg-0">
                    <h5>¿Quiénes pueden postularse a Reto POSiBLE Zacatecas?</h5>
                    <p>
                        Modalidad 1: Docentes de nivel superior que impartan materias relacionadas con emprendedores, tengan al menos 5 horas semanales frente a grupo, se encuentren asesorando proyectos y residan en Zacatecas. Personal a cargo de programas de emprendimiento,
                        incubación o aceleración universitarios, con al menos 6 meses en el cargo y residan en Zacatecas.
                    </p>
                    <p>
                        Modalidad 2: Estudiantes de nivel superior y recién egresados de instituciones públicas y privadas, que residan en Zacatecas, con ideas de proyectos innovadores o emprendimientos en su etapa inicial, con potencial de escalabilidad y cuyo impacto sea positivo
                        en la mejora de sus entornos.
                    </p>
                </div>
                <div class="col-12 d-flex align-items-center justify-content-center mt-4" id="convocatorias">
                    <div class="row d-flex align-items-center justify-content-center">
                        <div class="col-12 col-md-6">
                            <img src="../../../../assets/img/microsites/Logotipo_RP_Zacatecas.png" alt="" style="width: 350px;" class="img-fluid">
                        </div>
                        <div class="col-12 col-md-6 mt-3 mt-md-0 mt-lg-0">
                            <div class="row">
                                <!-- <div class="col-12 mb-3">
                                    <a class="button-register" href="assets/RP_Zacatecas_Convocatoria_DOCENTES.pdf">Convocatoria de formadores</a>
                                </div> -->
                                <div class="col-12">
                                    <a class="button-register-students" href="assets/RP_Zacatecas_Convocatoria_Alumnos.pdf">Convocatoria de alumnos</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid container-steps" id="pasos">
        <div class="container mt-5 mb-5">
            <div class="row row-teachers">
                <div class="col-12 text-center mb-4">
                    <h1>Pasos a seguir si eres docente </h1>
                </div>
                <div class="col-12 col-md-6 col-lg-3 mb-4">
                    <div class="card">
                        <div class="card-header pt-4">
                            <div class="div-circle">
                                <img src="../../../../assets/img/retoPosible/certificado.png" alt="" class="img-fluid">
                            </div>
                        </div>
                        <div class="card-body">
                            <p class="card-text pl-3 pr-3 text-center">Regístrate en Reto POSIBLE Zacatecas 2022</p>

                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3 mb-4">
                    <div class="card">
                        <div class="card-header pt-4">
                            <div class="div-circle">
                                <img src="../../../../assets/img/retoPosible/megafono.png" alt="" class="img-fluid">
                            </div>
                        </div>
                        <div class="card-body">
                            <p class="card-text pl-3 pr-3 text-center">Regístrate y acredita el MOOC, Emprender es un reto, hazlo posible.</p>

                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3 mb-4">
                    <div class="card">
                        <div class="card-header pt-4">
                            <div class="div-circle">
                                <img src="../../../../assets/img/retoPosible/seleccion.png" alt="" class="img-fluid">
                            </div>
                        </div>
                        <div class="card-body">
                            <p class="card-text pl-3 pr-3 text-center">Selección de formadores</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3 mb-4">
                    <div class="card">
                        <div class="card-header pt-4">
                            <div class="div-circle">
                                <img src="../../../../assets/img/retoPosible/conocimiento.png" alt="" class="img-fluid">
                            </div>
                        </div>
                        <div class="card-body">
                            <p class="card-text pl-3 pr-3 text-center">Campamento virtual</p>

                        </div>
                    </div>
                </div>
            </div>
            <div class="row row-students mt-4">
                <div class="col-12 text-center mb-4">
                    <h1>Pasos a seguir si eres estudiante</h1>
                </div>
                <div class="col-12 col-md-6 col-lg-3 mb-4">
                    <div class="card">
                        <div class="card-header pt-4">
                            <div class="div-circle">
                                <img src="../../../../assets/img/retoPosible/certificado.png" alt="" class="img-fluid">
                            </div>
                        </div>
                        <div class="card-body">
                            <p class="card-text pl-3 pr-3 text-center">Registra tu idea</p>

                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3 mb-4">
                    <div class="card">
                        <div class="card-header pt-4">
                            <div class="div-circle">
                                <img src="../../../../assets/img/retoPosible/megafono.png" alt="" class="img-fluid">
                            </div>
                        </div>
                        <div class="card-body">
                            <p class="card-text pl-3 pr-3 text-center">Regístrate y acredita el MOOC, El reto del emprendimiento de alto impacto.</p>

                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3 mb-4">
                    <div class="card">
                        <div class="card-header pt-4">
                            <div class="div-circle">
                                <img src="../../../../assets/img/retoPosible/seleccion.png" alt="" class="img-fluid">
                            </div>
                        </div>
                        <div class="card-body">
                            <p class="card-text pl-3 pr-3 text-center">Presenta tu idea en paneles de emprendimiento<br> Selección de proyectos
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3 mb-4">
                    <div class="card">
                        <div class="card-header pt-4">
                            <div class="div-circle">
                                <img src="../../../../assets/img/retoPosible/conocimiento.png" alt="" class="img-fluid">
                            </div>
                        </div>
                        <div class="card-body">
                            <p class="card-text pl-3 pr-3 text-center">Campamento virtual</p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid container-benefits" id="beneficios">
        <div class="container pt-5 pb-5">
            <div class="row">
                <div class="col-12 mb-4 text-center">
                    <h1>Beneficios del programa</h1>
                </div>
                <div class="col-12 col-md-12 col-lg-6 col-benefits mb-3 mb-lg-0">
                    <h2 class="mt-4 mb-4 text-center">Docentes</h2>
                    <div class="row benefits teachers">
                        <div class="col-12 col-md-2 text-center d-md-flex align-items-center">
                            <img src="../../../../assets/img/retoPosible/manual.png" alt="" class="img-fluid">
                        </div>
                        <div class="col-12 col-md-10 d-flex align-items-center">
                            <p>Guía digital de enseñanza para el emprendimiento de alto impacto que incluye contenidos teóricos, actividades sugeridas en clase, glosario, plantillas de apoyo y vídeos de repaso.</p>
                        </div>
                    </div>
                    <div class="row benefits teachers">
                        <div class="col-12 col-md-2 text-center d-md-flex align-items-center">
                            <img src="../../../../assets/img/retoPosible/social.png" alt="" class="img-fluid">
                        </div>
                        <div class="col-12 col-md-10 d-flex align-items-center">
                            <p>Comunidad para docentes en Facebook donde intercambiarán experiencias, recibirán actualizaciones de contenidos y compartirán evidencia de su trabajo.</p>
                        </div>
                    </div>
                    <div class="row benefits teachers">
                        <div class="col-12 col-md-2 text-center d-md-flex align-items-center">
                            <img src="../../../../assets/img/retoPosible/actualizacion-de-sistema.png" alt="" class="img-fluid">
                        </div>
                        <div class="col-12 col-md-10 d-flex align-items-center">
                            <p>Actualizaciones constantes para el desarrollo de su profesión.</p>
                        </div>
                    </div>

                </div>
                <div class="col-12 col-md-12 col-lg-6 col-benefits mb-3 mb-lg-0">
                    <h2 class="mt-4 mb-4 text-center">Estudiantes</h2>
                    <div class="row benefits students">
                        <div class="col-12 col-md-2 text-center d-md-flex align-items-center">
                            <img src="../../../../assets/img/retoPosible/manual.png" alt="" class="img-fluid">
                        </div>
                        <div class="col-12 col-md-10 d-flex align-items-center">
                            <p>Visibilidad para dar a conocer su idea de negocio en medios masivos y eventos especiales.</p>
                        </div>
                    </div>
                    <div class="row benefits students">
                        <div class="col-12 col-md-2 text-center d-md-flex align-items-center">
                            <img src="../../../../assets/img/retoPosible/social.png" alt="" class="img-fluid">
                        </div>
                        <div class="col-12 col-md-10 d-flex align-items-center">
                            <p>Posibilidad de acceder a mentorías presenciales y en línea, con mentores expertos.</p>
                        </div>
                    </div>
                    <div class="row benefits students">
                        <div class="col-12 col-md-2 text-center d-md-flex align-items-center">
                            <img src="../../../../assets/img/retoPosible/actualizacion-de-sistema.png" alt="" class="img-fluid">
                        </div>
                        <div class="col-12 col-md-10 d-flex align-items-center">
                            <p>Red de contactos y vinculación con diferentes actores del ecosistema emprendedor.</p>
                        </div>
                    </div>

                </div>
                <div class="col-12 col-md-12 col-lg-6 offset-lg-3 d-flex align-items-center mt-4">
                    <img src="../../../../assets/img/retoPosible/chiapas-seccion2.jpg" alt="" class="img-fluid">
                </div>
            </div>
        </div>

    </div>
    <div class="container-fluid container-conoce">
        <div class="container text-center pt-4 pb-4">
            <div class="row">
                <div class="col-12">
                    <h1>Conoce más acerca de Reto POSiBLE</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid container-video">
        <div class="container text-center pt-4">
            <div class="row">
                <div class="col-12">
                    <iframe width="70%" height="500" src="https://www.youtube.com/embed/3Py7TFGm6aw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div class="col-12 mt-5 col-logos">
                    <img src="../../../../assets/img/microsites/Logos.png" alt="Logos" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
    <footer class="d-flex align-items-center">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-6 offset-lg-2">
                    <a [routerLink]="['/terminos']">Términos y condiciones</a>
                    <a [routerLink]="['/aviso-privacidad']">Aviso de privacidad</a>
                    <a href="assets/RP_Zacatecas_Convocatoria_Alumnos.pdf" target="_blank">Convocatoria de alunmnos</a>

                </div>
                <div class="col-12 col-md-6 col-lg-4 text-md-center text-lg-left">
                    <h4 class="mb-0">Contactanos por</h4>
                    <div class="div-icons">
                        <a href="https://api.whatsapp.com/send/?phone=525566223249&text=Hola%2C+tengo+una+duda+🟡" target="_blank"><i class="fa fa-whatsapp"></i></a>
                        <a href="mailto:reto.posible@fundaciontelevisa.org"><i class="fa fa-envelope-o" aria-hidden="true"></i></a>
                    </div>

                </div>
            </div>
        </div>
    </footer>
</div>
<!-- main-container-->