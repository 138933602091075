import { Directive, ElementRef, Renderer2, OnInit, Input } from '@angular/core';

@Directive({
  selector: '[appSearchHighlight]'
})
export class SearchHighlightDirective implements OnInit {
  // Value that receives a string to evaluate
  @Input() text: string;

  // Value that receives a string to evaluate
  @Input() textSearched: string;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    const div = this.el.nativeElement;
    const p = this.renderer.createElement('p');
    p.innerHTML = this.identifySearch();
    p.setAttribute('class', 'description');
    this.renderer.appendChild(div, p);
  }

  // Method that will find and replace all the hashtags for an html span element
  identifyHashtags = (): string => {
    const regex = /(^|\s)(#[a-z\d-]+)/gi;
    const str = this.text;
    const subst = `$1<span class="hashtags">$2</span>`;

    // The substituted value will be contained in the result variable
    const result = str.replace(regex, subst);
    return result;
  }

  // Method that will find and replace all the hashtags for an html span element
  identifySearch = (): string => {
    const regex = new RegExp(`(^|)(${this.textSearched}+)`);
    const str = this.identifyHashtags();
    const subst = `$1<span class="highlight">$2</span>`;
    // The substituted value will be contained in the result variable
    const result = str.replace(regex, subst);
    return result;
  }

}
