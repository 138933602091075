<div class="main-container" style="background-color: #F7F7F7;">
  <div class="container animated fadeIn fast px-4">
    <header style="padding-top:40px">
      <div class="txt_title">¡Bienvenido!</div>
    </header>
    <section>
      <p class="txt_common">
        <br>
        Inicia sesión y podrás formar parte de la comunidad POSiBLE.
      </p>
      <div class="mt-5 animated fadeIn fast">
        <form autocomplete="off" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <div class="input-group input-group-lg">
            <input type="email" required class="form-control input_posible" aria-label="Large"
              placeholder="Correo electrónico" formControlName="username" (change)="mailHelperInput($event)"
              [class.is-invalid]="submitted && formFields.username.errors">
          </div>
          <div *ngIf="submitted && formFields.username.errors" class="errors pt-1">
            <small *ngIf="formFields.username.errors.required" class="text-danger animated fadeIn">
              El correo electronico es obligatorio.
            </small>
            <small *ngIf="formFields.username.errors.email" class="text-danger animated fadeIn">
              El formato del correo electronico es invalido.
            </small>
          </div>
          <br>

          <div class="input-group input-group-lg">
            <input [type]="showPassword ? 'text' : 'password'" class="form-control input_posible_pass "
              aria-label="Large" placeholder="Contraseña" formControlName="password"
              [class.is-invalid]="submitted && formFields.password.errors">
            <div class="input-group-append">
              <span (click)="togglePassword()" class="input-group-text" id="basic-addon2">
                <i style='font-size:x-large;' class='fa' aria-hidden="true"
                  [ngClass]="{'fa-eye': showPassword, 'fa-eye-slash': !showPassword}"></i>
              </span>
            </div>
          </div>
          <div *ngIf="submitted && formFields.password.errors" class="errors pt-1">
            <small *ngIf="formFields.password.errors.required" class="text-danger animated fadeIn">
              La contraseña es obligatoria.
            </small>
          </div>
          <br>
          <div style="text-align: center;">
            <p>
              <a [routerLink]="['/recuperar-contrasena']" class="mt-3 recover_pass" style="text-align: center;"> Olvidé
                mi contraseña</a>
            </p>
            <button type="submit" class="btn-lg btn btn-warning popup-button submit__btn "> <span class="txt_log_btn">
                Iniciar sesión
              </span></button>
          </div>
        </form>
      </div>
      <div class="mt-5">
        <!-- <button class="btn-lg btn btn-block btn-light btn_txt_social btn_social"><i
            class="fa fa-linkedin-square linkedin-icon" aria-hidden="true"></i> Iniciar con Linkedin</button>
        <button class="btn-lg btn btn-block btn-light btn_txt_social btn_social"><i class="fa fa-google"
            aria-hidden="true" style=" font-size:x-large;"></i> Iniciar con Google</button> -->
        <button class="btn-lg btn btn-block btn-warning btn_register">
          <a class="txt_registrarme" [routerLink]="['/crear-cuenta']"> Registrarme</a>
        </button>
      </div>
    </section>
  </div>
  <footer>
    <div class="pt-2">
      <a (click)="openModal()" class="btn-openmodal">
        <span class="txt_benefits"><i class="fa fa-arrow-up" aria-hidden="true"></i> Conoce los beneficios</span>
      </a>
    </div>
  </footer>
</div> <!-- main-container-->
