<div class="card mb-3 publication">
  <div class="card-body">
    <div class="row pb-1 no-gutters" [ngClass]="{'ad-header': publication.isAd}">
      <div class="col-auto">
        <img *ngIf="publication.userPicture && !publication.feedPosible else noImg" class="img-circle" height="56px" width="56px" [src]="publication.userPicture">
        <ng-template #noImg>
          <img class="img-circle" *ngIf="!publication.feedPosible" height="54px" width="54px" src="assets/img/home/profile_img.png"
            alt="">
          <img class="img-circle" *ngIf="publication.feedPosible" height="54px" width="54px" src="assets/img/home/posibleAdmin.png"
            alt="">
        </ng-template>
        <span class="online" *ngIf="publication.userStatus === userStatus.Online"></span>
        <span class="inactive" *ngIf="publication.userStatus === userStatus.Inactive"></span>
        <span class="offline" *ngIf="publication.userStatus === userStatus.Offline"></span>
      </div>
      <div class="col pt-1 pl-4 d-flex flex-column">
        <span *ngIf="!publication.feedPosible" class="txt_title_feed_profile">{{ publication.company }}</span>
        <p class="txt_name" *ngIf="!publication.isAd else advertisement">
          <span *ngIf="!publication.feedPosible">{{ publication.firstName }} {{ publication.lastName }}</span>
          <span *ngIf="publication.feedPosible">Team Posible</span>
        </p>
        <ng-template #advertisement>
          <p class="txt_name">
            PUBLICIDAD
          </p>
        </ng-template>
      </div>
      <div class="col-12" *ngIf="sharedPublication">
        <p class="txt_date mb-0 mt-2">
          {{ publication.date }}
        </p>
      </div>
    </div>
    <app-image-slider [publication]="publication"></app-image-slider>

    <ng-container *ngIf="!showMore else smallerDescription">
      <p class="txt_date mb-0 mt-2" *ngIf="!sharedPublication">
        {{ publication.date }}
      </p>
      <div appHighlight [text]="publication.description | slice:0:300"></div>
      <span class="showMore" *ngIf="!showMore && publication.description.length >= 300" (click)="toggleShowMore()"> Ver
        más</span>
    </ng-container>
    <ng-template #smallerDescription>
      <p class="txt_date mb-0 mt-2">
        {{ publication.date }}
      </p>
      <div appHighlight [text]="publication.description"></div>
    </ng-template>
  </div>

  <app-shared-publication [publication]="sharedPublication"></app-shared-publication>
  <div *ngIf="!isShareable">
    <div class="card-body interactions__box" [ngClass]="{'ad-footer': publication.isAd}">
      <div class="row interactions__row justify-content-center">
        <div class="col-auto d-flex align-items-center pr-0">
          <button class="btn_rounded" [ngClass]="{'liked': publication.liked}" [disabled]="isLoading"
            (click)="like(publication.guid)"><i class="fa fa-thumbs-o-up" aria-hidden="true"></i></button>
          <span class="likes" *ngIf="publication.likes">
            {{publication.likes}} {{publication.likes > 1 ? 'LIKES' : 'LIKE'}}
          </span>
        </div>
        <div class="col">
          <div class="row justify-content-end">
            <div class="col-auto pr-0">
              <ng-container *ngIf="!isDetail else displayComments">
                <button class="btn btn-light btn_footer_item" (click)="goToPublicationDetail(publication.guid)">
                  <span class="comments">
                    <i class="fa fa-commenting-o invert btn__icons" aria-hidden="true"></i>
                    <span class="hide-xxs">comentarios </span> <span class="font-weight-bold"
                      *ngIf="publication.comments">&nbsp;({{publication.comments}})</span>
                  </span>
                </button>
              </ng-container>
              <ng-template #displayComments>
                <button class="btn btn-light btn_footer_item" (click)="toggleShowComments()">
                  <span class="comments">
                    <i class="fa fa-commenting-o invert btn__icons" aria-hidden="true"></i>
                    <span class="hide-xxs">comentarios </span> <span class="font-weight-bold"
                      *ngIf="publication.comments">&nbsp;({{publication.commentsList.length}})</span>
                  </span>
                </button>
              </ng-template>
            </div>
            <div class="col-auto">
              <button class="btn btn-light btn_footer_item" (click)="goToShare(publication.guid)">
                <span class="btn_txt"><i class="fa fa-share" aria-hidden="true"></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body comments__section pb-3 pt-0" *ngIf="showComments && isDetail">
      <div class="row comments__bar">
        <div class="col">
          <div class="mb-0 comments__counter">
            <div>
              Comentarios&nbsp;
            </div>
            <i class="fa float-right comments__toggler cursor__pointer" (click)="toggleCloseComments()"
              [ngClass]="{'fa-chevron-up': commentsOpen, 'fa-chevron-down': !commentsOpen}"></i>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="isDetail && commentsOpen && showComments">
      <app-create-comment [publicationGuid]="publication.guid" (commentCreated)='insertComment($event)' [parentPage]="parentPage">
      </app-create-comment>
      <ng-container *ngIf="publication.commentsList.length">
        <div *ngFor="let comment of publication.commentsList">
          <app-comment [comment]="comment"></app-comment>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>


