<div class="container p-0">
    <div class="main-container animated fadeIn fast">
        <div class="color-background card text-center border-0 animated fadeIn fast">
            <div class="card-body animated fadeIn">
                <div class="row">
                    <div class="col-12">
                        <img src="/assets/img/logos_mujer.png" alt="" class="img-fluid w-50">
                    </div>
                </div>
                <span class="txt_title">
                    Regístrate al Evento
        </span>
                <p class="txt_common mt-1">
                    Comienza a darle forma a tu sueño
                </p>
                <div class="mt-2">
                    <form autocomplete="off" [formGroup]="formAccount">
                        <div class="input-group input-group-lg">
                            <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large" placeholder="Nombre" formControlName="nombre">
                        </div>
                        <div *ngIf="submitted && formFields.nombre.errors" class="errors pt-1">
                            <small *ngIf="formFields.nombre.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
                            <small *ngIf="formFields.nombre.errors.minlength" class="text-danger animated fadeIn">
                Al menos dos caracteres
              </small>
                        </div>
                        <div class="mt-4 input-group input-group-lg">
                            <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large" placeholder="Primer Apellido" formControlName="apellido">
                        </div>
                        <div *ngIf="submitted && formFields.apellido.errors" class="errors pt-1">
                            <small *ngIf="formFields.apellido.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
                            <small *ngIf="formFields.apellido.errors.minlength" class="text-danger animated fadeIn">
                Al menos dos caracteres
              </small>
                        </div>
                        <div class="mt-4 input-group input-group-lg">
                            <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large" placeholder="Segundo Apellido" formControlName="apellidoMat">
                        </div>
                        <div class="mt-4 input-group input-group-lg">
                            <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large" placeholder="Correo electrónico" formControlName="correo" (change)="mailHelperInput($event)">
                        </div>
                        <div *ngIf="submitted && formFields.correo.errors" class="errors pt-1">
                            <small *ngIf="formFields.correo.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
                            <small *ngIf="formFields.correo.errors.pattern" class="text-danger animated fadeIn">
                Correo inválido.
              </small>
                        </div>
                        <div class="mt-4 input-group input-group-lg">
                            <label for="date" *ngIf="messageDate == true" class="label-date"><b>Favor de seleccionar en el orden: Mes, Año y Día</b></label>
                            <input type="text" #date ngxDaterangepickerMd (click)="onClickDate()" class="form-control inpts_posible border-rounded datePlaceholder datepicker-calendar-icon" name="fechaNac" formControlName="fechaNac" [closeOnAutoApply]="true" [autoApply]="true" [showDropdowns]="true"
                                (change)="birthDate = $event" [singleDatePicker]="true" placeholder="Fecha de nacimiento" class="datepicker-calendar-icon" [minDate]="minBirthDate" />
                        </div>
                        <div *ngIf="submitted && formFields.fechaNac.errors" class="errors pt-1">
                            <small *ngIf="formFields.fechaNac.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
                            <small *ngIf="formFields.fechaNac.errors.minimumAge" class="text-danger animated fadeIn">
                Debe ser mayor de 18 años.
              </small>
                            <small *ngIf="formFields.fechaNac.errors?.maxlength" class="text-danger animated fadeIn">
                Fecha inválida.
              </small>
                        </div>
                        <div class="mt-4 input-group input-group-lg">
                            <ng-select class="form-control" placeholder="Género" formControlName="genero" [clearable]="false" notFoundText="No se encontraron resultados" loadingText="Cargando...">
                                <ng-option *ngFor="let gender of genders | keyvalue" [value]="gender.key">{{gender.value}}</ng-option>
                            </ng-select>
                        </div>
                        <div *ngIf="submitted && formFields.genero.errors" class="errors pt-1">
                            <small *ngIf="formFields.genero.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
                        </div>
                        <div class="mt-4 input-group input-group-lg">
                            <input type="text" (keyup)="validatePhoneNo($event.target)" (paste)="validatePhoneNo($event.target)" onpaste="return false" class="form-control inpts_posible border-rounded" aria-label="Large" placeholder="Teléfono" formControlName="telefono" maxlength="13"
                                autocomplete="not-autocomplete">
                        </div>
                        <div *ngIf="submitted && formFields.telefono.errors" class="errors pt-1">
                            <small *ngIf="formFields.telefono.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
                            <small *ngIf="formFields.telefono.errors.pattern" class="text-danger animated fadeIn">
                Teléfono inválido.
              </small>
                            <small *ngIf="formFields.telefono.errors.minlength || formFields.telefono.errors.maxlength" class="text-danger animated fadeIn">
                10 dígitos requeridos
              </small>
                        </div>
                        <div class="mt-4 input-group input-group-lg">
                            <ng-select [items]="states" bindLabel="description" class="form-control" (change)="getCities()" placeholder="Estado" [clearable]="false" formControlName="estado" notFoundText="No se encontraron resultados" loadingText="Cargando..." bindValue="state_guid">
                            </ng-select>
                        </div>
                        <div *ngIf="submitted && formFields.estado.errors" class="errors pt-1">
                            <small *ngIf="formFields.estado.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
                        </div>

                        <div class="mt-4 input-group input-group-lg">
                            <ng-select [items]="cities" bindLabel="description" class="form-control" [placeholder]="isLoadingCities ? 'Cargando...' : 'Municipio'" formControlName="municipio" [clearable]="false" notFoundText="No se encontraron resultados" loadingText="Cargando..."
                                bindValue="municipality_guid">
                            </ng-select>
                        </div>
                        <div *ngIf="submitted && formFields.municipio.errors" class="errors pt-1">
                            <small *ngIf="formFields.municipio.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
                        </div>
                        <div class="mt-3" *ngIf="chiapas">
                            <p class="" style="font-size: 1rem; text-align: left; margin-bottom: 0.25rem;">
                                ¿Participaste en Mujer POSIBLE Chiapas?
                            </p>
                        </div>

                        <div class="input-group input-group-lg" *ngIf="chiapas">
                            <div class="d-flex flex-row">
                                <div class="form-check mr-4">
                                    <input class="form-check-input" type="radio" name="participated" [value]="true" formControlName="participated" (change)="retoParticipated(true)">
                                    <label class="form-check-label">
    <span class="txt_common_bold"> Si </span>
  </label>
                                </div>
                                <div class="form-check mr-4">
                                    <input class="form-check-input" type="radio" name="participated" [value]="false" formControlName="participated" (change)="retoParticipated(false)">
                                    <label class="form-check-label">
    <span class="txt_common_bold"> No </span>
  </label>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="submitted && formFields.participated.errors" class="errors pt-1">
                            <small *ngIf="formFields.participated.errors.required" class="text-danger animated fadeIn">
Campo requerido.
</small>
                        </div>

                        <div class="mt-4 input-group input-group-lg" *ngIf="chiapasAccept">
                            <p class="" style="font-size: 1rem; text-align: left; margin-bottom: 0.25rem;">
                                ¿En qué componente de Mujer POSIBLE Chiapas participaste?
                            </p>
                            <ng-select [items]="componentsListParticipated" bindLabel="name" class="form-control" placeholder="Seleccionar" [clearable]="false" formControlName="componentsListParticipated" notFoundText="No se encontraron resultados" loadingText="Cargando..." bindValue="id"
                                (change)="componentParticipated($event)">
                            </ng-select>
                        </div>
                        <div *ngIf="submitted && formFields.componentsListParticipated.errors" class="errors pt-1">
                            <small *ngIf="formFields.componentsListParticipated.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
                        </div>
                        <div class="mt-4 input-group input-group-lg" *ngIf="sede">
                            <p class="" style="font-size: 1rem; text-align: left; margin-bottom: 0.25rem;">
                                ¿A qué sede acudiste?
                            </p>
                            <ng-select [items]="sedesList" bindLabel="name" class="form-control" placeholder="Seleccionar" [clearable]="false" formControlName="sedesList" notFoundText="No se encontraron resultados" loadingText="Cargando..." bindValue="id">
                            </ng-select>
                        </div>
                        <div *ngIf="submitted && formFields.sedesList.errors" class="errors pt-1">
                            <small *ngIf="formFields.sedesList.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
                        </div>

                        <div class="mt-4 input-group input-group-lg" *ngIf="zacatecas">
                            <p class="" style="font-size: 1rem; text-align: left; margin-bottom: 0.25rem;">
                                ¿En qué componente de Mujer POSIBLE Zacatecas te interesa registrarte?
                            </p>
                            <ng-select [items]="componentsListZacatecas" (change)="componentsZacatecas($event)" bindLabel="name" class="form-control" placeholder="Seleccionar" [clearable]="false" formControlName="mujerPosible" notFoundText="No se encontraron resultados" loadingText="Cargando..."
                                bindValue="id">
                            </ng-select>
                        </div>
                        <div *ngIf="submitted && formFields.mujerPosible.errors" class="errors pt-1">
                            <small *ngIf="formFields.mujerPosible.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
                        </div>
                        <div class="mt-4 input-group input-group-lg" *ngIf="componentSelected">
                            <p class="" style="font-size: 1rem; text-align: left; margin-bottom: 0.25rem;">
                                ¿En qué municipio deseas acudir?
                            </p>
                            <ng-select [items]="listMunicipalityAttend" bindLabel="name" class="form-control" placeholder="Seleccionar" [clearable]="false" formControlName="municipalityAttend" notFoundText="No se encontraron resultados" loadingText="Cargando..." bindValue="id">
                            </ng-select>
                        </div>
                        <div *ngIf="submitted && formFields.municipalityAttend.errors" class="errors pt-1">
                            <small *ngIf="formFields.municipalityAttend.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
                        </div>




                        <!-- <div class="mt-4 input-group input-group-lg" *ngIf="zacatecas">
                            <p class="" style="font-size: 1rem; text-align: left; margin-bottom: 0.25rem;">
                                En qué componente de Mujer Posible participarás:
                            </p>
                            <ng-select [items]="componentsList" bindLabel="name" class="form-control" placeholder="Seleccionar" [clearable]="false" formControlName="mujerPosible" notFoundText="No se encontraron resultados" loadingText="Cargando..." bindValue="id">
                            </ng-select>
                        </div>
                        <div *ngIf="submitted && formFields.mujerPosible.errors" class="errors pt-1">
                            <small *ngIf="formFields.mujerPosible.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
                        </div> -->
                        <div class="mt-4 input-group input-group-lg">
                            <ng-select class="form-control inpts_posible border-rounded" placeholder="Educación" formControlName="educacionNivel" [clearable]="false" notFoundText="No se encontraron resultados" loadingText="Cargando...">
                                <ng-option *ngFor="let education of educationsType | keyvalue" [value]="educations[education.value]">{{education.value}}</ng-option>
                            </ng-select>
                        </div>
                        <div *ngIf="submitted && formFields.educacionNivel.errors" class="errors pt-1">
                            <small *ngIf="formFields.educacionNivel.errors.required" class="text-danger animated fadeIn">
                Campo requerido.
              </small>
                        </div>

                        <div class="mt-3">
                            <p class="" style="font-size: 1rem; text-align: left; margin-bottom: 0.25rem;">
                                ¿Eres cliente de Banco Compartamos?
                            </p>
                        </div>

                        <div class="input-group input-group-lg">
                            <div class="d-flex flex-row">
                                <div class="form-check mr-4">
                                    <input class="form-check-input" type="radio" name="isCliente" [value]="true" formControlName="isCliente">
                                    <label class="form-check-label">
    <span class="txt_common_bold"> Si </span>
  </label>
                                </div>
                                <div class="form-check mr-4">
                                    <input class="form-check-input" type="radio" name="isCliente" [value]="false" formControlName="isCliente">
                                    <label class="form-check-label">
    <span class="txt_common_bold"> No </span>
  </label>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="submitted && formFields.isCliente.errors" class="errors pt-1">
                            <small *ngIf="formFields.isCliente.errors.required" class="text-danger animated fadeIn">
Campo requerido.
</small>
                        </div>


                        <p class="text-left mt-4 pb-0">
                            <small>
                <input type="checkbox" class="form-input" name="avisoPrivacidad" formControlName="avisoPrivacidad"
                  [class.is-invalid]="avisoNoValido">
                <span class="txt_terms">
                    He leído y estoy de acuerdo con el <b>
                    <a class="link-style" [routerLink]="['/aviso-privacidad-mujer-posible']" target="_blank">Aviso de privacidad</a> </b>.</span>
              </small>
                        </p>
                        <div *ngIf="submitted && formFields.avisoPrivacidad.errors" class="errors mb-1">
                            <small *ngIf="formFields.avisoPrivacidad.errors.required" class="text-danger animated fadeIn">
                ¡Debes de aceptar el aviso de privacidad!
              </small>
                        </div>

                        <p class="text-left mt-4 pb-0">
                            <small>
                <input type="checkbox" class="form-input" name="acuerdoConformidad" formControlName="acuerdoConformidad"
                  [class.is-invalid]="acuerdoNoValido">
                <span class="txt_terms">
                    Estoy de acuerdo con el tratamiento de mis datos personales de conformidad con el  <b>
                    <a class="link-style" [routerLink]="['/aviso-privacidad-mujer-posible']" target="_blank">Aviso de privacidad</a> </b>.</span>
              </small>
                        </p>
                        <div *ngIf="submitted && formFields.acuerdoConformidad.errors" class="errors mb-1">
                            <small *ngIf="formFields.acuerdoConformidad.errors.required" class="text-danger animated fadeIn">
                ¡Debes de aceptar el aviso de privacidad!
              </small>
                        </div>


                        <p class="text-left mt-4 pb-0">
                            <small>
                <input type="checkbox" class="form-input" name="terminos" formControlName="terminos"
                  [class.is-invalid]="terminosNoValido">
                <span class="txt_terms">
                    Estoy de acuerdo con la transferencia de mis datos personales de conformidad con lo que se establece en el presente <b>
                    <a class="link-style" [routerLink]="['/aviso-privacidad-mujer-posible']" target="_blank">Aviso de privacidad</a> </b>.</span>
              </small>
                        </p>
                        <div *ngIf="submitted && formFields.terminos.errors" class="errors mb-1">
                            <small *ngIf="formFields.terminos.errors.required" class="text-danger animated fadeIn">
                ¡Debes de aceptar el aviso de privacidad!
              </small>
                        </div>
                        <div class="form-group row mt-3">
                            <div class="input-group col">
                                <button type="submit" class="btn-lg btn btn-block btn-warning btn_continuar" (click)="save()" [disabled]="isLoading"> <span class="txt_btn_continuar"> <b> Continuar</b> </span></button>
                            </div>
                        </div>
                    </form>
                    <a class="text-center nav-link backText" [routerLink]="['/login']"><i class="fa fa-arrow-left"
              aria-hidden="true"></i> Regresar</a>
                    <img class="rounded mx-auto d-block" src="assets/img/company.png" alt="">
                </div>
            </div>
            <!--card body-->
        </div>
        <!-- card main-->
    </div>
</div>