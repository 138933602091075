import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/User/user';
import { faSearch, faBars, faChevronRight, faSignOutAlt, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { FirebaseService } from 'src/app/services/firebase-service/firebase.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {

  // Checks if the user is Authenticated
  isLoggedIn: boolean;
  userSubscribe: Subscription;
  currentUser: User;
  faSearch = faSearch;
  faBars = faBars;
  faChevronRight = faChevronRight;
  faSignOutAlt = faSignOutAlt;
  faSignInAlt = faSignInAlt;
  isLanding: boolean = false;
  constructor(private authService: AuthService,
    private firebase: FirebaseService, private router: Router) { }

  // Executes when component is initialized
  ngOnInit(): void {
    // Subscribe and watch for user events
    this.userSubscribe = this.authService.currentUser.subscribe( (user) => {
      let authenticated = this.authService.isAuthenticated();
      this.isLoggedIn = authenticated;
      this.currentUser = user;
    });

    if (this.router.url == '/' || this.router.url == 'tv'){
      this.isLanding = true;
    }
  }

  // Executes when component is destroyed
  ngOnDestroy(): void {
    this.userSubscribe.unsubscribe();
  }

  // Opens the sidebar menu
  openNav(): void {
    this.firebase.logEvent('sidebarOpen');
    document.getElementById('mySidenav').classList.add("display");
  }

  // Closes the sidebar menu
  closeNav(): void {
    document.getElementById('mySidenav').classList.remove("display");
  }

  // Function to logout and delete user data
  logout(): void {
    this.closeNav();
    this.authService.logout();
  }

}
