import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../classes/global';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  globals = Globals;

  constructor( private http: HttpClient) { }

  //it creates  a new project from inicio section
  newProject(project) {
    const url = `${this.globals.urlPosibleBase}projects/`;
    return this.http.post(url, project);
  }


  //it creates  a new project from inicio section
  updateProject(project, guid) {
    const url = `${this.globals.urlPosibleBase}projects/${guid}/`;
    return this.http.put(url, project);
  }


  //it creates  a new diagnostic from the login and can be accessed only one time
  nuevoProyecto(
    name,
    statusPosible,
  ) {

    const url = `${this.globals.urlPosibleBase}diagnostic/`;
    return this.http.post(url, {
                          name: name,
                          statusPosible: statusPosible,
                        });
  }

  /*
  * Method to set status of a model business to 2 when its finished at 100 %
  * @param data - status 2, string reason
  */
 async sendModelBusinessReview(guid: string, data){
  const url = `${this.globals.urlPosibleBase}${this.globals.projects.projects}${guid}/${this.globals.projects.changeStatus}`;
  return await this.http.post(url, data).toPromise();
}


 // get project stage 1
 async getProjectStageData(guid): Promise<any> {
  const url = `${this.globals.urlPosibleBase}${this.globals.projects.projects}${guid}/`;
  return await this.http.get(url).toPromise();
}
  /* Method to get all the projects that are of the current logged user
  * @param id - string id of the project
  * @param about - string
  * @param mission - string
  * @param vision - string
  */
  saveProfileProject(id:string, about: string, mission: string, vision:string) {
    let data = {
      about: about,
      mission: mission,
      vision: vision
    }
    const url = `${this.globals.urlPosibleBase}${this.globals.projects.pathProjects}/${id}/${this.globals.projects.save}`;
    return this.http.post(url, data);
  }

  /* Method to get all the projects that are of the current logged user
  *
  */
  async getMyProjects(): Promise<any>{
    const url = `${this.globals.urlPosibleBase}${this.globals.projects.pathProjects}/${this.globals.projects.myProjects}/`;
    return await this.http.get(url).toPromise();
  }

  /* Method to get a project by id
  * @param id - businessModel_guid
  */
  async getProject(id:string): Promise<any>{
    const url = `${this.globals.urlPosibleBase}${this.globals.projects.pathProjects}/${id}/`;
    return await this.http.get(url).toPromise();
  }

  // get all jobCounts
  async getjobCounts(): Promise<any> {
    const url = `${this.globals.urlPosibleBase}${this.globals.projects.jobCounts}`;
    return await this.http.get(url).toPromise();
  }

  // get all acquisitionLevels
  async getAcquisitionLevels(): Promise<any> {
    const url = `${this.globals.urlPosibleBase}${this.globals.projects.acquisitionLevels}`;
    return await this.http.get(url).toPromise();
  }


  // get all genders
  async getGenders(): Promise<any> {
    const url = `${this.globals.urlPosibleBase}${this.globals.projects.genders}`;
    return await this.http.get(url).toPromise();
  }

  //  clientTypes
  async getClientTypes(): Promise<any> {
    const url = `${this.globals.urlPosibleBase}${this.globals.projects.clientTypes}`;
    return await this.http.get(url).toPromise();
  }

  // get all optionsSolutions
  async getOptionsSolutions(): Promise<any> {
    const url = `${this.globals.urlPosibleBase}${this.globals.projects.optionsSolutions}`;
    return await this.http.get(url).toPromise();
  }


  // get all supportTypes
  async getSupportTypes(): Promise<any> {
    const url = `${this.globals.urlPosibleBase}${this.globals.projects.supports}`;
    return await this.http.get(url).toPromise();
  }

  // get all ageRanges
  async getAgesRanges(): Promise<any> {
    const url = `${this.globals.urlPosibleBase}${this.globals.projects.agesRanges}`;
    return await this.http.get(url).toPromise();
  }


  // get all sellTypes
  async getSellTypes(): Promise<any> {
    const url = `${this.globals.urlPosibleBase}${this.globals.projects.sells}`;
    return await this.http.get(url).toPromise();
  }


  // get all publicityTypes
  async getpublicityTypes(): Promise<any> {
    const url = `${this.globals.urlPosibleBase}${this.globals.projects.publicity}`;
    return await this.http.get(url).toPromise();
  }


  // Method to get all the project categories
  async getCategories(): Promise<any> {
    const url = `${this.globals.urlPosibleBase}${this.globals.projects.categories}`;
    return await this.http.get(url).toPromise();
  }

  // Method to get all the project income types
  async getIncomeTypes(): Promise<any> {
    const url = `${this.globals.urlPosibleBase}${this.globals.projects.incomes}`;
    return await this.http.get(url).toPromise();
  }

  // Method to get the current project announcements
  async getCurrentAnnouncements(): Promise<any> {
    const url = `${this.globals.urlPosibleBase}${this.globals.projects.currentAnnouncements}`;
    return await this.http.get(url).toPromise();
  }


  /**
   * Method remove project
   * @param project
   */
   async delete(projectId: string): Promise<any> {
    return await this.http.delete(`${this.globals.urlPosibleBase}${this.globals.projects.projects}${projectId}/`).toPromise();
  }

  /**
   * Method remove project
   * @param project
   */
   async download(projectId: string): Promise<any> {
    return await this.http.get(`${this.globals.urlPosibleBase}${this.globals.projects.projects}${projectId}/download_module`, {responseType: 'blob'}).toPromise().then((result) => {
      this.downloadFile(result, 'application/pdf');  
      return result;
      }).catch((err) => {
        return err;
      });
  }

  
  async downloadCertificate(projectId: string): Promise<any> {
    
    return await this.http.get(`${this.globals.urlPosibleBase}${this.globals.projects.projects}${projectId}/certificatePaneles`, {responseType: 'blob'}).toPromise().then((result) => {

      this.downloadC(result, 'application/pdf');  
      return result;
      }).catch((err) => {
        return err;
      });
  }

  downloadFile(data:any, type: string) {
    let blob = new Blob([data], {type: type});
    let url = window.URL.createObjectURL(blob);
    let pwa = window.open(url);
  }

  downloadC(data:any, type: string) {
    const link = document.createElement( 'a' );
    link.style.display = 'none';
    document.body.appendChild( link );
    let blob = new Blob([data], {type: type});
    let url = URL.createObjectURL(blob);
    link.href = url;
    link.href = URL.createObjectURL(blob);
    link.download =  "constancia_paneles_2022";
    link.click();
   
  }

  /**
   * Method that get business model status
   */
  async getBusinessModelsStatus(){
    return await this.http.get(`${this.globals.urlPosibleBase}${this.globals.projects.pathProjects}/${this.globals.projects.businessModelStatus}`).toPromise();
  }

}
