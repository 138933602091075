import { NotificationTypes } from './../notification-types/notification-types';
import * as moment from 'moment';
moment.locale('es');

export class Notification {
    id: string;
    contactId: string;
    contactName: string;
    contactPicture: string;
    publicationId: string;
    date: string;
    daysPassed: string;
    seen: boolean;
    type: NotificationTypes;
    description: string;
    contactStatus : string;

    constructor(data?: any) {
      if (data)
      {

        this.id = data.id;
        this.contactId = data.contactId;
        this.contactName = data.contactName;
        this.contactPicture = data.contactPicture;
        this.publicationId = data.publicationId;
        this.date = data.date;
        this.daysPassed = this.diffDays(this.date);
        this.seen = data.seen;
        this.type = data.type;
        this.description = this.generateDescription();
        this.contactStatus = data.contactStatus || 'Offline';
      }
    }

    // get the difference in days between a date and now
    diffDays(date: string): string {
        const start = moment(date);
        const now = moment();
        const days = Math.trunc(moment.duration(now.diff(start)).asDays());
        return (days == 0) ? `Hoy ${new Date(this.date).getHours()}:${ (new Date(this.date).getMinutes()<10?'0':'') + new Date(this.date).getMinutes() }` : ((days > 1) ? `${days} Días` : `${days} Día`);
    }

    // generate the description or body of the notification
    generateDescription(): string {
        let message: string;
        switch (this.type) {
            case NotificationTypes.Like:
                message = `${this.contactName} le ha dado like a tu publicación.`;
                break;

            case NotificationTypes.Share:
                message = `${this.contactName} ha compartido tu publicación.`;
                break;

            case NotificationTypes.Comment:
                message = `${this.contactName} ha comentado tu publicación.`;
                break;
        }

        return message;
    }
}
