import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-slide-header-menu',
  templateUrl: './slide-header-menu.component.html',
  styleUrls: ['./slide-header-menu.component.css']
})
export class SlideHeaderMenuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  openNav() {
    document.getElementById("mySidenav2").style.height      = "489px";
    //document.getElementById("mySidenav2").style.paddingTop  = "0px";
    //document.getElementById("mySidenav2").style.marginTop  = "60px";
  }
   closeNav() {
   // document.getElementById("mySidenav2").style.paddingTop  = "60px";
   document.getElementById('mySidenav2').style.height      = "60px";
   //document.getElementById("mySidenav2").style.marginTop  = "0px";

  }
}
