// Class that creates instances for tech colleges
export class TechSchool {
    id: string;
    name: string;

    constructor(data){
        this.id = data.institution_guid;
        this.name = data.description;
    }
}
