import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SnackbarService } from 'src/app/services/notifications/snackbar.service';
import { AuthService } from '../../../services/auth.service';
import { ModalGenericComponent } from '../../components/modal-generic/modal-generic.component';
import { ModalGenericService } from '../../components/modal-generic/modal-generic.service';

@Component({
  selector: 'app-confirm-password',
  templateUrl: './confirm-password.component.html',
  styleUrls: ['./confirm-password.component.scss']
})
export class ConfirmPasswordComponent implements OnInit {
  // Form group
  forma: FormGroup;

  // token request received from the url param
  id: string;

  // Property to change the password inputs to text inputs and reversely
  showPassword = false;

  // Property to identify if the form has been already submitted
  submitted = false;

  // Property to identify if a petition is in progress
  isLoading = false;
  // Reference to modal instance
  modalRef: NgbModalRef;
  // Indicates the initial configuration of modals
  configModal: NgbModalOptions = {windowClass: 'modal-holder', centered: true, backdrop : 'static'};

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private route: ActivatedRoute,
    private snackbarService: SnackbarService,
    private modalGenericService: ModalGenericService,
    private modalService: NgbModal
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.id = params.id;
    });

    this.forma = this.fb.group({
      pass1: ['', [Validators.required, Validators.minLength(4), Validators.pattern('([ña-zA-Z0-9]*)+[ÑA-Z]{1,}([ña-zA-Z0-9]*)')]],
      pass2: ['', Validators.required]
    });
  }

  // Convenience getter for easy access to form fields
  get formFields() {
    return this.forma.controls;
  }

  // Method to identify if both of the password entered are equal
  passwordsAreEqual(): boolean {
    return (this.formFields.pass1.value === this.formFields.pass2.value) ? false : true;
  }

  // Method to change the input type password to type text and the other way around
  togglePassword = (): boolean => this.showPassword = !this.showPassword;

  // Method to handle the submission event of the form
  submit(): void {

    this.submitted = true;
    this.isLoading = true;

    // stop here if form is invalid
    if (this.forma.invalid || this.passwordsAreEqual()) {
      this.isLoading = false;
      return;
    }

    this.auth.confirmPassword(this.forma.value.pass1, this.forma.value.pass2, this.id)
      .then(resp => {
        let data ={
          title: '¡Contraseña reestablecida!',
          description: 'Ahora puede acceder a la aplicación con las nuevas credenciales',
          url: '/app/login'   
        };
        this.modalRef = this.modalService.open(ModalGenericComponent, this.configModal);
        this.modalGenericService.emitChange(data);
      }).catch((err) => this.snackbarService.showSnackBar('Cerrar', err))
      .finally(() => this.isLoading = false);

  }
}
