<div *ngIf="isLoading; else elseLoading" class="d-flex justify-content-center mt-5">
    <mat-spinner diameter="60"></mat-spinner>
</div>
<ng-template #elseLoading>
    <div class="container contain-data">
        <div class="row header">
            <div class="col-12 d-flex flex-column align-items-center justify-content-center">
                <span class="title pt-3">{{ project.name }}</span>
                <span class="folio text-center" *ngIf="project.status != projectList.Nuevo">
          Folio: <strong>#{{ project.invoice }}</strong>
        </span>
                <div class="row">
                    <div class="col-12">
                        <button class="btn btn-lg btn btn-block btn-warning btn_continuar" style="height: 25px; line-height: 0px;" (click)="regresar()">Regresar</button>
                    </div>
                </div>
            </div>
        </div>
        <form autocomplete="off" [formGroup]="formAppointment">
            <div class="row">

                <div class="col-3 mt-4 pl-4" style="border: 5px solid #FF9F2E; border-radius: 25px;">
                    <div class="mt-2 row">
                        <div class="col-12">
                            <label>Fecha: <br>{{detalles.fecha}}</label>
                        </div>
                    </div>
                    <div class="mt-2 row">
                        <div class="col-12">
                            <label>Hora: <br>{{detalles.hora}}</label>
                        </div>
                    </div>
                    <div class="mt-2 row">
                        <div class="col-12">
                            <label>Link reunion zoom: <br>{{detalles.zoom_url}}</label>
                        </div>
                    </div>
                    <div class="mt-2 row">
                        <div class="col-12">
                            <label>Id reunion zoom: <br>{{detalles.zoom_id}}</label>
                        </div>
                    </div>
                    <div class="mt-2 row">
                        <div class="col-12">
                            <p>Contraseña reunion zoom: <br>{{detalles.zoom_pass}}</p>
                        </div>
                    </div>
                </div>

                <div class="col-8 mt-4 pl-4">
                    <div class="mt-2 row">
                        <div class="col-12">
                            <h4>¡Prepárate para los paneles de POSIBLE 2022!</h4>
                        </div>
                        <div class="col-12">
                            <label>Antes de que te conectes a tu cita, tendrás que elaborar una presentación  y un video descriptivo de tu proyecto (El video es opcional)</label>
                        </div>
                    </div>
                    <div class="mt-2 row">
                        <div class="col-12">
                            <h4>1. Descarga la plantilla de presentación ¡Te servirá de apoyo visual durante tu pitch!</h4>
                        </div>
                        <div class="col-12">
                            <label> <a href="../../../../../assets/Presentacion_paneles_gral.pptx" style="border-radius: 10px; border: 2px solid #FF9F2E; padding: 5px; color: black;margin: 2px;">Plantilla de presentacion general</a></label>
                            <label> <a href="../../../../../assets/Presentacion_paneles_edomex.pptx" style="border-radius: 10px; border: 2px solid #FF9F2E; padding: 5px; color: black;margin: 2px;">Plantilla de presentacion México</a></label>
                            <label> <a href="../../../../../assets/Presentacion_paneles_zacatecas.pptx" style="border-radius: 10px; border: 2px solid #FF9F2E; padding: 5px; color: black;margin: 2px;">Plantilla de presentacion Zacatecas</a></label>
                        </div>
                    </div>
                    <div class="mt-2 row">
                        <div class="col-12">
                            <h4>2. Sube aquí la presentación de tu proyecto. <span style="font-weight: 100; font-size: 18px;">(Formato ppt o PDF)</span></h4>
                        </div>
                        <div class="col-12">
                            <input type="file" id="file" class="d-none" (change)="uploadFile($event.target.files)" accept=".ppt, .pptm, .pptx, .pdf">
                            <label for="file" *ngIf="detalles.ppt" style="border-radius: 10px; border: 2px solid #FF9F2E; padding: 5px;">{{detalles.ppt.split('/')[3]}}</label>
                            <label for="file" *ngIf="!detalles.ppt" style="border-radius: 10px; border: 2px solid lightgray; padding: 5px;">Click aqui para subir archivo</label>
                        </div>
                    </div>
                    <div class="mt-2 row">
                        <div class="col-12">
                            <h4>3. Ingresa la url del video de tu proyecto (Opcional)</h4>
                        </div>
                        <div class="col-12">
                            <p>En máx. 3 minutos, cuéntanos de que trata tu proyecto, cuanto tiempo tienes desarrollándolo, quién es tu equipo de trabajo y si tienes ventas.</p>
                            <!-- <p>Indica si ya tienes ventas, clientes y el tiempo que llevas con tu emprendimiento.</p> -->
                            <input type="text" class="form-control inpts_posible border-rounded" aria-label="Large" placeholder="Enlace" formControlName="enlace">
                        </div>
                    </div>
                    <div class="mt-2 row">
                        <div class="col-12">
                            <button class="btn btn-lg btn btn-block btn-warning btn_continuar" (click)="guardar()">Guardar</button>
                        </div>
                    </div>
                </div>

            </div>
        </form>
        <div class="row button-place" *ngIf="project.status === projectList['Nuevo']">
            <button class="btn btn-block btn-default" #btnDeleteProject (click)="deleteProject()">
        <img src="../../../../assets/img/icons/eliminar.svg" class="mr-2"/>
        <div class="text">Eliminar proyecto</div>
      </button>
        </div>
    </div>
</ng-template>