
// List of genders
export enum Genders {
    Masculino = 0,
    Femenino = 1,
    Otro = 2
}

export namespace GendersType {

    export function values() {
        return Object.keys(Genders).filter(k => isNaN(Number(k)) );
    }

}