import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl, AbstractControl } from '@angular/forms';
import { ProjectService } from 'src/app/services/project.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BusinessModel } from '../../../models/businessModel/businessModel';
import { SnackbarService } from '../../../services/notifications/snackbar.service';
import { NavigationSteps } from 'src/app/classes/navigationSteps';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ProjectCompletedDialogComponent } from '../../shared/dialogs/project-completed-dialog/project-completed-dialog/project-completed-dialog.component';
import { ModelProgress } from 'src/app/models/modelProgress/modelProgress';
@Component({
  selector: 'app-generate-income',
  templateUrl: './generate-income.component.html',
  styleUrls: ['./generate-income.component.css']
})
export class GenerateIncomeComponent implements OnInit {
  // current step number of this page
  stepNumber = 6;
  // total number of steps
  totalNumSteps = 7;

  navigationSteps = NavigationSteps;

  forma: FormGroup;
  // Income Types list
  incomeTypes: [];
  //support types list
  SupportTypes: [];
  // Property to identify if the other income textarea is being displayed
  showOtherIncome = false;
  // Property to identify if the other income textarea is being displayed
  minWordsCompleted = false;
  // Property to identify if the form has been already submitted
  submitted = false;
  // Property to store if the product/service have cost
  haveCost: any;
  // Property to store the product/service have cost description
  descriptionCost: string;
  // Property to store the info of the supporters of your product from the localstorage
  otherSupport: any;
  // Property to identify the current project
  currentProject: BusinessModel;
  // Property to identify the project id based on a url param
  projectId: string;
  // Property to manage the project select disabled
  visiblePdescriptionCost: boolean = false;
  // Property to manage the project select disabled for last option textarea
  visibleSupportDescr: boolean = false;
  //property to store guid from api others support
  otros_value: string = '';
  //property to store guid from api others income
  otherIncome_value: string = '';
  // Indicates with colors the steps that are already done
  modelProgress: ModelProgress[];
  // Load steps of diagnostic in order to display navbar
  projectSteps = NavigationSteps;

  constructor(private snackbarService: SnackbarService,
    private fb: FormBuilder,
    private router: Router,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private dialog: MatDialog) {
    this.forma = this.fb.group({
      precioDefinido: [''],
      descriptionCost: ['', [Validators.maxLength(600)]],
      otherSupport: ['', [Validators.maxLength(30)]],
      checkArray: this.fb.array([]),
      supportTypes: this.fb.array([]),
      otherIncomes: ['']
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      if (params.id) {
        this.projectId = params.id;
        this.setProjectValues();
      } else {
        this.getIncomeTypes();
        this.getSupportTypes();
      }
    });
  }

  // Method to add/remove value of a checkbox into the checkCategories formcontrol value
  onPriceDescChange(e: any): void {
    if (this.formFields.precioDefinido.value == false) {
      this.visiblePdescriptionCost = false;
      this.formFields.descriptionCost.setValidators([Validators.maxLength(600)]);
    } else {
      this.visiblePdescriptionCost = true;
      this.formFields.descriptionCost.setValue(null);
      this.formFields.descriptionCost.setValidators([Validators.required,Validators.maxLength(600)]);
    }
    this.forma.get('descriptionCost').setErrors(null);
    this.forma.updateValueAndValidity();
  }


  toggleIncome(): void {
    const checkArray: FormArray = this.forma.get('checkArray') as FormArray; 
    if (checkArray.value.includes(this.otherIncome_value)) {
      this.showOtherIncome = true;
      this.formFields.otherIncomes.setValidators([Validators.required]);
    } else {
      this.showOtherIncome = false;
      this.formFields.otherIncomes.setValue(null);
      this.formFields.otherIncomes.setValidators([]);
    }
    this.forma.get('otherIncomes').setErrors(null);
    this.forma.updateValueAndValidity();
  }

  toggleCheck(): void {
    const supportTypes: FormArray = this.forma.get('supportTypes') as FormArray;
    if (supportTypes.value.includes(this.otros_value)) {
      this.visibleSupportDescr = true;
      this.formFields.otherSupport.setValidators([Validators.maxLength(30),Validators.required]);
    } else {
      this.visibleSupportDescr = false;
      this.formFields.otherSupport.setValue(null);
      this.formFields.otherSupport.setValidators([]);
    }
    this.forma.get('otherSupport').setErrors(null);
    this.forma.updateValueAndValidity();
  }

  /**
   * Method to return the lenght of a textfield
   * @param str - string to look for the chars
   * Return - number of chars
   */
  countChars(str): number {
    if (str) {
      const chars = str.length;
      return chars;
    } else {
      return 0;
    }
  }

  // Method to set the current form fields data from  api's database according to the current model_guid
  setProjectValues(): void {
    this.projectService.getProjectStageData(this.projectId)
      .then(data => {
        this.currentProject = new BusinessModel(data.data);
        this.modelProgress = this.currentProject.modelProgress;
        this.formFields.precioDefinido.setValue(data.data.haveCost);
        this.formFields.descriptionCost.setValue(data.data.descriptionCost);
        this.formFields.otherSupport.setValue(data.data.otherSupport);
        this.formFields.otherIncomes.setValue(data.data.otherIncomes);
        const checkArray: FormArray = this.forma.get('checkArray') as FormArray;
        data.data.incomeTypes.forEach(element => {
          checkArray.push(new FormControl(element.incomeType_guid));
        });
        const supportTypes: FormArray = this.forma.get('supportTypes') as FormArray;
        data.data.supportTypes.forEach(element => {
          supportTypes.push(new FormControl(element.supportType_guid));
        });
        this.getIncomeTypes();
        this.getSupportTypes();
        if (this.formFields.precioDefinido.value === false) {
          this.visiblePdescriptionCost = false;
          this.formFields.descriptionCost.setValue(null);
        } else {
          this.visiblePdescriptionCost = true;
          this.formFields.descriptionCost.setValidators([Validators.required,Validators.maxLength(600)]);
        }

        if(this.formFields.otherSupport.value){
          this.visibleSupportDescr = true;
          this.formFields.otherSupport.setValidators([Validators.maxLength(30),Validators.required]);
        }else{
          this.visibleSupportDescr = false;
          this.formFields.otherSupport.setValue(null);
          this.formFields.otherSupport.setValidators([Validators.maxLength(30)]);
        }
        this.forma.updateValueAndValidity();

      })
      .catch(error => {
        this.router.navigate(['app/inicio']);
        this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error. ⚠️');
      });
    this.toggleCheck();
    this.toggleIncome();
  }


  /* Method to get the options to be displayed as checked
  * @param guid - checkbox id
  */
  checkedGenerator(guid): boolean {
    return this.formFields.checkArray.value.includes(guid);
  }
  /* Method to get the options to be displayed as checked
   * @param guid - checkbox id
   */
  checkedGenerator2(guid): boolean {
    return this.formFields.supportTypes.value.includes(guid);
  }
  // Method to get the project sells-list
  getSupportTypes(): void {
    this.projectService.getSupportTypes()
      .then(data => {
        this.SupportTypes = data.data;
        data.data.forEach(item => {
          if (item.description === 'Otro') {
            this.otros_value = item.supportType_guid;
          }
        });
        this.toggleCheck();
      })
      .catch(error => {
        this.router.navigate(['app/inicio']);
        this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error. ⚠️');
      }
      );
  }
  /**
   * Method to valide if the num of words entered are the minimum
   * @param str - string to look for the words
   */
  wordCountHandler(str: string): void {
    const words = this.wordCount(str);
    if (words >= 30) {
      this.minWordsCompleted = true;
    } else {
      this.minWordsCompleted = false;
    }
  }
  /**
   * Method to count all the words in a string
   * @param str - string to look for the words
   */
  wordCount = (str: string): number => {
    if (!str) {
      return;
    }
    return str.trim().split(/\s+/).length;
  }

  // Convenience getter for easy access to form fields
  get formFields() {
    return this.forma.controls;
  }
  /**
   * Method to manage the valid inputs with a css color
   */
  get precioDefinidoNoValido() {
    return this.forma.get('precioDefinido').invalid && this.forma.get('precioDefinido').touched;
  }

  /*
  * Method to add/remove value of a checkbox into the checkArray formcontrol value
  * @param evt - event triggered from web app when a change is presented on the element
  * @param formControl - an element to manage array from checkboxes values
  */
  onCheckboxChange(evt, formControl: string): void {
    const checkArray: FormArray = this.forma.get(formControl) as FormArray;
    if (evt.target.checked) {
      checkArray.push(new FormControl(evt.target.value));
    } else {
      let i = 0;
      checkArray.controls.forEach((item: FormControl) => {
        if (item.value == evt.target.value) {
          checkArray.removeAt(i);
          return;
        }
        i++;
      });
    }
    if(formControl == 'supportTypes'){
      this.toggleCheck();
    }
    this.toggleIncome();
  }
  /**
   * Method to toggle the value of the showOtherIncome property based in the value received
   * @param value - checkbox value received
   */
  togglerOtherIncome(value: string): void {
    if (value === 'other') {
      this.showOtherIncome = !this.showOtherIncome;
    }
  }
  /**
   * Validator method to disable all the other checkbox when there is 2 already checked.
   * @param incomeTypeGuid - incomeType guid to verify if is inside the array of checked values
   */
  validatorCheckbox(incomeTypeGuid: string): boolean {
    const control = this.forma.get('checkArray').value;
    return control.length === 2 && !control.includes(incomeTypeGuid);
  }
  // Method to get the project income types list
  getIncomeTypes(): void {
    this.projectService.getIncomeTypes()
      .then(data => {
        this.incomeTypes = data.data;
        data.data.forEach(item => {
          if (item.description === 'Otro') {
            this.otherIncome_value = item.incomeType_guid;
          }
        });
        this.toggleIncome();


      })
      .catch(error => {
        this.router.navigate(['app/inicio']);
        this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error. ⚠️');
      }
      );
  }

  // Method to send data to store new project info
  generateProject(): void {
    if (this.projectId) {
      this.currentProject.incomeTypes = this.formFields.checkArray.value;
      this.currentProject.otherIncomes = (this.formFields.otherIncomes.value !== null ? this.formFields.otherIncomes.value.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '') : null);
      this.currentProject.haveCost = this.forma.value.precioDefinido;
      this.currentProject.descriptionCost = (this.formFields.descriptionCost.value !== null ? this.formFields.descriptionCost.value.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '') : null);
      this.currentProject.supportTypes = this.formFields.supportTypes.value;
      this.currentProject.otherSupport = (this.formFields.otherSupport.value !== null ? this.formFields.otherSupport.value.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '') : null);
    }

  }

  // Method to send data to store account info
  update(): void {
    this.submitted = true;
    if (this.forma.invalid) {
      this.snackbarService.showSnackBar('Cerrar', 'Revise los campos requeridos ⚠️');
      return;
    }
    this.generateProject();
    if (this.projectId) {
      this.projectService.updateProject(this.currentProject,this.projectId).subscribe(data => {
          this.snackbarService.showSnackBar('Cerrar', 'Datos guardados! 🎉');
          this.router.navigate(['app/traccion-crecimiento', this.projectId]);
      }, (err) => {  this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error. ⚠️'); },
      () => { this.submitted = false;});
    }
  }
}
