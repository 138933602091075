import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProjectService } from '../../../services/project.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BusinessModel } from '../../../models/businessModel/businessModel';
import { SnackbarService } from '../../../services/notifications/snackbar.service';
import { NavigationSteps } from 'src/app/classes/navigationSteps';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ProjectCompletedDialogComponent } from '../../shared/dialogs/project-completed-dialog/project-completed-dialog/project-completed-dialog.component';
import { ModelProgress } from 'src/app/models/modelProgress/modelProgress';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalGenericComponent } from '../../components/modal-generic/modal-generic.component';
import { ModalGenericService } from '../../components/modal-generic/modal-generic.service';
declare var $: any;
@Component({
  selector: 'app-traction-growth',
  templateUrl: './traction-growth.component.html',
  styleUrls: ['./traction-growth.component.css']
})
export class TractionGrowthComponent implements OnInit {
  // current step number of this page
  stepNumber = 7;

  // total number of steps
  totalNumSteps = 7;

  // property to store the %% of the business model in order to be displayed at the screen
  completed: number;

  forma: FormGroup;

  //variable to store the jobs counts of your project
  jobCounts: [];
  // Property to identify the current project
  currentProject: BusinessModel;
  // Property to identify the project id based on a url param
  projectId: string;
  // Property to manage the project select disabled
  visible: boolean = false;
  // Property to manage the project select disabled for pay input
  visiblePay: boolean = false;
  // Property to manage the project select disabled for web input
  visibleWeb: boolean = false;
  // Indicates with colors the steps that are already done
  modelProgress: ModelProgress[];
  // Load steps of diagnostic in order to display navbar
  projectSteps = NavigationSteps;
  // Indicates if form was submitted
  submitted = false;
  // Reference to modal instance
  modalRef: NgbModalRef;
  // Indicates the initial configuration of modals
  configModal: NgbModalOptions = {windowClass: 'modal-holder', centered: true, backdrop : 'static'};

  constructor(private snackbarService: SnackbarService,
    private fb: FormBuilder,
    private router: Router,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private modalGenericService: ModalGenericService,
    private modalService: NgbModal) {

    this.forma = this.fb.group({
      empleos: [''],
      cantidad: [''],
      enOperacion: [''],
      enUso: [''],
      pago: [''],
      cuanto: ['', [Validators.pattern('^[0-9]*$'), Validators.min(1)]],
      web: [''],
      pagina_web: ['', [Validators.min(1)]],
      licencia: [''],
      actvididades: ['', [Validators.maxLength(300)]]
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      if (params.id) {
        this.projectId = params.id;
        this.setProjectValues();
      } else {
        this.getjobCounts();
      }
    });
  }

  // Method to set the current form fields data from  api's database according to the current model_guid
  setProjectValues(): void {
    this.projectService.getProjectStageData(this.projectId)
      .then(data => {
        this.currentProject = new BusinessModel(data.data);
        this.modelProgress = this.currentProject.modelProgress;
        this.formFields.empleos.setValue(data.data.generateJob);
        this.formFields.cantidad.setValue(data.data.jobCount?.jobCount_guid);
        this.formFields.enOperacion.setValue(this.currentProject.operating);
        this.formFields.enUso.setValue(data.data.productUsed);
        this.formFields.pago.setValue(data.data.productPay);
        this.formFields.cuanto.setValue(data.data.pay);
        this.formFields.pagina_web.setValue(data.data.web_page);
        this.formFields.web.setValue(data.data.haveWeb);
        this.formFields.licencia.setValue(data.data.haveName);
        this.formFields.actvididades.setValue(data.data.activitiesImpulse);
        this.getjobCounts();
        if (this.formFields.empleos.value === true) {
          this.visible = true;
          this.formFields.cantidad.setValidators([Validators.required]);
        } else {
          this.visible = false;
          this.formFields.cantidad.setValue(null);
          this.formFields.cantidad.setValidators([]);
        }
        if (this.formFields.pago.value === false) {
          this.visiblePay = false;
          this.formFields.cuanto.setValue(null);
          this.formFields.cuanto.setValidators([]);
        } else {
          this.visiblePay = true;
          this.formFields.cuanto.setValidators([Validators.required,Validators.pattern('^[0-9]*$'), Validators.min(1)]);
        }
        if (this.formFields.web.value === false){
          this.visibleWeb = false;
          this.formFields.pagina_web.setValue(null);
          this.formFields.pagina_web.setValidators([]);
        }else{
          this.visibleWeb = true;
          this.formFields.pagina_web.setValidators([Validators.required, Validators.min(1)]);
        }
        this.forma.updateValueAndValidity();
      })
      .catch(error => {
        this.router.navigate(['app/inicio']);
        this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error. ⚠️');
      }
      );
  }


  // Method to get the project job count list
  getjobCounts(): void {
    this.projectService.getjobCounts()
      .then(data => {
        this.jobCounts = data.data;
      })
      .catch(error => {
        this.router.navigate(['app/inicio']);
        this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error. ⚠️');
      }
      );
  }

  // Convenience getter for easy access to form fields
  get formFields() {
    return this.forma.controls;
  }

  // Method to send data to store new project info
  generateProject(): void {
    if (this.projectId) {
      this.currentProject.generateJob = this.formFields.empleos.value;
      this.currentProject.jobCount = this.formFields.cantidad.value;
      this.currentProject.operating = this.formFields.enOperacion.value;
      this.currentProject.productUsed = this.formFields.enUso.value;
      this.currentProject.productPay = this.formFields.pago.value;
      this.currentProject.pay = this.formFields.cuanto.value;
      this.currentProject.haveWeb = this.formFields.web.value;
      this.currentProject.haveName = this.formFields.licencia.value;
      this.currentProject.activitiesImpulse = (this.formFields.actvididades.value !== null ? this.formFields.actvididades.value.replace(/(?:[\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '') : null ) || null;
      this.currentProject.web_page = this.formFields.pagina_web.value;
    }
  }
  /**
   * Method to return the lenght of a textfield
   * @param str - string to look for the chars
   * Return - number of chars
   */
  countChars(str): number {
    if (str) {
      const chars = str.length;
      return chars;
    } else {
      return 0;
    }
  }

  // Method to add/remove value of a checkbox into the checkCategories formcontrol value
  onJobChange(e): void {
    if (this.formFields.empleos.value === false) {
      this.visible = false;
      this.formFields.cantidad.setValidators([]);
      this.forma.get('cantidad').setErrors(null);
    } else {
      this.visible = true;
      this.formFields.cantidad.setValue(null);
      this.formFields.cantidad.setValidators([Validators.required]);
    }
    this.forma.get('cantidad').setErrors(null);
    this.forma.updateValueAndValidity();
  }

  // Method to add/remove value of a checkbox into the checkCategories formcontrol value
  onPayChange(e): void {
    if (this.formFields.pago.value == false) {
      this.visiblePay = false;
      this.formFields.cuanto.setValidators([]);
    } else {
      this.visiblePay = true;
      this.formFields.cuanto.setValue(null);
      this.formFields.cuanto.setValidators([Validators.required,Validators.pattern('^[0-9]*$'), Validators.min(1)]);
    }
    this.forma.get('cuanto').setErrors(null);
    this.forma.updateValueAndValidity();
  }

  // Method to require or not a page url depending on the radio button selected
  onWebChange(e): void {
    if (this.formFields.web.value == false) {
      this.visibleWeb = false;
      this.formFields.pagina_web.setValidators([]);
    } else {
      this.visibleWeb = true;
      this.formFields.pagina_web.setValue(null);
      this.formFields.pagina_web.setValidators([Validators.required, Validators.min(1)]);
    }
    this.forma.get('pagina_web').setErrors(null);
    this.forma.updateValueAndValidity();
  }

  // open project completed dialog
  openProjectCompleted(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '450px';
    dialogConfig.data = { id: this.projectId, nextPage: '' };

    this.dialog.open(ProjectCompletedDialogComponent, dialogConfig);
  }

  // Method to send data to store account info
  update(): void {
    this.submitted = true;
    if (this.forma.invalid) {
      this.snackbarService.showSnackBar('Cerrar', 'Revise los campos requeridos ⚠️');
      return;
    }
    this.generateProject();
      const project = this.currentProject;
      this.projectService.updateProject( project,this.projectId).subscribe(data => {
        if (data['completed'] === 100) {
          this.openProjectCompleted();
        } else {
          this.completed = data['completed'];
          this.snackbarService.showSnackBar('Cerrar', 'Datos guardados! 🎉');
          let modalData ={
            title: `Haz completado el ${this.completed}% de tu proyecto`,
            description: `Tu modelo de negocio ya se encuentra guardado y puedes completarlo en otro momento, ingresando desde tu
            perfil o desde el menú principal en la sección de proyectos.`,
            url: '/app/perfil',
            isProject:true
          };
          this.modalRef = this.modalService.open(ModalGenericComponent, this.configModal);
          this.modalGenericService.emitChange(modalData);
        }
      }, (err) => { this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error. ⚠️'); },
      () => this.submitted = false);
  }

}
