import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FriendRequest } from 'src/app/models/friend-request/friend-request';
import { ContactService } from 'src/app/services/contact/contact.service';
import { SnackbarService } from 'src/app/services/notifications/snackbar.service';
import { FirebaseService } from '../../../services/firebase-service/firebase.service';
@Component({
  selector: 'app-friend-request',
  templateUrl: './friend-request.component.html',
  styleUrls: ['./friend-request.component.scss']
})
export class FriendRequestComponent implements OnInit {
  // Friend request object
  @Input() request: FriendRequest;

  // Index of this element in the friend request list
  @Input() index: number;

  // Event emitter to remove an element from the list and update the friends list
  @Output() requestUpdated = new EventEmitter<{ index: number, updateList: boolean }>();

  // verify if there is a petition in progress
  isLoading = false;

  constructor(private contactService: ContactService,
    private snackBarService: SnackbarService,
    private firebase: FirebaseService) { }

  ngOnInit(): void {
  }

  // Method to accept a friend request
  acceptFriend(): void {
    this.isLoading = true;
    this.contactService.acceptRequest(this.request.guid).then(response => {
      if (response['success']) {
        this.requestUpdateEmmiter(this.index, true);
        this.firebase.logEvent('acceptFriend');
        this.snackBarService.showSnackBar('Cerrar', `Solicitud Aceptada.`);
        this.contactService.raisefriendRequestChanged([]);
      }
    })
      .catch(error => {
        this.snackBarService.showSnackBar('Cerrar', `${error} ⚠️`);
      })
      .finally(() => this.isLoading = false);
  }

  // Method to decline a friend request
  declineFriend(): void {
    this.isLoading = true;
    this.contactService.cancelRequest(this.request.fromUserid).then(response => {
      if (response['success']) {
        this.requestUpdateEmmiter(this.index, false);
        this.snackBarService.showSnackBar('Cerrar', `Solicitud Rechazada.`);
        this.contactService.raisefriendRequestChanged([]);
      }
    })
      .catch(error => {
        this.snackBarService.showSnackBar('Cerrar', `${error} ⚠️`);
      })
      .finally(() => this.isLoading = false);
  }

  /**
   * Method to send the index of the updated request to the parent component
   * @param index - index of the current element in the friends request list
   * @param updateList - flag to verify if is necessary to update the list of friends
   */
  requestUpdateEmmiter(index: number, updateList: boolean): void {
    this.requestUpdated.emit({ index, updateList });
  }

}
