import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SnackbarService } from '../../../services/notifications/snackbar.service';
import { BusinessModel } from '../../../models/businessModel/businessModel';
import { ProjectService } from './../../../services/project.service';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalGenericService } from '../../components/modal-generic/modal-generic.service';
import { ModalGenericComponent } from '../../components/modal-generic/modal-generic.component';
@Component({
  selector: 'app-project-success',
  templateUrl: './project-success.component.html',
  styleUrls: ['./project-success.component.scss']
})
export class ProjectSuccessComponent implements OnInit {

  // Property to identify the current project
  currentProject: BusinessModel;
  // Property to identify the project id based on a url param
  projectId: string;
  // Reference to modal instance
  modalRef: NgbModalRef;
  // Indicates the initial configuration of modals
  configModal: NgbModalOptions = {windowClass: 'modal-holder', centered: true, backdrop : 'static'};

  constructor(
    private snackbarService: SnackbarService,
    private router: Router,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private modalGenericService: ModalGenericService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      if (params.id) {
        this.projectId = params.id;
      }
    });
  }
  // Method to navigate to inicio web-app page
  navigateHome() {
    this.router.navigate(['app/perfil']);
  }
  
  // Sends last
  sendReview(): void {
    this.projectService.sendModelBusinessReview(this.projectId,{ status: 2 }).then(data => {
      let modalData ={
        title: '¡¡ Felicidades 🎉 !!',
        description: 'Tu proyecto ha sido enviado a revisión, muy pronto obtendrás una retroalimentación de nuestra parte.',
        url: 'app/perfil'   
      };
      this.modalRef = this.modalService.open(ModalGenericComponent, this.configModal);
      this.modalGenericService.emitChange(modalData);
    }).catch(error => {
      this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error. ⚠️');
      this.router.navigate(['app/perfil']);
    });
  }
}
