import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalGenericService } from './modal-generic.service';

@Component({
  selector: 'app-modal-generic',
  templateUrl: './modal-generic.component.html',
  styleUrls: ['./modal-generic.component.scss']
})
export class ModalGenericComponent implements OnInit {
  title = '';
  description = '';
  url = '';
  isProject: boolean = false;

  constructor(private router: Router,
    public activeModal: NgbActiveModal,
    private modalGenericService: ModalGenericService) {

      this.modalGenericService.changeEmitted$.subscribe( data => {
        this.title = data['title'];
        this.description = data['description'];
        this.url = data['url'];
        this.isProject = data['isProject'];
      })
  }

  ngOnInit(): void {
  }

  redirectToPage(): void{
    this.activeModal.close();
    this.router.navigate([this.url]);
  }

  modalDismiss(): void{
    this.activeModal.close();
  }

}
