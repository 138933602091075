import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { adsParams } from 'src/app/classes/ads-params';
import { FirebaseService } from 'src/app/services/firebase-service/firebase.service';
import { microsites } from 'src/app/classes/microsites';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-kids-zacatecas',
  templateUrl: './kidsZacatecas.component.html',
  styleUrls: ['./kidsZacatecas.component.scss']
})
export class KidsZacatecasComponent implements OnInit {
  benefitsCampaings = [];
  processCampaings = [];
  retoCampaings = [];
  selectedCampaing = 'Reto POSiBLE';
  adsParams = adsParams;
  microsites = microsites;
  // Edo microsite info
  show = false;
  currentMicroSiteStateInfo = {}
  constructor(
    private route: ActivatedRoute,
    private firebase: FirebaseService,
    private router: Router,
    private _vps: ViewportScroller
  ) { }

  ngOnInit(): void {
    this.currentMicroSiteStateInfo = this.microsites['no-edo']
    this.firebase.logEvent('PrincialLandingView');
    // get return url from route parameters or default to 'feed'
    this.route.queryParams.subscribe((params: Params) => {
      if (params.utm_campaign) {
        // Validates if Ad FB params are expected for this event
        if (params['utm_source'] == this.adsParams.pageView.utm_source &&
            params['utm_medium'] == this.adsParams.pageView.utm_medium &&
            params['utm_campaign'] == this.adsParams.pageView.utm_campaign &&
            params['utm_content'] == this.adsParams.pageView.utm_content) {
              
        }
      }
    })

    // Get microsite param
    this.route.params.subscribe((params: any) => {
      this.currentMicroSiteStateInfo = this.microsites['kidsZacatecas']
    });

    this.benefitsCampaings = [
      {
        backgroundImg: '/assets/img/landing/back-posible.png',
        title: 'POSiBLE',
        subtitle: 'Convocatoria 2021',
        description: 'Es un programa de Fundación Televisa y Nacional Monte de Piedad, totalmente gratuito, que apoya a las personas para que puedan aprovechar su talento, energía y compromiso para crear empresas escalables y de alto impacto, basadas en innovación y que generen un beneficio a la sociedad.',
        benefits: [
          {img: 'assets/img/landing/icon-hands.png', title: 'Comunidad POSiBLE', text: 'Formarás parte de una comunidad de emprendimiento que te ofrecerá alianzas de crecimiento.'},
          {img: 'assets/img/landing/icon-comunity.png', title: 'Comunicación', text: 'Gran red de aliados, contactos y mentores que enriquecerán tu idea de negocio. Difusión de tu idea de negocio en redes sociales y medios masivos.'},
          {img: 'assets/img/landing/icon-target.png', title: 'Oportunidad', text: 'Invitación a eventos especiales para platicar de tu experiencia como emprendedor.'}
        ]
      },
      {
        backgroundImg: '/assets/img/landing/back-coop.png',
        title: 'POSiBLE COOP',
        subtitle: '',
        description: 'Programa de aceleración durante 6 meses que incluye 3 campamentos de capacitación y 1 evento de cierre',
        benefits: [
          {img: 'assets/img/landing/icon-profile.png', title: 'Comunidad Reto', text: 'Asignación de un mentor de acompañamiento.'},
          {img: 'assets/img/landing/icon-class.png', title: 'Apoyo', text: 'Seguimiento personalizado durante 6 meses.'},
          {img: 'assets/img/landing/icon-target.png', title: 'Comunidad COOP', text: 'Comunidad digital de cooperativas y colectivos.'},
          {img: 'assets/img/landing/icon-youtube.png', title: 'Recursos', text: 'MOOC para todos los cooperativistas.'}
        ]
      },
      {
        backgroundImg: '/assets/img/landing/back-reto.png',
        title: 'Reto POSiBLE',
        subtitle: '',
        description: 'Inspirar y hacer ver a los alumnos la importancia de emprender proyectos de alto impacto, al analizar las características de los emprendimientos con mayor potencial.',
        benefits: [
          {img: 'assets/img/landing/icon-hands.png', title: 'Comunidad Reto', text: 'Comunidad de docentes.'},
          {img: 'assets/img/landing/icon-youtube.png', title: 'Recursos', text: 'Mooc de 15 horas.'},
          {img: 'assets/img/landing/icon-target.png', title: 'Oportunidad', text: 'Conferencias y talleres adicionales por invitación.'}
        ]
      },
      {
        backgroundImg: '/assets/img/landing/back-max.png',
        title: 'POSiBLE MAX',
        subtitle: '',
        description: 'POSiBLE MAX es un programa con duración de 10 meses, enfocado en ayudar a 20 empresas en etapa de escalamiento a conseguir sus objetivos de crecimiento. Buscamos impulsar y fortalecer a las empresas para que desarrollen una visión innovadora y transformadora que les permitan ser competitivas, escalables y sostenibles en el largo plaza, a través de la capacitación, las herramientas y la red de aliados de POSIBLE.',
        benefits: [
          {img: 'assets/img/landing/icon-videocall.png', title: 'Mentoría', text: 'Más de 20 sesiones de acompañamiento personalizado con mentores expertos.'},
          {img: 'assets/img/landing/icon-class.png', title: 'Consultoría', text: 'Consultorías especializadas impartidas por empresarios y mentores expertos en temas específicos del rubro de cada empresa.'},
          {img: 'assets/img/landing/icon-tablet.png', title: 'Crecimiento', text: 'Más de 10 horas de talleres en temas relevantes a la estructuración de su empresa.'}
        ]
      }
    ];

    // reto posible
    this.retoCampaings = [
      {
        campaing: "Docentes",
        slide: 1,
        subslide: 'Criterios de selección',
        process: [{ number: 1, img: '', title: "", text: "Tendrás acceso a contenidos y herramientas de apoyo para la <b>enseñanza del emprendimiento de alto impacto</b>" },
        { number: 2, img: '', title: "", text: "Seras parte de la <b>Comunidad activa en Facebook </b>para intercambiar experiencias, recibir actualizaciones y nuevos contenidos." },
        { number: 3, img: '', title: "", text: '<b>Recibirás 15 horas de capacitación, accediendo al curso gratuito "Emprender es un Reto, hazlo POSiBLE"</b> en la Plataforma México X, https://www.mexicox.gob.mx/.' },
        { number: 4, img: '', title: "", text: "<b>Puedes ser uno de los 60 seleccionados para recibir un taller en línea </b> que complementa el curso para realizarse en septiembre." },
        { number: 5, img: '', title: "", text: "Se otorgará una <b>Guía digital para el emprendimiento de alto impacto </b>con contenidos teóricos, actividades sugeridas en clase, glosario, plantillas de apoyo y videos de repaso." },
        { number: 6, img: '', title: "", text: "<b>El programa es gratuito </b>sólo debes registrarte en <b>posible.org.mx/retoposibleoax</b> e indicar en tu registro que quieres ser parte de Reto POSiBLE Oaxaca. Tienes hasta el 31 de agosto." }],
        subprocess: [{ number: 1, text: "El docente debe impartir materias relacionadas con el emprendimiento." },
        { number: 2, text: "Debe impartir al menos 5 horas semanales a nivel universitario o bien personal a cargo de programas de emprendimiento, incubación o aceleración universitarios. Con al menos 6 meses en el cargo.." },
        { number: 3, text: 'Buscamos tener equidad de género de por lo menos 60% hombres y 40% mujeres, así como presencia de las ocho regiones de Oaxaca' },
        { number: 4, text: "Será considerada la fecha de inscripción en caso de contar con múltiples perfiles similares, dando preferencia aquellos que hayan terminado su perfil antes." }]
      },
      {
        campaing: "Estudiantes universitarios",
        slide: 2,
        subslide: 'Características de los proyectos',
        subslide1: 'EQUIPOS',
        subslide2: 'PROYECTOS',
        process: [{ number: 1, img: '', title: "", text: "Formaras parte de la <b>comunidad de emprendedores POSiBLE.</b>" },
        { number: 2, img: '', title: "", text: "Adicional al campamento, <b>podrás tener sesiones grupales de mentoría </b>posteriores al campamento para verificar el avance del proyecto <b>durante 6 meses.</b>" },
        { number: 3, img: '', title: "", text: '<b>Recibirás 15 horas de capacitación, accediendo al curso gratuito "El reto de emprender con alto impacto"</b> en la plataforma México X, https://www.mexicox.gob.mx/.' },
        { number: 4, img: '', title: "", text: "<b>El programa es gratuito </b>sólo debes <b>registrarte en posible.org.mx, llenar tu modelo de negocio y enviarlo a revisión</b> una vez que abra la convocatoria Reto POSiBLE Oaxaca." },
        { number: 5, img: '', title: "", text: "Podrás ser uno de los <b>20 seleccionados para un campamento universitario digital, </b> de 5 días donde trabajarán de manera intensiva con un mentor para aterrizar y fortalecer su modelo de negocio." }],
        subprocess: [{ number: 1, text: "Preferentemente que tengan un componente científico, tecnológico y/o de innovación." },
        { number: 2, text: "Con potencial para ser altamente escalables." },
        { number: 3, text: 'Incluyan por lo menos un prototipo o idealmente un producto mínimo viable que pueda ser probado con clientes reales.' },
        { number: 4, text: "Presencia digital preferentemente (página web y/o redes sociales)." }],
        subprocess2: [{ text: "De hasta 3 integrantes" },
        { text: "Multidisciplinarios y diversos: en género y formación académica." }]
      }
    ]
    // Process campaings
    this.processCampaings =[
      {
        campaing: "POSiBLE",
        process: [{ number: 1, img: 'assets/img/landing/process/posible/1.png', title: "Convocatoria", text: "Regístrate en https://posible.org.mx y completa tu perfil como miembro de nuestra comunidad" },
        { number: 2, img: 'assets/img/landing/process/posible/2.png', title: "Participa", text: "Participa, interactúa y aprovecha los recursos en la plataforma" },
        { number: 3, img: 'assets/img/landing/process/posible/3.png', title: "Paneles de selección", text: "Cuando se abra la convocatoria anual, completa la información de tu idea de negocio, en la sección “Mis proyectos” y envíala a revisión" },
        { number: 4, img: 'assets/img/landing/process/posible/4.png', title: "Panel Estatal", text: "Si tu proyecto es de los mejores, avanzarás a la etapa de 'Paneles Estatales'" },
        { number: 5, img: 'assets/img/landing/process/posible/5.png', title: "Evaluación", text: "En los paneles estatales presentarás tu proyecto que será evaluado por un grupo de expertos" },
        { number: 6, img: 'assets/img/landing/process/posible/6.png', title: "POSiBLE 2021", text: "Los mejores proyectos calificados llegarán al Campamento POSIBLE 2021" }]
      },
      {
        campaing: "Posible COOP",
        process: [{ number: 1, img: 'assets/img/landing/process/coop/1.png', title: "Convocatoria", text: "Estar al pendiente de la publicación de convocatorias por Estado. y Entrar a https://coop.posible.org.mx/" },
        { number: 2, img: 'assets/img/landing/process/coop/2.png', title: "Registro", text: "Ir al apartado 'convocatorias' y seleccionar tu Estado. Una vez que hayas leído cuidadosamente la convocatoria, es momento de registrarte." },
        { number: 3, img: 'assets/img/landing/process/coop/3.png', title: "Aplicación", text: "Cuando ya estés registrado, podrás iniciar sesión y comenzar el llenado de tu 'aplicación' donde nos harás saber todo sobre tu cooperativa." },
        { number: 4, img: 'assets/img/landing/process/coop/4.png', title: "Contacto", text: "No olvides llenar el apartado tus datos de contacto." },
        { number: 5, img: 'assets/img/landing/process/coop/5.png', title: "Evaluación", text: "Todas las aplicaciones serán revisadas por el comité de selección y eligieran a las cooperativas que formarán parte del programa se aceleración." },
        { number: 6, img: 'assets/img/landing/process/coop/6.png', title: "POSiBLE COOP", text: "Si eres seleccionado te notificaremos vía correo electrónico y ¡Bienvenido, eres parte de POSIBLE Coop!" }]
      },
      {
        campaing: "POSiBLE MAX",
        process: [{ number: 1, img: 'assets/img/landing/process/max/1.png', title: "Convocatoria", text: "POSiBLE MAX es un programa con duración de 10 meses, enfocado en ayudar a 20 empresas en etapa de escalamiento a conseguir sus objetivos de crecimiento." },
        { number: 2, img: 'assets/img/landing/process/max/2.png', title: "Participa", text: "Los emprendedores que conforman POSiBLE MAX son egresados de los diferentes campamentos de POSiBLE, seleccionados para complementar su formación y trabajar necesidades específicas de sus empresas." },
        { number: 3, img: 'assets/img/landing/process/max/3.png', title: "Crecimiento", text: "Durante el programa los emprendedores recibirán más de 20 sesiones de acompañamiento personalizado con mentores expertos." },
        { number: 4, img: 'assets/img/landing/process/max/4.png', title: "Cursos", text: "Contarán con consultorías especializadas impartidas por empresarios y mentores con expertise en temas específicos del rubro de cada empresa." },
        { number: 5, img: 'assets/img/landing/process/max/5.png', title: "Mentoría", text: "El programa de aceleración tiene entre sus mentores a líderes de industria, emprendedores experimentados, consultores de negocios y especialistas en empresas emergentes." },
        { number: 6, img: 'assets/img/landing/process/max/6.png', title: "POSiBLE MAX", text: "Buscamos impulsar y fortalecer a las empresas para que desarrollen una visión innovadora y transformadora que les permitan ser competitivas, escalables y sostenibles en el largo plaza, a través de la capacitación, las herramientas y la red de aliados de POSIBLE." }]
      },
      {
        campaing: "Reto POSiBLE",
        process: [{ number: 1, img: 'assets/img/landing/process/reto/1.png', title: "Convocatoria", text: "Entra a <a href='http://www.mexicox.gob.mx/'>http://www.mexicox.gob.mx/</a>" },
        { number: 2, img: 'assets/img/landing/process/reto/2.png', title: "Participa", text: "Crea una cuenta dentro de la plataforma" },
        { number: 3, img: 'assets/img/landing/process/reto/3.png', title: "Navega", text: 'Teclea en el buscador "Emprender es un reto, hazlo posible"' },
        { number: 4, img: 'assets/img/landing/process/reto/4.png', title: "Cursos", text: "Entra al curso y comienza las lecciones." },
        { number: 5, img: 'assets/img/landing/process/reto/5.png', title: "Constancia", text: "Una vez que llegues al 80% de avance podrás solicitar tu constancia" },
        { number: 6, img: 'assets/img/landing/process/reto/6.png', title: "Reto POSiBLE", text: 'Recuerda que adicional al MOOC contamos con una comunidad en Facebook llamada "Reto POSIBLE 2020" donde podrás interactuar con otros docentes.' }]
      },
    ]
  }

  scrollFn(anchor: string): void{
  	this._vps.scrollToAnchor(anchor)
  }
}
