<div class="container p-0">
  <app-dynamic-navigation [projectId]="projectId" [actualStep]="navigationSteps.sixthStep"></app-dynamic-navigation>
  <app-stepsbar-progress [totalNumSteps]="totalNumSteps" [currentStep]="stepNumber" [modelProgress]="modelProgress" [stepsNav]="projectSteps"></app-stepsbar-progress>
  <div class="main-container animated fadeIn fast marginTop">
    <div class="card border-0 animated fadeIn fast main-container-card">
      <div class="card-body animated fadeIn">
        <div class="mt-3">
          <form autocomplete="off" [formGroup]="forma" (ngSubmit)="update()">
            <label for="" class="subtitle text-left">
              1. ¿Cómo darás a conocer tu producto o servicio? (Seleccionar máximo 2)
            </label>

            <div class="my-3 form-check" *ngFor="let publicity of publicities; let i=index">
              <input class="form-check-input" type="checkbox" [checked]="checkedGenerator(publicity.publicityType_guid)"
                [id]="publicity.publicityType_guid" [value]="publicity.publicityType_guid"
                (change)="onCheckboxChange($event, 'checksProducts')"
                [disabled]="validatorCheckboxes(publicity.publicityType_guid, 'checksProducts')">
              <label class="form-check-label" [for]="publicity.publicityType_guid">
                <span class=" txt_common_bold"> {{publicity.description}}</span>
              </label>
              <p class="introduction-style">
                {{publicity.detail}}
              </p>
            </div>
            <div *ngIf="visible_publicity" class="animated fadeIn fadeOut">
              <textarea class="form-control input-rounded" rows="4" aria-label="Large"
                formControlName="otrosPublicity" [class.is-invalid]=" " maxlength="600" placeholder="Respuesta"></textarea>
              <div class="txt_advice text-right">
                <div class="d-flex align-items-center justify-content-between mt-2 mb-3">
                  <p *ngIf="submitted && formFields.otrosPublicity.errors && formFields.otrosPublicity.errors.required" class="text-danger animated fadeIn mb-0">
                    Este campo es requerido.
                  </p>                  
                  <div class="ml-auto">
                    <div *ngIf="countChars(formFields.otrosPublicity.value) == 0">Máx 600 caracteres</div>
                    <div *ngIf="countChars(formFields.otrosPublicity.value) >= 1 && countChars(formFields.otrosPublicity.value) < 600">{{600 - countChars(formFields.otrosPublicity.value) }} caracteres faltantes.</div>
                    <div *ngIf="countChars(formFields.otrosPublicity.value) == 600">Máximo de caracteres alcanzado.</div>
                  </div>      
                </div>           
              </div>
            </div>
            <!--mt-3 mb-3 form-check-->
            <hr>
            <label for="" class="subtitle text-left">
              2. Tus clientes, ¿en dónde podrán adquirir tu producto o servicio? (Seleccionar máximo 2)
            </label>
            <div class="my-3 form-check" *ngFor="let sells of sellTypes; let i=index">
              <input class="form-check-input" type="checkbox" [id]="sells.sellType_guid" [value]="sells.sellType_guid"
                [checked]="checkedGenerator2(sells.sellType_guid)"
                (change)="onCheckboxChange($event, 'checksLocations')"
                [disabled]="validatorCheckboxes(sells.sellType_guid,  'checksLocations')">
              <label class="form-check-label" [for]="sells.sellType_guid">
                <span class=" txt_common_bold">{{sells.description}}</span>
              </label>
              <p class="introduction-style">
                {{sells.detail}}
              </p>
            </div>
            <!--mt-3 mb-3 form-check-->
            <div *ngIf="submitted && formFields.checksLocations.errors" class="errors pt-1 pb-3">
              <small *ngIf="formFields.checksLocations.errors.required" class="text-danger animated fadeIn">
                Debe seleccionar mínimo una opción.
              </small>
            </div>
            <div *ngIf="visible" class="animated fadeIn fadeOut">
              <textarea class="form-control input-rounded" rows="4" aria-label="Large"
                formControlName="otrosDescr" [class.is-invalid]=" " maxlength="600" placeholder="Respuesta"></textarea>
              <div class="txt_advice text-right">
                <div class="d-flex align-items-center justify-content-between mt-2 mb-3">
                  <p *ngIf="submitted && formFields.otrosDescr.errors && formFields.otrosDescr.errors.required" class="text-danger animated fadeIn mb-0">
                    Este campo es requerido.
                  </p>                  
                  <div class="ml-auto">
                    <div *ngIf="countChars(formFields.otrosDescr.value) == 0">Máx 600 caracteres</div>
                    <div *ngIf="countChars(formFields.otrosDescr.value) >= 1 && countChars(formFields.otrosDescr.value) < 600">{{600 - countChars(formFields.otrosDescr.value) }} caracteres faltantes.</div>
                    <div *ngIf="countChars(formFields.otrosDescr.value) == 600">Máximo de caracteres alcanzado.</div>
                  </div>      
                </div>           
              </div>
            </div>
            <button type="submit" class="btn-lg btn btn-block btn-warning btn_style">
              <span class="btn_style_text">Guardar y continuar</span>
            </button>
            <br><br>
          </form>
        </div>
      </div>
    </div>

  </div>
</div>


