import { Component, OnInit } from '@angular/core';
import { PublicationService } from 'src/app/services/publication/publication.service';
import { Publication } from 'src/app/models/publication/publication';
import { SnackbarService } from 'src/app/services/notifications/snackbar.service';
import { Router } from '@angular/router';
import { AdService } from 'src/app/services/ad/ad.service';
import { PublicationDetail } from 'src/app/models/publication-detail/publication-detail';
import { FirebaseService } from 'src/app/services/firebase-service/firebase.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  // Actual page
  pageIndex = 1;
  // list of publications
  publications: Publication[];
  // loading spinner
  isLoading = false;
  // list of ads
  ads: [];
  // list of all the posts that are going to be listed
  allPosts = [];
  // flag to identify if the last page have been reached
  lastPage = false;
  parentPage = 'homePosible';

  constructor(
    private publicationService: PublicationService,
    private adService: AdService,
    private router: Router,
    private snackBarService: SnackbarService,
    private firebase: FirebaseService
  ) { }

  ngOnInit(): void {
    this.getAds();
    this.firebase.logEvent('feedEntrepeneur');
  }

  openProfile(): void {
    this.router.navigate(['app/perfil']);
  }

  /**
   * Method to get all the publications by a speficic page
   * @param page - page number
   */
  getPublications(page): void {
    this.isLoading = true;
    this.publicationService.getAll(page).then(response => {
      this.publications = response['results'].map(publication => new PublicationDetail(publication));
      this.insertAds();
      this.allPosts = [...this.allPosts, ...this.publications];
      if (response['next'] === null) {
        this.lastPage = true;
      }
    })
      .catch(error => {
        this.snackBarService.showSnackBar('Cerrar', `${error} ⚠️`);
      })
      .finally(() => this.isLoading = false);
  }

  // Method to get all the advertisements
  getAds(): void {
    this.adService.getAll().then(response => {
      this.ads = response['data'].map(ad => new PublicationDetail(ad));
      this.getPublications(this.pageIndex);
    })
      .catch(error => {
        this.snackBarService.showSnackBar('Cerrar', `${error} ⚠️`);
      });
  }

  /**
   * Method to insert ads in the list of publications
   * @param startPosition - index position where the ads are going to start being displayed
   * @param itemInterval - interval of items between every ad
   */
  insertAds(startPosition = 10, itemInterval = 10): void {
    // initialize our counter of the current lap
    let currentLap = 0;
    while (startPosition < this.publications.length) {
      // verify if there is an element in the specific index, if not restart the counter to keep showing ads
      if (this.ads[currentLap] != null) {
        this.publications.splice(startPosition, 0, this.ads[currentLap]);
      } else {
        currentLap = 0;
        this.publications.splice(startPosition, 0, this.ads[currentLap]);
      }
      currentLap++;
      startPosition += itemInterval;
    }
  }

  // Method to retrieve more publications when the user gets to the bottom of the available publications
  onScroll(): void {
    if (this.lastPage) {
      return;
    }
    this.pageIndex++;
    this.getPublications(this.pageIndex);
  }

}
