import { BrowserModule } from '@angular/platform-browser';

import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { PrivacyAdviceComponent } from './components/pages/privacy-advice/privacy-advice.component';
import { CreateProjectComponent } from './components/pages/create-project/create-project.component';
import { CreateAcountComponent } from './components/pages/create-acount/create-acount.component';
import { ForgotPasswordComponent } from './components/pages/forgot-password/forgot-password.component';
import { NavbarComponent } from './components/shared/navbar/navbar.component';
import { CreateProject2Component } from './components/pages/create-project2/create-project2.component';
import { CreateProject3Component } from './components/pages/create-project3/create-project3.component';
import { CreateProject4Component } from './components/pages/create-project4/create-project4.component';
import { CreateProject5Component } from './components/pages/create-project5/create-project5.component';
import { CreateProject6Component } from './components/pages/create-project6/create-project6.component';
import { CreateProject7Component } from './components/pages/create-project7/create-project7.component';
import { CreateProject8Component } from './components/pages/create-project8/create-project8.component';


import { LoginComponent } from './components/pages/login/login.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { TermsComponent } from './components/pages/terms/terms.component';
import { FAQComponent } from './components/pages/faq/faq.component';
import { AuthGuard } from './auth.guard';
import { HomeComponent } from './components/pages/home/home.component';
import { NewProjectComponent } from './components/pages/new-project/new-project.component';
import { DescribeProblemComponent } from './components/pages/describe-problem/describe-problem.component';
import { ExplainIdeaComponent } from './components/pages/explain-idea/explain-idea.component';
import { DescribeClientsComponent } from './components/pages/describe-clients/describe-clients.component';
import { ValueProposalComponent } from './components/pages/value-proposal/value-proposal.component';
import { DefineAdvertisingComponent } from './components/pages/define-advertising/define-advertising.component';
import { GenerateIncomeComponent } from './components/pages/generate-income/generate-income.component';
import { TractionGrowthComponent } from './components/pages/traction-growth/traction-growth.component';
import { SlideHeaderMenuComponent } from './components/shared/slide-header-menu/slide-header-menu.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './modules/material/material.module';
import { StepsbarProgressComponent } from './components/shared/stepsbar-progress/stepsbar-progress.component';
import { ConfirmPasswordComponent } from './components/pages/confirm-password/confirm-password.component';
import { ProjectSuccessComponent } from './components/pages/project-success/project-success.component';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { NewPostComponent } from './components/pages/new-post/new-post.component';
import { PublicationComponent } from './components/components/publication/publication.component';
import { ProfileComponent } from './components/pages/profile/profile.component';
import { ProfileDetailComponent } from './components/pages/profile/detail/detail.component';
import { ProfileProjectComponent } from './components/pages/profile/project/project.component';
import { ScheduleProjectComponent } from './components/pages/profile/schedule/schedule.component';
import { AppointmentDetailComponent } from './components/pages/profile/appointment/appointment.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { CommentComponent } from './components/components/comment/comment.component';
import { CreateCommentComponent } from './components/components/create-comment/create-comment.component';
import { FeedComponent } from './components/pages/feed/feed.component';
import { SupportModuleComponent } from './components/pages/support-module/support-module.component';
import { DynamicNavigationComponent } from './components/shared/dynamic-navigation/dynamic-navigation.component';
import { HighlightDirective } from './directives/highlight/highlight.directive';
import { SearchPanelComponent } from './components/pages/search-panel/search-panel.component';
import { ShortPublicationComponent } from './components/components/short-publication/short-publication.component';
import { SearchComponent } from './components/shared/search/search.component';
import { PersonComponent } from './components/components/person/person.component';
import { ContactsComponent } from './components/pages/contacts/contacts.component';
import { ChatComponent } from './components/pages/contacts/chat/chat.component';
import { TableFilterPipe } from './pipes/table-filter.pipe';
import { ProfileTopicsComponent } from './components/pages/profile/topics/topics.component';
import { PublicationDetailComponent } from './components/pages/publication-detail/publication-detail.component';
import { SearchHighlightDirective } from './directives/search-highlight/search-highlight.directive';
import { SafePipe } from './pipes/safe.pipe';
import { FriendRequestComponent } from './components/components/friend-request/friend-request.component';
import { NetworkComponent } from './components/pages/network/network.component';
import { UserProfileComponent } from './components/pages/user-profile/user-profile.component';
import { SurveyComponent } from './components/shared/dialogs/survey/survey.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SharePublicationComponent } from './components/components/share-publication/share-publication.component';
import { SharedPublicationComponent } from './components/components/shared-publication/shared-publication.component';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { ImageSliderComponent } from './components/components/image-slider/image-slider.component';
import { NotificationsComponent } from './components/pages/notifications/notifications.component';
import { NotificationComponent } from './components/components/notification/notification.component';
import { ProjectCompletedComponent } from './components/components/project-completed/project-completed.component';
import { LoadingFilesComponent } from './components/pages/contacts/chat/loading-files/loading-files.component';
import { ProjectCompletedDialogComponent } from './components/shared/dialogs/project-completed-dialog/project-completed-dialog/project-completed-dialog.component';
import { ConfirmAppointmentDialogComponent } from './components/shared/dialogs/confirm-appointment/confirm-appointment-dialog/confirm-appointment-dialog.component';
import { CongratulationsDialogComponent } from './components/shared/dialogs/congratulations-dialog/congratulations-dialog/congratulations-dialog.component';
import { PostUploadModalComponent } from './components/components/post-upload/post-upload-modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalPostUploadService } from './components/components/post-upload/post-upload-modal.service';
import { CommonModule } from '@angular/common';
import { ModalLimitFilesComponent } from './components/components/modal-limit-files/modal-limit-files.component';
import { HomeappComponent } from './components/components/homeapp/homeapp.component';
import { ModalGenericComponent } from './components/components/modal-generic/modal-generic.component';
import { ModalGenericService } from './components/components/modal-generic/modal-generic.service';
import { LoginModalComponent } from './components/components/login-modal/login-modal.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ModalMediafileComponent } from './components/pages/contacts/chat/modal-mediafile/modal-mediafile.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { environment } from 'src/environments/environment';
import * as firebase from 'firebase';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { LandingComponent } from './components/pages/landing/landing.component';
import { RetoOaxacaComponent } from './components/pages/retoOaxaca/retoOaxaca.component';
import { KidsZacatecasComponent } from './components/pages/kidsZacatecas/kidsZacatecas.component';
import { HackatonComponent } from './components/pages/hackaton/hackaton.component';
import { PendingBusinessModelsComponent } from './components/shared/dialogs/pending-business-models/pending-business-models.component';
import { PendingBusinessmodelsModalComponent } from './components/components/pending-businessmodels-modal/pending-businessmodels-modal.component';
import { ProjectsDialogComponent } from './components/shared/dialogs/projects-dialog/projects-dialog.component';
import { RetoChiapasComponent } from './components/pages/reto-chiapas/reto-chiapas.component';
import { CreateAccountWomanComponent } from './components/pages/create-account-woman/create-account-woman.component';
import { PrivacyAdviceWomanComponent } from './components/pages/privacy-advice-woman/privacy-advice-woman.component';
import { RetoZacatecasComponent } from './components/pages/reto-zacatecas/reto-zacatecas.component';
import { RetoChiapasEmprendedoresComponent } from './components/pages/reto-chiapas-emprendedores/reto-chiapas-emprendedores.component';
import { CreateAccountWomenEntrepreneurComponent } from './components/pages/create-account-women-entrepreneur/create-account-women-entrepreneur.component';
import { CongratulationWomenEntrepreneurComponent } from './components/pages/congratulation-women-entrepreneur/congratulation-women-entrepreneur.component';


if (environment.production === true) {
  firebase.initializeApp(environment.firebase);
}

//  modules to import only if the site is in PRODUCTION mode
let prodImports = [
  AngularFireModule.initializeApp(environment.firebase),
  AngularFireAnalyticsModule,
];

// if the environment is DEV clear production imports
if (environment.production !== true) {
  prodImports = [];
}

@NgModule({
  declarations: [
    AppComponent,
    PrivacyAdviceComponent,
    CreateProjectComponent,
    CreateAcountComponent,
    ForgotPasswordComponent,
    NavbarComponent,
    CreateProject2Component,
    CreateProject3Component,
    CreateProject4Component,
    CreateProject5Component,
    CreateProject6Component,
    CreateProject7Component,
    CreateProject8Component,
    LoginComponent,
    FooterComponent,
    TermsComponent,
    FAQComponent,
    HomeComponent,
    NewProjectComponent,
    DescribeProblemComponent,
    ExplainIdeaComponent,
    DescribeClientsComponent,
    ValueProposalComponent,
    DefineAdvertisingComponent,
    GenerateIncomeComponent,
    TractionGrowthComponent,
    SlideHeaderMenuComponent,
    StepsbarProgressComponent,
    PublicationComponent,
    ConfirmPasswordComponent,
    ProjectSuccessComponent,
    NewPostComponent,
    CommentComponent,
    CreateCommentComponent,
    ProfileComponent,
    ProfileDetailComponent,
    ProfileProjectComponent,
    ScheduleProjectComponent,
    AppointmentDetailComponent,
    ProfileTopicsComponent,
    FeedComponent,
    SupportModuleComponent,
    HighlightDirective,
    SearchPanelComponent,
    ShortPublicationComponent,
    SearchComponent,
    PersonComponent,
    DynamicNavigationComponent,
    PublicationDetailComponent,
    SearchHighlightDirective,
    HighlightDirective,
    ContactsComponent,
    ChatComponent,
    TableFilterPipe,
    SafePipe,
    NetworkComponent,
    FriendRequestComponent,
    UserProfileComponent,
    SurveyComponent,
    SharePublicationComponent,
    SharedPublicationComponent,
    CustomDatePipe,
    ImageSliderComponent,
    NotificationsComponent,
    NotificationComponent,
    ProjectCompletedComponent,
    LoadingFilesComponent,
    ProjectCompletedDialogComponent,
    ConfirmAppointmentDialogComponent,
    CongratulationsDialogComponent,
    PostUploadModalComponent,
    ModalLimitFilesComponent,
    HomeappComponent,
    ModalGenericComponent,
    LoginModalComponent,
    ModalMediafileComponent,
    LandingComponent,
    RetoOaxacaComponent,
    KidsZacatecasComponent,
    HackatonComponent,
    PendingBusinessModelsComponent,
    PendingBusinessmodelsModalComponent,
    ProjectsDialogComponent,
    RetoChiapasComponent,
    CreateAccountWomanComponent,
    PrivacyAdviceWomanComponent,
    RetoZacatecasComponent,
    RetoChiapasEmprendedoresComponent,
    CreateAccountWomenEntrepreneurComponent,
    CongratulationWomenEntrepreneurComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule,
    NgImageSliderModule,
    InfiniteScrollModule,
    NgbModule,
    FontAwesomeModule,
    NgSelectModule,
    ...prodImports,
    NgxDaterangepickerMd.forRoot({
      separator: '/',
      applyLabel: 'Aceptar',
  })
  ],
  entryComponents:[
    PostUploadModalComponent,
    ModalLimitFilesComponent,
    ModalGenericComponent,
    ModalMediafileComponent
  ],
  providers: [
    AuthGuard,
    ModalPostUploadService,
    ModalGenericService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
