import * as moment from 'moment';
// Class to implement attributes of a comment
export class Comment {

    guid: string;
    userGuid: string;
    username: string;
    userPicture: string;
    userStatus: string;
    company: string;
    description: string;
    feedPosible: boolean;
    createdAt: string;

    constructor(data: any) {
        // setup moment language and set the time passed of this publication
        moment.locale('es');

        this.guid = data.publication_comment_guid;
        this.userGuid = data.user_guid;
        this.username = data.user_full_name;
        this.userPicture = data.user.image_path;
        this.userStatus = data.user.status;
        this.company = data.user.company;
        this.description = data.description;
        this.feedPosible = data.feedPosible;
        this.createdAt = moment(data.createDate).format('LL');
    }

}
