<div class="modal-content">
    <div class="modal-body text-center">
        <br>
        <br>
        <h2>{{title}}</h2>
        <br>
        <div class="animated fadeIn fast px-4">
            <span class="common_txt">
        {{description}}
      </span>
        </div>
    </div>
    <div class="modal-footer border-0">
        <button class="btn-lg btn btn-block btn-warning btn_continuar modal-button" (click)="redirectToPage()" data-dismiss="modal" *ngIf="!isProject"> Continuar</button>
        <button class="btn-lg btn btn-block btn-warning btn_continuar modal-button" (click)="redirectToPage()" data-dismiss="modal" *ngIf="isProject"> Editar después</button>
        <button class="btn-lg btn btn-block btn-success btn_continuar modal-button" data-dismiss="modal" (click)="modalDismiss()" *ngIf="isProject"> Seguir editando</button>
    </div>
    <br>
</div>