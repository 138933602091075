import { Injectable } from '@angular/core';

declare const fbq: any;

@Injectable({
  providedIn: 'root'
})
export class TrackingPixelService {

  constructor() { }

  public trackEvent(properties: Object, event: string = 'ViewContent') {
   if (typeof fbq === 'undefined') {
     return;
   }

   fbq('track', event, properties);
 }

}
