<nav class="navbar navbar-expand-md">
    <div class="container">
        <fa-icon [icon]="faBars" (click)="openNav()" class="main_menu_button"></fa-icon>
        <div class="mx-auto order-0">
            <img src="/assets/img/logo_white.png" *ngIf="!isLoggedIn" alt="" class="logo-main-navbar">
            <img src="/assets/img/logo_white.png" *ngIf="isLoggedIn" [routerLink]="['/app/perfil']" alt="" class="logo-main-navbar logo">
        </div>

        <fa-icon *ngIf="isLoggedIn" [icon]="faSearch" class="search-icon pull-right cursor__pointer" [routerLink]="['/app/busqueda']"></fa-icon>
    </div>
</nav>

<div id="mySidenav" class="sidenav">
    <div class="sidenav-container">
        <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">&times;</a>

        <!-- Profile info -->
        <div class="sidenav_header" *ngIf="isLoggedIn">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-auto p-0">
                        <div [routerLink]="['/app/perfil']" (click)="closeNav()">
                            <img class="img-circle rounded-circle" *ngIf="currentUser?.image" height="56px" width="56px" [src]="currentUser?.image">
                            <img class="img-circle rounded-circle" *ngIf="!currentUser?.image" height="56px" width="56px" src="assets/img/home/profile_img.png">
                        </div>
                        <span class="online"></span>
                    </div>
                    <div class="col d-flex flex-column" *ngIf="isLoggedIn">
                        <a [routerLink]="['/app/perfil']" (click)="closeNav()">
                            <span class="txt_title_profile"> {{currentUser?.firstName}} {{currentUser?.lastName}}</span>
                            <p class="job_title">Emprendedor</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="sidenav_body" *ngIf="isLoggedIn">
            <div class="container p-0">
                <ul>
                    <li routerLink="app/perfil/detalles" (click)="closeNav()">
                        <a>Configuración de perfil</a>
                        <fa-icon [icon]="faChevronRight"></fa-icon>
                    </li>
                    <li routerLink="app/perfil" (click)="closeNav()">
                        <a>Mis proyectos</a>
                        <fa-icon [icon]="faChevronRight"></fa-icon>
                    </li>
                    <li routerLink="terminos" (click)="closeNav()">
                        <a>Términos y condiciones</a>
                        <fa-icon [icon]="faChevronRight"></fa-icon>
                    </li>
                    <li routerLink="preguntas" (click)="closeNav()">
                        <a>Preguntas frecuentes</a>
                        <fa-icon [icon]="faChevronRight"></fa-icon>
                    </li>
                    <li routerLink="aviso-privacidad" (click)="closeNav()">
                        <a>Aviso de privacidad</a>
                        <fa-icon [icon]="faChevronRight"></fa-icon>
                    </li>
                    <li (click)="logout()" class="active">
                        <a>
                            <fa-icon [icon]="faSignOutAlt"></fa-icon> Cerrar Sesión</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="sidenav_body" *ngIf="!isLoggedIn">
            <div class="container p-0">
                <ul>
                    <li class="mt-5">

                    </li>
                    <li routerLink="login" (click)="closeNav()" class="active">
                        <a>Iniciar sesión</a>
                        <fa-icon [icon]="faSignInAlt"></fa-icon>
                    </li>
                </ul>
            </div>
        </div>
    </div>

</div>
<div class="sidenav-backdrop" (click)="closeNav()"></div>