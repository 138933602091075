<!-- Old Version -->
<!-- <div class="content-dialog">
  <div class="header-mobile">
    <span class="circle"><img src="assets/img/dialogs/foco-popup-posible.png"></span>
    <div class="col-12">
      <div class="dot-background">
        <div class="dot-container" *ngFor="let i of [].constructor(100)">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      </div>
    </div>
    <a class="" mat-button mat-dialog-close>
      <i class="fa fa-times"></i>
    </a>
  </div>
  <ngb-carousel interval="false">
    <ng-template ngbSlide>
      <div class="row">
        <div class="col-12 header">
          <div class="dot-background">
            <div class="dot-container" *ngFor="let i of [].constructor(100)">
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
            </div>
          </div>
          <h1>¿PARA QUÉ SIRVE <span class="border-yellow">EL MODELO DE NEGOCIO POSiBLE?</span></h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h3>Para poder <b>acceder al Campamento POSiBLE 2021 y recibir todos los beneficios del programa:</b></h3>
        </div>
      </div>
      <div class="text-container" style="background-image:url(assets/img/dialogs/background-lamp.png)">
        <div class="row mt-5">
          <div class="col-md-4 col-xs-12">
            <span class="simple-text">Tener <b>más claridad</b> para desarrollar <b>tu proyecto</b></span>
          </div>
          <div class="col-md-4 col-xs-12">
            <span class="simple-text"></span>
          </div>
          <div class="col-md-4 col-xs-12">
            <span class="simple-text">Tener un <b>mentor especializado</b> que <b>ayude a mejorar tu idea</b></span>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-4 col-xs-12">
            <span class="simple-text">Obtener <b>capacitación</b> y <b>mentoria especializada</b></span>
          </div>
          <div class="col-md-4 col-xs-12">
            <span class="simple-text"></span>
          </div>
          <div class="col-md-4 col-xs-12">
            <span  class="simple-text"><b>Acceder</b> a <b>fuentes de financiamiento</b></span>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-4 col-xs-12">
            <span class="simple-text"></span>
          </div>
          <div class="col-md-5 col-xs-12">
            <span class="simple-text">Ampliar tu <b>visibilidad</b> para <b>potenciar</b> tus ventas</span>
          </div>
          <div class="col-md-3 col-xs-12">
            <span class="simple-text"></span>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <img class="logo" src="assets/img/dialogs/logo.png">
          </div>
        </div>
      </div>
      <div class="text-container-mobile">
        <div class="row">
            <div class="col-6"><span class="simple-text">Tener <b>más claridad</b> para desarrollar <b>tu proyecto</b></span></div>
            <div class="col-6"><span class="simple-text">Tener un <b>mentor especializado</b> que <b>ayude a mejorar tu idea</b></span></div>
            <div class="col-6"><span class="simple-text">Obtener <b>capacitación</b> y <b>mentoria especializada</b></span></div>
            <div class="col-6"><span  class="simple-text"><b>Acceder</b> a <b>fuentes de financiamiento</b></span></div>
            <div class="col-6"><span class="simple-text">Ampliar tu <b>visibilidad</b> para <b>potenciar</b> tus ventas</span></div>
            <div class="col-6"><img class="logo" src="assets/img/dialogs/logo.png"></div>
        </div>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="video-responsive">
        <iframe width="1904" height="752" src="https://www.youtube.com/embed/lElP-GHnqeU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </ng-template>
  </ngb-carousel>
</div> -->
<div style="position: relative;">
  <img src="../../../../../assets/img/popup-profile.png" alt="" class="w-100 h-auto img-banner" >
  <div class="mt-2" style="padding: 0 12.5%;">
    <p class="text-center font-weight-bold bold-txt">Ahora te invitamos a llenar tu proyecto que conformará tu modelo de negocio para participar en la Convocatoria POSiBLE 2022*</p>
    <P class="font-italic">*Recuerda que llenar tu modelo de negocio es un requisito para poder avanzar a las siguientes etapas de POSiBLE.</P>
  </div>
  <img src="../../../../../assets/img/b_cerrar.png" alt="" style="position: absolute; top: -25px; right: -25px;" (click)="close()">
</div>
