<div class="modal-content">
  <div class="modal-body text-center">
    <div class="my-3 py-4">
      <span class="new-post-title"> Máximo 5 archivos </span>
    </div>
    <div class="animated fadeIn fast px-4">
      <span class="common_txt">
        El límite de subida es de 5 archivos.
      </span>
    </div>
  </div>
    <div class="modal-footer border-0">
    <button  class="btn-lg btn btn-block btn-warning btn_continuar modal-button" (click)="closeModal()">Continuar</button>
  </div>
  <br>
</div>