import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';
import { StateService } from '../../../../services/state/state.service';
import { Profile } from '../../../../models/User/profile';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SnackbarService } from 'src/app/services/notifications/snackbar.service';
import { Topic } from '../../../../models/topic/topic';
import { TopicService } from '../../../../services/topic.service';
import { GendersType } from 'src/app/models/gender/gender';
import { SchoolType, Education, EducationType } from '../../../../models/education/education';
import { Subject } from 'rxjs';
import { ItemsMultiselect } from 'src/app/classes/select/item-multiselect';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { TechSchool } from 'src/app/models/tech-school/tech-school';
import { SchoolTechService } from 'src/app/services/school-tech.service';
import { FirebaseService } from 'src/app/services/firebase-service/firebase.service';
@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class ProfileDetailComponent implements OnInit {

  // loading spinner
  isLoading = true;
  editable = false;
  statesLoading = true;
  municipalityLoading = true;

  user: Profile;
  states = [];
  municipalities = [];
  topics = [];

  form: FormGroup;
  submitted = false;

  image: any;
  // List of genders
  genders = GendersType.values();
  // List of educations level
  educationsTypes = EducationType.values();
  // List of school types
  schoolTypes = SchoolType;
  // List of educations level
  educations = Education;
  // Items already shown in the select, when Tecnologicos is shown
  tech_schools: ItemsMultiselect[] = [];
  // Show loading when is getting more items
  loading = false;
  // Actual page on paginate
  currentPage = 1;
  // Numebr of items to display per page
  itemsPerPage = 10;
  // Total items on API
  totalItems: number;
  // total of paginate pages
  totalPages: number;
  // Observer waiting for new 
  select$ = new Subject<string>();
  // Use to search item
  searchText = '';
  // Selected institution {id: string, name: string} 
  selectedInstitution;

  constructor(
    private router: Router,
    private authenticationService: AuthService,
    private stateService: StateService,
    private formBuilder: FormBuilder,
    private snackbarService: SnackbarService,
    private topicService: TopicService,
    private techSchoolService: SchoolTechService,
    private firebase: FirebaseService
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      description: [''],
      gender: ['', Validators.required],
      phonenumber: ['', [Validators.required, Validators.minLength(13), Validators.maxLength(13), Validators.pattern('^[(][0-9]{3}[)][0-9]{3}[-\.][0-9]{4,6}$')]],
      birth_date: ['', Validators.required],
      education: ['', Validators.required],
      is_public: [''],
      state: ['', Validators.required],
      municipality: ['', Validators.required],
      institution: [null]
    });
    this.getStates();
    this.getUserTopics();
    this.onSearch();
    this.firebase.logEvent('configProfile');
  }

  /* Method to get all topics ProfileTopicsComponent
  *
  */
  getUserTopics(): void {
    this.topicService.getUserTopics()
      .then(data => {
        data.data.forEach(topic => {
          this.topics.push(new Topic(topic));
        });
      })
      .catch(error => this.snackbarService.showSnackBar('Cerrar', error));
  }

  /* Method to do when form submit
  *
  */
  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      this.snackbarService.showSnackBar('Cerrar', "Verifique que los campos esten completos.")
      return;
    }
    const userUpdated = this.generateUserObject();
    this.authenticationService.saveProfile(userUpdated)
      .subscribe(resp => {
        this.updateUserObject();
        this.snackbarService.showSnackBar('Cerrar', resp['message']);
        this.router.navigate(['app/feed']);
      }, (error) => this.snackbarService.showSnackBar('Cerrar', error));
  }

  // update the current user object that is being used in the navbar
  updateUserObject(): void {
    this.authenticationService.getCurrentUser()
      .then(data => {
        this.user = new Profile(data);
      })
      .catch(error => this.snackbarService.showSnackBar('Cerrar', error));
  }
  /**
     * Method to return the lenght of a textfield
     * @param str - string to look for the chars
     * Return - number of chars
     */
   countChars(str): number {
    if (str) {
      const chars = str.length;
      return chars;
    } else {
      return 0;
    }
  }
  /* Method to get the user data
  *
  */
  getUser(): void {
    this.authenticationService.getCurrentUser()
      .then(data => {
        this.isLoading = false;
        this.user = new Profile(data);
        this.setUserData();
        this.getMunicipalities();
      })
      .catch(error => this.snackbarService.showSnackBar('Cerrar', error));
  }

  // Convenience getter for easy access to form fields
  get formFields() {
    return this.form.controls;
  }

  // method to set the user data received in our form controls
  setUserData() {
    let phoneNumber = this.user.phonenumber;
    if(this.user.phonenumber.length > 0){
      phoneNumber = `(${this.user.phonenumber.substring(0,3)})${this.user.phonenumber.substring(3,6)}-${this.user.phonenumber.substring(6,11)}`;
    }
    this.formFields.first_name.setValue(this.user.first_name);
    this.formFields.last_name.setValue(this.user.last_name);
    this.formFields.description.setValue(this.user.description);
    this.formFields.gender.setValue(this.user.gender);
    this.formFields.phonenumber.setValue(phoneNumber);
    this.formFields.birth_date.setValue(this.user.birth_date);
    this.formFields.education.setValue(this.user.education);
    this.formFields.is_public.setValue(this.user.is_public);
    this.formFields.state.setValue(this.user.state);
    this.formFields.municipality.setValue(this.user.municipality);
    if(this.user.education == this.educations['Tecnológicos']){
      this.selectedInstitution = this.user.institution;
      this.formFields.institution.setValidators([Validators.required]);
    }else{
      this.selectedInstitution = null;
      this.formFields.institution.clearValidators();
    }
    this.form.get('institution').setErrors(null);
    this.form.updateValueAndValidity();
  }

  // method to generate the updated user object to send
  generateUserObject(): object {
    return {
      user_guid: this.user.user_guid,
      birth_date: this.formFields.birth_date.value,
      description: this.formFields.description.value,
      education: this.formFields.education.value,
      first_name: this.formFields.first_name.value,
      last_name: this.formFields.last_name.value,
      gender: this.formFields.gender.value,
      phonenumber: this.formFields.phonenumber.value.replace(/([(]|[)]|-)/g , ''),
      is_public: this.formFields.is_public.value,
      state: this.formFields.state.value,
      municipality: this.formFields.municipality.value,
      institution_guid: this.selectedInstitution && this.formFields.education.value == this.educations['Tecnológicos'] ? this.selectedInstitution['id']: null
    };
  }

  /* Method to get all the states
  *
  */
  getStates(): void {
    this.stateService.getAll()
      .then(data => {
        this.statesLoading = false;
        this.states = data;
        this.getUser();
      })
      .catch(error => this.snackbarService.showSnackBar('Cerrar', error));
  }

  /* Method to get all the municipalities
  *
  */
  getMunicipalities(): void {
    this.stateService.getCities(this.formFields.state.value)
      .then(data => {
        this.municipalityLoading = false;
        this.municipalities = data;
      })
      .catch(error => this.snackbarService.showSnackBar('Cerrar', error));
  }

  /* Method to get load and send the new profile image
  * @param files - FileList from image input
  */
  handleFileInput(files: FileList): void {
    let image = files.item(0);
    let formData: FormData = new FormData();
    formData.append('image', image, image.name);
    this.authenticationService.uploadProfileImage(formData)
      .subscribe(resp => {
        this.snackbarService.showSnackBar('Cerrar', resp['message']);
        this.updateUserObject();
      }, (error) => this.snackbarService.showSnackBar('Cerrar', error));
  }

  /* Method to delete de profile image
  *
  */
  deleteImage(): void {
    this.authenticationService.deleteImage()
      .subscribe(resp => {
        this.snackbarService.showSnackBar('Cerrar', resp['message']);
        this.updateUserObject();
      }, (error) => this.snackbarService.showSnackBar('Cerrar', error));
  }

  getSchoolType(id: boolean): string {
    if (this.user.education == this.educations['Licenciatura']) {
      return `- ${SchoolType.find(type => type.id === id)['description']}`;
    }
    return '';
  }
  
  // Returns institution if education is 'Tecnológicos'
  getSchoolTech(): string {
    if (this.user.education == this.educations['Tecnológicos']){
      return " - " + this.user.institution['name'];
    }
    return '';
  }
  
  // Retrieve the first page of results
  resetQuery(): void {
    this.tech_schools = [];
    this.currentPage = 1;
    this.searchText = '';
    this.fetchMore();
  }

  // Return all query parameters into json object
  getQueryParameters(): string {
    const searchParams = new URLSearchParams();
    const queryParams: object = {
      description: this.searchText || '',
      current_page: this.currentPage,
      per_page: this.itemsPerPage
    };
    Object.keys(queryParams).forEach(key => searchParams.append(key, queryParams[key]));
    return searchParams.toString();
  }
  
  // Get next data to display with infinity scroll
  fetchMore(): void {
    this.loading = true;
    let queryString = this.getQueryParameters();

    this.techSchoolService.getTechnologics(queryString).then( data => {
      this.loadData(data);
    }).catch(error => {
      this.snackbarService.showSnackBar('Cerrar', 'Ha ocurrido un error al consultar tecnológicos. ⚠️');
    }).finally(() => this.loading = false );
  }
  
  
  // Execute on typing to search
  onSearch(): void {    
    this.select$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      tap(() => this.loading = true))
      .subscribe( data => {
        this.searchText = data;
        this.fetchMore();
      });
  }


  // Execute when scroll bar is on bottom
  onScrollToEnd(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.fetchMore();
    }
  }

  /**
   * Set retrieved data  to select and paginations options
   * @param data Info requested
   */
  loadData(data: object): void{
    this.currentPage = data['current_page'];
    this.totalPages = data['total_pages'];
    this.totalItems = data['total'];
    if (this.currentPage == 1) {
      this.tech_schools = [];
    }  
    let newData = data['data'].map(tech => new TechSchool(tech));
    this.tech_schools = this.tech_schools.concat(newData);

  }

    /**
   * Method that add format to phone number input
   * @param field Phone number input
   */
  validatePhoneNo(field: { value: string }): void { 
    let phoneNumDigits = field.value.replace(/\D/g, '');  
    let formattedNumber = phoneNumDigits;
    if (phoneNumDigits.length > 6)
      formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ')' + phoneNumDigits.substring(3, 6) + '-' + phoneNumDigits.substring(6);
    else if (phoneNumDigits.length > 3)
      formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ')' + phoneNumDigits.substring(3);
  
    field.value = formattedNumber;
  }

  /** 
   * Method that update validation rules of institutions
   * @param {event} Education selected
   */
  educationChange($event){
    const education = $event.target.value;
    if(education == this.educations['Tecnológicos']){
      this.selectedInstitution = this.user.institution;
      this.formFields.institution.setValidators([Validators.required]);
    }else{
      this.selectedInstitution = null;
      this.formFields.institution.clearValidators();
    }
    this.form.get('institution').setErrors(null);
    this.form.updateValueAndValidity();
  }

}

