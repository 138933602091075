import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PublicationService } from '../../../services/publication/publication.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SnackbarService } from '../../../services/notifications/snackbar.service';
import { PublicationDetail } from 'src/app/models/publication-detail/publication-detail';
import { isObject } from 'util';
import { ChatService } from 'src/app/services/chat/chat.service';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationTypes } from 'src/app/models/notification-types/notification-types';

@Component({
  selector: 'app-share-publication',
  templateUrl: './share-publication.component.html',
  styleUrls: ['./share-publication.component.scss']
})
export class SharePublicationComponent implements OnInit {

  shareForm: FormGroup;
  publicationId = '';
  isLoading = false;

  constructor(private router: Router,
    private publication: PublicationService,
    private formbuilder: FormBuilder,
    private route: ActivatedRoute,
    private snackBarService: SnackbarService,
    private chatService: ChatService,
    private authenticationService : AuthService,
    ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      if (params.id) {
        this.publicationId = params.id;
      }
    });
    this.shareForm = this.formbuilder.group({
      shareText: new FormControl([''])
    })
  }

  // Returns to the list of publications
  goBack(): void{
    this.router.navigate(['app/inicio']);
  }

  // Convenience getter for easy access to form fields
  get formFields() {
    return this.shareForm.controls;
  }

  // Method that share the publication with an optional description from a given user
  sharePublication(): void{
    this.isLoading = true;
    let description = typeof(this.formFields.shareText.value) === 'object' ? '': this.formFields.shareText.value;

    this.publication.share(description,this.publicationId).then( (data :any)=> {
      let user = this.authenticationService.getDecodeTokenInfo();
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));

      if(user.user_id != data.data.publicationParent.user.user_guid)
      {
        this.chatService.SendActionPublicationRequest(
            data.data.publicationParent.publication_guid,
            user.user_id,
            currentUser.firstName + " " +currentUser.lastName,
            currentUser.image,
            data.data.publicationParent.user.user_guid,
            data.data.publicationParent.user.firstName + " " +data.data.publicationParent.user.lastName,
            NotificationTypes.Share
        );
      }

      this.router.navigate(['app/inicio']);
    }).catch( error => this.snackBarService.showSnackBar('Cerrar', `${error} ⚠️`))
    .finally( () => this.isLoading = false )

  }

}
