<div class="container px-0" #messagesContent>
  <div class="container user">
    <div class="row align-items-center">
      <div class="col-auto" *ngIf="contact">
        <div *ngIf="!contact.image_path" class="img-placeholder"></div>
        <img *ngIf="contact.image_path" clang-template class="profile-image" height="56px" width="56px"
          [src]="contact.image_path" [routerLink]="['/app/perfil', contact.user_guid]">
          <span class={{contact.status}}></span>
      </div>
      <div class="col">
        <span class="name" *ngIf="contact" [routerLink]="['/app/perfil', contact.user_guid]">{{ contact.first_name + " " + contact.last_name }}</span>
      </div>
    </div>
  </div>
  <div *ngIf="isloadingMessages" class="container-fluid messages">
    <div class=" col-12 d-flex justify-content-center mt-5">
      <mat-spinner diameter="60"></mat-spinner>
    </div>
  </div>
  <div *ngIf="!isloadingMessages" class="container-fluid messages" (window:scroll)="onScroll()"
  [ngClass]="{'uploading-files': loadingFiles.fileList.uploadingFiles.length}">
    <div class="row align-items-center">
      <div *ngIf="cancelEvent" class="col-12 d-flex justify-content-center mt-5">
        <mat-spinner diameter="60"></mat-spinner>
      </div>
      <div *ngFor="let message of messages" class="col-12 message"
        [ngClass]="{'contact': message.fromUser == idContact, 'me': message.fromUser == idUser, 'date my-4': message.division}">
        <div *ngIf="message.division">{{ message.date | customDate }}</div>
        <span *ngIf="!message.division" class="d-inline-block">

          <img *ngIf="message.type.toString().includes('image')" [src]="message.message" class="img-fluid">

          <video *ngIf="message.type.toString().includes('video')" class="img-fluid" controls>
            <source [src]="message.message" [type]="message.type">
          </video>

          {{ !message.type.toString().includes('image') && !message.type.toString().includes('video') ? message.message : '' }}

          <i class="time">{{message.date | date:'shortTime'}}</i>

        </span>
      </div>
    </div>
  </div>

  <app-loading-files #loadingFiles></app-loading-files>

  <div class="container message-box">
    <div class="row">
      <div class="col-auto align-self-center pr-0">
        <button class="add-images" (click)="openModal()">
          <img src="assets/img/icons/image-add.svg">
        </button>
      </div>
      <div class="col">
        <input type="text" placeholder="Nuevo mensaje" [(ngModel)]="newMessage" (keydown.enter)="addMessage()">
      </div>
    </div>
  </div>
</div>

