import { Component, Input, OnInit } from '@angular/core';
import { FriendshipStatus } from 'src/app/models/friendship-status/friendship-status.enum';
import { UserSearch } from 'src/app/models/user-search/user-search';
import { ContactService } from 'src/app/services/contact/contact.service';
import { SnackbarService } from 'src/app/services/notifications/snackbar.service';
import { ChatService } from 'src/app/services/chat/chat.service';
import { AuthService } from 'src/app/services/auth.service';
import { FirebaseService } from 'src/app/services/firebase-service/firebase.service';

@Component({
  selector: 'app-person',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.scss']
})
export class PersonComponent implements OnInit {
  @Input() person: UserSearch;

  // property to identify if there is a petition in progress
  isLoading = false;

  // available friendship status
  friendShipStatus = FriendshipStatus;

  constructor(private contactService: ContactService,
       private snackBarService: SnackbarService,
       private chatService: ChatService,
       private authenticationService: AuthService,
       private firebase: FirebaseService
       ) { }

  ngOnInit(): void {
  }

  /**
   * Send friend request
   * @param id - user id to add to friends
   */
  addFriend(id: string): void {
    this.isLoading = true;
    this.contactService
      .addFriend(id)
      .then(response => {
        this.person.status = this.friendShipStatus.RequestSent;
        response["contact"] = id,
        this.chatService.SendAddContactRequest(response);        
        this.firebase.logEvent('addFriend');
      })
      .catch(error => this.snackBarService.showSnackBar('Cerrar', `${error} ⚠️`))
      .finally(() => this.isLoading = false);

    }

  /**
   * Remove a user from your friends list
   * @param id - user id to remove
   */
  removeFriend(id: string): void {
    this.isLoading = true;
    this.contactService.removeFriend(id)
      .then(response => {
        this.person.status = this.friendShipStatus.NotFriends;
      })
      .catch(error => this.snackBarService.showSnackBar('Cerrar', `${error} ⚠️`))
      .finally(() => this.isLoading = false);
  }

  /**
   * Cancel friend request
   * @param id - user id to cancel friend request
   */
  cancelFriendRequest(id: string): void {
    this.isLoading = true;

    this.contactService.cancelRequest(id)
      .then(response => {
        this.person.status = this.friendShipStatus.NotFriends;
        let user =this.authenticationService.getDecodeTokenInfo();
        this.chatService.SendCancelFriendRequest({ "fromUser": user.user_id, "toUser": id } );
        this.contactService.raisefriendRequestChanged([]);
      })
      .catch(error => {
        this.snackBarService.showSnackBar('Cerrar', `${error} ⚠️`);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
  
  /** Depending on person status and action will be done
   *  @param - instance with person's data
   */
  friendAction(person): void {
    if(person.status === this.friendShipStatus.NotFriends){
      this.addFriend(person.guid);
    }else if(person.status === this.friendShipStatus.Friends){
      this.removeFriend(person.guid);
    }else if(person.status === this.friendShipStatus.RequestSent){
      this.cancelFriendRequest(person.guid)
    }
  }
  /** Returns a sentence action that indicates what action will be trigger if it is clicked
  * @param - instance with person's data
  */
  showActionTooltip(person): string{
    if(person.status === this.friendShipStatus.NotFriends){
      return "Enviar solicitud";
    }else if(person.status === this.friendShipStatus.Friends){
      return "Dejar de seguir";
    }else if(person.status === this.friendShipStatus.RequestSent){
      return "Cancelar solicitud";
    }
  }
}
