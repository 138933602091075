<div class="container">
    <app-dynamic-navigation [projectId]="projectId" [actualStep]="navigationSteps.firstStep"></app-dynamic-navigation>
    <!-- my2 ml3 -->
    <div class="main-container animated fadeIn fast">
        <div class="card border-0 animated fadeIn fast" style="background-color: #F7F7F7;">
            <div class="card-body animated fadeIn">
                <label class="txt_common text-left">
          Para comenzar, cuéntanos un poco sobre tu idea de negocio. No dejes ningún campo en blanco para poder
          conocerte
          mejor, más adelante podrás explicar a detalle tu proyecto.
        </label>
                <div class="mt-2">
                    <form autocomplete="off" [formGroup]="forma" (ngSubmit)="save()">
                        <div class="form-group">
                            <label for="nombre" class="subtitle text-left">Nombre del proyecto</label>
                            <textarea class="form-control inpts_posible" id="nombre" rows="2" aria-label="Large" formControlName="nombre" [class.is-invalid]="submitted && formFields.nombre.errors" placeholder="Respuesta" maxlength="200"></textarea>
                            <div *ngIf="submitted && formFields.nombre.errors" class="errors pt-1">
                                <small *ngIf="formFields.nombre.errors.required" class="text-danger animated fadeIn">
                                  El nombre es obligatorio.
                                </small>
                                <small *ngIf="formFields.nombre.errors.minlength" class="text-danger animated fadeIn">
                                  El nombre debe de contener mínimo 2 caracteres.
                                </small>
                            </div>
                            <div class="txt_advice text-right">
                                <p class="mt-2" *ngIf="countChars(formFields.nombre.value) == 0"> Máx 200 caracteres</p>
                                <p class="mt-2" *ngIf="countChars(formFields.nombre.value) >= 1 && countChars(formFields.nombre.value) < 200">
                                    {{200 - countChars(formFields.nombre.value) }} caracteres faltantes.</p>
                                <p class="mt-2 text-danger" *ngIf="countChars(formFields.nombre.value) == 200"> Máximo de caracteres alcanzado.
                                </p>
                            </div>
                        </div>
                        <hr>
                        <div class="form-group">
                            <label for="descripcion" class="subtitle text-left">Breve descripción del
                proyecto</label>
                            <textarea class="form-control inpts_posible" id="descripcion" ng-bind-html="text | unsafeLinky:'_blank'" rows="4" aria-label="Large" formControlName="descripcion" [class.is-invalid]="submitted && formFields.descripcion.errors" placeholder="Respuesta"
                                maxlength="600"></textarea>
                            <div *ngIf="submitted && formFields.descripcion.errors" class="errors pt-1">
                                <small *ngIf="formFields.descripcion.errors.required" class="text-danger animated fadeIn">
                                  La descripción es obligatoria.
                                </small>
                                <small *ngIf="formFields.descripcion.errors.minlength" class="text-danger animated fadeIn">
                                  La descripción debe de contener mínimo 2 caracteres.
                                </small>
                            </div>
                            <div class="text-right txt_advice">
                                <br>
                                <div class="txt_advice text-right">
                                    <p class="mt-2" *ngIf="countChars(formFields.descripcion.value) == 0"> Máx 600 caracteres</p>
                                    <p class="mt-2" *ngIf="countChars(formFields.descripcion.value) >= 1 && countChars(formFields.descripcion.value) < 600">
                                        {{600 - countChars(formFields.descripcion.value) }} caracteres faltantes.</p>
                                    <p class="mt-2 text-danger" *ngIf="countChars(formFields.descripcion.value) == 600"> Máximo de caracteres alcanzado.</p>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <span class="txt_2categorias">Selecciona hasta 2 categorías relacionadas con tu negocio:</span>
                        <div class="my-3 form-check" *ngFor="let category of listCategories; last as isLast; let i=index">
                            <input class="form-check-input" type="checkbox" [checked]="checkedGenerator(category.category_guid)" [id]="category.category_guid" [name]="category.description" [value]="category.category_guid" (change)="onCheckboxChange($event, isLast)" [disabled]="validatorCheckbox(category.category_guid)">
                            <label class="form-check-label" [for]="category.category_guid">
                              <span class=" txt_common_bold"> {{category.description}}</span>
                            </label>
                        </div>
                        <div *ngIf="submitted && formFields.checkCategories.errors" class="errors pt-1 pb-3">
                            <small *ngIf="formFields.checkCategories.errors.required" class="text-danger animated fadeIn">
                              Debe seleccionar mínimo una categoría.
                            </small>
                        </div>
                        <ng-container *ngIf="showOthers" class="animated fadeIn">
                            <textarea class="form-control inpts_posible" id="customCategories" rows="2" aria-label="Large" formControlName="customCategories" [class.is-invalid]="submitted && formFields.customCategories.errors" placeholder="Respuesta" maxlength="200"></textarea>
                            <div class="txt_advice text-right mt-2 mb-4 d-flex align-items-center">
                                <small *ngIf="submitted && formFields.customCategories.errors && formFields.customCategories.errors.required" class="text-danger animated fadeIn">
                                  Este campo es requerido.
                                </small>
                                <div class="ml-auto">
                                    <p class="mt-2" *ngIf="countChars(formFields.customCategories.value) == 0"> Máx 200 caracteres</p>
                                    <p class="mt-2" *ngIf="countChars(formFields.customCategories.value) >= 1 && countChars(formFields.customCategories.value) < 200">
                                        {{200 - countChars(formFields.customCategories.value) }} caracteres faltantes.</p>
                                    <p class="mt-2 text-danger" *ngIf="countChars(formFields.customCategories.value) == 200"> Máximo de caracteres alcanzado.
                                    </p>
                                </div>
                            </div>
                        </ng-container>

                        <button type="submit" class="my-5 btn-lg btn btn-block btn-warning btn_continuar">
                          <span class="btn_continuar_text">Siguiente</span>
                        </button>
                        <br>
                    </form>
                </div>
            </div>
            <!--card body-->
        </div>
        <!-- card main-->
    </div>
</div>