<!-- Modal -->
<div class="modal-content file-management">
    <div class="modal-body container d-flex align-items-end p-0">
        <div class="row m-0 w-100">
        <div class="col-12">
            <input #picture type="file" (change)="addFile($event.target.files)" style="display:none;"
            accept="image/*, video/*" />
            <button type="button" class="mt-2 btn btn-element btn-block" data-dismiss="modal" (click)="picture.click()">
            <img class="picture" src="assets/icons/picture.png"> Fotos y videos
            </button>
        </div>
        </div>
    </div>
    <div class="modal-footer container">
        <button type="button" class="btn btn-cancel btn-block" data-dismiss="modal" (click)="closeModal()">Cancelar</button>
    </div>
</div>