<div class="container privacy animated fadeIn fast">
    <div class="row">
        <div class="col-12 mt-4">
            <a class="backText text-left cursor__pointer" (click)="goBack()"><i class="fa fa-arrow-left"
          aria-hidden="true"></i>
        Regresar</a>
            <h2 class="txt_title text-left">Aviso de privacidad</h2>
        </div>
    </div>

    <section class="pb-4 txt_common">
        <div class="animated fadeIn text-justify">
            <p><strong>RESPONSABLE</strong><br> Fundación Televisa, A.C. (en adelante "Fundación”) con domicilio en Avenida Vasco de Quiroga No. 2000, Colonia Santa Fe, Alcaldía Álvaro Obregón, Ciudad de México, C.P. 01210, tiene la convicción de proteger
                la información personal (en adelante "Datos Personales”) proporcionada por los participantes del proyecto “Mujer POSIBLE” (en adelante los “Participantes”) y es el responsable de su tratamiento (término que se define más adelante) cuando
                sean recabados a través del sitio y/o plataforma de Internet https://posible.org.mx/mujerposible (en adelante el “Medio”). Fundación pone a su disposición el presente Aviso de Privacidad conforme a la Ley Federal de Protección de Datos
                Personales en Posesión de los Particulares (en adelante la “LFPDPPP”).
            </p>
            <strong>DATOS PERSONALES A RECABAR</strong><br>Fundación podrá recabar las siguientes categorías de datos personales:
            <br>
            <ul>
                <li><b>Datos de Identificación.</b>
                    <ul>
                        <li>Nombre completo</li>
                        <li>Fecha de nacimiento</li>
                        <li>Género</li>
                        <li>Nivel educativos</li>
                        <li>Identificación como cliente Compartamos</li>
                    </ul>
                </li>
                <li><b>Datos de contacto.</b>
                    <ul>
                        <li>Correo electrónico</li>
                        <li>Teléfono</li>
                        <li>Género</li>
                    </ul>
                </li>
                <li><b>Datos de demográficos.</b>
                    <ul>
                        <li>Estado y municipio</li>
                    </ul>
                </li>
            </ul>
            <p><strong>FINALIDAD DEL TRATAMIENTO DE DATOS PERSONALES.</strong><br>Fundación podrá solicitar y/o recabar a través del Medio Datos Personales de los Participantes para los fines abajo señalados, así como para dar cumplimiento con disposiciones
                legales que así lo requieran (en adelante “Tratamiento”). Fundación y/o cualquier tercero, con el que Fundación tenga una relación jurídica, que llegue a intervenir en cualquier fase del Tratamiento de Datos Personales guardará confidencialidad
                respecto de los mismos conforme a las disposiciones legales aplicables en los Estados Unidos Mexicanos (en adelante “México”).
            </p>
            <p>Los Datos Personales que los Participantes proporcionen, tienen como finalidad:</p>
            <ul style="list-style: lower-roman;">
                <li>
                    Generar base de datos como forma de registro al programa.
                </li>
                <li>
                    Compartir información sobre las sesiones, días y horarios del programa.
                </li>
                <li>
                    Generar bases de datos como comprobación de la ejecución del programa.
                </li>
                <li>
                    Generar reportes estadísticos con fines de comprobación y promoción del programa.
                </li>
            </ul>
            <p>Siempre que no se oponga al tratamiento de sus Datos Personales, los mismos podrán ser tratados para las siguientes finalidades secundarias:</p>
            <ul style="list-style: lower-roman;">
                <li>Envío de información de convocatorias de otros programas de la Fundación.</li>
            </ul>
            <p>Fundación podrá hacer uso de los Datos Personales para otras finalidades, siempre y cuando dichas finalidades sean compatibles y puedan considerarse análogas a las anteriores.</p>
            <p>A fin de dejar de recibir mensajes promocionales por parte de Fundación, podrá solicitarlo a través del correo electrónico bajaposible@posible.org.mx</p>
            <p><strong>PRIVACIDAD Y CONSENTIMIENTO.</strong></p>
            <p>Para el caso de mayores de edad, el llenado del formato puesto a disposición a través del Medio implica el consentimiento pleno para el Tratamiento de los Datos Personales de acuerdo con el presente Aviso de Privacidad.</p>
            <p>El titular de los Datos Personales manifiesta que es mayor de edad a la fecha en que proporciona los Datos Personales y cuenta con plena capacidad jurídica. </p>
            <p>Cuando se traten datos de menores de edad o de personas que se encuentren en estado de interdicción o incapacidad establecida por ley, no se recabarán Datos Personales de dichos titulares, sin contar con el consentimiento previo de la persona
                que ejerce la patria potestad, o en su caso, del tutor o representante legal, de conformidad con las reglas de representación aplicables en cada caso y conforme al apartado siguiente.</p>
            <p><strong>MEDIDAS DE SEGURIDAD.</strong></p>
            <p>Fundación cuenta con medidas físicas, técnicas y administrativas para la protección de los Datos Personales. </p>
            <p><strong>TRANSFERENCIA DE DATOS PERSONALES.</strong></p>
            <p>Fundación podrá, sin consentimiento adicional del titular, conforme a lo establecido en la LFPDPPP, revelar, divulgar y/o transferir dentro y fuera del país los Datos Personales proporcionados por los participantes a sus empresas filiales,
                subsidiarias y/o relacionadas, así como para dar cumplimiento con disposiciones legales que así lo requieran, o bien cuando sea solicitado por autoridades competentes. </p>
            <p>Asimismo, Fundación podrá transferir sus Datos Personales dentro de México con Fundación Gentera A.C (en adelante “Compartamos”) únicamente a efecto de que Compartamos en su carácter responsable receptor pueda comprobar el recurso donado para
                la ejecución del programa “MUJER POSIBLE”.</p>

            <p><strong>DERECHOS DEL TITULAR DE DATOS PERSONALES.</strong></p>
            <p>Lo Participantes podrán solicitar el ejercicio del derecho a:</p>
            <ul>
                <li>Acceder a sus Datos Personales;</li>
                <li>Rectificar sus Datos Personales cuando sean inexactos o incompletos;</li>
                <li>Cancelar sus Datos Personales;</li>
                <li>Oponerse por causa legítima al Tratamiento de sus Datos Personales, y/o revocar su consentimiento para el Tratamiento de sus Datos Personales, a fin de que se deje de hacer uso de los mismos.
                </li>
                <li>Limitar el uso o divulgación de sus datos personales. </li>
            </ul>
            <p>Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud para cancelar sus Datos Personales, oponerse al Tratamiento o revocar su consentimiento, ya que es posible que por alguna obligación legal
                requiramos seguir tratando sus Datos Personales. </p>
            <p>La solicitud de derechos que se haga deberá solicitarse al correo electrónico que más adelante se señala (conforme a lo establecido en la LFPDPPP) a Fundación y contener al menos lo siguiente:</p>
            <ul>
                <li>El nombre del titular, domicilio y/o correo electrónico para comunicarle la respuesta a su solicitud;</li>
                <li>Los documentos que acrediten la identidad o, en su caso, la representación legal del titular;</li>
                <li>La descripción clara y precisa de los Datos Personales respecto de los que se busca ejercer alguno de los derechos antes mencionados (en el caso de rectificación se deberá indicar las modificaciones a realizar y aportar la documentación
                    que sustente su petición);</li>
                <li>Cualquier otro elemento o documento que facilite la localización de los Datos Personales.</li>
            </ul>
            <p>Fundación dará respuesta a la solicitud de los Participantes en los plazos establecidos por la Ley, a través del correo electrónico: bajaposible@posible.org.mx</p>

            <p><strong>CAMBIOS AL AVISO DE PRIVACIDAD.</strong></p>
            <p>
                Fundación se reserva el derecho de modificar el contenido del presente Aviso de Privacidad. Cualquier cambio que requiera de su consentimiento le será informado a través de nuestro sitio y/o el correo electrónico que haya registrado con nosotros.
            </p>
            <p>La fecha de actualización del presente Aviso de Privacidad es el 01 de julio de 2022.</p>
        </div>

        <hr class="line_txt_style_msj">
        <div class="text-center">
            <small class="txt_style_msj">
        <p><b> © 2020 POSiBLE</b></p>
      </small>
        </div>


    </section>

</div>