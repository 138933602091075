import { Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { NavigationSteps } from 'src/app/classes/navigationSteps';

@Component({
  selector: 'app-dynamic-navigation',
  templateUrl: './dynamic-navigation.component.html',
  styleUrls: ['./dynamic-navigation.component.scss']
})
export class DynamicNavigationComponent implements OnInit {
  @Input() projectId: string;
  @Input() actualStep;

  navigationSteps = NavigationSteps;
  step = 0;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  setStep(index: number) {
    this.step = index;
  }

  // Method to keep the order of our object properties
  keepOrder = (a, b) => {
    return a;
  }

  navigateTo(url: string): void {
    if (this.projectId) {
      this.router.navigate([`app/${url}`, this.projectId]);
    } else {
      this.router.navigate([`app/${url}`]);
    }
  }

}
