import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { ProjectService } from '../../../services/project.service';
import { SnackbarService } from 'src/app/services/notifications/snackbar.service';
import { ProjectStatus } from '../../../models/project-status/project-status';
import { Project } from '../../../models/project/project';
import { FirebaseService } from 'src/app/services/firebase-service/firebase.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ProjectsDialogComponent } from '../../shared/dialogs/projects-dialog/projects-dialog.component';
import { SurveyComponent } from '../../shared/dialogs/survey/survey.component';
import { ProjectCompletedDialogComponent } from '../../shared/dialogs/project-completed-dialog/project-completed-dialog/project-completed-dialog.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  // loading spinner
  isLoading = true;
  user: any;
  projects = [];
  // Loads the lists of status for a project
  projectStatus = ProjectStatus;
  isMaxProjects: boolean;
  maxProjects: number = 3;
  announcementClosed: boolean;
  profileAuth:boolean;

  constructor(private router: Router,
    private authenticationService: AuthService,
    private projectService: ProjectService,
    private snackbarService: SnackbarService,
    private firebase: FirebaseService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getUser();
    this.getMyProjects();
    this.firebase.logEvent('projects');
    localStorage.removeItem('currentAnnouncementGuidSelected');
    //this.openProjectInfoModal();
  }

  // Method to navigate to a NEW business model creation flow
  newBusinessModel(): void{
    this.router.navigate(['app/nuevo-proyecto']);
  }

  /* Method to open the project detail page by id
  * @param projectID
  */
  openProjectDetails(projectID: string, status, event): void {
    if(event.srcElement.tagName == "BUTTON" || event.srcElement.tagName == "SPAN"){
      if(status == this.projectStatus.Aprobado){
        this.router.navigate(['app/perfil/proyecto', projectID]);
      }else{
        this.openProjectCompleted(projectID);
      }
    }else{
      if( status == this.projectStatus.Nuevo) {
        this.router.navigate(['app/describe-problema', projectID]);
      }
      else {
        this.router.navigate(['app/perfil/proyecto', projectID]);
      }
    }
    
  }

  // open project completed dialog
  openProjectCompleted(projectID: string): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '450px';
    dialogConfig.data = { id: projectID, nextPage: '', editCompleted : true };

    this.dialog.open(ProjectCompletedDialogComponent, dialogConfig);
  }

  /* Method to open the profile detail page
  *
  */
  openProfileDetails(): void {
    this.router.navigate(['app/perfil/detalles']);
  }

  /* Method to get the current user
  *
  */
  getUser(): void {
    this.authenticationService.getCurrentUser()
      .then(data => {
        this.user = data;
        this.profileAuth = JSON.parse(localStorage.getItem('currentUser'))['profileAuth'];
        
        if( this.user.state__id == "00000000-0000-0000-0000-00000000000f" || (this.user.state__id == "00000000-0000-0000-0000-000000000020" && this.profileAuth) ){
          this.announcementClosed = true;
        }else{
          this.announcementClosed = false;
        }
      })
      .catch(error => this.snackbarService.showSnackBar('Cerrar', error));
  }

  /* Method to get all the project list
  *
  */
  getMyProjects(): void {
    this.projectService.getMyProjects()
      .then(data => {
        this.isMaxProjects = data.data.length >= data.maxProjects;
        this.projects = data.data.map( project => new Project(project));
        this.isLoading = false;
      })
      .catch(error => this.snackbarService.showSnackBar('Cerrar', error));
  }

  // Open directions projects info
  openProjectInfoModal(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '750px';
    dialogConfig.height = '80%';
    dialogConfig.data = { };
    dialogConfig.panelClass = ['no-padding', 'Profile-Popup'];

    this.dialog.open(ProjectsDialogComponent, dialogConfig);
  }

}
