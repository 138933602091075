import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { Notification } from 'src/app/models/notification/notification';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  @Input() notification: Notification;
  constructor(private route: Router) { }

  ngOnInit(): void {
  }

  // navigate to the publication where the notification happens
  openNotification(): void {
    this.route.navigate(['/app','publicacion', this.notification.publicationId]);
  }

}
