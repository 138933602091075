import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from 'src/app/classes/global';
import { Notification } from 'src/app/models/notification/notification';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  globals = Globals;
  baseUrl = `${this.globals.urlPosibleBase}${this.globals.notifications.pathNotifications}`;
  // data just for testing porpouses
  mockData = [
    { id: '4',
      contactId: '1234',
      contactName: 'Cynthia Romero',
      contactPicture: null,
      publicationId: '8635d783-f616-4439-864b-f291c918a609',
      date: '2020-07-28T01:30:51.685761Z',
      seen: false,
      type: 'Comentar'
    },
    { id: '1', contactId: '123', contactName: 'Jose Monge', contactPicture: null, publicationId: '8635d783-f616-4439-864b-f291c918a609', date: '2020-07-25T01:30:51.685761Z', seen: false, type: 'Like' },
    { id: '2', contactId: '1234', contactName: 'Jesus Lopez', contactPicture: null, publicationId: '8635d783-f616-4439-864b-f291c918a609', date: '2020-07-25T01:30:51.685761Z', seen: true, type: 'Compartir' },
    { id: '3', contactId: '1234', contactName: 'Alejandro Carranza', contactPicture: null, publicationId: '8635d783-f616-4439-864b-f291c918a609', date: '2020-07-30T01:30:51.685761Z', seen: true, type: 'Comentar' },
  ];

  constructor(private http: HttpClient) { }

  // Method to get all the states
  //getAll(userId:string): Promise<any> {
    getAll(userId:string, notificationType:string) : Promise<any>{
    const url = `${this.globals.chatSocket.chatSocketPath}${this.globals.chatSocket.user}${userId}${this.globals.chatSocket.notifications}/${notificationType}`;

    return this.http.get<any>(url)
      .pipe( map (result =>{
        let notifications = []
        if (result && result.result && result.result.length>0){
          result.result.forEach(notification => {
             notifications.push(
              {
                id: notification._id,//'4',
                contactId: notification.from_user,//'1234',
                contactName: notification.from_user_name,//'Cynthia Romero',
                contactPicture: notification.from_user_image,
                publicationId: notification.publication_guid,//'8635d783-f616-4439-864b-f291c918a609',
                date: notification.created_date,//'2020-07-28T01:30:51.685761Z',
                seen: notification.viewed,
                type: notification.publication_action//'Comentar'
              });
          });

          //sort by descending date
          notifications.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

          return notifications;
        }
      }))
      .toPromise();
    //return await this.mockData;

    // TODO: implement the real service
    // return await this.http.get<Notification[]>(this.baseUrl).toPromise();
  }
}
