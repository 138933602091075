// Class to implement attributes of User
export class User {
    username: string;
    firstName: string;
    lastName: string;
    token: string;
    image: string;
    status: string;
    profileAuth:boolean;

    constructor(data: any) {
        this.username = data.user.username;
        this.firstName = data.user.first_name;
        this.lastName = data.user.last_name;
        this.token = data.token;
        this.profileAuth = data.profileAuth;
        this.image = data.user.image_path;
        this.status = data.user.status;        
    }
}
