<div class="container privacy animated fadeIn fast">
    <div class="row">
        <div class="col-12 mt-4">
            <a class="backText text-left cursor__pointer" (click)="goBack()"><i class="fa fa-arrow-left"
          aria-hidden="true"></i>
        Regresar</a>
            <h2 class="txt_title text-left">Aviso de privacidad</h2>
        </div>
    </div>

    <section class="pb-4 txt_common">
        <div class="animated fadeIn text-justify">
            <p><strong>RESPONSABLE DE LOS DATOS PERSONALES.</strong><br>Fundación Televisa, A.C. (en adelante "Fundación") con domicilio en Avenida Vasco de Quiroga No. 2000, Colonia Santa Fe, Alcaldía. Álvaro Obregón, Ciudad de México, C.P. 01210 tiene
                la convicción de proteger la información personal proporcionada por los participantes (en adelante "Datos Personales”) que se registren en la dinámica Posible (en adelante los “Participantes”) y es el responsable de su Tratamiento (término
                que se define más adelante) cuando sean recabados a través del sitio y/o plataforma de Internet https://posible.org.mx/. (en adelante el “Sitio”)
            </p>
            <p><strong>FINALIDAD DEL TRATAMIENTO DE DATOS PERSONALES.</strong><br>Fundación podrá solicitar y/o recabar a través del Sitio, los Datos Personales de los Participantes para los fines abajo señalados, así como para dar cumplimiento con disposiciones
                legales que así lo requieran (en adelante “Tratamiento”). Fundación y/o cualquier tercero que llegue a intervenir en cualquier fase del Tratamiento de Datos Personales guardará confidencialidad respecto de los mismos conforme a las disposiciones
                legales aplicables en los Estados Unidos Mexicanos (en adelante “México”).<br> Los Datos Personales que los Participantes proporcionen a través del Sitio, tienen como finalidad i) asesorar a los Participantes en temas de emprendimiento,
                ii) identificación del usuario y la creación de su perfil único dentro de la plataforma, para verificación de identidad, como medio de contacto primario para seguimiento informativo, para dar seguimiento a la actividad del usuario dentro
                de la plataforma, para enviar notificaciones sencillas y para la instrumentación de herramientas de difusión personalizadas con base en el perfil demográfico del usuario, ii) para verificar el cumplimiento de las bases de la convocatoria,
                para segmentación de audiencias, para personalizar contenidos informativos, para crear un perfil de seguimiento demográfico y optimizar la inversión en herramientas digitales de difusión, iv) para clasificar necesidades estratégicas del
                emprendimiento y para optimizar la inversión en herramientas digitales de difusión y v) para revisar el cumplimiento de acuerdos con instituciones educativas, para decidir actividades de vinculación o colaboración y para optimizar la inversión
                en herramientas digitales de difusión.</p>
            <p><strong>DATOS PERSONALES A RECABAR.</strong><br>Los Datos Personales que se solicitan de los Participantes son:
                <br> 
                · Nombre completo<br> 
                · Correo electrónico<br> 
                · Fecha de nacimiento<br> 
                · Apellidos<br> 
                · Ubicación<br> 
                · Género<br> 
                · Máximo grado de estudios<br> 
                · Teléfono de casa y/o móvil<br>
                <br></p>
            <p><strong>PRIVACIDAD Y CONSENTIMIENTO.</strong><br>Para el caso de mayores de edad, el envío de sus datos mediante el Sitio, implica el consentimiento pleno y sin reservas de los Participantes para el Tratamiento de sus Datos Personales de acuerdo
                con el presente Aviso de Privacidad.<br> El titular de los Datos Personales manifiesta que es mayor de edad a la fecha en que se proporcionan los mismos y cuenta con plena capacidad jurídica. Cuando se traten datos de menores de edad o
                de personas que se encuentren en estado de interdicción o incapacidad establecida por ley, no se recabarán Datos Personales de dichos titulares, sin contar con el consentimiento previo de la persona que ejerce la patria potestad, o en
                su caso, del tutor o representante legal, de conformidad con las reglas de representación aplicables en cada caso y conforme al apartado siguiente.</p>
            <p><strong>MEDIOS PARA LIMITAR EL USO O DIVULGACIÓN DE DATOS PERSONALES.</strong><br>Fundación cuenta con medidas físicas, técnicas y administrativas de seguridad para la protección de Datos Personales proporcionados por los Participantes.</p>
            <p><strong>EXCLUSIÓN DE RESPONSABILIDAD DEL SITIO.</strong><br>Al navegar o usar el Sitio el Usuario podría encontrar hipervínculos o hipertextos “links”, banners, botones y/o herramientas de búsquedas en la “World Wide Web” que al ser utilizados
                por los Participantes transportan a otros portales o sitios de Internet que podrían ser propiedad de terceros. Los Datos Personales que los Participantes llegaren a proporcionar a través de estos portales o sitios de Internet no se encuentran
                contemplados por este Aviso de Privacidad y su Tratamiento no es responsabilidad de Fundación. Recomendamos a los Participantes verificar los Avisos de Privacidad desplegados y aplicables a estos portales y sitios de Internet.<br>Los Participantes
                podrán encontrar dentro del Sitio páginas, promociones, micrositios, tiendas virtuales, encuestas, bolsas de trabajo y otros servicios que pueden estar compartidos con terceros y que podrían solicitar sus Datos Personales (en adelante
                “Sitios de Terceros”). Cualquier información relacionada con los Datos Personales que se proporcionen a través de los sitios de Terceros se sujetará a los respectivos avisos de privacidad que se contengan en cada uno de ellos, salvo que
                el Fundación señale lo contrario.<br> Fundación advierte a sus Participantes que algunos servicios del Sitio podrían incluir, de manera enunciativa más no limitativa, foros de discusión, páginas de Internet personales, avisos clasificados,
                correos electrónicos, salas de chat, entre otros. Por lo tanto, los Datos Personales que se revelen a través de dichos medios podrían en algunos casos ser accesibles por terceras personas. Televisa no será responsable por el uso, publicación,
                revelación y/o divulgación que se haga respecto de los Datos Personales usados, publicados, revelados y/o divulgados a través de los medios antes enunciados. Recomendamos a los Participantes ser cuidadosos y responsables sobre toda información
                de carácter personal que proporcionen en dichos medios.</p>
            <p><strong>TRANSFERENCIA DE DATOS PERSONALES.</strong><br>Fundación podrá, sin consentimiento del titular, revelar, divulgar y/o transferir dentro y fuera del país los Datos Personales proporcionados por los participantes, a sus empresas filiales,
                subsidiarias y/o relacionadas, así como para dar cumplimiento con disposiciones legales que así lo requieran, o bien cuando sea solicitado por autoridades competentes.</p>
            <p><strong>MEDIOS PARA EJERCER LOS DERECHOS DE LOS TITULARES DE DATOS PERSONALES Y REVOCACIÓN DEL
          CONSENTIMIENTO.</strong><br>Los Participantes podrán solicitar el ejercicio de sus derechos a:<br> · Accesar a sus Datos Personales;<br> · Rectificar sus Datos Personales cuando sean inexactos o incompletos;
                <br> · Cancelar sus Datos Personales;<br> · Oponerse por causa legítima al Tratamiento de sus Datos Personales, y/o<br> · Revocar su consentimiento para el Tratamiento de sus Datos Personales en cualquier momento, a fin de que se deje
                de hacer uso de los mismos.<br> Sin embargo es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud para cancelar sus Datos Personales, oponerse al Tratamiento o revocar su consentimiento, ya que es posible
                que por alguna obligación legal requiramos seguir tratando sus Datos Personales.<br> El ejercicio de los derechos de Acceso, Rectificación, Cancelación u Oposición de Datos Personales y Revocación del consentimiento que realicen los Participantes
                deberá solicitarse a Televisa y contener al menos lo siguiente:<br> · El nombre del titular, domicilio y correo electrónico para comunicarle la respuesta a su solicitud;<br> · Los documentos que acrediten la identidad o, en su caso, la
                representación legal del titular;<br> · La descripción clara y precisa de los Datos Personales respecto de los que se busca ejercer alguno de los derechos antes mencionados (en el caso de rectificación se deberá indicar las modificaciones
                a realizar y aportar la documentación que sustente su petición); . Cualquier otro elemento o documento que facilite la localización de los Datos Personales.<br> Para efecto de lo anterior Fundación pone a disposición de los usuarios como
                único medio de comunicación el siguiente correo electrónico contacto@posible.org.mx<br> Televisa dará respuesta a la solicitud de los Participantes en un plazo no mayor a 20 días hábiles contados a partir de la fecha en que reciba la petición
                correspondiente, a través del correo electrónico que haya sido proporcionado para tal efecto.<br> El ejercicio por parte de los Participantes de los derechos antes mencionados deberá sujetarse a las leyes y reglamentos aplicables vigentes
                en México.</p>
            <p><strong>COOKIES Y WEB BEACONS.</strong><br>Con el objetivo de mejorar la experiencia de los participantes en el Sitio, Fundación podrá utilizar “cookies”. Para efectos del presente Aviso de Privacidad “cookies” se identificará como los archivos
                de texto de información que un sitio web transfiere al disco duro de la computadora de los Participantes con el objeto de almacenar ciertos registros y preferencias. Televisa puede utilizar “cookies” para mejor entendimiento de la interacción
                de los Participantes con el Sitio y los servicios que se proporcionan. El Sitio puede permitir publicidad o funciones de terceros que envíen “cookies” a las computadoras de los Participantes. Mediante el uso de “cookies” no se identifica
                personalmente a los Participantes, únicamente a sus computadoras.<br>Por su parte las “web beacons” son imágenes insertadas en una página de Internet o correo electrónico que pueden ser utilizadas para monitorear el comportamiento de un
                visitante, como almacenar información sobre la dirección IP del usuario, horario de navegación, duración del tiempo de interacción en el Sitio, secciones consultadas, páginas de Internet accedidas previo a la nuestra y el tipo de navegador
                utilizado.<br>Los Participantes podrán cambiar sus opciones a través de sus equipos de cómputo y/o navegadores para dejar de aceptar “cookies” y/o “web beacons” o bien confirmar si aceptan o no las mismas</p>
            <p><strong>DATOS PERSONALES SENSIBLES.</strong><br>De conformidad con la legislación mexicana vigente, se conocen como “Datos Personales Sensibles” Aquellos datos personales que afecten a la esfera más íntima de su titular, o cuya utilización
                indebida pueda dar origen a discriminación o conlleve un riesgo grave para éste. En particular, se consideran sensibles aquellos que puedan revelar aspectos como origen racial o étnico, estado de salud presente y futuro, información genética,
                creencias religiosas, filosóficas y morales, afiliación sindical, opiniones políticas, preferencia sexual.<br>En caso de que Fundación llegue a requerir Datos Personales Sensibles de los Participantes, estos deberán proporcionar su consentimiento
                expreso.</p>
            <p><strong>CAMBIOS AL AVISO DE PRIVACIDAD.</strong><br>Fundación se reserva el derecho de modificar en cualquier momento el contenido del presente Aviso de Privacidad. Cualquier cambio en el Aviso de Privacidad le será informado a sus participantes
                a través del Sitio.<br>Una vez que se publique el Aviso de Privacidad entrará en vigor automáticamente.<br>La fecha de actualización del presente aviso es el 5 de marzo de 2020.
            </p>
        </div>



        <hr class="line_txt_style_msj">
        <div class="text-center">
            <small class="txt_style_msj">
        <p><b> © 2020 POSiBLE</b></p>
      </small>
        </div>


    </section>

</div>