<div class="main-container row">
    <div class="container-header col-12 pl-md-5 pr-md-5">
        <div class="content-header">
            <div class="row">
                <div class="col-12 col-md-4 col-lg-4 mt-5">
                    <img src="../../../../assets/img/mail/Posible_amarillo.png" alt="" class="img-fluid">
                </div>
                <div class="col-12 col-md-8 col-lg-8 mt-4 menu-landing">
                    <ul class="d-md-flex p-0">
                        <li><a href="assets/CONVOCATORIA_RETO_DOCENTES.pdf" target="_blank">Convocatoria</a></li>
                        <li on-click="scrollFn('pasos')">Pasos a seguir</li>
                        <li on-click="scrollFn('beneficios')">Beneficios</li>
                    </ul>
                </div>

            </div>
            <div class="row mb-4">
                <div class="col-12 col-lg-4 mt-5 text-center">
                    <img src="../../../../assets/img/microsites/logo_reto.png" alt="" style="width: 250px;">
                    <h3 class="text-center mb-3 text-oaxaca">OAXACA</h3>
                    <p class="txt_common" [innerHTML]="currentMicroSiteStateInfo['text']"></p>
                    <div class="mt-5 mb-3 animated fadeIn fast d-flex justify-content-center d-md-block">
                        <a class="button-register" [routerLink]="['/crear-cuenta']">Regístrate</a>
                    </div>
                </div>
                <div class="col-12 col-lg-7 offset-lg-1 mt-3 mt-md-0">
                    <img src="../../../../assets/img/retoPosible/section1.jpg" alt="" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid container-convocatory">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-7 d-flex align-items-center justify-content-center">
                    <div class="row d-flex align-items-center justify-content-center">
                        <div class="col-12 col-md-6">
                            <img src="../../../../assets/img/microsites/logo_reto.png" alt="" style="width: 350px;" class="img-fluid">
                        </div>
                        <div class="col-12 col-md-6 d-flex justify-content-center">
                            <a class="button-register" href="assets/CONVOCATORIA_RETO_DOCENTES.pdf" target="_blank">Convocatoria</a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-5 col-info mt-4 mt-lg-0">
                    <h5>¿Qué es Reto POSiBLE Oaxaca?</h5>
                    <p>
                        Es un programa que busca inspirar a docentes de nivel medio superior y superior a emprender proyectos de negocio escalables y con innovación.
                    </p>
                    <h5>¿Quiénes pueden postularse a Reto POSiBLE Oaxaca?</h5>
                    <ul>
                        <li>Docentes de nivel superior que impartan materias relacionadas con emprendimiento.</li>
                        <li>Docentes de nivel superior que impartan materias relacionadas con emprendimiento.</li>
                        <li>En ambos casos deben ser residentes del estado de Oaxaca.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid container-steps" id="pasos">
        <div class="container mt-5 mb-5">
            <div class="row">
                <div class="col-12 text-center mb-4">
                    <h1>Pasos a seguir</h1>
                </div>
                <div class="col-12 col-md-6 col-lg-3 mb-4">
                    <div class="card">
                        <div class="card-header pt-4">
                            <div class="div-circle">
                                <img src="../../../../assets/img/retoPosible/certificado.png" alt="" class="img-fluid">
                            </div>
                        </div>
                        <div class="card-body">
                            <p class="card-text pl-3 pr-3 text-center">Acreditar el MOOC para emprendedores “Emprender es un RETO, hazlo POSIBLE” <br>Vigencia: 04 de abril al 18 de mayo del 2022</p>

                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3 mb-4">
                    <div class="card">
                        <div class="card-header pt-4">
                            <div class="div-circle">
                                <img src="../../../../assets/img/retoPosible/megafono.png" alt="" class="img-fluid">
                            </div>
                        </div>
                        <div class="card-body">
                            <p class="card-text pl-3 pr-3 text-center">Regístrate en Reto POSIBLE Oaxaca 2022 <br>Vigencia: 04 de abril al 18 de mayo del 2022</p>

                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3 mb-4">
                    <div class="card">
                        <div class="card-header pt-4">
                            <div class="div-circle">
                                <img src="../../../../assets/img/retoPosible/seleccion.png" alt="" class="img-fluid">
                            </div>
                        </div>
                        <div class="card-body">
                            <p class="card-text pl-3 pr-3 text-center">Selección de docentes <br>Vigencia: 18 de mayo del 2022</p>

                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3 mb-4">
                    <div class="card">
                        <div class="card-header pt-4">
                            <div class="div-circle">
                                <img src="../../../../assets/img/retoPosible/conocimiento.png" alt="" class="img-fluid">
                            </div>
                        </div>
                        <div class="card-body">
                            <p class="card-text pl-3 pr-3 text-center">Campamento Virtual Reto POSiBLE Oaxaca 2022, edición docentes <br>Vigencia: 08 al 10 de junio 2022</p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid container-benefits" id="beneficios">
        <div class="container pt-5 pb-5">
            <div class="row">
                <div class="col-12 mb-4 text-center">
                    <h1>Beneficios del programa</h1>
                </div>
                <div class="col-12 col-md-12 col-lg-6 col-benefits mb-3 mb-lg-0">
                    <div class="row benefits">
                        <div class="col-12 col-md-2 text-center d-md-flex align-items-center">
                            <img src="../../../../assets/img/retoPosible/manual.png" alt="" class="img-fluid">
                        </div>
                        <div class="col-12 col-md-10 d-flex align-items-center">
                            <p>Guía digital de enseñanza para el emprendimiento de alto impacto que incluye contenidos teóricos, actividades sugeridas en clase, glosario, plantillas de apoyo y vídeos de repaso.</p>
                        </div>
                    </div>
                    <div class="row benefits">
                        <div class="col-12 col-md-2 text-center d-md-flex align-items-center">
                            <img src="../../../../assets/img/retoPosible/social.png" alt="" class="img-fluid">
                        </div>
                        <div class="col-12 col-md-10 d-flex align-items-center">
                            <p>Comunidad para docentes en Facebook donde intercambiarán experiencias, recibirán actualizaciones de contenidos y compartirán evidencia de su trabajo en el aula.</p>
                        </div>
                    </div>
                    <div class="row benefits">
                        <div class="col-12 col-md-2 text-center d-md-flex align-items-center">
                            <img src="../../../../assets/img/retoPosible/actualizacion-de-sistema.png" alt="" class="img-fluid">
                        </div>
                        <div class="col-12 col-md-10 d-flex align-items-center">
                            <p>Actualizaciones constantes para el desarrollo de su profesión.</p>
                        </div>
                    </div>

                </div>
                <div class="col-12 col-md-12 col-lg-6 d-flex align-items-center">
                    <img src="../../../../assets/img/retoPosible/section4.jpeg" alt="" class="img-fluid">
                </div>
            </div>
        </div>

    </div>
    <div class="container-fluid container-conoce">
        <div class="container text-center pt-4 pb-4">
            <div class="row">
                <div class="col-12">
                    <h1>Conoce más acerca de Reto POSiBLE</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid container-video">
        <div class="container text-center pt-4 pb-4">
            <div class="row">
                <div class="col-12">
                    <iframe width="70%" height="500" src="https://www.youtube.com/embed/UQSR77JAeVk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div class="col-12 mt-5 col-logos">
                    <img src="../../../../assets/img/retoPosible/Asset 9.png" alt="" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
    <footer class="d-flex align-items-center">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-6 offset-lg-2">
                    <a [routerLink]="['/terminos']">Términos y condiciones</a>
                    <a [routerLink]="['/aviso-privacidad']">Aviso de privacidad</a>
                    <a href="assets/CONVOCATORIA_RETO_DOCENTES.pdf" target="_blank">Convocatoria</a>

                </div>
                <div class="col-12 col-md-6 col-lg-4 text-md-center text-lg-left">
                    <h4 class="mb-0">Contactanos por</h4>
                    <div class="div-icons">
                        <a href="https://api.whatsapp.com/send/?phone=525549130130&text=Hola%2C+tengo+una+duda+🟡" target="_blank"><i class="fa fa-whatsapp"></i></a>
                        <a href="mailto:reto.posible@fundaciontelevisa.org"><i class="fa fa-envelope-o" aria-hidden="true"></i></a>
                    </div>

                </div>
            </div>
        </div>
    </footer>
</div>
<!-- main-container-->