import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../classes/global';
import { AuthService } from './auth.service';
import { Topic } from '../models/topic/topic';

@Injectable({
  providedIn: 'root'
})
export class TopicService {
  globals = Globals;

  constructor( private http: HttpClient,
               private authenticationService: AuthService
            ) { }

  /* Method to get all the topics
  *
  */
  async getTopics(): Promise<any> {
    const url = `${this.globals.urlPosibleBase}${this.globals.topics.pathTopics}`;
    return await this.http.get(url).toPromise();
  }

  /* Method to get all the topics of the current user
  *
  */
  async getUserTopics(): Promise<any> {
    const url = `${this.globals.urlPosibleBase}${this.globals.topics.userTopics}`;
    return await this.http.get(url).toPromise();
  }

  /* Method to post the topics that a user select
  * @param topics Topic[]
  */
  save(topics:Topic[]) {
    const url = `${this.globals.urlPosibleBase}${this.globals.topics.userTopics}`;
    return this.http.post(url, { topics });
  }

    /* Method to get all the topics
  *
  */
 async getActiveTopics(): Promise<any> {
  const url = `${this.globals.urlPosibleBase}${this.globals.topics.pathTopics}${this.globals.topics.activeTopics}`;
  return await this.http.get(url).toPromise();
}


}
