import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'src/app/classes/global';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  globals = Globals;
  baseUrl = `${this.globals.urlPosibleBase}${this.globals.states.pathStates}`;

  constructor(private http: HttpClient) { }

  // Method to get all the states
  async getAll(): Promise<any> {
    return await this.http.get(this.baseUrl).toPromise();
  }

  /**
   * Method to get the cities based on the state id received
   * @param id - state id
   */
  async getCities(id: string): Promise<any> {
    const url = `${this.baseUrl}${id}${this.globals.states.municipalities}`;
    return await this.http.get(url).toPromise();
  }
}
