import { ActivatedRoute } from '@angular/router';
import { PublicationService } from 'src/app/services/publication/publication.service';
import { Component, OnInit, Input } from '@angular/core';
import { SnackbarService } from 'src/app/services/notifications/snackbar.service';
import { PublicationDetail } from 'src/app/models/publication-detail/publication-detail';

@Component({
  selector: 'app-publication-detail',
  templateUrl: './publication-detail.component.html',
  styleUrls: ['./publication-detail.component.scss']
})
export class PublicationDetailComponent implements OnInit {
  
  // Publication 
  @Input() publicationGiven: PublicationDetail;
  // Instance of publication
  publication: PublicationDetail;
  // Instance of shared publication
  publicationShared: PublicationDetail;
  // loading spinner
  isLoading = false;
  // Publication
  @Input() isDetail = true;
  // Defines if user is enable to add a comment and share publication
  @Input() isShareable = false;
  // Its means that will show a view where publication is already shared
  isShared = false;
  @Input() parentPage: string;

  constructor(
    private publicationService: PublicationService,
    private snackBarService: SnackbarService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    // Given an instance just render publication
    if(this.publicationGiven){
      this.publication = this.publicationGiven;
      this.setPublicationStructure(this.publication);
    }
    // if the publication just show a preview or the publication detail
    this.route.params.subscribe((params: any) => {
      if (params.id) {
        this.getDetails(params.id);
      }
    });
    // Check by the url if the publication will be shared
    this.route.url.subscribe(url => {
      if(url[0].path == 'compartir-publicacion'){
        this.isShareable = true;
      }
    });
    this.parentPage = this.parentPage || this.route.snapshot.queryParamMap.get('feed');
  }

  // Method to get all the publications
  getDetails(id: string): void {
    this.isLoading = true;
    this.publicationService.getDetails(id).then(response => {
     let post = new PublicationDetail(response['data']);
      this.setPublicationStructure(post);
    })
      .catch(error => {
       this.snackBarService.showSnackBar('Cerrar', `${error} ⚠️`);
      })
      .finally(() => this.isLoading = false);
  }
  
  /**
   * Method that verifies how to show the instance as a shared
   * publication or a single publication
   * @param post Instance of publication
   */
  setPublicationStructure(post: PublicationDetail):void{
    if(post.publicationParent){
      this.isShared = true;
      this.publicationShared = post.publicationParent;
      // If its possible to take the original post then show it
      if(this.publicationShared.publicationParent){        
        this.publicationShared = this.publicationShared.publicationParent;
      }
      this.publication = post;
    }else{
      this.isShared = false;
      this.publication = post;
      this.publicationShared = null;
    }
  }

}
