import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter,Output } from '@angular/core';
import { Globals } from 'src/app/classes/global';


@Injectable({
  providedIn: 'root'
})
export class ContactService {
  @Output() friendRequestChanged = new EventEmitter<any>();

  globals = Globals;
  baseUrl = `${this.globals.urlPosibleBase}${this.globals.contacts.pathContacts}`;

  constructor(private http: HttpClient) { }

  /**
   * Send friend request
   * @param id - user id to add to friends
   */
  async addFriend(id: string): Promise<object> {
    const url = `${this.baseUrl}${id}${this.globals.contacts.addFriend}`;
    return await this.http.post(url, {}).toPromise();
  }

  /**
   * Remove a user from your friends list
   * @param id - user id to remove
   */
  async removeFriend(id: string): Promise<object> {
    const url = `${this.baseUrl}${id}${this.globals.contacts.removeFriend}`;
    return await this.http.post(url, {}).toPromise();
  }

  /**
   * Accept friend request
   * @param id - user id to accept the  friend request
   */
  async acceptRequest(id: string): Promise<object> {
    const url = `${this.baseUrl}${id}${this.globals.contacts.acceptRequest}`;
    return await this.http.post(url, {}).toPromise();
  }

  /**
   * Cancel friend request
   * @param id - user id to cancel friend request
   */
  async cancelRequest(id: string): Promise<object> {
    const url = `${this.baseUrl}${id}${this.globals.contacts.cancelRequest}`;
    return await this.http.post(url, {}).toPromise();
  }

  // List of friend requests
  async friendRequests(): Promise<object> {
    const url = `${this.baseUrl}${this.globals.contacts.list}`;
    return await this.http.get(url).toPromise();
  }

  /**
   * Check if the current user have a pending friend request of a speficic user
   * @param id - user id that sends the friend request
   */
  async haveRequest(id: string): Promise<object> {
    const url = `${this.baseUrl}${id}${this.globals.contacts.haveRequest}`;
    return await this.http.get(url).toPromise();
  }

  /**
   * This method Raise event of FriendrequestChange to notify if the request changes for
   * hidding the litte dot of notification
   * @param friendRequests: any - list of friend request pending
  */
  raisefriendRequestChanged(friendRequests:any) : void
  {
    this.friendRequestChanged.emit(friendRequests);
  }

  /**
   * This method set the las message as viewed
   * @param idRoom Identifier of the room
   */
  async ViewMessages(idRoom:string): Promise<any>{
    const url = `${this.globals.urlPosibleBase}${this.globals.chatroom.room}${idRoom}/ViewMessages/`;
    return await this.http.put(url, {}).toPromise();
  }
}
