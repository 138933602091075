export const environment = {
    production: true,
    // apiUrl: "https://api.posible.org.mx/",
    // apiChat: "https://chat.posible.org.mx",
    apiUrl: 'https://dev-posible-api.gilasw.com/',
    apiChat: 'https://dev-posible-chat.gilasw.com:9000',
    firebase: {
        apiKey: "AIzaSyBwCTinzyo8PF2TxgKlqZpqs-RRtlRuXoo",
        authDomain: "plataforma-posible.firebaseapp.com",
        databaseURL: "https://plataforma-posible.firebaseio.com",
        projectId: "plataforma-posible",
        storageBucket: "plataforma-posible.appspot.com",
        messagingSenderId: "47684265781",
        appId: "1:47684265781:web:ccd909f3f5599ee9c30e7c",
        measurementId: "G-CTS6ZBEGKD"
    }
};
