import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalPostUploadService } from './post-upload-modal.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-post-upload',
  templateUrl: './post-upload-modal.component.html',
  styleUrls: ['./post-upload-modal.component.scss'],
})
export class PostUploadModalComponent implements OnInit {
  // var to manage the loading files status at the webapp
  isLoading = false;
  // Initial value, when media files are uploading
  filesUploading = 1;
  // var to display the upload percent at the webapp
  uploadPercent: any = null;
  // total files
  totalFiles = 1;

  constructor(private router: Router,
    public activeModal: NgbActiveModal,
    private modalPostUpdate: ModalPostUploadService) {

      this.modalPostUpdate.changeEmitted$.subscribe( data => {
        this.filesUploading = data['filesUploading'];
        this.isLoading = data['isLoading'];
        this.uploadPercent = data['uploadPercent'];
        this.totalFiles = data['totalFiles'];
      })
  }

  ngOnInit(): void {
  }

  /**
   * Method to manage the navigate to certain page
   */
  redirect(): void{
    this.activeModal.close();
    this.router.navigate(['app/inicio']);
  }

}
